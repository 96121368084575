import baseConfig from "./baseconfig.json";

const host = window.location.protocol + "//" + window.location.host;

const domainMapping = baseConfig.domainMapping;
// console.log('domainMapping**',domainMapping);

const currentFlavor =
  Object.keys(domainMapping).find((key) =>
    domainMapping[key].some((domain) => host.includes(domain))
  ) || "default";

// console.log('currentFlavor**',currentFlavor);
// console.log('flavors**',baseConfig.flavors);

const selectedConfig = baseConfig.flavors[currentFlavor] || {};
// console.log('selectedConfig**',selectedConfig);

const conf = {
  ...baseConfig.common,
  ...selectedConfig,
  fleetURL: selectedConfig.url + "/public/fleet",
  baseURL: selectedConfig.url + "/app/",
  fleetURLAdvertise: selectedConfig.url + "/public/fleet/",
};

export default conf;
