import React, { PureComponent } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
  ComposedChart,
  Bar,
  LineChart,
  ReferenceLine,
  Scatter,
} from "recharts";

export default class Example extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  handleButtonClick = (val) => {
    console.log(val);
  };
  render() {
    const fuelGraphData = this.props?.graphData?.map((item) => {
      return {
        name: item.timestamp,
        uv: parseFloat(item.refillfuellevel ? item.refillfuellevel : 0),
        pv: parseFloat(item.refillfuel ? item.refillfuel : 0),
        latitude: item.latitude,
        longitude: item.longitude,
      };
    });

    return (
      <ResponsiveContainer height={200}>
        <ComposedChart
          width={500}
          height={400}
          data={fuelGraphData}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
          onClick={(val) => {
            this.props.mapRef.current.setView(
              [
                val.activePayload[0]?.payload?.latitude,
                val.activePayload[0]?.payload?.longitude,
              ],
              16
            );
          }}
        >
          <Tooltip />

          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="name" />
          <YAxis />

          <Bar
            dataKey="pv"
            barSize={20}
            fill="#56bb32"
            // fill={(data) => {
            //   console.log(data, "data");
            //   // if (data.name === this.props.selectedFuelData?.timestamp) {
            //   //   return "red";
            //   // }
            //   // return "green";
            // }}
            // fill={fuelGraphData.map((val) => {
            //   val.name == this.props.selectedFuelData?.timestamp
            //     ? "red"
            //     : "green";
            // })}
          />
          <Line type="monotone" dataKey="uv" stroke="#8884d8" strokeWidth={4} />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}
