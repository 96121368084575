import React from "react";
import {
  Form,
  Input,
  message,
  Select,
  Button,
  Space,
  Tag,
  Avatar,
  Row,
  Col,
  Dropdown,
  Modal,
  Upload,
  Tooltip,
  Switch,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";

import {
  DeleteFilled,
  PlusOutlined,
  UserOutlined,
  DownOutlined,
  UploadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import companyStore from "../store/CompanyStore";
import DistributorStore from "../store/DistributorStore";
import withRouter from "../withRouter";
import ListTable from "./ListTable";

import DeleteConfirm from "./DeleteConfirm";
import config from "../config";
import CompanyAutocomplete from "./CompanyAutocomplete";
import Cookies from "js-cookie";
import AddAccount from "./AddAccount";
import AccountListFields from "./Plugins/AddAccount/AccountListFields";
import AccountListCertificateDropdown from "./Plugins/AddAccount/AccountListCertificateDropdown";

const { Search } = Input;

class CompanyList extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSearch = this.onSearch.bind(this);

    this.selectDistributor = this.selectDistributor.bind(this);

    this.companyStore = new companyStore(this);
    this.distributorStore = new DistributorStore(this);

    console.log(companyStore, "companyStore");
    this.companyRef = React.createRef();
    this.uploadFormRef = React.createRef();
    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      distributor: "",
      distCompanyId: null,
      distributorType: "",
      status: "",
      company_name: "",
      companyVal_Lab: "",
      emailId: "",
      listUpdated: 0,
      waslStation: false,
      wasl: false,
      companyId: "",

      // export use
      dType: "",
      isadmin: "",
      cid: "",
      cType: "",
      cName: "",
      email: "",

      menuPropsDis: false,
      showUpload: false,

      createdBy: "",

      openAccountForm: false,
      companyIdForEdit: "",
      listDataUpdate: false,
      showStatusConfirm: false,
      isActive: false,
      AccountListFieldComponent: null,
    };
    this.user = Number(this.props.getUserData().isadmin);

    this.waslMenuProps = {
      items: [
        {
          label: (
            <Link to={"/waslcompanylist"}>
              {" "}
              {/* <PlusOutlined  style={{marginRight:"5px"}}/> */}
              {/* WASL */}
              {context.translate("_WASL_")}
            </Link>
          ),
          key: "1",
        },
        {
          label: (
            <Link to={"/waslstation"}>
              {" "}
              {/* <PlusOutlined  style={{marginRight:"5px"}}/> */}
              {/* WASL Station */}
              {context.translate("_WASL_STATION_")}
            </Link>
          ),
          key: "2",
        },
      ],
      onClick: () => {},
    };

    this.columns = [
      {
        // title: "Account Name",
        title: context.translate("_FLEET_COMPANY_COMPANYLIST_COMPANYNAME_"),
        dataIndex: "name",
        width: "15%",
        render: (value, record) => {
          // if (
          //   Number(this.props.getUserData().isadmin) === -1 ||
          //   Number(this.props.getUserData().isadmin) === 3
          // ) {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {value}
            </Link>
          );
          // } else {
          //   return value;
          // }
        },
      },
      {
        title: context.translate("_COMPANY_NAME_ARABIC_"),
        dataIndex: "companyNameArabic",
        width: "15%",
      },
      {
        // title: "Contact Phone",
        title: context.translate("_FLEET_COMPANY_COMPANYLIST_PHONE_"),
        dataIndex: "contact1No",
        width: "15%",
      },
      {
        // title: "Contact Email",
        title: context.translate("_FLEET_COMPANY_COMPANYLIST_EMAIL_"),
        dataIndex: "contact1Email",
        width: "15%",
      },
      {
        // title: "Created By",
        title: context.translate("_CREATED_BY_"),
        dataIndex: "createdBy",
        width: "10%",
        render: (value) => {
          return (
            <span>
              <Avatar
                style={{ backgroundColor: config.userColor, margin: 2 }}
                size={15}
                icon={<UserOutlined />}
              />
              {value}
            </span>
          );
        },
      },
      {
        // title: "Create Date",
        title: context.translate("_CREATE_DATE"),
        dataIndex: "createDate",
        width: "10%",
      },
      {
        // title: "Type",
        title: context.translate("_FLEET_DASHBORD_NEWPOI_TYPE_"),
        dataIndex: "customerDistributor",
        width: "6%",
        render: (value) => {
          if (value == "Customer") {
            return <Tag color={config.typeColor1}>{value}</Tag>;
          }
          if (value == "Distributor") {
            return <Tag color={config.typeColor2}>{value}</Tag>;
          }
        },
      },
      {
        title: context.translate("_AGREEMENT_"),
        dataIndex: "agreement",
        key: "agreement",
        width: "8%",
        align: "center",
        render: (val) => {
          return (
            <DownloadOutlined
              onClick={() => {
                this.companyStore.downloadTradeLicense(val);
              }}
            />
          );
        },
      },
      {
        title: context.translate("_TRADE_LICENSE_"),
        dataIndex: "tradelicense",
        key: "tradelicense",
        width: "10%",
        align: "center",
        render: (val) => {
          return (
            <DownloadOutlined
              onClick={() => {
                this.companyStore.downloadTradeLicense(val);
              }}
            />
          );
        },
      },
      {
        // title: "Status",
        title: context.translate("_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"),
        dataIndex: "status2",
        width: "7%",
        render: (mainValue) => {
          let value = Number(mainValue);

          if (value === 1) {
            return (
              <Tag
                style={{ width: "56px", textAlign: "center" }}
                color={config.deleteColor}
              >
                Expired
              </Tag>
            );
          } else if (value === 2) {
            return (
              <Tag
                style={{ width: "87px", textAlign: "center" }}
                color={config.ExpirySoonColor}
              >
                Expiring Soon
              </Tag>
            );
          } else if (value === 3) {
            return (
              <Tag
                style={{ width: "56px", textAlign: "center" }}
                color={config.activeColor}
              >
                Active
              </Tag>
            );
          } else if (value === 4) {
            return (
              <Tag
                style={{ width: "56px", textAlign: "center" }}
                color={config.inActiveColor}
              >
                Inactive
              </Tag>
            );
          }
        },
      },

      {
        // title: "Action",
        title: context.translate("_ACTION_"),
        dataIndex: "Action",
        fixed: "right",
        width: "6%",
        render: (_, record) => {
          return (
            <>
              {(Number(this.props.getUserData().isadmin) === -1 ||
                Number(this.props.getUserData().isadmin) === 3) && (
                <>
                  <DeleteConfirm
                    onConfirm={(e) => {
                      this.onDelete(record);
                    }}
                  >
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <Tooltip
                        // title="Delete Account"
                        title={this.context.translate("_DELETE_ACCOUNT_")}
                        placement="bottom"
                        color={config.tooltipColorDelete}
                      >
                        <DeleteFilled style={{ color: config.deleteColor }} />
                      </Tooltip>
                    </Link>
                  </DeleteConfirm>

                  <Tooltip
                    title={
                      record.status == "1"
                        ? this.context.translate("_ACTIVE_")
                        : this.context.translate(
                            "_FLEET_USER_USERLIST_STATUS_INACTIVE_"
                          )
                    }
                    placement="top"
                    color={
                      record.status == "1"
                        ? config.activeColor
                        : config.inActiveColor
                    }
                  >
                    <Switch
                      size="small"
                      className="form-offlineAlerts"
                      checked={Number(record.status)}
                      // checkedChildren="Yes"
                      // unCheckedChildren="No"
                      // checkedChildren={this.context.translate(
                      //   "_ACTIVE_"
                      // )}
                      // unCheckedChildren={this.context.translate(
                      //   "_IN_ACTIVE_"
                      // )}
                      onChange={async (val) => {
                        this.setState({ companyId: record.companyId });
                        this.setState({ isActive: val });
                        this.setState({ showStatusConfirm: true }); // open modal
                      }}
                      // defaultChecked
                    />
                  </Tooltip>
                  {/* </Space> */}
                </>
              )}
            </>
          );
        },
      },
    ];
    console.log("Scal", typeof this.user);
    let column = this.columns;
    if (this.user === -1 || this.user === 3) {
      column = this.columns;
    } else {
      column = this.columns.filter((val) => val.dataIndex !== "Action");
    }
    this.columns = column;

    let hideColumns = this.columns;
    if (
      config.project === "awtltrack" ||
      config.project === "starlingeye" ||
      config.project === "trackeazy"
    ) {
      hideColumns = this.columns?.filter(
        (val) =>
          val.dataIndex !== "tradelicense" && val.dataIndex !== "agreement"
      );
      // console.log(hideColumns,"hideColumns",config.project )
    }
    this.columns = hideColumns;
  }
  closeModal = () => {
    this.setState({ openAccountForm: false, companyIdForEdit: "" });
  };
  onEdit = async (record) => {
    console.log(record);
    // console.log(record.companyId)
    // this.context.viewStateStore.setAccountStates({distributor:record.createdBy,filterByType:record.customerDistributor,status:record.status,companyId:record.companyId,emailId:record.contact1Email  })

    await this.context.viewStateStore.setAccountStates({
      distributorType: this.state.distributorType,
      companyId: this.state.companyId,
      createdBy: this.state.createdBy,
      distCompanyId: this.state.distCompanyId,
      filterByType: this.state.distributorType,
      status: this.state.status,
      emailId: this.state.emailId,
    });

    //  await this.companyStore.setEditedRecordId(record.companyId)
    // await this.props.navigate("/addaccount/" + record.companyId);
    await this.setState({ companyIdForEdit: record.companyId });
    await this.setState({ openAccountForm: true });
  };

  async onDelete(record) {
    const response = await this.companyStore.deleteRecord({
      companyId: record.companyId,
    });

    if (response?.data?.success === "S") {
      message.success(response.data.message);

      this.handleSearch({ distCompanyId: null });
    } else {
      message.error(response.data.message);
    }
  }
  onExcelSubmit = async (fieldsValue) => {
    let userId = JSON.parse(base64_decode(Cookies.get("data"))).userId;
    const resp = await this.companyStore.accountImport(
      this.state.excelFile,
      userId
    );
    console.log(resp.message);
    if (resp?.success == "1") {
      message.success(resp.message);
      this.setState({ showUpload: false });
      this.setState({ excelFile: "" });
    } else {
      message.error(resp.message);
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    if (Number(this.props.getUserData().isadmin) === 0) {
      // console.log('innnnnn');
      this.props.navigate("/login");
    }

    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const viewStateStoreArray = this.context.viewStateStore.accountStatesObj;

      console.log(viewStateStoreArray, "arr");
      const distCompanyId = viewStateStoreArray.distCompanyId;

      const companyId = viewStateStoreArray.companyId;
      const distributorType = viewStateStoreArray.distributorType
        ? viewStateStoreArray.distributorType
        : null;
      await this.setState({ distributorType: distributorType });
      const status =
        viewStateStoreArray.status >= 0 ? viewStateStoreArray.status : null;

      console.log(status);
      await this.setState({ status: status });
      const emailId = viewStateStoreArray.emailId
        ? viewStateStoreArray.emailId
        : null;
      console.log(distCompanyId, companyId);
      await this.setState({ distCompanyId, companyId });
      const singleData = await this.companyStore.getCompanyDataById(
        viewStateStoreArray.companyId
      );

      if (distCompanyId > 0) {
        await this.setState({
          companyVal_Lab: singleData,
          distributorType: distributorType,
          status: status,
          emailId: emailId,
        });
        await this.handleSearch({
          distCompanyId,
          companyId,
          distributorType,
          status,
          emailId,
        });
        console.log("adsf");
      } else {
        await this.setState({
          companyVal_Lab: singleData,
          distributorType,
          status: status,
          emailId: emailId,
        });
        await this.handleSearch({
          distCompanyId: null,
          companyId,
          distributorType: null,
          status,
          emailId,
        });
      }
      this.setState({ listDataUpdate: false });
    }
  }
  async componentDidMount() {
    // console.log(Cookies.get("data"))
    // const d = Cookies.get("data")
    // let decode = base64_decode(d);
    // console.log(decode)
    // await this.companyStore.loadData({ offset: 0, limit: 50 });

    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log("h");
      this.setState({ companyId: this.props.getUserData().userCompanyId });
    }
    //console.log(Number(this.props.getUserData().isadmin));

    this.ExportMenuProps = {
      items: [
        {
          // label: "Excel",
          label: this.context.translate("_EXCEL_"),
          key: "excel",
        },
        // {
        //   // label: "PDF",
        //   label: this.context.translate("_PDF_"),
        //   key: "pdf",
        // },
      ],
      onClick: (val) => {
        const result = [];
        this.columns.map((obj) => {
          if (obj.dataIndex === "status2") {
            result.push("status");
          } else {
            result.push(obj.dataIndex);
          }
        });
        //  console.log(result.toString())
        var getData = {
          dType: this.state.dType,
          isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
          cid: this.state.companyId,
          cType: this.state.distributorType,
          cName: this.state.cName,
          email: this.state.emailId,
          colList: result,
          cstatus: this.state.status,
        };
        console.log(getData);
        if (val.key == "excel") {
          this.companyStore.exportExcel(getData);
        }
        if (val.key == "pdf") {
          this.companyStore.exportPdf(getData);
        }
      },
    };

    await this.distributorStore.distributorList();
    console.log(this.distributorStore.distributors);

    // console.log(viewStateStoreArray)
    //     await this.companyStore.loadData({
    //       offset: (this.state.currentPage - 1) * this.state.pageSize,
    //       limit: this.state.pageSize,
    //       distributor: viewStateStoreArray.distributor,
    //       emailId: viewStateStoreArray.emailId,
    //       status: viewStateStoreArray.status,
    //       company_name: "",
    //       distributorType: "",
    //       searchCompanyId: "",
    //       companyId: viewStateStoreArray.companyId,
    //     });
    //     console.log(viewStateStoreArray)
    //     await this.setState({distributor:[{label:viewStateStoreArray.createdBy ,value: viewStateStoreArray.distributor}]})

    // this.context.viewStateStore.setAccountStates({distributor:viewStateStoreArray.distributor,filterByType:record.customerDistributor,status:record.status,companyId:record.companyId,emailId:record.contact1Email  })
    // if(viewStateStoreArray.createdBy ){
    //   console.log("adsf",{label:viewStateStoreArray.createdBy ,value: viewStateStoreArray.distributor})
    //   await this.setState({distributor:[{label:viewStateStoreArray.createdBy ,value: viewStateStoreArray.distributor}]})
    // }else{
    //   // await this.setState({distributor:""})
    //   console.log("hello")

    // }
    // await this.setState({distributor:[{label:viewStateStoreArray.createdBy ,value: viewStateStoreArray.distributor}]})
    await this.handleSearch({ distCompanyId: null, distributorType: null });
    await this.setState({ listUpdated: 1 }); //need to reload data

    // this.handleSearch()
    this.setState({ pageSize: 50 });
    //

    // console.log(this.companyStore.editedRecord_Id,"idd")
    // this.setState({companyId:this.companyStore.editedRecord_Id})

    //this.importComponent();
  }
  importComponent = async () => {
    console.log("importComponent");

    try {
      const component = await import(
        `./Plugins/${config.project}_AddAccount/AccountListFields`
      );
      this.setState({ AccountListFieldComponent: component.default });
    } catch (error) {
      console.error("Error loading AccountListFields component:", error);
    }
  };

  async handleTableChange(pagination, filters, sorter) {
    //   console.log(pagination);
    console.log(pagination, "paadsadsfsg");
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch({
      distCompanyId: null,
      companyId: null,
      status: null,
      emailId: null,
      distributor: null,
      distributorType: null,
    });
  }

  async selectDistributor(value, option) {
    console.log("distributor", option);
    await this.setState({
      distCompanyId: value,
      createdBy: option.name,
      currentPage: 1,
    });
    // await this.setState({ distributorType: [{value:value,label:option.createdBy}] });

    await this.handleSearch({
      offset: 0,
      distCompanyId: null,
      companyId: null,
      status: null,
      emailId: null,
      distributor: null,
      distributorType: null,
    });
  }

  async handleChange(comp) {
    console.log(comp);
    // console.log(comp,this.props)
    this.setState({ cName: comp.label, companyId: comp.value, currentPage: 1 });
    let companyId = -1;
    if (comp !== undefined) {
      companyId = comp.value;
    }
    const singleData = await this.companyStore.getCompanyDataById(companyId, 1);
    console.log(singleData);
    await this.setState({ companyVal_Lab: singleData });

    await this.setState({ currentPage: 1, companyId: companyId });
    await this.handleSearch({
      distCompanyId: null,
      companyId: companyId,
      status: null,
      emailId: null,
    });
  }

  async onSearch(value) {
    console.log(value);
    await this.setState({ emailId: value, pageSize: 50, currentPage: 1 });
    this.handleSearch({
      distCompanyId: null,
      companyId: null,
      status: null,
      emailId: null,
      distributor: null,
      distributorType: null,
    });
  }

  async handleSearch({
    distCompanyId,
    companyId,
    status,
    emailId,
    distributor,
    distributorType,
    offset,
  }) {
    console.log(typeof status, "handlesearch", offset);
    console.log(companyId);
    this.setState({ loading: true });
    await this.companyStore.loadData({
      offset:
        // offset == 0
        //   ? offset
        //   : (this.state.currentPage - 1) * this.state.pageSize,

        (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      distCompanyId:
        distCompanyId > 0 && distCompanyId !== null
          ? distCompanyId
          : this.state.distCompanyId,
      emailId: emailId ? emailId : this.state.emailId,
      status: this.state.status,
      company_name: this.state.cName,
      distributorType: this.state.distributorType,
      searchCompanyId: companyId > 0 ? companyId : this.state.companyId,
      cname: this.state.cName,
      // companyId: 358,
    });
    // console.log(this.state.distributor)
    //  await this.context.viewStateStore.setAccountStates({createdBy:this.state.createdBy,distCompanyId:this.state.distCompanyId,filterByType:this.state.distributorType,status:this.state.status,companyId:this.state.companyId,emailId:this.state.emailId  })

    await this.setState({ listUpdated: 1 }); //need to reload data
  }
  handleClear = async () => {
    console.log("adsf");
    await this.setState({ companyVal_Lab: null, companyId: "", cName: "" });

    await this.companyStore.loadData({
      companyId: "1",
      isadmin: "-1",
      offset: 0,
      limit: this.state.pageSize,
    });
    await this.setState({ loadData: 1 });
  };

  getUploadData = async (fileUp) => {
    let fileinfo = fileUp.file.response;

    console.log(fileinfo);

    if (fileinfo?.success == "S") {
      // console.log(fileinfo.data)

      await this.setState({ excelFile: fileinfo.data });
    }

    this.setState({ update: 1 });
  };

  responseMsg = async (response) => {
    // console.log(resp);
    if (response?.success === "S") {
      message.success(response.message);
      // this.props.navigate("/companylist");
      this.setState({ openAccountForm: false });
      this.setState({ listDataUpdate: true });
    } else {
      if (response.errors[0].companyname) {
        message.error(this.context.translate(response.errors[0]?.companyname));
      }
      if (response.errors[0]?.username) {
        message.error(this.context.translate(response.errors[0]?.username));
      }
      this.setState({ loading: false });
    }
  };

  confirmStatus = async (status) => {
    // alert("Work In Progress");
    // this.companyStore?.companies = [];
    const data = {};

    data.companyId = this.state.companyId;
    data.activeStatus = this.state.isActive == true ? 1 : 0;

    await this.companyStore.updateStatus(data);

    this.setState({ showStatusConfirm: false });

    await this.setState({ loading: true });
    await this.companyStore.loadData({ offset: 0, limit: 50 });

    await this.setState({ loading: false });
  };
  cancelConfirm = async (response) => {
    this.setState({ showStatusConfirm: false });
  };
  setshowUpload = (val) => {
    this.setState({ showUpload: val });
  };

  render() {
    //console.log('in render1')
    const { AccountListFieldComponent } = this.state;
    return (
      <>
        <div style={{ padding: 0 }}>
          {(Number(this.props.getUserData().isadmin) === -1 ||
            Number(this.props.getUserData().isadmin) === 3) && (
            <Row className="headerFixed" style={{ height: "50px" }}>
              <Col span={12}>
                {/* <div className="heading">Account List</div> */}
                <div className="heading">
                  {this.context.translate("_ACCOUNT_LIST_")}
                </div>
              </Col>
              <Col
                span={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Space style={{}}>
                  {/* <AddButton
                link="/useradd"
                title={this.context.translate("_ADD_")}
              /> */}

                  <Space
                    style={{
                      float:
                        this.context.locale.direction == "ltr"
                          ? "right"
                          : "left",
                    }}
                  >
                    {/* <AddButton
                  link="/addaccount"
                  title={this.context.translate("_ADD_")}
                /> */}
                    <Tooltip
                      placement="left"
                      // title="Add"
                      title={this.context.translate(
                        "_FLEET_DRIVER_DRIVERLIST_ADDBTN_"
                      )}
                      color={config.primaryColor}
                    >
                      <Button
                        className="addButton"
                        icon={<PlusOutlined />}
                        type="primary"
                        onClick={() =>
                          this.setState({
                            openAccountForm: true,
                            companyIdForEdit: "",
                          })
                        }
                      ></Button>
                    </Tooltip>

                    {/* <Dropdown menu={this.waslMenuProps}>
                      <Button>
                        <Space>
                         
                          {this.context.translate("_WASL_")}
                          <DownOutlined />
                        </Space>
                      </Button>
                    </Dropdown> */}

                    {/*<Button  ><PlusOutlined />WASL</Button>

                <Button  ><PlusOutlined />WASL Station</Button>*/}
                    {/* <Button>
                  <CSVLink data={this.companyStore?.companies}>
                    <DownloadOutlined></DownloadOutlined>{" "}
                  </CSVLink>
                </Button> */}

                    <Dropdown
                      menu={this.ExportMenuProps}
                      disabled={
                        this.companyStore?.companies?.length > 0 ? false : true
                      }
                    >
                      <Button className="exportDropdown">
                        <Space>
                          {/* Export */}
                          {this.context.translate("_EXPORT_")}
                          <DownOutlined />
                        </Space>
                      </Button>
                    </Dropdown>

                    {AccountListFields ? (
                      <AccountListFields
                        setshowUpload={this.setshowUpload}
                        menu={this.waslMenuProps}
                      />
                    ) : null}

                    {/* <Tooltip
                      //  title="Upload"
                      title={this.context.translate("_UPLOAD_")}
                      color={config.tooltipColorEdit}
                    >
                      <Button
                        type={config.uploadBoxType}
                        onClick={() => this.setState({ showUpload: true })}
                      >
                        <UploadOutlined />
                      </Button>
                    </Tooltip> */}
                  </Space>
                </Space>
              </Col>
            </Row>
          )}
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          {(Number(this.props.getUserData().isadmin) === -1 ||
            Number(this.props.getUserData().isadmin) === 3) && (
            <Row className="listInputRow">
              <Col span={16}>
                <Space>
                  <CompanyAutocomplete
                    ref={this.companyRef}
                    onChange={(val) => {
                      this.handleChange(val);
                      console.log(val);
                    }}
                    // onChange={(val)=>{console.log(val.target.value)}}
                    value={
                      this.state.companyVal_Lab
                        ? this.state.companyVal_Lab
                        : null
                    }
                    // onSelect={(val)=>console.log(val)}
                    // value={this.state.companyId}
                    // onSearch={(val)=>{this.setState({company_name:val});console.log(val)}}
                    allowClear={true}
                    onClear={this.handleClear}
                    style={{ width: 165 }}
                    fromAccountList={1}
                  />
                  {Number(this.props.getUserData().isadmin) === -1 && (
                    <Select
                      style={{
                        width: 170,
                      }}
                      fieldNames={{ label: "name", value: "companyId" }}
                      value={
                        this.state.distCompanyId
                          ? this.state.distCompanyId
                          : null
                      }
                      // placeholder=" Select Distributor1"
                      placeholder={this.context.translate(
                        "_SELECT_DISTRIBUTOR1_"
                      )}
                      onChange={async (val, option) => {
                        await this.selectDistributor(val, option);
                        await this.setState({ distCompanyId: val });
                      }}
                      optionFilterProp="children"
                      options={this.distributorStore?.distributors}
                    />
                  )}

                  {Number(this.props.getUserData().isadmin) === -1 && (
                    <Select
                      style={{
                        width: 180,
                      }}
                      value={
                        this.state.distributorType
                          ? this.state.distributorType
                          : null
                      }
                      placeholder={this.context.translate("_FILTER_TYPE_")}
                      optionFilterProp="children"
                      onSelect={async (value) => {
                        await this.setState({
                          distributorType: value,
                          currentPage: 1,
                        });
                        await this.handleSearch({
                          distCompanyId: null,
                          companyId: null,
                          distributorType: null,
                          status: null,
                        });
                      }}
                      options={[
                        {
                          value: "All",
                          label: "All",
                          // label:this.context.translate("_ALL_")
                        },
                        {
                          value: "Customer",
                          label: "Customer",
                          // label:this.context.translate("_FLEET_CUSTOMER_")
                        },
                        {
                          value: "Distributor",
                          label: "Distributor",
                          // label:this.context.translate("_DISTRIBUTOR_")
                        },
                      ]}
                    />
                  )}

                  {Number(this.props.getUserData().isadmin) === 3 && (
                    <Select
                      style={{
                        width: 180,
                      }}
                      value={
                        this.state.distributorType
                          ? this.state.distributorType
                          : null
                      }
                      placeholder={this.context.translate("_FILTER_TYPE_")}
                      optionFilterProp="children"
                      onSelect={async (value) => {
                        await this.setState({
                          distributorType: value,
                          currentPage: 1,
                        });
                        await this.handleSearch({
                          distCompanyId: null,
                          companyId: null,
                          distributorType: null,
                          status: null,
                        });
                      }}
                      options={[
                        {
                          value: "All",
                          label: "All",
                          // label:this.context.translate("_ALL_")
                        },
                        {
                          value: "Customer",
                          label: "Customer",
                          // label:this.context.translate("_FLEET_CUSTOMER_")
                        },
                        // {
                        //   value: "Distributor",
                        //   label: "Distributor",
                        //   // label:this.context.translate("_DISTRIBUTOR_")
                        // },
                      ]}
                    />
                  )}

                  <AccountListCertificateDropdown />

                  <Select
                    style={{
                      width: 150,
                    }}
                    placeholder={this.context.translate(
                      "_FLEET_USER_USERLIST_STATUS_"
                    )}
                    value={
                      this.state.status !== "" ? this.state.status : undefined
                    }
                    optionFilterProp="children"
                    onSelect={async (value) => {
                      await this.setState({ status: value, currentPage: 1 });
                      await this.handleSearch({
                        distCompanyId: null,
                        companyId: null,
                        distributorType: null,
                        status: null,
                      });
                      await this.setState({ listUpdated: 1 });
                    }}
                    options={[
                      {
                        value: 0,
                        label: "All",
                        // label:this.context.translate("_ALL_")
                      },
                      {
                        value: 1,
                        label: "Expired",
                        // label:this.context.translate("_EXPIRED_")
                      },
                      {
                        value: 2,
                        label: "Expiring Soon",
                        // label:this.context.translate("_EXPIRINGSOON_")
                      },
                      {
                        value: 3,
                        label: "Active",
                        // label:this.context.translate("_FLEET_USER_USERLIST_STATUS_ACTIVE_")
                      },
                      {
                        value: 4,
                        label: "Inactive",
                        // label:this.context.translate("_FLEET_USER_USERLIST_STATUS_INACTIVE_")
                      },
                    ]}
                  />

                  <Search
                    onChange={(e) => this.setState({ emailId: e.target.value })}
                    style={{
                      width: 300,
                    }}
                    value={this.state.emailId ? this.state.emailId : ""}
                    // placeholder="Email Id"
                    placeholder={this.context.translate(
                      "_FLEET_USER_USERLIST_EMAIL_"
                    )}
                    allowClear
                    onSearch={this.onSearch}
                    enterButton
                  />
                </Space>
              </Col>
            </Row>
          )}
          <ListTable
            currentPage={this.state.currentPage}
            total={this.companyStore?.total}
            columns={this.columns}
            dataSource={this.companyStore?.companies}
            pageSize={this.state.pageSize}
            // pagination={{

            //   total:this.companyStore.total || 0

            // }}
            loading={this.companyStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        {/* {this.state.showUpload && (
          <Modal
            open={this.state.showUpload}
            maskClosable={false}
            // title="File Upload"
            title={this.context.translate("_FILE_UPLOAD_")}
            onOk={() => this.setState({ showUpload: false })}
            onCancel={() => {
              this.setState({ showUpload: false, excelFile: "" });
            }}
            footer={false}
            width={400}
          >
            <Form
              ref={this.uploadFormRef}
              name="excelimport"
              layout="vertical"
              onFinish={this.onExcelSubmit}

              // className="formClass"
            >
              <Form.Item
                name="excelFile"
                // label="Excel File"
                style={{ marginTop: "15px" }}
                rules={[{ required: true, message: "Please enter excel file" }]}
              >
                <Upload
                  name="excelFile"
                  action={config.baseURL + "/index.php?c=company&a=fileupload"}
                  listType="text"
                  data={{ fileType: "accountimport", fieldName: "excelFile" }}
                  onChange={this.getUploadData}
                  maxCount={1}
                >
                  <Button icon={<UploadOutlined />}>
                    {this.context.translate("_CLICKTOUPLOAD_EXCELFILE_")}
                  </Button>
                </Upload>
              </Form.Item>

              <div className="formBtnGroup">
                <Button
                  className="formCancelButton"
                  // style={{ backgroundColor: config.activeColor }}
                  onClick={() => this.companyStore.sampleExcel()}
                >
                  {this.context.translate("_SAMPLE_")}
                </Button>

                <Button
                  type="primary"
                  className="formSaveButton"
                  htmlType="submit"
                  // loading={this.state.loading}
                >
                  {this.context.translate("_UPLOAD_")}
                 
                </Button>
              </div>
             
            </Form>
          </Modal>
        )} */}
        {this.state.openAccountForm && (
          <Modal
            // bodyStyle={{ height: "90%" }}
            className="formShowModal" //  {/* css class */}
            maskClosable={false}
            // title="Account Details"
            title={this.context.translate(
              "_FLEET_COMPANY_COMPANYDETAIL_TITLE_"
            )}
            centered
            visible={this.state.openAccountForm}
            footer={null}
            onCancel={() => {
              this.setState({ openAccountForm: false, companyIdForEdit: "" });
            }}
            width={665}
          >
            <div>
              <AddAccount
                companyIdForEdit={this.state.companyIdForEdit}
                closeModal={this.closeModal}
                response={this.responseMsg}
              />
            </div>
          </Modal>
        )}

        {this.state.showStatusConfirm && (
          <Modal
            maskClosable={false}
            title={this.context.translate("_CONFIRM_")}
            description="Are you sure to change this status?"
            centered
            visible={this.state.showStatusConfirm}
            //footer={null}
            onOk={this.confirmStatus}
            onCancel={() => {
              this.setState({ showStatusConfirm: false });
            }}
            width={400}
          >
            {this.state.isActive === true ? (
              <p>{this.context.translate("_STATUS_ACTIVE_")}</p>
            ) : (
              <p>{this.context.translate("_STATUS_INACTIVE_")}</p>
            )}
          </Modal>
          // <Popconfirm
          //   open={this.state.showStatusConfirm}
          //   placement="top"
          //   title="Confirm"
          //   description="Are you sure to change this status?"
          //   onConfirm={this.confirmStatus}
          //   onCancel={this.cancelConfirm}
          //   okText="Yes"
          //   cancelText="No"
          //   overlayStyle={{ alignItems: 'center', justifyContent: 'center', top: '10%', transform: 'translateY(-50%)' }}

          // >
          // </Popconfirm>
        )}
      </>
    );
  }
}
CompanyList.contextType = StoreContext;
export default observer(withRouter(CompanyList));
