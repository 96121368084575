import React from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Space,
  Dropdown,
  message,
  Select,
  Tooltip,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";
import { DeleteFilled, DownOutlined, SearchOutlined } from "@ant-design/icons";

import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";

import DeleteConfirm from "./DeleteConfirm";
import config from "../config";
import withRouter from "../withRouter";

import ParentStore from "../store/ParentStore";
import NotificationStore from "../store/NotificationStore";
import ParentsAdd from "./ParentsAdd";

class ParentList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.onDelete = this.onDelete.bind(this);

    this.parentStore = new ParentStore();
    this.notificationStore = new NotificationStore();

    // console.log(this.context);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      openConfirm: false,
      confirmLoading: false,
      companyId: "",
      loadList: 0,
      openParentAddForm: false,
      parentId: "",
      listDataUpdate: false,
      companyFieldHighlight: false,
      searchField: "",
      classId: "",
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      await this.handleSearch();
    }
  }
  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
      await this.handleSearch();
    }
    await this.notificationStore.getAllClass();
    this.setState({ pageSize: 50 });
    // await this.gradTimingStore.loadData({ offset: 0, limit: 50 });
    //this.handleSearch();
    this.columns = [
      {
        // title: "Students Name",
        title: this.context.translate("_STUDENTS_NAME_"),
        width: 150,
        dataIndex: "studentName",
        key: "studentName",
        fixed: "left",
        render: (val, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {val}
            </Link>
          );
        },
      },
      {
        // title: "Class",
        title: this.context.translate("_CLASS_"),
        width: 150,
        dataIndex: "className",
        key: "className",
        fixed: "left",
      },
      {
        // title: "Parents Name",
        title: this.context.translate("_PARENTS_NAME_"),
        width: 150,
        dataIndex: "username",
        key: "username",
        fixed: "left",
      },
      {
        // title: "Mobile No",
        title: this.context.translate(
          "_FLEET_DASHBORD_VEHICLE_INFORMATION_MOBILE_"
        ),
        width: 150,
        dataIndex: "mobileno",
        key: "mobileno",
        fixed: "left",
      },
      {
        // title: "Password",
        title: this.context.translate("_FLEET_LOGIN_PASSWORDLBL_"),
        width: 150,
        dataIndex: "password",
        key: "password",
        fixed: "left",
      },
      {
        // title: "Email Id",
        title: this.context.translate("_FLEET_USER_USERLIST_EMAIL_"),
        width: 150,
        dataIndex: "emailId",
        key: "emailId",
        fixed: "left",
      },

      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "Action",
        key: "Action",
        fixed: "right",
        width: 50,
        render: (_, record) => {
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip 
                  // title="Edit"
                  title={this.context.translate("_FLEET_COMPANY_COMPANYLIST_EDIT_")}
                   placement="bottom" color={config.tooltipColorEdit}>
                    <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Link>
                    <Tooltip
                      //  title="Delete"
                      title={this.context.translate(
                        "_FLEET_COMPANY_COMPANYLIST_DELETE_"
                      )}
                      placement="bottom"
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];

    this.ExportMenuProps = {
      items: [
        {
          // label: "Excel",
          label: this.context.translate("_EXCEL_"),
          key: "excel",
        },
        {
          // label: "PDF",
          label: this.context.translate("_PDF_"),
          key: "pdf",
        },
      ],
      onClick: (val) => {
        if (!this.state.companyId) {
          console.log(
            this.state.companyId,
            "sfsdgdfgdfhfg",
            this.state.companyFieldHighlight
          );
          this.setState({ companyFieldHighlight: true });
        } else {
          this.setState({ companyFieldHighlight: false });
        }
        if (this.state.companyId) {
          this.setState({ companyFieldHighlight: false, loading: true });
          const result = [];
          this.columns.map((obj) => result.push(obj.dataIndex));
          //  console.log(result.toString())
          var getData = {
            cid: this.state.companyId,
            searchField: this.state.searchField,
            colList: result,
            classId: this.state.classId,
          };
          console.log(getData);
          if (val.key == "excel") {
            this.parentStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.parentStore.exportPdf(getData);
          }
        }
      },
    };
  }

  // async componentDidMount() {
  //   await this.notificationStore.getAllClass();
  //   // await this.gradTimingStore.loadData({ offset: 0, limit: 50 });
  //   //this.handleSearch();

  //   this.setState({ pageSize: 50 });

  // }
  onEdit = async (record) => {
    console.log(record);
    console.log(record.parentId);
    const parentId = record.parentId;
    if (parentId) {
      // this.props.navigate("/parentadd/" + parentId);
      await this.setState({ parentId: parentId });
      await this.setState({ openParentAddForm: true });
    }
  };
  async handleTableChange(pagination, filters, sorter) {
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
    console.log(comp);
    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.parentStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }

  searchInput = async (e) => {
    const value = e.target.value;

    // await this.setState({searchField:value})
    if (value.length > 3) {
      await this.parentStore.loadData({
        offset: 0,
        limit: this.state.pageSize,
        searchField: value,
        companyId: this.state.companyId,
      });

      await this.setState({ loadList: 1 });
    } else if (value.length === 0) {
      await this.parentStore.loadData({
        offset: 0,
        limit: this.state.pageSize,
        // searchField: value,
        companyId: this.state.companyId,
      });
      await this.setState({ currentPage: 1 });
    }
  };

  async onDelete(record) {
    console.log(record);
    const response = await this.parentStore.deleteRecord({
      // passengerId : record.passengerId
      parentId: record.parentId,
    });
    // console.log(response.data.success)
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }
  fillClassName = () => {
    var classNames = [];
    classNames.push("KG1", "KG2");
    for (let i = 1; i <= 12; i++) {
      classNames.push("Grade" + i);
    }
    if (classNames) {
      return classNames.map((value, index) => {
        return <Select.Option value={index + 1}>{value}</Select.Option>;
      });
    }
  };
  responseMsg = async (response) => {
    if (response?.success === "S") {
      await message.success(response.message);
      // this.props.navigate("/parentlist");

      await this.setState({ openParentAddForm: false });
      this.handleSearch();
      await this.setState({ listDataUpdate: true });
      // await this.parentStore.loadData({
      //   offset: (this.state.currentPage - 1) * this.state.pageSize,
      //   limit: this.state.pageSize,
      //   searchField: this.state.searchField,
      //   companyId: this.state.companyId,
      // });
    } else {
      message.error(response.message);
    }
  };

  closeModal = () => {
    this.setState({ openParentAddForm: false });
  };

  render() {
    const companyInputBox = this.state.companyFieldHighlight
      ? { border: "2px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    console.log("in render");
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_PARENTS_LIST_")}
                {/* Parents List */}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space style={{}}>
                {/* <AddButton title={this.context.translate('_ADD_')} link="/parentadd" /> */}
                <Dropdown
                  // disabled={
                  //   this.parentStore?.parents?.length > 0 ? false : true
                  // }
                  menu={this.ExportMenuProps}
                  // disabled={
                  //   this.companyStore?.companies.length > 0 ? false : true
                  // }
                >
                  <Button
                    className="exportDropdown"
                    // style={{ backgroundColor: config.activeColor }}
                  >
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </div>

        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          {/* <div style={{padding:"24px"}}> */}
          {/* <div className='heading'>Parents List</div> */}
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  style={companyInputBox}
                  // allowClear={true}
                  // style={{ width: 200 }}
                />
                <Input
                  // placeholder="Search by Student Name,Parent Name,Mobile No,Email Id"
                  placeholder={this.context.translate(
                    "_SEARCH_BY_STUDENT_PARENT_MOBILE_EMAIL_"
                  )}
                  style={{ width: 500 }}
                  onChange={async (e) => {
                    await this.setState({ searchField: e.target.value });
                    await this.searchInput(e);
                  }}
                />

                <Select
                  style={{ width: 125 }}
                  name="classId"
                  placeholder={this.context.translate("_SELECT_CLASS_")}
                  // placeholder="Select Class"
                  onSelect={async (clsId) => {
                    await this.parentStore.loadData({
                      offset: 0,
                      classId: clsId,
                      limit: this.state.pageSize,
                      // searchField: value,
                      companyId: this.state.companyId,
                    });
                    await this.setState({ loadList: 1, classId: clsId });
                  }}
                  // placeholder={this.context.translate("")}

                  // onChange={async (val) => {
                  //   await this.setState({ classId: val });
                  //   console.log(val);
                  // }}
                  fieldNames={{ value: "classId", label: "className" }}
                  optionFilterProp="children"
                  options={this.notificationStore?.getClass}
                />

                {/* <Select placeholder=" Class Name">
                  {this.fillClassName()}
                </Select> */}
                <Button
                  icon={<SearchOutlined />}
                  type="primary"
                  onClick={() => {
                    this.handleSearch();
                  }}
                />
              </Space>
            </Col>
            {/* <Col span={8}>
            <Space style={{ float: this.context.locale.direction == "ltr" ? "right" : "left" }}>
              <AddButton title={this.context.translate('_ADD_')} link="/parentadd" />
              <Button>
                <ExportOutlined />
              </Button>
            </Space>
          </Col> */}
          </Row>

          <ListTable
            current={this.state.currentPage}
            total={this.parentStore?.total}
            columns={this.columns}
            dataSource={this.parentStore?.parents}
            loading={this.parentStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        {this.state.openParentAddForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal" //  {/* css class */}
            // title="Parents Details"
            title={this.context.translate("")}
            centered
            visible={this.state.openParentAddForm}
            footer={null}
            onCancel={() => {
              this.setState({ openParentAddForm: false });
            }}
            width={665}
          >
            <div>
              {/* <AssignVehicleToUser
                  companyId={this.state.companyId}
                  userId={this.state.assignId}
                  response={this.assignresponseMsg}
                /> */}
              <ParentsAdd
                parentId={this.state.parentId}
                response={this.responseMsg}
                closeModal={this.closeModal}
              />
            </div>
          </Modal>
        )}
      </>
    );
  }
}
ParentList.contextType = StoreContext;
export default observer(withRouter(ParentList));
