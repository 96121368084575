import { encode as base64_encode } from "base-64";
import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class VideoMonitoringStore {
  deviceListArray = [];
  playbackData = [];

  constructor(rootStore) {
    makeObservable(this, {
      deviceListArray: observable,
      playbackData: observable,
      getDevices: action,
      getPlayBackVideoNew: action,
      getPlaybackData: action,
      stopBsjVideo: action,
      getPlaybackVideoData: action,
      bsjVideoMonitoring: action,
    });
    this.rootStore = rootStore;
  }

  async getDevices(data) {
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=videoStreaming&a=getdevices2",
        "postData=" + base64Input
      );

    this.deviceListArray = response.data.data?.vehicles;

    return response.data.data?.vehicles;
  }

  async getPlayBackVideoNew(data) {
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=videoStreaming&a=getPlayBackVideoNew",
        "postData=" + base64Input
      );
    return response.data.data[0];
  }

  async getPlaybackData(data) {
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=videoStreaming&a=getbsjdata",
        "postData=" + base64Input
      );

    this.playbackData = response?.data?.data;
    return response;
  }

  async getPlaybackVideoData(data) {
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=videoStreaming&a=bsjPlaybackVideoMonitoring",
        "postData=" + base64Input
      );
    return response?.data?.data;
  }

  async stopBsjVideo(data) {
    var postData = {
      channelId: data.logicalChannelNumber,
      full_imei: data.terminalPhone,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=videoStreaming&a=bsjVideoStopCommand",
        "postData=" + base64Input
      );
    return response;
  }

  async bsjVideoMonitoring(channelId, data) {
    var postData = {
      channelId: channelId,
      vehicleId: data.vehicleId,
      full_imei: data.full_imei,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=videoStreaming&a=bsjVideoMonitoring",
        "postData=" + base64Input
      );
    return response;
  }
}

export default VideoMonitoringStore;
