import React, { Component } from "react";
import { Button, Col, Form, Input, Row, Select, Space } from "antd";

import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";

import Password from "antd/es/input/Password";

import withRouter from "../withRouter";

import PoliceOfficerStore from "../store/PoliceOfficerStore";
import { Link } from "react-router-dom";
import config from "../config";
const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 20 },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
/* eslint-enable no-template-curly-in-string */

class PoliceOfficerAdd extends Component {
  formRef = React.createRef();
  constructor(prop) {
    super(prop);
    this.state = { dateFormatList: ["DD/MM/YYYY"] };
    this.policeOfficerStore = new PoliceOfficerStore();
  }
  normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  async componentDidMount() {
    console.log(this.props.params.staffId);
    const staffId = this.props.params.staffId;
    if (staffId) {
      const getStaffData = await this.policeOfficerStore.getStaffData(staffId);
      this.formRef.current.setFieldsValue({
        name: getStaffData.name,
      });
    }
  }

  onPoliceDetailsSubmit = async (fieldsValue) => {
    // const data = fieldsValue.driver
    console.log(fieldsValue);
    const resp = await this.policeOfficerStore.addData(fieldsValue);
    // console.log("resp", resp)

    let values;
    // if (!data.dateOfBirth || !data.visaExpiryDate || !data.permitExpiryDate || !data.licenceExpiryDate) {
    //     values = data
    // } else {
    //     values = {
    //         ...data,
    //         'dateOfBirth': data['dateOfBirth'].format('YYYY-MM-DD'),
    //         'visaExpiryDate': data["visaExpiryDate"].format('YYYY-MM-DD'),
    //         'licenceExpiryDate': data['licenceExpiryDate'].format('YYYY-MM-DD'),
    //         'permitExpiryDate': data['permitExpiryDate'].format('YYYY-MM-DD')

    //     }
    // }
    // console.log("data", values)
    // axios.post("http://192.168.1.100:8088/phpmyadmin/sql.php?server=1&db=pmistrack_react&table=fm_company&pos=0", values)
    //     .then((res) => console.log(res)).catch((err) => console.log(err))
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <Form
          ref={this.formRef}
          {...layout}
          layout="vertical"
          name="nest-messages"
          onFinish={this.onPoliceDetailsSubmit}
          style={{ maxWidth: "100%" }}
          validateMessages={validateMessages}
        >
          <Row
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              width: "100%",
              display: "flex",
              alignItems: "center",
              height: "50px",
              background: config.headerColor,
              padding: "0 24px",
            }}
          >
            <Col span={12}>
              <h1>Police Officer Details</h1>
            </Col>

            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space style={{}}>
                <Link to={"/policeofficer"}>
                  <Button ghost type="primary">
                    Back
                  </Button>
                </Link>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
                <Button
                  ghost
                  danger
                  type="primary"
                  onClick={(e) => {
                    this.formRef.current?.resetFields();
                  }}
                >
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>

          <Row style={{ padding: "24px" }}>
            <Col span={11}>
              {/* <Form.Item initialValue={-1} name='id' label="Id" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item initialValue={-1} name='userId' label="userId" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item> */}

              <Form.Item
                name="companyId"
                label="Account"
                rules={[{ required: true }]}
              >
                <Select placeholder="Select Account">
                  <Select.Option value="304">
                    Fatima Suleman Al Balwe
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item name="assetNmae" label="Asset Name">
                <Select>
                  <Select.Option value="Sales Executive">
                    Sales Executive
                  </Select.Option>
                  <Select.Option value="Sales Co-ordinator">
                    Sales Co-ordinator
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="firstNmae"
                label="First Name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="policeOfficerId"
                label="Police Officer Id"
                rules={[{ required: true, type: Number }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={2}></Col>
            <Col span={11}>
              <Form.Item
                name="email"
                label="Email "
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="phoneno"
                label="Phone No"
                rules={[{ type: Number }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password "
                rules={[{ type: Password }]}
              >
                <Input />
              </Form.Item>

              <Form.Item name="designation" label="Designation" rules={[{}]}>
                <Input />
              </Form.Item>
              <Form.Item name="department" label="Department" rules={[{}]}>
                <Input />
              </Form.Item>

              {/* <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                                <Button type="primary" htmlType="submit">
                                    Save
                                </Button>
                            </Form.Item> */}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
PoliceOfficerAdd.contextType = StoreContext;
export default observer(withRouter(PoliceOfficerAdd));
