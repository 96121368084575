export default {
  /* old */
  _FLEET_VEHICLE_HISTORY_: "Asset History Details",

  _FLEET_SHOW_BTN_: "Show",
  _FLEET_CLEAR_BTN_: "Clear",
  _FLEET_CLOSE_BTN_: "Close",
  _FLEET_RESET_BTN_: "Reset",
  _REFUELS_: "Refuels",

  _FLEET_REPORT_EVENT_: "Event",
  _FLEET_REPORT_EVENT_NO_: "Event No",
  _FLEET_ALL_EVENT_: "All Event",
  _FLEET_START_EVENT_: "Start Event",
  _FLEET_STOP_EVENT_: "Stop Event",
  _FLEET_START_TIME_MIN: "Start Time (Min)",
  _FLEET_STOP_TIME_MIN: "Stop Time (Min)",
  _FLEET_START_TIME_: "Start Time",
  _FLEET_STOP_TIME_: "Stop Time",
  _FLEET_ACC_EVENT_: "ACC On/Off",
  _FLEET_STARTSTOP_EVENT_: "Start/Stop",
  _FLEET_OTHER_EVENT_: "Other Event",
  _FLEET_SPEEDTIME_EVENT_: "Speed and Time",
  _ASSIGN_ALL_VEHICLES_OF_COMPANY_: "Assign all vehicles of company",
  _ASSIGN_VEHICLES_COMPANY_USERSELECTION_:
    "Assign vehicles of company(user selection)",
  _ASSIGN_ALL_VEHICLES_SELECTEDGRP_: " Assign all vehicles of selected groups",
  _ASSIGN_VEHCLES_SELECTEDGRP_USERSELECTION_:
    "Assign Vehicles of selected groups(user selection)",

  _FLEET_RECORD_DISPLAYMSG_: "Displaying Records {0} - {1} of {2}",
  _FLEET_RECORD_EMPTYMSG_: "No Records to display",

  _FLEET_EVENT_REPORT_: "Event View on Map",
  _FLEET_RULETRIGGER_REPORT_: "Rule Trigger on Map",
  _FLEET_GEOFENCE_REPORT_: "Geofence on Map",
  _FLEET_CHAT_REPORT_: "Asset Position",
  _FLEET_CHAT_ALERT_: "Asset Position Not Available",
  _REGISTER_IN_WASL_: "Register in WASL",

  _FLEET_LOGIN_SETLOAD_: "login...",

  _FLEET_LOGIN_LANGUAGE_: "Language",
  _FLEET_LOGIN_LANGUAGEDDL_ENGLISH_: "English",
  _FLEET_LOGIN_LOGINBTNDDL_SPANISH_: "Spanish",
  _FLEET_LOGIN_LOGINBTN_: "Login",
  _FLEET_LOGIN_FRWDBTN_: "Forgot Password",
  _FLEET_LOGIN_REMEMBER_: "Remember Me",
  _FLEET_LOGIN_EMAILLBL_: "Email",
  _FLEET_LOGIN_PASSWORDLBL_: "Password",
  _FLEET_LOGIN_INVALIDERROR_: "Incorrect Username or Password",

  _FLEET_ALERT_: "Alert",
  _REMOVE_ALL_: "Remove All",
  _FLEET_VEHICLEPOSITION_ON_: "ON",
  _FLEET_VEHICLEPOSITION_OFF_: "OFF",
  _FLEET_VEHICLEPOSITION_VALID_: "Valid",
  _FLEET_VEHICLEPOSITION_INVALID_: "InValid",
  _FLEET_VEHICLEPOSITION_OPEN_: "Open",
  _FLEET_VEHICLEPOSITION_CLOSE_: "Close",
  _FLEET_VEHICLEPOSITION_NA_: "NA",

  _FLEET_WELCOME_: "Welcome ",
  _FLEET_LOGOUT_: "Logout",
  _FLEET_REQUIRED_ERROR_: "This field is required",
  _FLEET_TELEPHONE_ERROR_:
    "Not a valid telephone No. Must be ex. (123-123-1234 or 123-4567 or 1234567896)",
  _FLEET_EMAIL_ERROR_:
    'This field should be an e-mail address in the format "user@example.com"',

  _FLEET_DASHBORD_RULETRIGGER_TITLE_: "Notifications",
  _FLEET_DASHBORD_RULETRIGGER_NAME_: "Rule Name",
  _FLEET_DASHBORD_RULETRIGGER_VEHICLE_: "Vehicle No",
  _FLEET_DASHBORD_RULETRIGGER_TIME_: "Time",
  _FLEET_DASHBORD_RULETRIGGER_DELETE_: "Delete",
  _VEHICLE_NO_: "Vehicle No",

  _FLEET_DASHBORD_NAVIRIGHT_NEWPOI_: "New POI",
  _FLEET_DASHBORD_NEWPOI_TITLE_: "Create POI",

  _FLEET_DASHBORD_NEWPOI_COMPANY_: "Company",
  _confirmation_: "Are you sure you want to delete record?",
  _FLEET_DASHBORD_NEWPOI_NAME_: "Name",
  _FLEET_CUSTOMER_: "Customer",
  _FLEET_DASHBORD_NEWPOI_CUSTOMER_: "Account",
  _FLEET_DASHBORD_NEWPOI_ADDRESS_: "Address",
  _FLEET_DASHBORD_NEWPOI_TYPE_: "Type",
  _CONFIRM_: "Confirm",

  _FLEET_DASHBORD_NEWPOI_TYPEDDL_CUSTOMER_: "Account",
  _FLEET_DASHBORD_NEWPOI_TYPEDDL_FUEL_: "Fuel Station",
  _FLEET_DASHBORD_NEWPOI_TYPEDDL_HOUSE_: "House",
  _FLEET_DASHBORD_NEWPOI_TYPEDDL_SHOP_: "Shop",
  _FLEET_DASHBORD_NEWPOI_TYPEDDL_WAREHOUSE_: "Warehouse",
  _FLEET_DASHBORD_NEWPOI_TYPEDDL_CEDIS_: "CEDIS",
  _FLEET_DASHBORD_NEWPOI_TYPEDDL_AIRPORT_: "Airport",
  _FLEET_DASHBORD_NEWPOI_PHONE_: "Phone No",
  _FLEET_DASHBORD_NEWPOI_PHOTO_: "Upload Photo",
  _FLEET_DASHBORD_NEWPOI_SAVEBTN_: "Save",
  _FLEET_DASHBORD_NEWPOI_REMOVEBTN_: "Remove POI",
  _FLEET_DASHBORD_NEWPOI_REMOVETITLE_: "Remove POI?",
  _FLEET_DASHBORD_NEWPOI_REMOVEMSG_: "Are you sure you want to remove?",
  _FLEET_DASHBORD_NEWPOI_REMOVEYES_: "yes",
  _FLEET_DASHBORD_NEWPOI_SAVEMSG_: "Saving the file...",
  _FLEET_DASHBORD_NEWPOI_COMPMSG_: "This is a required field.",
  _FLEET_DASHBORD_NEWPOI_NAMEMSG_: "This is a required field.",
  _FLEET_DASHBORD_NEWPOI_ERRORMSG_:
    "Allowed Image Extensions are png,jpg,jpeg,bmp and Size upto 1mb",

  _FLEET_DASHBORD_NAVIRIGHT_SHOWPOI_: "Show POI",
  _FLEET_DASHBORD_SHOWPOI_TITLE_: "Show All POI",
  // _FLEET_DASHBORD_SHOWPOI_COMPANY_: 'Company',
  _FLEET_DASHBORD_SHOWPOI_COMPANY_: "Account",
  _FLEET_DASHBORD_SHOWPOI_COMPMSG_: "This is a required field.",
  _FLEET_DASHBORD_SHOWPOI_SHOWBTN_: "SHOW POI",
  _FLEET_DASHBORD_SHOWPOI_CANCELBTN_: "Cancel",
  _ASSIGNED_VEHICLES_: "Assigned Vehicles",
  _ASSIGNED_: "Assigned",
  _NOT_ASSIGNED_: "Not Assigned",

  _FLEET_DASHBORD_NAVIRIGHT_CLEARPOI_: "Clear POI",

  _FLEET_DASHBORD_NAVIRIGHT_SEARCHPOI_: "Search POI",
  _FLEET_DASHBORD_SEARCHPOI_TITLE_: "Search POI",
  //_FLEET_DASHBORD_SEARCHPOI_COMPANY_: 'Company',
  _FLEET_DASHBORD_SEARCHPOI_COMPANY_: "Account",
  _FLEET_DASHBORD_SEARCHPOI_NAME_: "POI Name",
  _FLEET_DASHBORD_SEARCHPOI_COMPMSG_: "This is a required field.",
  _FLEET_DASHBORD_SEARCHPOI_NAMEMSG_: "This is a required field.",
  _FLEET_DASHBORD_SEARCHPOI_SEARCHBTN_: "SEARCH",
  _FLEET_DASHBORD_SEARCHPOI_CANCELBTN_: "CANCEL",

  _FLEET_DASHBORD_NAVIRIGHT_IMPORTPOI_: "Import POI from kml",
  _FLEET_DASHBORD_IMPORTPOI_TITLE_: "Import POI",
  //_FLEET_DASHBORD_IMPORTPOI_COMPANY_: 'Company',
  _FLEET_DASHBORD_IMPORTPOI_COMPANY_: "Account",
  _FLEET_DASHBORD_IMPORTPOI_FILE_: "Select File",
  _FLEET_DASHBORD_IMPORTPOI_UPLOADBTN_: "UPLOAD",
  _FLEET_DASHBORD_IMPORTPOI_CANCELBTN_: "CANCEL",
  _FLEET_DASHBORD_IMPORTPOI_SAVEMSG_: "Saving the file...",
  _FLEET_DASHBORD_IMPORTPOI_ERRORFILE_:
    "Allowed File Extensions are kml, xml and Size upto 1mb",

  _FLEET_DASHBORD_NAVIRIGHT_DROWGEO_: "Draw Geofence",
  _FLEET_DASHBORD_DROWGEO_TITLE_: "Draw Geofence",

  _FLEET_DASHBORD_DROWGEO_COMPANY_: "Account",
  _FLEET_DASHBORD_DROWGEO_ADDRESS_: "Address",
  _FLEET_DASHBORD_DROWGEO_ADDRESSMSG_: "Address not found",
  _FLEET_DASHBORD_DROWGEO_NAME_: "Name",
  _FLEET_DASHBORD_DROWGEO_DISCARDBTN_: "Discard and Create New",
  _FLEET_DASHBORD_DROWGEO_SAVEBTN_: "SAVE",
  _FLEET_DASHBORD_DROWGEO_CANCEL_: "CANCEL",
  _FLEET_DASHBORD_DROWGEO_LOADMSG_: "Saving Geofence...",
  _FLEET_DASHBORD_DROWGEO_ALERTSTATUS_: "Status",
  _FLEET_DASHBORD_DROWGEO_ALERTMSG_: "Geofence saved successfully",
  _FLEET_DASHBORD_DROWGEO_ERRORMSG_: "Fill all fields marked red",

  _FLEET_DASHBORD_NAVIRIGHT_SHOWGEO_: "Show Geofence",
  _FLEET_DASHBORD_SHOWGEO_TITLE_: "Geofence List",

  _FLEET_DASHBORD_SHOWGEO_COMPANY_: "Account",
  _FLEET_DASHBORD_SHOWGEO_NAME_: "Name",
  _FLEET_DASHBORD_SHOWGEO_ADDRESS_: "Address",
  _FLEET_DASHBORD_SHOWGEO_DELETE_: "Delete Geofence",
  _FLEET_DASHBORD_SHOWGEO_REMOVETITLE_: "Remove Geofence?",
  _FLEET_DASHBORD_SHOWGEO_REMOVEMSG_: "are you want to sure remove?",
  _FLEET_DASHBORD_SHOWGEO_REMOVEYESBTN_: "yes",
  _FLEET_DASHBORD_SHOWGEO_REMOVELODING_: "Removing Geofence Data...",
  _FLEET_DASHBORD_SHOWGEO_REMOVEALERT_: "Alert",

  _FLEET_DASHBORD_NAVIRIGHT_IMPORTGEO_: "Import/Export <br/> from XML",
  _FLEET_DASHBORD_IMPORTGEO_TITLE_: "Import/Export from XML",

  _FLEET_DASHBORD_IMPORTGEO_COMPANY_: "Account",
  _FLEET_DASHBORD_IMPORTGEO_FILE_: "Select File",
  _FLEET_DASHBORD_IMPORTGEO_UPLOADBTN_: "UPLOAD",
  _FLEET_DASHBORD_IMPORTGEO_CANCELBTN_: "CANCEL",
  _FLEET_DASHBORD_IMPORTGEO_SAVEMSG_: "Saving the file...",
  _FLEET_DASHBORD_IMPORTGEO_ERRORMSG_:
    "Allowed File Extensions are kml, xml and Size upto 1mb",

  _FLEET_DASHBORD_NAVIRIGHT_SEARCHVEHICLE_: "Search Asset",
  _FLEET_DASHBORD_SEARCHVEHICLE_TITLE_: "Search Nearest Vehicle",

  _FLEET_DASHBORD_SEARCHVEHICLE_COMPANY_: "Account",
  _FLEET_DASHBORD_SEARCHVEHICLE_VEHICLE_: "Asset",
  _FLEET_DASHBORD_SEARCHVEHICLE_SEARCHBTN_: "SEARCH",
  _FLEET_DASHBORD_SEARCHVEHICLE_CANCELBTN_: "CANCEL",
  _FLEET_DASHBORD_SEARCHVEHICLE_COMPMSG_: "This is a required field.",
  _FLEET_DASHBORD_SEARCHVEHICLE_VEHICLEMSG_: "This is a required field.",

  _FLEET_DASHBORD_NAVIRIGHT_SEARCHADDRESS_: "Search Address",
  _FLEET_DASHBORD_SEARCHADDRESS_TITLE_: "Search Address",
  _FLEET_DASHBORD_SEARCHADDRESS_ADDRESS_: "Enter Address",
  _FLEET_DASHBORD_SEARCHADDRESS_ADDRESSMSG_: "This is a required field.",
  _FLEET_DASHBORD_SEARCHADDRESS_SEARCHBTN_: "SEARCH",
  _FLEET_DASHBORD_SEARCHADDRESS_CANCELBTN_: "CANCEL",

  _FLEET_DASHBORD_NAVIRIGHT_DISTMEASURE_: "Distance Measure",
  _FLEET_DASHBORD_DISTMEASURE_TITLE_: "Distance Measure",
  _FLEET_DASHBORD_DISTMEASURE_DISTCALCEBTN_: "Calculate Distance",
  _DISTANCE_MEASUREMENT_: "Distance Measurement",
  _FLEET_DASHBORD_DISTMEASURE_CANCELBTN_: "CANCEL",

  _FLEET_DASHBORD_NAVIRIGHT_CREATEROUTE_: "Create/Assign Trip",
  _FLEET_DASHBORD_CREATEROUTE_ROUTELIST_: "Route List",
  _FLEET_DASHBORD_ROUTELIST_NAME_: "Name",
  _FLEET_DASHBORD_ROUTELIST_DISCRIPTION_: "Description",
  _FLEET_DASHBORD_ROUTELIST_TIME_: "Time",
  _FLEET_DASHBORD_ROUTELIST_EMPTYTEXT_: "No Routes available",

  _FLEET_DASHBORD_CREATEROUTE_TAB_COMPANY_: "Account",
  _FLEET_DASHBORD_CREATEROUTE_TAB_ADDROUTEBTN_: "Add Route",
  _FLEET_DASHBORD_CREATEROUTE_TAB_LISTBTN_: "List",
  _FLEET_DASHBORD_ROUTEADD_SAVEROUTBTN_: "Save Route",
  _FLEET_CREATEROUTE_ROUTEADD_SAVEBTN_: "Save",
  _FLEET_CREATEROUTE_ROUTEADD_CREATEPLANBTN_: "Create Plan",
  _FLEET_CREATEROUTE_ROUTEADD_ROUTENAME_: "Route Name",
  _FLEET_CREATEROUTE_ROUTEADD_ROUTEDESCRIPTION_: "Route Description",
  _FLEET_CREATEROUTE_ROUTEADD_ZONEORGANIZER_: "Zone Organizer",
  _FLEET_CREATEROUTE_ROUTEADD_DESCRIPTION_: "Description",
  _FLEET_CREATEROUTE_ROUTEADD_COMPANY_: "Select Account",
  _FLEET_CREATEROUTE_ROUTEADD_COMMENT_: "Comment",
  _FLEET_CREATEROUTE_ROUTEADD_VEHICLE_: "Asset",
  _FLEET_CREATEROUTE_ROUTEADD_SRTDATE_: "Start Date",
  _FLEET_CREATEROUTE_ROUTEADD_ROUTEORGANIZER_: "Route Organizer",
  _FLEET_CREATEROUTE_ROUTEADD_VEHCHKBXLBL_: "Asset Parked here at route start",
  _FLEET_CREATEROUTE_ROUTEADD_TOOLTIP_STARTTIME_:
    "Define the time when the vehicle will start the route !",
  _FLEET_CREATEROUTE_ROUTEADD_TOOLTIP_DISTANCE_:
    "Define the distance to the location from previous location !",
  _FLEET_CREATEROUTE_ROUTEADD_TOOLTIP_ESTIMATETIME_:
    "Define the estimate time to arrive to the location from the previous location !",
  _FLEET_CREATEROUTE_ROUTEADD_TOOLTIP_ESTIMATETIMESPEND_:
    "Define the time when the vehicle will start the route !",
  _FLEET_CREATEROUTE_ROUTEADD_TOTALDISTANCE_: "Total Distance",
  _FLEET_CREATEROUTE_ROUTEADD_TOTALTIME_: "Total Time",
  _FLEET_CREATEROUTE_ROUTEADD_ALERTSTATUS_: "Status",
  _FLEET_CREATEROUTE_ROUTEADD_ALERTMSG_: "Route save successfully...",
  _FLEET_CREATEROUTE_ROUTEADD_ERRORMSG_: "Data Not Saved...",

  _FLEET_DASHBORD_ROUTEADD_SUGGROUTBTN_: "Suggest Route",
  _FLEET_DASHBORD_ROUTEADD_CANCELBTN_: "Cancel",
  _FLEET_DASHBORD_ROUTEADD_SUGGROUTE_SETROUTEBTN_: "Set Route",
  _FLEET_DASHBORD_ROUTEADD_ADDRESS_ERRORMSG_: "Please Try After Sometime",

  _FLEET_DASHBORD_NAVIRIGHT_CREATECHAT_: "Create Chat",
  _FLEET_DASHBORD_CREATECHAT_TITLE_: "Chat",

  _FLEET_DASHBORD_CREATECHAT_COMPANY_: "Account",
  _EDIT_TRIP_DETAILS_: "Edit Trip Details",

  _FLEET_DASHBORD_NAVIRIGHT_SETTING_TITLE_: "Setting",
  _FLEET_DASHBORD_SETTING_STATUS_: "Status",
  _FLEET_DASHBORD_SETTING_VEHICLE_: "Vehicles Name/Alias",
  _FLEET_DASHBORD_SETTING_PLATE_: "Plates",
  _FLEET_DASHBORD_SETTING_IMEI_: "IMEI",
  _FLEET_DASHBORD_SETTING_DATE_: "Datetime",
  _FLEET_DASHBORD_SETTING_ADDRESS_: "Address",
  _FLEET_DASHBORD_SETTING_SPEED_: "Speed",
  _FLEET_DASHBORD_SETTING_ODOMETER_: "Odometer",
  _FLEET_DASHBORD_SETTING_HEADING_: "Heading",
  _FLEET_DASHBORD_SETTING_INGNITION_: "Ignition status",
  _FLEET_DASHBORD_SETTING_EVENT_: "Event",
  _FLEET_DASHBORD_SETTING_TEMPERATURE_: "Temperature",
  _FLEET_DASHBORD_SETTING_FUELLEVEL_: "Fuel Level (%)",
  _FLEET_DASHBORD_SETTING_FUELLEVEL1_: "Fuel Level 1 (%)",
  _FLEET_DASHBORD_SETTING_FUELLEVEL2_: "Fuel Level 2 (%)",
  _FLEET_DASHBORD_SETTING_FUELLEVEL3_: "Fuel Level 3 (%)",
  _FLEET_DASHBORD_SETTING_FUELVOLUME_: "Fuel Volume (Lt)",
  _FLEET_DASHBORD_SETTING_FUELVOLUME1_: "Analog Input 1",
  _FLEET_DASHBORD_SETTING_FUELVOLUME2_: "Analog Input 2",
  _FLEET_DASHBORD_SETTING_FUELVOLUM3_: "Analog Input 3",
  _FLEET_DASHBORD_SETTING_FUELVOLUME4_: "Analog Input 4",
  _FLEET_DASHBORD_SETTING_FUELVOLUME3_: "Fuel Volume(Lt)",
  _FLEET_DASHBORD_SETTING_GPS_: "GPS Status",
  _FLEET_DASHBORD_SETTING_GSM_: "GSM Signal",
  _FLEET_DASHBORD_SETTING_BATTERY_: "Battery",
  _FLEET_DASHBORD_SETTING_DOOR_: "Door",
  _FLEET_DASHBORD_SETTING_ACC_: "ACC",
  _FLEET_DASHBORD_SETTING_SEATBELT_: "SeatBelt",
  _FLEET_DASHBORD_SETTING_AC_: "AC",
  _FLEET_DASHBORD_SETTING_PANIC_: "Panic",
  _FLEET_DASHBORD_SETTING_ANALOG_: "Analog Input",
  _FLEET_DASHBORD_SETTING_LATITUDE_: "Latitude",
  _FLEET_DASHBORD_SETTING_LONGITUDE_: "Longitude",
  _FLEET_DASHBORD_SETTING_MESSAGE_: "Message",
  _FLEET_DASHBORD_SETTING_EXTERNAL_: "External Alarm Data",
  _FLEET_DASHBORD_SETTING_SELECTALLBTN_: "Select All",
  _FLEET_DASHBORD_SETTING_DELETEALLBTN_: "Deselect All",
  _FLEET_DASHBORD_SETTING_SAVEBTN_: "Save",

  _FLEET_DASHBORD_VEHICLE_INFORMATION_TITLE_: "Asset Information Details",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_PLATENO_: "Plate No",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_BRAND_: "Brand",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_MODEL_: "Model",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_TYPE_: "Type",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_YEAR_: "Year",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_IMEI_: "IMEI",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_ADDRESS_: "Formatted Address",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_ODOMETER_: "Odometer",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_: "Driver Name",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_MOBILE_: "Mobile No",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_SPEED_: "Speed Kmh",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_UPDATE_: "Last Update",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_STOPTIME_: "Stop Time",
  _FLEET_DASHBORD_INFORMATION_DAY_: "Day(s)",
  _FLEET_DASHBORD_INFORMATION_HOUR_: "Hour(s)",
  _FLEET_DASHBORD_INFORMATION_MIN_: "Min(s)",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_EVENT_: "Event Type",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_LATITUDE_: "Latitude",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_LONGI_: "Longitude",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_SERVICE_: "Last Service Done",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_OIL_: "Last Oil Change",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_TIRE_: "Last Tyre Change",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_TYRE_: "Last Tyre Change KM",
  _FLEET_DASHBORD_VEHICLE_INFORMATION_OIL_KM_: "Last Oil Change KM",

  _FLEET_DASHBORD_VEHICLE_INFORMATION_TYRE_DATE_: "Last Tyre Change Date",

  _INVALID_EMAILID_: "Invalid Email Id",
  _FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_: "Asset Name",

  _FLEET_DASHBORD_VEHICLE_HISTORY_TITLE_: "Asset Offline Tracking",

  _FLEET_DASHBORD_VEHICLE_HISTORY_COMPANY_: "Account",
  _FLEET_DASHBORD_VEHICLE_HISTORY_VEHICLE_: "Asset Number",
  _FLEET_DASHBORD_VEHICLE_HISTORY_FROM_: "From",
  _FLEET_DASHBORD_VEHICLE_HISTORY_TO_: "To",
  _FLEET_DASHBORD_VEHICLE_HISTORY_SHOWBTN_: "Show",
  _FLEET_DASHBORD_VEHICLE_HISTORY_ERROR_: "This is a required field.",
  _FLEET_DASHBORD_VEHICLE_HISTORY_SETLOAD_: "Fetching data...",
  _FLEET_DASHBORD_HISTORY_TOOLTIP_LOCATION_: "Location",
  _FLEET_DASHBORD_HISTORY_TOOLTIP_SPEED_: "Speed",
  _FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_: "Driver",
  _FLEET_DASHBORD_HISTORY_TOOLTIP_MOBILE_: "Mobile No",
  _FLEET_DASHBORD_HISTORY_TOOLTIP_LASTREPORT_: "Last Reported Date",
  _FLEET_DASHBORD_HISTORY_TOOLTIP_NODRIVER_: "No Driver",
  _FLEET_DASHBORD_HISTORY_TOOLTIP_NOMOBILE_: "No Mobile",
  _FLEET_DASHBORD_VEHICLE_HISTORY_ALERTMSG_: "This feature is not available.",

  _FLEET_DASHBORD_VEHICLE_STREET_ALERT_: "Alert",
  _FLEET_DASHBORD_VEHICLE_STREET_ALERTMSG_:
    "Street View not available for this location.",
  _FLEET_DASHBORD_VEHICLE_STREET_ERROR_: "This feature is not available.",

  _FLEET_DASHBORD_VEHICLE_COMMAND_TITLE_: "Please Enter Command",
  _FLEET_DASHBORD_VEHICLE_COMMAND_SELECT_: "Select Command",
  _FLEET_DASHBORD_COMMAND_SELECTDDL_ON_: "Immobilizer On",
  _FLEET_DASHBORD_COMMAND_SELECTDDL_OFF_: "Immobilizer OFF",
  _FLEET_DASHBORD_COMMAND_SELECTDDL_POSITION_: "Get Position",
  _FLEET_DASHBORD_COMMAND_SELECTDDL_DOORLOCK_: "Door Lock",
  _FLEET_DASHBORD_COMMAND_SELECTDDL_DOORUNLOCK_: "Door Unlook",
  _FLEET_DASHBORD_VEHICLE_COMMAND_ERRORMSG_: "This is a required field.",
  _FLEET_DASHBORD_VEHICLE_COMMAND_SENDBTN_: "Send",
  _FLEET_DASHBORD_VEHICLE_COMMAND_OKBTN_: "OK",
  _FLEET_DASHBORD_VEHICLE_COMMAND_CANCELBTN_: "Cancel",
  _FLEET_DASHBORD_VEHICLE_COMMAND_SUCCESSMGS_: "Command Sent to Device.",
  _FLEET_DASHBORD_VEHICLE_COMMAND_UNSUCCESSMGS_:
    "Command Could Not Sent to Device.",

  _FLEET_DASHBORD_LEFT_VEHLIST_NEXTREFRESH_: "Next Refresh",
  _FLEET_DASHBORD_LEFT_VEHLIST_SETREFRESH_: "Set Refresh",
  _FLEET_DASHBORD_LEFT_VEHLIST_DEFAULT_: "Default..",
  _FLEET_DASHBORD_LEFT_VEHLIST_TIMER_: "To set Timer please select vehicles.",

  _FLEET_DASHBORD_MAP_LOCK_: "Lock",
  _FLEET_DASHBORD_MAP_UNLOCK_: "Unlock",
  _FLEET_DASHBORD_MAP_LOCK_TOOLTIP_: "Click to lock the map",
  _CLICKTOUPLOAD_EXCELFILE_: "Click To Upload Excel File",
  _FLEET_DASHBORD_MAP_PHOTO_: "Photos",
  _FLEET_DASHBORD_MAP_PHOTO_TOOLTIP_: "Click to see the photos",
  _FLEET_DASHBORD_MAP_WEATHER_: "Weather",
  _FLEET_DASHBORD_MAP_WEATHER_TOOLTIP_: "Click to see the weather",
  _FLEET_DASHBORD_MAP_TRAFFIC_: "Traffic",
  _FLEET_DASHBORD_MAP_TRAFFIC_TOOLTIP_: "Click to see the traffic",
  _FLEET_DASHBORD_MAP_MAP_: "Map",
  _FLEET_DASHBORD_MAP_MAP_TOOLTIP_: "Map",
  _FLEET_DASHBORD_MAP_SATELLITE_: "Satellite",
  _FLEET_DASHBORD_MAP_SATELLITE_TOOLTIP_: "Satellite",

  _FLEET_MANAGEUSER_TAB_: "Manage User",
  _FLEET_MANAGEUSER_COMPANY_TAB_: "Account",

  _FLEET_COMPANY_COMPANYLIST_: "Account List",
  _FLEET_COMPANY_COMPANYLIST_ADDBTN_: "ADD",

  _FLEET_COMPANY_COMPANYLIST_COMPANYNAME_: "Account Name",
  _FLEET_COMPANY_COMPANYLIST_PHONE_: "Contact Phone",
  _FLEET_COMPANY_COMPANYLIST_EMAIL_: "Contact Email",
  _FLEET_COMPANY_COMPANYLIST_STATUS_: "Status",
  _FLEET_COMPANY_COMPANYLIST_EDIT_: "Edit",
  _FLEET_COMPANY_COMPANYLIST_DELETE_: "Delete",
  _FLEET_COMPANY_COMPANYLIST_REMOVETITLE_: "Remove Company?",
  _FLEET_COMPANY_COMPANYLIST_REMOVEMSG_: "are you want to sure remove?",
  _FLEET_COMPANY_COMPANYLIST_YESBTN_: "yes",
  _FLEET_COMPANY_COMPANYLIST_DELETELODING_: "Removing Company Data..",

  // _FLEET_COMPANY_COMPANYDETAIL_TITLE_: 'Company Details',
  _FLEET_COMPANY_COMPANYDETAIL_TITLE_: "Account Details",
  _FLEET_COMPANY_COMPANYDETAIL_BACKBTN_: "BACK",
  _FLEET_COMPANY_COMPANYDETAIL_SAVEBTN_: "SAVE",
  _FLEET_COMPANY_COMPANYDETAIL_CANCELBTN_: "CANCEL",

  _FLEET_COMPANY_COMPANYDETAIL_COMPANYNAME_: "Account Name",
  //  _FLEET_COMPANY_COMPANYDETAIL_COMPANYNAME_: 'Company Name',
  _FLEET_COMPANY_COMPANYDETAIL_ADDRESS_: "Account Address",
  // _FLEET_COMPANY_COMPANYDETAIL_ADDRESS_: 'Company Address',
  _FLEET_COMPANY_COMPANYDETAIL_EMAIL_: "Contact Email",
  _FLEET_COMPANY_COMPANYDETAIL_PHONE_: "Contact Phone",
  _FLEET_COMPANY_COMPANYDETAIL_CITY_: "City",
  _FLEET_COMPANY_COMPANYDETAIL_STATE_: "State",
  _FLEET_COMPANY_COMPANYDETAIL_COUNTRY_: "Country",
  _FLEET_COMPANY_COMPANYDETAIL_TIME_: "Time Zone",
  _FLEET_COMPANY_COMPANYDETAIL_RFC_: "RFC",
  _FLEET_COMPANY_COMPANYDETAIL_STATUS_: "STATUS",
  _FLEET_COMPANY_COMPANYDETAIL_SERVER_: "SMTP Server",
  _FLEET_COMPANY_COMPANYDETAIL_PORT_: "SMTP Port",
  _FLEET_COMPANY_COMPANYDETAIL_SELECT_: "Select",
  _FLEET_COMPANY_COMPANYDETAIL_SMPTEMAIL_: "SMTP Email address",
  _FLEET_COMPANY_COMPANYDETAIL_SMPTPASSWD_: "SMTP Password",
  _FLEET_COMPANY_COMPANYDETAIL_EXPIREDATE_: "Account Expiry date",

  _FLEET_COMPANY_COMPANYDETAIL_EXPIREMONTHS_: "Account Expiry Months",

  _FLEET_COMPANY_COMPANYDETAIL_UPLOAD_: "Upload Company Logo",
  _FLEET_COMPANY_COMPANYDETAIL_TAXEXPIREDATE_: "Tax Expiry Date",
  //_FLEET_COMPANY_COMPANYDETAIL_LOGO_: 'Company Logo',
  _FLEET_COMPANY_COMPANYDETAIL_LOGO_: "Account Logo (150*150)",
  _FLEET_COMPANY_COMPANYDETAIL_NOLOGO_: "No Logo",
  _FLEET_COMPANY_COMPANYDETAIL_NOIMAGE_: "No Image",
  _FLEET_COMPANY_COMPANYDETAIL_ERRORMSG_:
    "Please fill correct data in fields that are marked red.",
  _FLEET_COMPANY_COMPANYDETAIL_ERRORCOMPANY_:
    "This company name already exists",
  // _FLEET_COMPANY_COMPANYDETAIL_ERRORPHOTO_: 'Please upload valid image. Image dimensions should be 189*65',
  _FLEET_COMPANY_COMPANYDETAIL_ERRORPHOTO_:
    "Please upload valid image. Image dimensions should be 150*150",
  _FLEET_COMPANY_COMPANYDETAIL_ERRORUSER_: "This user name already exists",

  _FLEET_COMPANY_USERDETAIL_TITLE_: "User Details",
  _FLEET_COMPANY_USERDETAIL_NAME_: "Name",
  _FLEET_COMPANY_USERDETAIL_USERNAME_: "User Name",
  _FLEET_COMPANY_USERDETAIL_PASSWORD_: "Password",
  _FLEET_COMPANY_USERDETAIL_REPASSWORD_: "Re-enter Password",
  _FLEET_COMPANY_USERDETAIL_PASSWDNOTMATCH_: "Passwords do not match.",
  _FLEET_COMPANY_USERNAME_PROVIDE_: "Please enter your Username",
  _FLEET_COMPANY_PASSWORD_PROVIDE_: "Please enter your Password",

  _FLEET_MANAGEUSER_USER_TAB_: "User",
  _FLEET_USER_USERLIST_TITLE_: "User List",

  _FLEET_USER_USERLIST_COMPANYDDL_: "Account",
  _FLEET_USER_USERLIST_ADDBTN_: "ADD",
  _FLEET_USER_USERLIST_NAME_: "Name",
  _FLEET_USER_USERLIST_ADMINUSER_: "Admin User",
  _FLEET_USER_USERLIST_ADDRESS_: "Address",
  _FLEET_USER_USERLIST_MOBILENO_: "Mobile No.",
  _FLEET_USER_USERLIST_EMAIL_: "Email Id",
  _FLEET_USER_USERLIST_USERNAME_: "User Name",
  _FLEET_USER_USERLIST_STATUS_: "Status",
  _FLEET_USER_USERLIST_STATUS_ACTIVE_: "Active",
  _FLEET_USER_USERLIST_STATUS_INACTIVE_: "Inactive",
  _FLEET_USER_USERLIST_EDIT_: "Edit User",
  _FLEET_USER_USERLIST_VEHICLE_: "Assign Vehicles",
  _FLEET_USER_USERLIST_DELETE_: "Delete User",
  _FLEET_USER_USERLIST_REMOVETITLE_: "Remove User?",
  _FLEET_USER_USERLIST_REMOVEMSG_: "are you want to sure remove?",
  _FLEET_USER_USERLIST_YESBTN_: "yes",
  _FLEET_USER_USERLIST_DELETELODING_: "Removing User Data..",

  _FLEET_USER_USERLIST_ASSIGNVEHICLE_TITLE_: "Assign Vehicles",
  _FLEET_USER_USERLIST_ASSIGNVEHICLE_BACKBTN_: "Back",
  _FLEET_USER_USERLIST_ASSIGNVEHICLE_SAVEBTN_: "Save",
  _FLEET_USER_USERLIST_ASSIGNVEHICLE_SELECTVEHICLE_: "Select Vehicles",

  _FLEET_USER_USERDETAIL_TITLE_: "User Details",
  _FLEET_USER_USERDETAIL_BACKBTN_: "BACK",
  _FLEET_USER_USERDETAIL_SAVEBTN_: "SAVE",
  _FLEET_USER_USERDETAIL_CANCELBTN_: "CANCEL",

  _FLEET_USER_USERDETAIL_COMPANY_: "Account",
  _FLEET_USER_USERDETAIL_NAME_: "Name",
  _FLEET_USER_USERDETAIL_MOBILE_: "Mobile No",
  _FLEET_USER_USERDETAIL_EMAIL_: "Email Id",
  _FLEET_USER_USERDETAIL_ADDRESS_: "Address",
  _FLEET_USER_USERDETAIL_USERNAME_: "User Name",
  _FLEET_USER_USERDETAIL_PASSWORD_: "Password",
  _FLEET_USER_USERDETAIL_REPASSWORD_: "Re-enter Password",
  _FLEET_USER_USERDETAIL_PASSWDNOTMATCH_: "Passwords do not match.",
  _FLEET_USER_USERDETAIL_STATUS_: "Status",
  _FLEET_USER_USERDETAIL_STATUSDDL_SELECT_: "Select Status",
  _FLEET_USER_USERDETAIL_STATUSDDL_ACTIVE_: "Active",
  _FLEET_USER_USERDETAIL_STATUSDDL_INACTIVE_: "Inactive",
  _FLEET_USER_USERDETAIL_ROLE_: "Select Role",
  _FLEET_USER_USERDETAIL_ROLE_EMPTY_: "Role..",
  _FLEET_USER_USERDETAIL_PHOTO_: "Upload Photo",
  _FLEET_USER_USERDETAIL_ICON_: "Icon",
  _FLEET_USER_USERDETAIL_NOICON_: "No Icon",
  _FLEET_USER_USERDETAIL_NOIMAGE_: "No Image",
  _FLEET_USER_USERDETAIL_SAVEMSG_: "User data save successfully",
  _FLEET_USER_USERDETAIL_ERRORMSG_:
    "Please fill correct data in fields that are marked red.",
  _FLEET_USER_USERDETAIL_ERRORPHOTO_:
    "Please upload valid image. Image dimensions should be 75*75",
  _FLEET_USER_USERDETAIL_ERRORUSER_: "This user name already exists",

  _FLEET_MANAGEUSER_CHANGEPASSWORD_TAB_: "Change Password",
  _FLEET_CHANGEPASSWORD_TITLE_: "Change Your Password",
  _FLEET_CHANGEPASSWORD_SAVEBTN_: "Save",
  _FLEET_CHANGEPASSWORD_CANCELBTN_: "CANCEL",
  _FLEET_CHANGEPASSWORD_OLDPASSWD_: "Old Password",
  _FLEET_CHANGEPASSWORD_NEWPASSWD_: "New Password",
  _FLEET_CHANGEPASSWORD_CONFIRMPASSWD_: "Confirm Password",
  _FLEET_CHANGEPASSWORD_PASSWDNOTMATCH_: "Passwords do not match.",
  _FLEET_CHANGEPASSWORD_SETLOAD_: "Saving New Password...",
  _FLEET_CHANGEPASSWORD_SAVEMSG_: "Save Successfully",
  _FLEET_CHANGEPASSWORD_ERRORMSG_:
    "Please fill all fields that are marked red.",
  _FLEET_CHANGEPASSWORD_ERRORPASSWD_: "Incorrect Old password",

  _FLEET_MANAGEUSER_GROUP_TAB_: "Group",
  _FLEET_GROUP_GROUPLIST_TITLE_: "Device List",

  _FLEET_GROUP_GROUPLIST_COMPANYDDL_: "Account",
  _FLEET_GROUP_GROUPLIST_ADDBTN_: "ADD",
  _FLEET_GROUP_GROUPLIST_DEVICENAME_: "Device",
  _FLEET_GROUP_GROUPLIST_EDIT_: "Edit Device",
  _FLEET_GROUP_GROUPLIST_DELETE_: "Remove Device",
  _FLEET_GROUP_GROUPLIST_REMOVETITLE_: "Remove Device?",
  _FLEET_GROUP_GROUPLIST_REMOVEMSG_: "are you want to sure remove?",
  _FLEET_GROUP_GROUPLIST_YESBTN_: "yes",
  _FLEET_GROUP_GROUPLIST_DELETELODING_: "Removing Device Data..",

  _FLEET_GROUP_GROUPDETAIL_TITLE_: "Device Details",
  _FLEET_GROUP_GROUPDETAIL_BACKBTN_: "BACK",
  _FLEET_GROUP_GROUPDETAIL_SAVEBTN_: "SAVE",
  _FLEET_GROUP_GROUPDETAIL_CANCELBTN_: "CANCEL",

  _FLEET_GROUP_GROUPDETAIL_COMPANY_: "Account",
  _FLEET_GROUP_GROUPDETAIL_PARENTGROUP_: "Select Parent Group",
  _FLEET_GROUP_GROUPDETAIL_DEVICEGROUP_: "Device Group",
  _FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_: "Select Asset",
  _FLEET_GROUP_GROUPDETAIL_SETLOAD_: "Saving Asset Data..",
  _FLEET_GROUP_GROUPDETAIL_SAVEMSG_: "Save Successfully",
  _FLEET_GROUP_GROUPDETAIL_DEVICESAVEMSG_:
    "Device Group Applied to Selected Vehicles",
  _FLEET_GROUP_GROUPDETAIL_ERRORMSG_:
    "Please fill correct data in the fields that are marked red.",
  _FLEET_GROUP_GROUPDETAIL_ERRORGROP_: "Device Group Name Already Exist.",

  _FLEET_MANAGEUSER_ROLE_TAB_: "Role",
  _FLEET_ROLE_ROLELIST_TITLE_: "Role List",

  _FLEET_ROLE_ROLELIST_COMPANYDDL_: "Account",
  _FLEET_ROLE_ROLELIST_ADDBTN_: "Add",
  _FLEET_ROLE_ROLELIST_ROLENAME_: "Role Name",
  _FLEET_ROLE_ROLELIST_STATUS_: "Status",
  _FLEET_ROLE_ROLELIST_STATUS_ACTIVE_: "Active",
  _FLEET_ROLE_ROLELIST_STATUS_INACTIVE_: "Inactive",
  _FLEET_ROLE_ROLELIST_EDIT_: "Edit Role",
  _FLEET_ROLE_ROLELIST_DELETE_: "Delete Role",
  _FLEET_ROLE_ROLELIST_REMOVETITLE_: "Remove Role?",
  _FLEET_ROLE_ROLELIST_REMOVEMSG_: "are you want to sure remove?",
  _FLEET_ROLE_ROLELIST_YESBTN_: "yes",
  _FLEET_ROLE_ROLELIST_DELETELODING_: "Removing Role Data..",

  _FLEET_ROLE_ROLEDETAIL_TITLE_: "Role Details",
  _FLEET_ROLE_ROLEDETAIL_BACKBTN_: "BACK",
  _FLEET_ROLE_ROLEDETAIL_SAVEBTN_: "SAVE",
  _FLEET_ROLE_ROLEDETAIL_CANCELBTN_: "CANCEL",
  _FLEET_ROLE_ROLEDETAIL_COMPANY_: "Select Account",
  _FLEET_ROLE_ROLEDETAIL_ROLENAME_: "Role Name",
  _FLEET_ROLE_ROLEDETAIL_MODULENAME_: "Module Name",
  _FLEET_ROLE_ROLEDETAIL_VIEW_: "View",
  _FLEET_ROLE_ROLEDETAIL_ADD_: "Add",
  _FLEET_ROLE_ROLEDETAIL_EDIT_: "Edit",
  _FLEET_ROLE_ROLEDETAIL_DELETE_: "Delete",
  _FLEET_ROLE_ROLEDETAIL_IMPORT_: "Import",
  _FLEET_ROLE_ROLEDETAIL_SETLOAD_: "Saving Role...",
  _FLEET_ROLE_ROLEDETAIL_SAVEMSG_: "Save Successfully",
  _FLEET_ROLE_ROLEDETAIL_ERRORMSG_: "fill all fields  marked as red",

  _FLEET_MANAGEVEHICLE_TAB_: "Manage Asset and Driver",
  _FLEET_MANAGEVEHICLE_VEHICLE_TAB_: "Assets",
  _FLEET_VEHICLE_VEHICLELIST_TITLE_: "Assets List",
  _FLEET_VEHICLE_VEHICLELIST_COMPANYDDL_: "Account",

  _FLEET_VEHICLE_VEHICLELIST_ADDBTN_: "Add",
  _FLEET_VEHICLE_VEHICLELIST_VEHICLENO_: "Asset No",
  _FLEET_VEHICLE_VEHICLELIST_DRIVER_: "Driver",
  _FLEET_VEHICLE_VEHICLELIST_NODRIVER_: "No Driver",
  _FLEET_VEHICLE_VEHICLELIST_DEVICE_: "Device",
  _FLEET_VEHICLE_VEHICLELIST_NODEVICE_: "No Device",
  _FLEET_VEHICLE_VEHICLELIST_IMEI_: "IMEI",
  _FLEET_VEHICLE_VEHICLELIST_SIMNO_: "Sim No.",
  _SIM_NUMBER_: "Sim Number",
  _FLEET_VEHICLE_VEHICLELIST_STATUS_: "Status",
  _FLEET_VEHICLE_VEHICLELIST_STATUS_ACTIVE_: "Active",
  _FLEET_VEHICLE_VEHICLELIST_STATUS_INACTIVE_: "Inactive",
  _FLEET_VEHICLE_VEHICLELIST_EDIT_: "Edit Asset",
  _FLEET_VEHICLE_VEHICLELIST_DELETE_: "Delete Asset",
  _FLEET_VEHICLE_VEHICLELIST_REMOVETITLE_: "Remove Asset?",
  _FLEET_GEOFENCE_GEOFENCELIST_REMOVETITLE_: "Remove Geofence?",
  _FLEET_VEHICLE_VEHICLELIST_REMOVEMSG_: "are you want to sure remove?",
  _FLEET_VEHICLE_VEHICLELIST_YESBTN_: "yes",
  _FLEET_VEHICLE_VEHICLELIST_DELETELODING_: "Removing Asset Data..",

  _FLEET_VEHICLE_VEHICLEDETAIL_TITLE_: "Assets Details",
  _FLEET_VEHICLE_VEHICLEDETAIL_BACKBTN_: "BACK",
  _FLEET_VEHICLE_VEHICLEDETAIL_SAVEBTN_: "SAVE",
  _FLEET_VEHICLE_VEHICLEDETAIL_CANCELBTN_: "CANCEL",

  _FLEET_VEHICLE_VEHICLEDETAIL_COMPANY_: "Account",
  _FLEET_VEHICLE_VEHICLEDETAIL_PLATENO_: "ID/Plate No",
  _FLEET_VEHICLE_VEHICLEDETAIL_VEHICLENAME_: "Asset Name/Alias",
  _FLEET_VEHICLE_VEHICLEDETAIL_DRIVER_: "Driver",
  _FLEET_VEHICLE_VEHICLEDETAIL_ADDDRIVER_: "Add New Driver",
  _FLEET_VEHICLEDETAIL_ADDDRIVER_TITLE_: "Add Driver",
  _FLEET_VEHICLE_VEHICLEDETAIL_SIMNO_: "Sim No",
  _FLEET_VEHICLE_VEHICLEDETAIL_BRAND_: "Brand",
  _FLEET_VEHICLE_VEHICLEDETAIL_MODEL_: "Model",
  _FLEET_VEHICLE_VEHICLEDETAIL_COLOR_: "Color",
  _FLEET_VEHICLE_VEHICLEDETAIL_DEVICE_: "Device",
  _FLEET_VEHICLE_VEHICLEDETAIL_IMEI_: "IMEI",
  _FLEET_VEHICLE_VEHICLEDETAIL_VIN_: "VIN",
  _FLEET_VEHICLE_VEHICLEDETAIL_ODOMETER_: "Odometer Reading",
  _FLEET_VEHICLE_VEHICLEDETAIL_LASTSERVICE_: "Last Service Done",
  _FLEET_VEHICLE_VEHICLEDETAIL_LASTOILCHANGE_: "Last Oil Change",

  _FLEET_VEHICLE_VEHICLEDETAIL_LASTOILCHANGE_DATE_: "Last Oil Change Date",

  _FLEET_VEHICLE_VEHICLEDETAIL_LASTTIRE_: "Last Tire Change",
  _FLEET_VEHICLE_VEHICLEDETAIL_OILCHANGE_: "Oil Change(km)",
  _FLEET_VEHICLE_VEHICLEDETAIL_TIRECHANGE_: "Tyre Change(km)",
  _FLEET_VEHICLE_VEHICLEDETAIL_SERVICEEXPIRE_: "Service Expire(Km)",
  _FLEET_VEHICLE_VEHICLEDETAIL_MANUFACTURE_: "Manufacture Year",

  _FLEET_VEHICLE_VEHICLEDETAIL_FUELCAPACITY_: "Fuel Capacity",
  _FLEET_VEHICLE_VEHICLEDETAIL_FUELTANK_: "No of Fuel Tank",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANKVOLUME1_: "Tank 1 Volume(lt)",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANKVOLUME2_: "Tank 2 Volume(lt)",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANKVOLUME3_: "Tank 3 Volume(lt)",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANKVOLUME_: "Tank Volume",

  _FLEET_VEHICLE_VEHICLEDETAIL_TANKSHAPE_HORIZONTAL_: "Horizontal Rectangle",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANKSHAPE_VERTICAL_: "Vertical Cylinder",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANKSHAPE_CYLINDRICAL_: "Horizontal Cylinder",

  _FLEET_VEHICLE_VEHICLEDETAIL_TANK1SHAPE_: "Tank 1 Shape",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK1LENGTH_: "Tank 1 Length(cm)",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK1WIDTH_: "Tank 1 Width(cm)",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK1HEIGHT_: "Tank 1 Height(cm)",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK1DIAMETER_: "Tank 1 Diameter(cm)",
  _FLEET_VEHICLE_VEHICLEDETAIL_FID1_: "F Id 1",
  _FLEET_VEHICLE_VEHICLEDETAIL_INSTALLATIONSPACE1_: "Installation Space 1(cm)",

  _FLEET_VEHICLE_VEHICLEDETAIL_TANK2SHAPE_: "Tank 2 Shape",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK2LENGTH_: "Tank 2 Length(cm)",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK2WIDTH_: "Tank 2 Width(cm)",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK2HEIGHT_: "Tank 2 Height(cm)",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK2DIAMETER_: "Tank 2 Diameter(cm)",
  _FLEET_VEHICLE_VEHICLEDETAIL_FID2_: "F Id 2",
  _FLEET_VEHICLE_VEHICLEDETAIL_INSTALLATIONSPACE2_: "Installation Space 2(cm)",

  _FLEET_VEHICLE_VEHICLEDETAIL_TANK3SHAPE_: "Tank 3 Shape",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK3LENGTH_: "Tank 3 Length(cm)",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK3WIDTH_: "Tank 3 Width(cm)",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK3HEIGHT_: "Tank 3 Height(cm)",
  _FLEET_VEHICLE_VEHICLEDETAIL_TANK3DIAMETER_: "Tank 3 Diameter(cm)",
  _FLEET_VEHICLE_VEHICLEDETAIL_FID3_: "F Id 3",
  _FLEET_VEHICLE_VEHICLEDETAIL_INSTALLATIONSPACE3_: "Installation Space 3(cm)",

  _LENGTH_: "Length",

  _FLEET_VEHICLE_VEHICLEDETAIL_FUELTYPE_: "Fuel Type",
  _FLEET_VEHICLEDETAIL_FUELTYPEDDL_PETROL: "Petrol",
  _FLEET_VEHICLEDETAIL_FUELTYPE_DIESEL_: "Diesel",
  _FLEET_VEHICLE_VEHICLEDETAIL_STATUS_: "Status",
  _FLEET_VEHICLEDETAIL_STATUSDDL_ACTIVE_: "Active",
  _FLEET_VEHICLEDETAIL_STATUSDDL_INACTIVE_: "Inactive",
  _FLEET_VEHICLE_VEHICLEDETAIL_UPLOADICON_: "Upload Icon",
  _FLEET_VEHICLE_VEHICLEDETAIL_ICON_: "Icon",
  _FLEET_VEHICLE_VEHICLEDETAIL_NOICON_: "No Icon",
  _FLEET_VEHICLEDETAIL_DIGITALIPDDL_DOOR_: "Door",
  _FLEET_VEHICLEDETAIL_DIGITALIPDDL_SEATBELT_: "Seatbelt",
  _FLEET_VEHICLEDETAIL_DIGITALIPDDL_ACC_: "ACC",
  _FLEET_VEHICLEDETAIL_DIGITALIPDDL_PANIC_: "Panic",
  _FLEET_VEHICLE_VEHICLEDETAIL_DIGITAL1_: "DigiInput1",
  _FLEET_VEHICLE_VEHICLEDETAIL_DIGITAL2_: "DigiInput2",
  _FLEET_VEHICLE_VEHICLEDETAIL_DIGITAL3_: "DigiInput3",
  _FLEET_VEHICLE_VEHICLEDETAIL_DIGITAL4_: "DigiInput4",
  _FLEET_VEHICLE_VEHICLEDETAIL_SETLOAD_: "Saving Asset Data...",
  _FLEET_VEHICLE_VEHICLEDETAIL_SAVEMSG_: "Save Successfully",
  _FLEET_VEHICLE_VEHICLEDETAIL_ERRORMSG_:
    "Please fill correct data in the fields that are marked red.",
  //_FLEET_VEHICLE_VEHICLEDETAIL_ERRORPHOTO_: 'Please upload valid image. Image dimensions should be 75*75',
  _FLEET_VEHICLE_VEHICLEDETAIL_ERRORPHOTO_:
    "Please upload valid image. Image dimensions should be 150*150",
  _FLEET_VEHICLE_VEHICLEDETAIL_ERRORIMEI_: "IMEI NO. Already Exist for Asset",

  _FLEET_MANAGEVEHICLE_DRIVER_TAB_: "Driver",
  _FLEET_DRIVER_DRIVERLIST_TITLE_: "Driver List",

  _FLEET_DRIVER_DRIVERLIST_COMPANYDDL_: "Account",
  _FLEET_DRIVER_DRIVERLIST_ADDBTN_: "Add",
  _FLEET_DRIVER_DRIVERLIST_WASLBTN_: "Wasl",
  _FLEET_DRIVER_DRIVERLIST_DRIVERNAME_: "Driver Name",
  _FLEET_DRIVER_DRIVERLIST_LICENCE_: "License Number",
  _LICENSE_NUMBER_ARABIC_: "License No Arabic",
  _DRIVER_NAME_ARABIC_: "Driver Name Arabic",
  _FLEET_DRIVER_DRIVERLIST_MOBILE_: "Mobile Number",
  _FLEET_DRIVER_DRIVERLIST_EDIT_: "Edit Driver",
  _FLEET_DRIVER_DRIVERLIST_DELETE_: "Delete Driver",
  _FLEET_DRIVER_DRIVERLIST_REMOVETITLE_: "Remove Driver?",
  _FLEET_DRIVER_DRIVERLIST_REMOVEMSG_: "are you want to sure remove?",
  _FLEET_DRIVER_DRIVERLIST_YESBTN_: "yes",
  _FLEET_DRIVER_DRIVERLIST_DELETELODING_: "Removing Driver Data..",
  _ADD_NEW_: "ADD NEW",

  _FLEET_DRIVER_DRIVERDETAIL_TITLE_: "Driver Details",
  _FLEET_DRIVER_DRIVERDETAIL_BACKBTN_: "BACK",
  _FLEET_DRIVER_DRIVERDETAIL_SAVEBTN_: "SAVE",
  _FLEET_DRIVER_DRIVERDETAIL_CANCELBTN_: "CANCEL",

  _FLEET_DRIVER_DRIVERDETAIL_COMPANY_: "Account",
  _FLEET_DRIVER_DRIVERDETAIL_LICENCENO_: "License Number",
  _FLEET_DRIVER_DRIVERDETAIL_DRIVERNAME_: "Driver Name",
  _FLEET_DRIVER_DRIVERDETAIL_ADDRESS_: "Driver Address",
  _FLEET_DRIVER_DRIVERDETAIL_UPLOAD_: "Upload Photo Id",
  _FLEET_DRIVER_DRIVERDETAIL_ICON_: "Icon",
  _FLEET_DRIVER_DRIVERDETAIL_NOIMAGE_: "No Image",
  _FLEET_DRIVER_DRIVERDETAIL_LICENSEEXPIRY_: "License Expiry Date",
  _FLEET_DRIVER_DRIVERDETAIL_MOBILE_: "Mobile Number",
  _FLEET_DRIVER_DRIVERDETAIL_EMERGENCYNAME_: "Emergency Contact Name",
  _FLEET_DRIVER_DRIVERDETAIL_EMERGENCYNUMBER_: "Emergency Contact Number",
  _FLEET_DRIVER_DRIVERDETAIL_BLOODGROUP_: "Blood Group",
  _FLEET_DRIVER_DRIVERDETAIL_NOTES_: "Notes",
  _FLEET_DRIVER_DRIVERDETAIL_SETLOAD_: "Saving Driver Data...",
  _FLEET_DRIVER_DRIVERDETAIL_SAVEMSG_: "Driver data save successfully",
  _FLEET_DRIVER_DRIVERDETAIL_ERRORMSG_:
    "Please fill correct data in the fields that are marked red.",
  _FLEET_DRIVER_DRIVERDETAIL_ERRORPHOTO_:
    "Please upload valid image. Image dimensions should be 75*75",

  _FLEET_MANAGERULE_TAB_: "Manage Rules",
  _FLEET_MANAGERULE_RULEADMIN_TAB_: "Rule Admin",
  _FLEET_RULEADMIN_RULELIST_TITLE_: "Rule List",

  _FLEET_RULEADMIN_RULELIST_COMPANYDDL_: "Account",
  _FLEET_RULEADMIN_RULELIST_ADDBTN_: "Add",
  _FLEET_RULEADMIN_RULELIST_RULEID_: "Rule Id",
  _FLEET_RULEADMIN_RULELIST_DESCRIPTION_: "Description",
  _FLEET_RULEADMIN_RULELIST_CRONRULE_: "Cron Rule",
  _FLEET_RULELIST_CRONRULE_NO_: "No",
  _FLEET_RULELIST_CRONRULE_5MIN_: "5 Minute",
  _FLEET_RULELIST_CRONRULE_15MIN_: "15 Minute",
  _FLEET_RULELIST_CRONRULE_30MIN_: "30 Minute",
  _FLEET_RULELIST_CRONRULE_HOURLY_: "Hourly",
  _FLEET_RULELIST_CRONRULE_DAILY_: "Daily",
  _FLEET_RULELIST_CRONRULE_WEEKLY_: "Weekly",
  _FLEET_RULEADMIN_RULELIST_ACTIVE_: "Active",
  _FLEET_RULEADMIN_RULELIST_EDIT_: "Edit Rule",
  _FLEET_RULEADMIN_RULELIST_DELETE_: "Delete Rule",
  _FLEET_RULEADMIN_RULELIST_REMOVETITLE_: "Remove Rule?",
  _FLEET_RULEADMIN_RULELIST_REMOVEMSG_: "Are you sure you want to remove?",
  _FLEET_RULEADMIN_RULELIST_YESBTN_: "yes",
  _FLEET_RULEADMIN_RULELIST_DELETELODING_: "Removing Rule Data..",

  _FLEET_RULEADMIN_RULEDETAIL_TITLE_: "Rule Details",
  _FLEET_RULEADMIN_RULEDETAIL_BACKBTN_: "BACK",
  _FLEET_RULEADMIN_RULEDETAIL_SAVEBTN_: "SAVE",
  _FLEET_RULEADMIN_RULEDETAIL_CANCELBTN_: "CANCEL",

  _FLEET_RULEADMIN_RULEDETAIL_COMPANY_: "Account",
  _FLEET_RULEADMIN_RULEDETAIL_RULENAME_: "Alert Name",
  _FLEET_RULEADMIN_RULEDETAIL_NOTIFICATIONEMAIL_: "Notification Email",
  _FLEET_RULEADMIN_RULEDETAIL_NOTIFICATIONMOBILE_: "Notification Mobile",
  _FLEET_RULEADMIN_RULEDETAIL_SYSTEMRULE_: "System Rule",
  _FLEET_RULEADMIN_RULEDETAIL_ACTIVE_: "Active",
  _FLEET_RULEDETAIL_ACTIVEDDL_NO_: "No",
  _FLEET_RULEDETAIL_ACTIVEDDL_YES_: "Yes",
  _FLEET_RULEADMIN_RULEDETAIL_CRONRULE_: "Cron Rule",
  _FLEET_RULEDETAIL_CRONRULE_NO_: "No",
  _FLEET_RULEDETAIL_CRONRULE_5MIN_: "5 Minute",
  _FLEET_RULEDETAIL_CRONRULE_15MIN_: "15 Minute",
  _FLEET_RULEDETAIL_CRONRULE_30MIN_: "30 Minute",
  _FLEET_RULEDETAIL_CRONRULE_HOURLY_: "Hourly",
  _FLEET_RULEDETAIL_CRONRULE_DAILY_: "Daily",
  _FLEET_RULEDETAIL_CRONRULE_WEEKLY_: "Weekly",
  _FLEET_RULEDETAIL_CRONRULE_RULETRIGGER_:
    "(Used for periodic Rule triggering)",
  _FLEET_RULEADMIN_RULEDETAIL_DESCRIPTION_: "Description",
  _FLEET_RULEADMIN_RULEDETAIL_RULESELECTOR_: "Alert Selector",
  _FLEET_RULEADMIN_RULEDETAIL_RULESELECTORBTN_: "Select Alert Selector",
  _FLEET_RULEADMIN_RULEDETAIL_TRIGGERACTION_: "Trigger Action",
  _FLEET_RULEDETAIL_TRIGGERACTION_EMAIL_: "EMail",
  _FLEET_RULEDETAIL_TRIGGERACTION_SAVE_: "Save/Alert",
  _FLEET_RULEDETAIL_TRIGGERACTION_QUEUE_: "Queue",
  _FLEET_RULEADMIN_RULEDETAIL_MININTERVAL_: "Alert Interval",
  _FLEET_RULEADMIN_RULEDETAIL_MININTERVALMIN_: "(minutes)",
  _FLEET_RULEADMIN_RULEDETAIL_PREDEFINEACTION_: "Predefined Actions",
  _FLEET_RULEDETAIL_PREDEFINEACTIONDDL_SELECT_: "Select",
  _FLEET_RULEDETAIL_PREDEFINEACTIONDDL_ON_: "Send command Immobilization ON",
  _FLEET_RULEDETAIL_PREDEFINEACTIONDDL_OFF_: "Send command Immobilization OFF",
  _FLEET_RULEADMIN_RULEDETAIL_EMAILSUBJECT_: "Email Subject",
  _FLEET_RULEADMIN_RULEDETAIL_SELECTPARAMETER_: "Select parameter",
  _FLEET_RULEDETAIL_SELECTPARAMETER_VEHICLE_: "Asset No",
  _FLEET_RULEDETAIL_SELECTPARAMETER_SPEED: "Speed",
  _FLEET_RULEDETAIL_SELECTPARAMETER_DISTANCE_: "Distance",
  _FLEET_RULEDETAIL_SELECTPARAMETER_LOCATION_: "Location",
  _FLEET_RULEDETAIL_SELECTPARAMETER_TEMPERATURE_: "Temperature",
  _FLEET_RULEADMIN_RULEDETAIL_EMAILMESSAGE_: "EMail Message",
  _FLEET_RULEADMIN_RULEDETAIL_SMSMESSAGE_: "SMS Message",
  _FLEET_RULEADMIN_VEHICLEDETAIL_TITLE_: "Asset/StatusCode Selection:",
  _FLEET_RULEADMIN_RULEDETAIL_VEHICLEID_: "By Asset ID",
  _FLEET_RULEADMIN_RULEDETAIL_VEHICLE_EMPTY_: "No Vehicles (Select By Groups)",
  _FLEET_RULEADMIN_RULEDETAIL_VEHICLE_ERRORMSG_: "Please Select Asset.",
  _FLEET_RULEADMIN_RULEDETAIL_GROUPID_: "By Group ID",
  _FLEET_RULEADMIN_RULEDETAIL_GROUP_EMPTY_: "No Groups (Select By Vehicles)",
  _FLEET_RULEADMIN_RULEDETAIL_GROUP_ERRORMSG_:
    "Please Select Asset ID or Group ID.",
  _FLEET_RULEADMIN_RULEDETAIL_STATUSCODE_: "Status Code",
  _FLEET_RULEDETAIL_STATUSCODEDDL_ALLCODE_: "All Code",
  _FLEET_RULEADMIN_RULEDETAIL_SETLOAD_: "Saving Rule Admin Data...",
  _FLEET_RULEADMIN_RULEDETAIL_SAVEMSG_: "Save Successfully",
  _FLEET_RULEADMIN_RULEDETAIL_ERRORMSG_:
    "Please fill correct data in the fields that are marked red.",

  _FLEET_RULEDETAIL_RULESELECTORWIN_TITLE_: "Alert Selector Window",
  _FLEET_RULEDETAIL_RULESELECTORWIN_ALERTTITLE_: "Close confirmation",
  _FLEET_RULEADMIN_RULESELECTORWIN_ALERTMSG_:
    "You have unsaved changes on this window. Do you want to close window and lose your changes?",
  _FLEET_RULEADMIN_RULESELECTORWIN_YESBTN_: "yes",

  _FLEET_RULESELECTORDETAIL_TITLE_: "Alert Selector Details",
  _FLEET_RULESELECTORDETAIL_SAVEBTN_: "SAVE",
  _FLEET_RULESELECTORDETAIL_ENABLEBTN_: "Enable",
  _FLEET_RULESELECTORDETAIL_DISABLEBTN_: "Disable",
  _FLEET_RULESELECTORDETAIL_DESCRIPTION_: "Description",
  _FLEET_RULESELECTORDETAIL_ERRORMSG_: "This is a required field.",

  _FLEET_RULESELECTORDETAIL_RULE1_TITLE_: "Harsh Braking",
  _FLEET_RULESELECTORDETAIL_RULE1__SETSPEEDLIMIT_:
    "Set Drop Speed Limit(km/hr)",
  _FLEET_RULESELECTORDETAIL_RULE1_DESCRIPTION_:
    "This Rule is triggered when sudden brake is applied by driver. User can set the drop speed limit in km/hr. Rule will be triggered when speed is dropped suddenly to the set limit and user will be notified for same.",

  _FLEET_RULESELECTORDETAIL_RULE2_TITLE_: "Harsh Acceleration",
  _FLEET_RULESELECTORDETAIL_RULE2_SETACCERSPEEDLIMIT_:
    "Set Accelearted Speed Limit(km/hr)",
  _FLEET_RULESELECTORDETAIL_RULE2_DESCRIPTION_:
    "This Rule is triggered when sudden acceleration is applied by driver. User can set the accelerated speed limit in km/hr. Rule will be triggered when speed is accelerated suddenly to the defined limit and user will be notified for same.",

  _FLEET_RULESELECTORDETAIL_RULE3_TITLE_: "Seatbelt",
  _FLEET_RULESELECTORDETAIL_RULE3_SETVEHICLESPEED_: "Set Asset Speed(km/hr)",
  _FLEET_RULESELECTORDETAIL_RULE3_DESCRIPTION_:
    "This rule is triggered when vehicle is moving with speed:10 kmph and driver is not wearing the seatbelt.",

  _FLEET_RULESELECTORDETAIL_RULE4_TITLE_: "Working out of Hours",
  _FLEET_RULESELECTORDETAIL_RULE4_FROMTIME_: "From Time",
  _FLEET_RULESELECTORDETAIL_RULE4_TOTIME_: "To Time",
  _FLEET_RULESELECTORDETAIL_RULE4_SPEEDLIMIT_: "Set Speed Limit(km/hr)",
  _FLEET_RULESELECTORDETAIL_RULE4_DESCRIPTION_:
    "This rule is triggered when vehicle in driven out of working hours with speed greater than defined speed limit. If speed limit is not required user can set it as 0. Note: Please define working hours in above time field.",

  _FLEET_RULESELECTORDETAIL_RULE5_TITLE_: "Asset Stopped Reporting",
  _FLEET_RULESELECTORDETAIL_RULE5_SETTIMELIMIT_: "Set Time Limit(Min)",
  _FLEET_RULESELECTORDETAIL_RULE5_DESCRIPTION_:
    "This Rule is triggered when vehicle has stopped reporting for defined time interval.",

  _FLEET_RULESELECTORDETAIL_RULE6_TITLE_: "Stopped Out of Geofence",
  _FLEET_RULESELECTORDETAIL_RULE6_GEOFENCE_: "Select Geofence",
  _FLEET_RULESELECTORDETAIL_RULE6_DESCRIPTION_:
    "This rule is triggered when vehicle is stopped outside geofence for defined time interval.",

  _FLEET_RULESELECTORDETAIL_RULE7_TITLE_: "Overspeeding",
  _FLEET_RULESELECTORDETAIL_RULE7_SETSPEEDLIMIT_: "Set Speed Limit(Km/hr)",
  _FLEET_RULESELECTORDETAIL_RULE7_DESCRIPTION_:
    "This rule is triggered when vehicle crosses defined speed limit.",

  _FLEET_RULESELECTORDETAIL_RULE8_TITLE_: "Input On/Off",
  _FLEET_RULESELECTORDETAIL_RULE8_INPUT_: "Select Input",
  _FLEET_RULESELECTORDETAIL_RULE8_EVENT_: "Select Event",
  _FLEET_RULESELECTORDETAIL_RULE8_EVENT_ON_: "ON",
  _FLEET_RULESELECTORDETAIL_RULE8_EVENT_OFF_: "OFF",
  _FLEET_RULESELECTORDETAIL_RULE8_DESCRIPTION_:
    "This rule is triggered when any input is turned on or off.",

  _FLEET_RULESELECTORDETAIL_RULE9_TITLE_: "Temperature Monitoring",
  _FLEET_RULESELECTORDETAIL_RULE9_SETTEMPERATURE_:
    "Set Temperature Level(MinTemp)",
  _FLEET_RULESELECTORDETAIL_RULE9_MAXTEMP_: "Max Temp",
  _FLEET_RULESELECTORDETAIL_RULE9_DESCRIPTION_:
    "This Rule is triggered when temperatue goes beyond the defined limit. User will set minimum and maximum temperature.",

  _FLEET_RULESELECTORDETAIL_RULE10_TITLE_: "Fuel Increase",
  _FLEET_RULESELECTORDETAIL_RULE10_FUELINCREASE_: "Set Fuel increase Level(%)",
  _FLEET_RULESELECTORDETAIL_RULE10_DESCRIPTION_:
    "This rule is triggered when fuel level increases by defined percentage.",

  _FLEET_RULESELECTORDETAIL_RULE11_TITLE_: "Fuel Drop",
  _FLEET_RULESELECTORDETAIL_RULE11_FUELDROP_: "Set Fuel Drop Level(%)",
  _FLEET_RULESELECTORDETAIL_RULE11_FUELDROPDDL_SENS1_: "Fuel SensorId 1",
  _FLEET_RULESELECTORDETAIL_RULE11_FUELDROPDDL_SENS2_: "Fuel SensorId 2",
  _FLEET_RULESELECTORDETAIL_RULE11_FUELDROPDDL_SENS3_: "Fuel SensorId 3",
  _FLEET_RULESELECTORDETAIL_RULE11_DESCRIPTION_:
    "This rule is triggered when fuel drops by defined percentage.",

  _FLEET_RULESELECTORDETAIL_RULE12_TITLE_: "Geofence Arrival/Departure",
  _FLEET_RULESELECTORDETAIL_RULE12_GEOFENCE_: "Select Geofence",
  _FLEET_RULESELECTORDETAIL_RULE12_EVENT_: "Select Event",
  _FLEET_RULESELECTORDETAIL_RULE12_EVENTDDL_INOUT_: "Inside/Outside",
  _FLEET_RULESELECTORDETAIL_RULE12_EVENTDDL_IN_: "Inside",
  _FLEET_RULESELECTORDETAIL_RULE12_EVENTDDL_OUT_: "Outside",
  _FLEET_RULESELECTORDETAIL_RULE12_DESCRIPTION_:
    "This Rule is triggered when Asset enters or exits assigned geofence.",

  _FLEET_RULESELECTORDETAIL_RULE13_TITLE_: "Distance travelled",
  _FLEET_RULESELECTORDETAIL_RULE13_ASSIGNDISTANCE_: "Assign Distance(KM)",
  _FLEET_RULESELECTORDETAIL_RULE13_DESCRIPTION_:
    "This Rule is triggered when vehicle has travelled given distance.",

  _FLEET_RULESELECTORDETAIL_RULE14_TITLE_: "Service Reminder",
  _FLEET_RULESELECTORDETAIL_RULE14_DESCRIPTION_:
    "This Rule is triggered with Service has been expired.",

  _FLEET_RULESELECTORDETAIL_RULE15_TITLE_:
    "Send Last Position at Fixed Interval",
  _FLEET_RULESELECTORDETAIL_RULE15_SELECTINTERVAL_: "Select Interval(Hour)",
  _FLEET_RULESELECTORDETAIL_RULE15_DESCRIPTION_:
    "This Rule will send latest postion by email at fixed time interval.",

  _FLEET_REPORTS_TAB_: "Reports",
  _REPORT_: "Report",

  _FLEET_REPORTS_COMPANY_: "Account",

  _FLEET_REPORTS_VEHICLENO_: "Asset Name",
  _FLEET_REPORTS_VEHICLETYPE_: "Asset Type",
  _FLEET_REPORTS_FROM_: "From",
  _FLEET_REPORTS_TO_: "To",
  _FLEET_REPORTS_SHOWBTN_: "Show",
  _FLEET_REPORTS_EMAILBTN_: "Email",

  _FLEET_REPORTS_COMPLETEREPORT_TITLE_: "Track Data",

  _FLEET_REPORTS_FUELREPORT_TITLE_: "Fuel Level Report",
  _FLEET_REPORTS_FUELREPORT_FUELVOLUME_: "Fuel Volume and Odometer",
  _FLEET_REPORTS_FUELREPORT_TIME_: "Time",

  _FLEET_REPORTS_TEMPREPORT_TITLE_: "Temperature Report",
  _FLEET_REPORTS_TEMPREPORT_TEMPERATURE_: "Temperature",
  _FLEET_REPORTS_TEMPREPORT_TIME_: "Time",
  _TOTAL_TRAVEL_REPORT_: "Total Travel Report",
  _FLEET_REPORTS_STARTSTOPREPORT_TITLE_: "Travel Report",
  _FLEET_REPORTS_STARTSTOPREPORT_STOPEDTIME_: "Stopped Time",
  _FLEET_REPORTS_STARTSTOPREPORT_STARTDATETIME_: "Start Date/Time",
  _FLEET_REPORTS_STARTSTOPREPORT_STARTADDRESS_: "Start Address",
  _FLEET_REPORTS_STARTSTOPREPORT_STOPDATETIME_: "Stop Date/Time",
  _FLEET_REPORTS_STARTSTOPREPORT_STOPADDRESS_: "Stop Address",
  _FLEET_REPORTS_STARTSTOPREPORT_DISTTRAVEL_: "Distance Travelled",
  _FLEET_REPORTS_STARTSTOPREPORT_ELAPSEDTIME_: "Duration",
  _FLEET_REPORTS_STARTSTOPREPORT_FUELUSED_1: "Fuel Used 1",
  _FLEET_REPORTS_STARTSTOPREPORT_FUELUSED_2: "Fuel Used 2",
  _FLEET_REPORTS_STARTSTOPREPORT_FUELUSED_3: "Fuel Used 3",

  _FLEET_REPORTS_DISTANCEREPORT_TITLE_: "Day Wise Distance Report",
  _FLEET_REPORTS_DISTANCEREPORT_STARTOD_: "Start Odometer",
  _FLEET_REPORTS_DISTANCEREPORT_STOPOD_: "Stop Odometer",

  _FLEET_REPORTS_STOPREPORT_TITLE_: "Stoppage Report",
  _FLEET_REPORTS_STOPREPORT_ADDRESS_: "Address",
  _FLEET_REPORTS_STOPREPORT_NEARESTPOI_: "Nearest POI(if exists)",
  _FLEET_REPORTS_STOPREPORT_ARRIVALTIME_: "Arrival Time",
  _FLEET_REPORTS_STOPREPORT_DEPARTURETIME_: "Departure Time",
  _FLEET_REPORTS_STOPREPORT_TIMESTOP_: "Time Stopped",
  _FLEET_REPORTS_STOPREPORT_VIEWMAP_: "View on map",

  _FLEET_REPORTS_EVENTREPORT_TITLE_: "Event Report",
  _FLEET_REPORTS_EVENTREPORT_DATETIME_: "Date/Time",
  _FLEET_REPORTS_EVENTREPOR_ADDRESS_: "Address",
  _FLEET_REPORTS_EVENTREPOR_SPEED_: "Speed",
  _FLEET_REPORTS_EVENTREPOR_ODOMETER_: "Odometer",
  _FLEET_REPORTS_EVENTREPOR_EVENT_: "Event",
  _FLEET_REPORTS_EVENTREPOR_IGNITION_: "Ignition Staus",
  _FLEET_REPORTS_EVENTREPOR_VIEWMAP_: "View on map",

  _FLEET_REPORTS_RULETRIGGERREPORT_TITLE_: "Rules Trigger Report",
  _FLEET_REPORTS_RULETRIGGERREPORT_DATETIME_: "Date/Time",
  _FLEET_REPORTS_RULETRIGGERREPORT_ADDRESS_: "Address",
  _FLEET_REPORTS_RULETRIGGERREPORT_SPEED_: "Speed",
  _FLEET_REPORTS_RULETRIGGERREPORT_ODOMETER_: "Odometer",
  _FLEET_REPORTS_RULETRIGGERREPORT_EVENT_: "Event",
  _FLEET_REPORTS_RULETRIGGERREPORT_IGNITION_: "Ignition Staus",
  _FLEET_REPORTS_RULETRIGGERREPORT_RULETRIGGER_: "Rule Triggered",
  _FLEET_REPORTS_RULETRIGGERREPORT_VIEWMAP_: "View on map",

  _FLEET_REPORTS_FLEETREPORT_TITLE_: "Fleet Summary Report",
  _FLEET_DAY_SUMMRY_REPORTS_FLEETREPORT_TITLE_: "Fleet Day Summary Report",
  _FLEET_REPORTS_FLEETREPORT_VEHICLE_: "Asset",
  _FLEET_REPORTS_FLEETREPORT_DATETIME_: "Date/Time",
  _FLEET_REPORTS_FLEETREPORT_MAXSPEED_: "Max Speed",
  _FLEET_REPORTS_FLEETREPORT_AVERAGESPEED_: "Average Speed",
  _FLEET_REPORTS_FLEETREPORT_DELTAMILAGE_:
    "Delta mileage in the selected period",
  _FLEET_REPORTS_FLEETREPORT_CURRENTODOMETER_: "Current Odometer",
  _FLEET_REPORTS_FLEETREPORT_MAXRPM_: "Max RPM(from OBD or CANBUS)",
  _FLEET_REPORTS_FLEETREPORT_AVERAGERPM_: "Average RPM(from OBD or CANBUS)",
  _FLEET_REPORTS_FLEETREPORT_FUELCONSUM_: "Fuel Consumption(Lt)",
  _FLEET_REPORTS_FLEETREPORT_FUELECONOMY_: "Fuel Economy(Km/lt)",
  _FLEET_REPORTS_FLEETREPORT_FATIGUEHOUR_: "Fatigue hours",
  _FLEET_REPORTS_FLEETREPORT_HARSHBREAK_: "Harsh break alarms",
  _FLEET_REPORTS_FLEETREPORT_HARSHACCELERATION_: "Harsh acceleration alarms",

  _FLEET_REPORTS_GEOFENCEREPORT_TITLE_: "Geofence Or POI In Out Report",
  _FLEET_REPORTS_GEOFENCEREPORT_INTIME_: "In Time",
  _FLEET_REPORTS_GEOFENCEREPORT_OUTTIME_: "Out Time",
  _FLEET_REPORTS_GEOFENCEREPORT_GEOPOI_: "Geofence or POI",
  _FLEET_REPORTS_GEOFENCEREPORT_ELAPSETIME_: "Elapsed Time Inside the Geofence",
  _FLEET_REPORTS_GEOFENCEREPORT_TIMESTOP_: "Time Stopped",

  _FLEET_REPORTS_COMMANDREPORT_TITLE_: "Commands Sent Report",
  _FLEET_REPORTS_COMMANDREPORT_DATETIME_: "Date/Time",
  _FLEET_REPORTS_COMMANDREPORT_COMMANDSENT_: "Command Sent",
  _FLEET_REPORTS_COMMANDREPORT_DEVICECOMMAND_:
    "Did the device received the command?",
  _FLEET_REPORTS_COMMANDREPORT_USER_: "User",
  _FLEET_REPORTS_COMMANDREPORT_INPUTSENT_: "Input Sent",
  _FLEET_REPORTS_COMMANDREPORT_REPLY_: "Reply String",

  _FLEET_REPORTS_OBDFAULTREPORT_TITLE_: "OBD Fault Codes",
  _FLEET_REPORTS_OBDFAULTREPORT_VEHICLE_: "Asset",
  _FLEET_REPORTS_OBDFAULTREPORT_LASTFAULTCODE_:
    "Date/time when last FAULT CODE has been received",
  _FLEET_REPORTS_OBDFAULTREPORT_FAULTCODE_: "Fault Code",
  _FLEET_REPORTS_OBDFAULTREPORT_LINK_: "Link**",

  _FLEET_REPORTS_MAINTENANCEREPORT_TITLE_: "Maintenance Fleet Report",
  _FLEET_REPORTS_MAINTENANCEREPORT_VEHICLE_: "Asset",
  _FLEET_REPORTS_MAINTENANCEREPORT_LASTOILCHANGE_:
    "Last Oil Change(Date/Time and Odometer)",
  _FLEET_REPORTS_MAINTENANCEREPORT_LASTTIRECHANGE_:
    "Last Tire change(Date/Time and Odometer)",
  _LAST_TYRE_CHANGE_: "Last Tyre Change(Date/Time and Odometer)",
  _FLEET_REPORTS_MAINTENANCEREPORT_LASTMAJORSERVICE_:
    "Last Major Service(Date/Time and Odometer)",

  // _FLEET_REPORTS_MAINTENANCEREPORT_NEXTOILCHANGE_: 'Next Oil Change(Date/Time and Odometer)',
  _FLEET_REPORTS_MAINTENANCEREPORT_NEXTOILCHANGE_: "Next Oil Change (km)",
  //_FLEET_REPORTS_MAINTENANCEREPORT_NEXTTIRECHANGE_: 'Next Tire Change(Date/Time and Odometer)',
  _FLEET_REPORTS_MAINTENANCEREPORT_NEXTTIRECHANGE_: "Next Tire change (km)",
  _NEXT_TYRE_CHANGE_KM_: "Next Tyre Change(Km)",
  //_FLEET_REPORTS_MAINTENANCEREPORT_NEXTMAJORSERVICE_: 'Next Major Service (Date/Time and Odometer)',
  _FLEET_REPORTS_MAINTENANCEREPORT_NEXTMAJORSERVICE_:
    "Next Major Service change(km) ",

  _FLEET_REPORTS_MAINTENANCEREPORT_CURRENTODOMETER_: "Current Odometer Value",

  _FLEET_REPORTS_VEHICLEREPORT_TITLE_: "Asset Registered Report",
  _FLEET_REPORTS_VEHICLEREPORT_VEHICLE_: "Asset",
  _FLEET_REPORTS_VEHICLEREPORT_DEVICE_: "Device ID",
  _FLEET_REPORTS_VEHICLEREPORT_PLATE_: "Plates",
  _FLEET_REPORTS_VEHICLEREPORT_SERIALNO_: "Serial Number",
  _FLEET_REPORTS_VEHICLEREPORT_BRAND_: "Brand",
  _FLEET_REPORTS_VEHICLEREPORT_MODEL_: "Model",
  _FLEET_REPORTS_VEHICLEREPORT_YEAR_: "Year",
  _FLEET_REPORTS_VEHICLEREPORT_COLOR_: "Color",
  _FLEET_REPORTS_VEHICLEREPORT_VEHICLETYPE_: "Asset Type",
  _FLEET_REPORTS_VEHICLEREPORT_FUELECONOMY_: "Fuel Economy(Km/lt)",
  _FLEET_REPORTS_VEHICLEREPORT_VEHICLESTATUS_: "Asset Status",
  _FLEET_REPORTS_VEHICLEREPORT_REGISTER_: "Register Date",

  _FLEET_REPORTS_GEOFENCELIST_TITLE_: "Geofences List",
  _FLEET_REPORTS_GEOFENCELIST_GEOFENCENAME_: "Geofence Name",
  _FLEET_REPORTS_GEOFENCELIST_CREATIONDATE_: "Creation Date",
  _FLEET_REPORTS_GEOFENCELIST_VIEWMAP_: "View on map",

  _FLEET_REPORTS_CHATREPORT_TITLE_: "Chat Report",
  _FLEET_REPORTS_CHATREPORT_DATETIME_: "Date/Time",
  _FLEET_REPORTS_CHATREPORT_MESSAGE_: "Message",
  _FLEET_REPORTS_CHATREPORT_RECEIVE_: "Received",
  _FLEET_REPORTS_CHATREPORT_SENT_: "Sent",
  _FLEET_REPORTS_CHATREPORT_LOCATION_: "Location of the vehicle",
  _FLEET_REPORTS_CHATREPORT_User_: "User",

  /* New */
  _TRACKING_: "Tracking",
  _MAINTENANCE_: "Maintenance",
  _REPORTS_: "Reports",
  _MANAGE_APP_: "Manage App",
  _MANAGE_ACCOUNTS_: "Manage Account",
  _DASHBOARD_: "Dashboard",
  _DASH_BOARD_: "DASHBOARD",
  _HELP_AND_SUPPORT_: "Help & Support",
  _ROAD_ASSISTANCE_: "Road Assistance",
  _ADMIN_ACCESS_: "Admin Access",
  _LIVE_TRACING_: "Live Tracking",
  _LIVE_TRAVERSE_: "Live Travers",
  _DETAILED_TRACKING_: "Detailed Tracking",
  _HISTORY_TRACKING_: "History Tracking",
  _MULTI_TRACKING_: "Multi Tracking",
  _HEAT_MAP_: "Heat Map",
  _TRACK_DATA_REPORT_: "Track Data Report",
  _TRAVEL_REPORT_: "Travel Report",
  _STOPPAGE_REPORT_: "Stoppage Report",
  _TRIP_REPORT_: "Trip Report",
  _EX_IDLE_REPORT_: "Ex-Idle Report",
  _IDLE_REPORT_: "Idle Report",
  _DISTANCE_REPORT_: "Distance Report",
  _DETAILED_DISTANCE_REPORT_: "Detailed Distance Report",
  _ACCOUNT_: "Account",
  _ASSET_NAME_: "Asset Name",
  _FROM_: "From",
  _FROM_TIME_: "From Time",
  _TO_TIME_: "To Time",
  _FILTER_: "Filter",
  _SHOW_: "Show",
  _EXPORT_: "Export",
  _EMAIL_: "Email",
  _TIKTOK_: "Tiktok",
  _NEW_TAB_: "New Tab",
  _START_ODOMETER_: "Start Odometer",
  _STOP_ODOMETER_: "Stop Odometer",
  _DISTANCE_TRAVELED_KMS_: "Distance Travelled (Kms)",
  _DISTANCE_TRAVELED_: "Distance Travelled",
  _START_DATETIME_: "Start Datetime",
  _STOP_DATETIME_: "Stop Datetime",
  _FLEET_REPORT_: "Fleet Report",
  _FLEET_SUMMARY_REPORT_: "Fleet Summary Report",
  _FUEL_FILLED_REPORT_: "Fuel Filled Report",
  _MILEAGE_REPORT_: "Mileage Report",
  _MILEAGE_: "Mileage",
  _FUEL_PRICE_: "Fuel Price",
  _FLEET_MANAGEMENT_REPORT_: "Fleet Management Report",

  _VEHICLE_DIAGNOSTIC_REPORT_: "Vehicle Diagnostic Report",
  _ALERT_REPORT_: "Alert Report",
  _SPEED_VIOLATION_REPORT_: "Speed Violation Report",
  _SPEED_KM_: "Speed(Km/h)",
  _GEOFENCE_OR_POI_INOUT_REPORT_: "Geofence Or POI IN/OUT Report",
  _TEMP_REPORT_: "Temp Report",
  _VEHICLE_NUMBER_: "Vehicle Number",
  _INTERVAL_: "Interval",
  _CRITICAL_DATA_: "Critical Data",
  _GEOFENCE_SUMMARY_REPORT_: "Geofence Summary Report",
  _SUMMARY_: "Summary",
  _DETAIL_GEOFENCE_SUMMARY_: "Detail Geofence Summary",
  _GEOFENCE_NAME_: "Geofence Name",
  _GEOFENCE_TYPE_: "Geofence Type",
  _IN_TIME_: "In Time",
  _OUT_TIME_: "Out Time",
  _DISTANCE_: "Distance",
  _FUEL_: "Fuel",
  _COST_: "Cost",
  _RT_IDLE_TIME_: "RT Idle Time",
  _RT_TRAVEL_TIME_: "RT Travel Time",
  _FS_IDLE_TIME_: "FS Idle Time",
  _FS_TRAVEL_TIME_: "FS Travel Time",
  _IDLE_TIME_: "Idle Time",
  _TRAVEL_TIME_: "Travel Time",
  _VEHICLE_NOT_POLL_REPORT_: "Vehicle Not Poll Report",
  _RULE_TRIGGER_REPORT_: "Rule Trigger Report",
  _RULE_SELECTOR_: "Rule Selector",
  _DRIVER_RELATED_REPORT_: "Driver Related Report",
  _DRIVER_LOGIN_REPORT_: "Driver Login Report",
  _DRIVER_NAME_: "Driver Name",
  _DRIVER_DETAILS_: "Driver Details",
  _DRIVER_NAME_: "Driver Name",
  _LOGIN_TIME_: "Login Time",
  _LOGOUT_TIME_: "Logout Time",
  _MAX_SPEED_1: "Max Speed",
  _DRIVER_PERFORMANCE_REPORT_: "Driver Performance Report",
  _DRIVER_PERFORMANCE_DETAIL_: "Driver Performance Details",
  _EXC_IDLE_: "Ex Idle",
  _HARSH_BREAK_: "Harsh Brake",
  _HARSH_ACCELERATION_: "Harsh Acceleration",
  _OVER_SPEED_: "Over Speed",
  _MAXIMUM_SPEED_AS_PER_THE_LIMIT_SET_: "Max. speed as per the limit set",
  _OVER_SPEED_AS_PER_THE_ROAD_SPEED_: "Over speed as per the road speed",
  _DRIVER_COMPLAIN_REPORT_: "Driver Complain Report",
  _OVERTIME_REPORT_: "Overtime Report",
  _MISCELLANEOUS_REPORTS_: "Miscellaneous Report",
  _USER_LOGIN_REPORT_: "User Login Report",
  _VEHICLE_REGISTERED_REPORT_: "Vehicle Registered Report",
  _COMMAND_SENT_REPORT_: "Command Sent Report",
  _EVENT_REPORT_: "Event Report",
  _EMAIL_REPORT_: "Email Report",
  _RAW_DATA_REPORT_: "Raw Data Report",
  _GEOFENCES_LIST_: "Geofences List",
  _PASSENGER_LOG_REPORT_: "Passenger Log Report",
  _ATTENDANCE_REPORT_: "Attendance Report",
  _ATTENDANCE_DETAILS_: "Attendance Details",

  _EMPLOYEE_TYPE_: "Employee Type",
  _OFFICE_NAME_: "Office name",
  _CATALOGS_: "Catalogs",
  _USERS_: "Users",
  _USERS_LIST_: "Users List",
  _NAME_: "Name",
  _LAST_NAME_: "Last Name",
  _USERNAME_: "Username",
  _USER_TYPE_: "User Type",
  _EMAIL_ID_: "Email Id",
  _MOBILE_NUMBER_: "Mobile No.",
  _STATUS_: "Status",
  _EMPLOYEE_ID_: "Employee Id",
  _ASSET_ID_: "Asset Id",
  _ACCOUNT_NAME_: "Account Name",
  _ASSET_TYPE_: "Asset Type",
  _DEVICE_: "Device",
  _IMEI_: "IMEI",
  _SIM_NO_: "SIM No",
  _INSTALLATION_: "Installation",
  _EXPIRY_DATE_: "Expiry Date",
  _DRIVER_: "Driver",
  _GROUPS_: "Groups",
  _LICENSE_NUMBER_: "License Number",
  _LICENSE_EXPIRE_: "License Expire",
  _OFFICE_LOCATION_: "Office Location",
  _COMPANY_NAME_: "Company Name",
  _OFFICE_NAME_: "Office Name",
  _OFFICE_TYPE_: "Office Type",
  _CONTACT_PERSON_: "Contact Person",
  _CONTACT_PERSONS_EMAIL_ID: "Contact Person Email Id",
  _ADD_: "Add",
  _REFRESH_: "Refresh",
  _ASSET_DEVICE_ASSIGNMENT_: "Aseet Device Assignment",
  _ALLOCATE_ASSET_: "Allocate Asset",
  _ALLOCATE_DATE_: "Allocate Date",
  _DAYS_USED_: "Days Used",
  _RETURN_ASSET_: "Return Asset",
  _RETURN_DATE_: "Return Date",
  _RULES_ENGINE_: "Rules Engine",
  _RULE_ID_: "Rule Id",
  _DESCRIPTION_: "Description",
  _CRON_RULE_: "Cron Rule",
  _ACTIVE_: "Active",
  _MAINTENANCE_MODULE_: "Maintenance Module",
  _ASSET_NUMBER_: "Asset Number",
  _CURRENT_ODOMETER_: "Current Odometer",
  _GARAGE_NAME_: "Garage Name",
  _GARAGE_ADDRESS_: "Garage Address",

  _FUEL_MANAGEMENT_: "Fuel Management",
  _FUEL_DETAIL_: "Fuel Details",
  _PETROL_: "Petrol",
  _DIESEL_: "Diesel",
  _FUEL_FILLED_: "Fuel Filled",
  // _FLEET_DASHBORD_SETTING_ODOMETER_: 'Odometer',
  _GEOFENCE_MANAGEMENT_: "Geofence Management",
  _CREATION_DATE_: "Creation Date",
  _BOUNDS_: "Bounds",
  _RADIUS_: "Radius",
  _ACTION_: "Action",
  _TRIP_CODE_: "Trip Code",
  _TRIP_COST_: "Trip Cost",
  _TRIP_DURATION_: "Trip Duration",
  _STATUS_AND_DURATION_: "Status & Last Duration",
  _TRIP_NAME_: "Trip Name",
  _ZONE_DETAILS_: "Zone Details",
  _ROUTE_MANAGEMENT_: "Route Management",
  _DELIVERY_MANAGEMENT_: "Delivery Management",
  _DELIVERY_MANAGEMENT_REPORT_: "Delivery Management Report",
  _CUSTOMER_NAME_: "Customer Name",
  _INVOICE_NO_: "Invoice No",
  _INVOICE_AMOUNT_: "Invoice Amount",
  _MATERIAL_DESCRIPTION_: "Material Description",
  _NO_OF_CARTONS_: "No. Of Cartons",
  _PICKUP_DELIVERY_: "Pickup Delivery",
  _BACK_: "Back",
  _SERVICE_: "Service",
  _LOCK_: "Lock",
  _TRANSIT_: "Transit",
  _TRAFFIC_: "Traffic",
  _EDIT_ACCOUNT_: "Edit Account",
  _EDIT_PROFILE_: "Edit Profile",
  _CHANGE_PASSWORD_: "Change Password",
  _NEW_POI_: "New POI",
  _SHOW_POI_: "Show POI",
  _CLEAR_POI_: "Clear POI",
  _SEARCH_POI_: "Search POI",
  _IMPORT_POI_FROM_KML_: "Import POI from KML",
  _DRAW_GEOFENCE_: "Draw Geofence",
  _SHOW_GEOFENCE_: "Show Geofence",
  _IMPORT_EXPORT_FROM_XML_: "Import Export From XML",
  _SEARCH_ASSET_: "Search Asset",
  _SEARCH_ADDRESS_: "Search Address",
  _HOSPITAL_: "Hospital",
  _FUEL_STATION_: "Fuel Station",
  _POLICE_STATION_: "Police Station",
  _DRIVER_: "Driver",
  _NEAREST_ASSET_: "Nearest Asset",
  _GARAGE_: "Garage",
  _NAVIGATION_: "Navigation",
  _CLEAR_MAP_: "Clear Map",
  _DISTANCE_MEASURE_: "Distance Measure",
  _CREATE_ASSIGN_TRIP_: "Create Assign Trip",
  _LOCATION_: "Location",
  _IGNITION_: "Ignition",
  _DATE_TIME_: "Date Time",
  _LAST_LOCATION_: "Last Location",
  _SEARCH_BY_VEHICLE_NO_: "Search by Vehicle No",
  _SEARCH_BY_IMEI_NO_: "Search by IMEI No",
  _ASSET_: "Asset",
  _TO_TIME_: "To Time",
  _MAINTENANCE_: "Maintenance",
  _GRAPH_: "Graph",
  _SERVICES_: "Services",
  _USER_PROFILE_: "User Profile",
  _CONTACT_: "Contact",

  _GPRS_COMMAND_: "GPRS Command",
  _FLEET_GPRS_TEXT_ADDBTN_: "Add Command",

  _WORKING_HOURS_REPORT_: "Working Hours Report",
  _ESN_POLL_REPORT_: "ESN Polling Report",
  _FLEET_REPORTS_ESNPOLLING_TITLE_: "ESN Polling",
  _FLEET_REPORTS_VEHICLEID_: "Vehicle Id",
  _FLEET_REPORTS_DATE_: "Date",
  _FLEET_REPORTS_DRIVER_ID_: "Driver Id",
  _FLEET_REPORTS_IN_HOURS_DISTANCE_: "Duty Time KM",
  _FLEET_REPORTS_OUT_HOURS_DISTANCE_: "Off Duty KM",
  _FLEET_REPORTS_DISTANCE_: "Distance(Km)",
  _FLEET_REPORTS_IN_HOURS_DURATION_: "Duty Time Duration",
  _FLEET_REPORTS_OUT_HOURS_DURATION_: "Off Duty Duration",
  _FLEET_REPORTS_IN_HOURS_START_: "In Hours Start",
  _FLEET_REPORTS_IN_HOURS_END_: "In Hours End",
  _FLEET_DASHBORD_SETTING_TEMP1_: "Temp1",
  _FLEET_DASHBORD_SETTING_TEMP2_: "Temp2",
  _FLEET_DASHBORD_SETTING_TEMP3_: "Temp3",
  _FLEET_DASHBORD_SETTING_RFID_: "RFID",
  _COMMAND_: "Command",
  _ALL_: "All",
  _EXCEL_: "Excel",
  _CSV_: "CSV",
  _PDF_: "PDF",
  _XML_: "XML",
  _POWER_: "Power",
  _LAST_ROUTE_: "Last Route",
  _BATTERY_: "Battery",
  _SPEED_: "Speed",

  _KEEP_AN_EYE_: "Keep An Eye",
  _STREET_VIEW_: "Street View",
  _OBD_DATA: "OBD Data",
  _SEND_COMMAND_: "Send Command",
  _IMMOBILIZER_ON_: "Immobilizer ON",
  _IMMOBILIZER_OFF_: "Immobilizer OFF",
  _DOOR_LOCK_: "Door Lock",
  _DOOR_UNLOCK_: "Door Unlock",
  _SHARE_LOCATION_: "Share Location",
  _RLID_: "rlId",
  _LOADING_: "Loading",
  _DISMISS_: "Dismiss",
  _FLEET_REPORTS_POILIST_TITLE_: "POI List",
  _FLEET_SERVICE_POILIST_SEARCH_: "Search by POI Name",
  _POI_MANAGEMENT_: "POI Management",
  _FLEET_REPORTS_POILIST_NAME_: "POI Name",
  _FLEET_SERVICE_POILIST_MAP_: "Maps",
  _FLEET_SERVICE_POILIST_VIEWMAP_: "View on Map",
  _FLEET_SERVICE_POILIST_SHARE_: "Share",
  _FLEET_SERVICE_POILIST_SHARELOCATION_: "Share Location",
  _FLEET_SERVICE_RULEALERS_VALUE_: "Value",
  // _FLEET_SERVICE_POILIST_SHARE_: "SHARE",
  _FLEET_DASHBORD_NEWPOI_SHAREEBTN_: "SHARE",
  _FLEET_DASHBORD_GPRS_COMMAND_REPORT_: "REPORT",
  _FLEET_SELECT_EVENT_: "Select Event",
  _REPORTS_HEALTHCHECK_TITLE_: "Health Check Report",
  //NEW
  _KPA_DASHBOARD_: "KPA Dashboard",
  _COURSE_: "Course",
  _HEIGHT_: "Height",
  _PID_: "PID",
  _CURRENT_TRIP_FUEL_CONSUMPTION_: "Current Trip Fuel consumption",
  _CURRENT_TRIP_MILEAGE_: "Current Trip Mileage",
  _CURRENT_TRIP_DURATION_: "Current Trip Duration",
  _MMCX_: "MMCX",
  _GSENSOR_1_XAXIS_: "GSensor1 X-Axis",
  _GSENSOR_1_YAXIS_: "GSensor1 Y-Axis",
  _GSENSOR_1_ZAXIS_: "GSensor1 Z-Axis",
  _GSENSOR_2_XAXIS_: "GSensor2 X-Axis",
  _GSENSOR_2_YAXIS_: "GSensor2 Y-Axis",
  _GSENSOR_2_ZAXIS_: "GSensor2 Z-Axis",
  _GSENSOR_3_XAXIS_: "GSensor3 X-Axis",
  _GSENSOR_3_YAXIS_: "GSensor3 Y-Axis",
  _GSENSOR_3_ZAXIS_: "GSensor3 Z-Axis",
  _GSENSOR_4_XAXIS_: "GSensor4 X-Axis",
  _GSENSOR_4_YAXIS_: "GSensor4 Y-Axis",
  _GSENSOR_4_ZAXIS_: "GSensor4 Z-Axis",
  _GSENSOR_5_XAXIS_: "GSensor5 X-Axis",
  _GSENSOR_5_YAXIS_: "GSensor5 Y-Axis",
  _GSENSOR_5_ZAXIS_: "GSensor5 Z-Axis",
  _VOLTAGE_: "Voltage",
  _REPORTS_OBD_TITLE_: "OBD Report",
  _FLEET_REPORTS_AREAWISESPEED_TITLE_: "Area Wise Speed Violation Report",
  _DEFINED_SPEED_: "Defined Speed",
  _VEHICLE_SPEED_: "Vehicle Speed",
  _SPEED_LIMIT_: "Speed Limit",
  _SPEED_AREA_: "Speed Area",
  _OVERSPEED_DATE_: "Overspeed Date",
  _PACKET_TYPE_: "Packet Type",
  _LOAD_VOLTAGE_: "Load Voltage",
  _IDENTITY_NUMBER_: "Identity Number",
  _COMPANY_IDENTITY_NUMBER_: "Company Identity Number",
  _SELECT_COMPANY_IDENTITY_NUMBER_: "Select Company Identity Number",
  _COMMERCIAL_RECORD_NUMBER_: "Commercial Record Number",
  _COMMERCIAL_RECORD_NUMBER_ARABIC_: "Commercial Record Number Arabic",
  _COMPANY_NAME_ARABIC_: "Company Name Arabic",
  _COMMERCIAL_RECORD_ISSUE_DATE_: "Commercial Record Issue Date Hijri",
  _EXTENSION_NUMBER_: "Extension Number",
  _MANAGER_NAME_: "Manager Name",
  _MANAGER_PHONE_: "Manager Phone",
  _MANAGER_MOBILE_: "Manager Mobile",
  _REPLY_: "Reply",
  _REGISTER_: "Register",
  _WASL_KEY_: "WASL Key",

  _WASL_VEHICLE_LIST_: "WASL Vehicle List",
  _WASL_DRIVER_LIST_: "WASL Driver List",
  _SEQUENCE_NUMBER_: "Sequence Number",
  _PLATE_REGISTER_NUMBER_: "Plate Registration No",
  _NUMBER_: "Plate Number",
  _RIGHT_LETTER: "Plate Right Letter",
  _MIDDLE_LETTER_: "Plate Middle Letter",
  _LEFT_LETTER_: "Plate Left Letter",
  _IMEI_NUMBER_: "IMEI Number",
  _RESPONSE_: "Response",
  _PLATE_TYPE_: "Plate Type",
  _WASL_VEHICLE_KEY_: "WASL Vehicle Key",
  _WASL_DRIVER_KEY_: "WASL Driver Key",
  _DOB_: "DOB",
  _DOB_FORMATE_: "DOB Format",
  _DOB_LABEL_: "Date of Birth",
  _SELECT_DRIVER_: "Select Driver",
  _WASL_: "WASL",
  _WEIGHT_VOLT_: "Weight/Volt",
  _LOAD_SENSOR_: "Load Sensor",
  _TOTAL_TRUCK_WEIGHT_: "Total Weight(Kg)",
  _EMPTY_TRUCK_WEIGHT_: "Empty Weight(Kg)",
  _LOAD_LUGGAGE_WEIGHT_: "Load/Luggage Weight(Kg)",
  _EMPTY_TRUCK_WEIGHT_KG_: "Empty Truck Weight(Kg)",
  _VEHICLE_STATUS_: "Vehicle Status",
  _WASL_REPLY_: "WASL",
  _VEHICLE_REFERENCE_KEY_: "Vehicle Referance Key",
  _DRIVER_REFERENCE_KEY_: "Driver Referance Key",
  _VELOCITY_: "Velocity",
  _WEIGHT_: "Weight",
  _WEIGHT_PDF_: "Weight PDF",
  _LOCATION_TIME_: "Location Time",
  _ROLE_CODE_: "Role Code",
  _REGISTRATION_TYPE_: "Registration Type",
  _POSTING_TIME: "Posting Time",
  _RESPONSE_TIME_: "Response Time",
  _FLEET_MAINTENANCE_REPORT_: "Fleet Maintanence Report",
  _GENSET_WORKING_HOUR_REPORT_: "Genset Working Hour Report",
  _VEHICLE_PERFORMANCE_REPORT_: "Vehicle Performance Report",
  _DATA_CONSUMPTION_REPORT_: "Data Consumption Report",
  // _WASL_REPORT_: "WASL Report",
  _TOTAL_: "Total",
  _HALTED_FROM_: "Halted From",
  _HALTED_TILL_: "Halted Till",
  _EMAIL_IDS_: "Email Ids",
  // _SUBMIT_: "submit",
  _TOTAL_STOP_REPORT_: "Total Stop Report",
  _FROM_DATE_: "From Date",
  _TO_DATE_: "To Date",
  _TOTAL_STOPPAGE_: "Total Stoppage",
  _TOTAL_DURATION_: "Total Duration",
  _SUB_ACCOUNT_: "Sub Accounts",
  _TOTAL_TRAVEL_REPORT_: "Total Traval Report",
  _TOTAL_TRIPS_: "Total Trips",
  _TOTAL_TRAVEL_DURATION_: "Total Travel Duration",
  _TAG_ID_: "Tag Id",
  _ACTIVITY_: "Activity",
  _TRIP_REPORT_MAP_: "Trip Report Map",
  _TRIP_MIN_KM_: "Trip Min Km",
  _TOTAL_IDLE_REPORT_: "Total Idle Report",
  _TOTAL_IDLING_COUNT_: "Total Idling Count",
  _IDLE_SINCE_: "Idle Since",
  _IDLE_FROM_: "Idle From",
  _IDLE_TO_: "Idle To",
  _IDLE_DURATION_: "Idle Duration",
  _TOTAL_EX_IDLE_REPORT_: "Total Ex Idle Report",
  _TOTAL_EXIDLING_COUNT_: "Total ExIdling Count",
  _VEHICLE_MODEL_: "Vehicle Model",
  _DETAILED_DISTANCE_RECORD_: "Detailed Distance Record",
  _EXIDLE_TIME_: "Exidle Time",
  _TOTAL_STOP_: "Total Stop",
  _IDLE_COUNT_: "Idle Count",
  _OVER_SPEED_: "Over Speed",
  _ENERED_ODOMETER_: "Entered odometer",
  _TOTAL_RUN_KM_: "Total Run Km",
  _FUEL_CONSUMED_: "Fuel Consumed",
  _FUEL_CONSUMPTION_: "Fuel Consumption(L)",
  _AMOUNT_: "Amount",
  _VEHICLE_TYPE_: "Vehicle Type",
  _LAST_POLLED_DATETIME_: "Last Polled Date/Time",
  _LAST_POLLED_LOCATION_: "Last Polled Location",
  _GEOFENCE_SUMMARY_TOTAL_: "Geofence Summary Total",
  _FS_IDLE_: "FS Idle",
  _FS_TRAVEL_: "FS Travel",
  _RT_IDLE_: "RT Idle",
  _RT_TRAVEL_: "RT Travel",
  _TOTAL_RT_TIME_: "Total RT Time(Minutes)",
  _TOTAL_FS_TIME_: "Total FS Time(Minutes)",
  _TOTAL_RT_COST_: "Total RT Cost",
  _TOTAL_FS_COST_: "Total FS Cost",
  _FREEZER_: "Freezer",
  _CHILLER_: "Chiller",
  _DRY_: "Dry",
  _INSTALLATION_DATE_: "Installation Date",
  _DEVICE_: "Device",
  _DRIVER_PERFORMANCE_TOTAL_: "Driver Performance Total",
  _NO_OF_VEHICLES_: "No of vehicles",
  _KM_: "KM",
  _TRAVEL_: "Travel",
  _IDLE_: "Idle",
  _EX_IDLE_: "Ex Idle",
  _MAX_SPEED_AS_LIMIT_SET_: "Maximum Speed as per the limit set",
  _COMPLAIN_: "Complain",
  _COMPLAIN_DATETIME_: "Complain Date Time",
  _OVER_TIME_REPORT_: "Over Time Report",
  _WORKING_HR_: "Working hours (Travel Time)",
  _ALLOCATE_WORKING_HR_: "Allocate Working Hour",
  _OVERTIME_HOURS_: "Overtime hours",
  _OVERTIME_CHARGE_: "Overtime Charges",
  _LOGIN_DATETIME_: "Login Datetime",
  _LOGIN_IP_: "Login IP",
  _LOGIN_LOCATION_: "Login Location",
  _CONTACT_NO_: "Contact No",
  _ACCOUNT_STATUS_: "Account Status",
  _REPORT_NAME_: "Report Name",
  _FREQUENCY_: "Frequency",
  _RAW_DATA_: "Raw Data",
  _CUSTOMER_TYPE_: "Customer type",
  _MAILID_: "Email ID",
  _CC_: "CC",
  _PASSENGER_TAGID_: "Passenger TagID",
  _PASSENGER_NAME_: "Passenger Name",
  // _LOGIN_LOCATION_: 'LogIn Location',
  _LOGOUT_LOCATION_: "LogOut Location",
  _TOTAL_ATTENDANCE_DETAILS_: "Total Attendance Details",
  _DRIVER_ID_: "Driver Id",
  _TOTAL_WORKING_HOURS_: "Total Working Hours",
  _TOTAL_OVERTIME_: "Total OverTime",
  _PUNCH_IN_: "Punch In",
  _PUNCH_OUT_: "Punch Out",
  _DAILY_WORKING_HOURS_: "Daily Working Hours",
  _OVERTIME_: "OverTime",
  _DIFFERENCE_: "Difference",
  _TRACKING_REPORT_: "Tracking Report",
  _USAGE_: "Usage",
  _MULTISCREEN_: "Multiscreen",
  _DEVICE_LOG_: "Device Log",
  _ASSET_DEVICE_ASSIGNMENT_: "Asset Device Assignments",
  _TASK_MANAGEMENT_: "Task Management",
  _FUEL_CONSUMPTION_: "Fuel Consumption",
  _WORKING_HOUR_: "Working Hour",
  _PASSENGER_: "Passenger",

  _VEHICLE_PERFORMANCE_TOTAL_: "Vehicle Performance Total",
  _VEHICLE_: "Vehicles",
  _TOTAL_DRIVER_: "Total Driver",
  _TOTAL_KM_: "Total Km",
  _TOTAL_TRAVEL_: "Total Travel",
  _TOTAL_IDLE_: "Total Idle",
  _TOTAL_EXC_IDLE_: "Total Exc Idle",
  _TOTAL_OVER_SPEED_: "Total Over Speed",
  _TOTAL_OVER_SPEED_AS_ROAD_SPEED_: "Total Over Speed as per the road speed",
  _VEHICLE_PERFORMANCE_DETAILS_: "Vehicle Performance Details",
  Travel: "Stoppage",
  _DATA_LOADING_: "Data Loading...",
  _BYTES_RECEIVED_: "Bytes Received",
  _VIN_: "VIn",
  _CONNECT_: "Connect",
  _SUPPORT_PID_: "Support Pid",
  _RPM_: "RPM",
  _COOLANT_TEMP_: "Coolant Temp",
  _DTC__CLEARED_: "DTC Cleared Mileage",
  _MIL_ACTIVATED_DISTANCE_: "Mil Activated Distance",
  _MIL_STATUS_: "Mil Status",
  _DTC_NUMBER_: "DTC Number",
  _DIAGNOSTIC_TROUBLE_CODE_: "Diagnostic Trouble Code",
  _THROTLE_POSITION_: "Throttle Position",
  _ENGINE_LOAD_: "Engine Load",
  _FUEL_LEVEL_INPUT_: "Fuel Level Input",
  _PROTOCOL_: "Protocol",
  _MILAGE_: "Mileage",

  _ACCOUNT_ID_: "Account Id",
  _ACCOUNT_ALISE_: "Account Alias",
  _POSTAL_CODE_: "Postal code",
  _TAX_ID_: "Tax ID",
  _ACCOUNT_SETTINGS_: "Account Settings",
  _INDUSTRY_: "Industry",
  _ACCOUNT_CREATED_: "Account Created",
  _PAYMENT_TERM_: "Payment Terms",
  _MAIN_CONTACT_: "Main Contact",
  _ADMINISTRATIVE_CONTACT_: "Administrative Contact",
  _TECHNICAL_CONTACT_: "Technical Contact",
  _WEBSITE_: "Website",
  _SHOW_HIDE_PASSWORD_: "Show/Hide Password",
  _CREATED_BY_: "Created By",
  _PHOTO_ID_: "Photo ID",
  _EMPLOYMENT_STATUS_: "Employment Status",
  _NATIONALITY_: "Nationality",
  // _TAG_ID_: 'Tag id',
  _EMPLOYEE_INFORMATION_: "Employee Information",
  _EMARGENCY_INFORMATION_: "Emergency Information",

  _TRACKING_DETAILS_: "Tracking Details",
  _TRACK_: "Track",
  _WORKING_HOUR_: "Working Hours",
  _OVERTIME_CHARGES_: "Overtime Charges",
  _SEARCH_BRAND_: "Search Brand",
  _SEARCH_DRIVER_: "Search Driver",
  _SEARCH_SIM_NO_: "Search Sim No",
  _SEARCH_IMEI_: "Search IMEI",
  _SEARCH_ASSET_: "Search Asset",
  _HOME_LOCATION_: "Home Location",
  _PAYMENT_TYPE_: "Payment Type",
  _COMMUNICATION_: "Communication",
  _ASSET_EXPIRY_DATE_: "Asset Expiry Date",
  _ATTENDANT_MOBILE_NO_: "Attendant Mobile No",
  _ATTENDANT_NAME_: "Attendant Name",
  _PLATE_NO_: "ID/Plate No",
  _INSTALLER_: "Installer",
  _INSATALLED_LOCATION_: "Installed Location",
  _FUEL_SENSOR_: "Fuel Sensor",
  _TEMP_SENSOR_: "Temp Sensor",
  _CANBUS_OBD_: "Canbus/OBD",
  _LOCK_ALARM_: "Lock/Alarm",
  _ASSET_IMAGE_: "Asset Image (150*150)",
  _ATTACHED_PICTURE_: "Attached Picture",
  _ADMINISTRATIVE_SETTINGS_: "Administrative Settings",
  _ASSET_PICTURE_: "Asset Picture",
  _DEVICE_SETTINGS_: "Device Settings",
  _CONNECTED_ACCESORIES_: "Connected Accesories",
  _FUELID1_: "FuelId1",
  _FUELID2_: "FuelId2",
  _SENSOR1_: "Sensor 1",
  _SPEED_LIMIT_: "Speed limit",
  _IDLE_LIMIT_: "Idle limit",
  _MAX_IDLE_LIMIT_: "Max Idle limit",
  _MAINTENANCE_MODULE_SETTING_: "Maintenance Module Settings",
  _DIFF_ODOMETER_: "Diff Odometer",
  _SERVICE_INTERVAL_: "Service Interval (km):",
  _SERVICE_INTERVAL_DAYS: "Service Interval (days):",
  _OIL_CHANGE_INTERVAL_: "Oil Change Interval(km)",
  _TIRE_CHANGE_KM_: "Tyre Change Interval(km)",
  _MACHINE_RUNNING_HR_: "Machine Running Hours",
  _SERVICING_HR_: "Servicing Hours ",
  _FUEL_MODULE_SETTING_: "Fuel Module Settings",
  _ASSET_MILAGE_: "Asset Mileage (km/lt)",
  _FUEL_CAPACITY1_: "Fuel Capacity1 (lt)",
  _FUEL_CAPACITY2_: "Fuel Capacity2 (lt)",
  _HEAVY_VEHICLE_: "Heavy Vehicle",
  _LIGHT_VEHICLE_: "Light Vehicle",
  _DRIVING_DETAILS_: "Driving Details",
  _ASSET_VEHICLE_: "Assets/Vehicles",
  _TAG_ID1_: "Tag Id 1",
  _TAG_ID2_: "Tag Id 2",
  _PERMIT_NO_: "Permit No",
  _PERMIT_EXPIRY_DATE_: "Permit Expiry Date",
  _VISA_EXPIRY_DATE_: "Visa Expiry Date",
  _CONTACT_PERSON_NAME_: "Contact Person Name",
  _CONTACT_PERSON_EMAILID_: "Contact Persons Email",
  _ASSIGN_ASSET_: "Assigned Asset",
  _ADD_WORKING_HOURS_: "Add Working Hours",
  _ADD_LOCATION_: "Add Location",
  _WORKING_TIME_FROM_: "Working Time From",
  _WORKING_TIME_TO_: "Working Time To",
  _SELECT_TYPE_: "Select Type",
  _EMPLOYEE_NAME_: "Employee Name",
  _DESIGNATION_: "Designation",
  _DEPARTMENT_: "Department",
  _DEPARTMENTS_: "Departments",
  _LOCATION_: "Location",
  _PICK_UP_TIME_: "Pick Up Time",
  _DROP_OFF_TIME_: "Drop Off Time",
  _ADD_PASSENGER_: "Add Passenger",
  _REMOVE_: "Remove",
  _IDNO_: "ID No",
  _BLOOD_GROUP_: "Blood Group",
  _PASSENGER_TYPE_: "Passenger Type",
  _WASL_COMPANY_LIST_: "WASL Company List",
  _ACCOUNT_LIST_: "Account List",
  _USER_PROFILE_LIST_: "User profile List",
  _RECORD_: "Record",
  _SEARCH_DERIVER_NAME_: "Search by Driver Name",
  _SEARCH_MOBILENO_: "Search by Mobile No",
  _OFFICE_LOCATION_LIST_: "Office Location List",
  _WORKING_HOUR_LIST_: "Working Hours List",
  _PASSENGER_LIST_: "Passengers List",
  _BUSNO_: "Bus No",
  _UPLOAD_: "Upload",
  _DOWNLOAD_SAMPLE_EXCEL_: "Downdload Sample Excel",
  _MODIFIEDBY_: "Modified By",
  _FIELD_NAME_: "Field Name",
  _BEFORE_: "Before",
  _AFTER_: "After",
  _ASSIGN_JOB_: "Assign Job",
  _JOB_: "Job",
  _DELIVERY_DATE_: "Delivery Date",
  _DELIVERY_TIME_: "Delivery Time",
  _HALT_TIME_: "Halt Time",
  _NOTE_TO_DRIVER_: "Note to Driver",
  _SEARCHBY_: "Search By",
  _CUSTOMER_ADDRESS_: "Customer Address",
  _CUST_NAME_: "Customer Name",
  _CUST_MOBILENO_: "Customer Mobile Number",
  _NOTIFICATION_MAIL_: "Notification Email",
  _EDIT_NOTIFICATION_: "Edit Notification",
  _ADD_TASK_: "Add Task Details",
  _UPLOAD_INVOICE_: "Upload Invoice",
  _QUANTITY_: "Quantity",
  _JOB_TO_: "Job To",
  _JOB_DATE_: "Job Date",
  _JOB_TIME_: "Job Time",
  _JOB_TYPE_: "Job Type",
  _ALLOCATE_TO_: "Allocate To",
  _CURRENT_VEHICLE_CONDITION_: "Current Vehicle Condition",
  _DUEDATE_: "Due Date",
  _ALLOCATE_VEHICLE_: "Allocate Vehicle",
  _PROJECT_NAME_: "Project/Company Name",
  _PAYMENT_MODE_: "Payment Mode",
  _TOTAL_AMOUNT_: "Total Amount",
  _SERVICE_TYPE_: "Service Type",
  _SERVICE_DATE_: "Service Date",
  _NEW_SERVICE_TYPE_: "New Service Type",
  _EXTRA_WORK_DONE_: "Extra Works Done",
  _TOTAL_DAYS_USED_: "Total Days Used",
  _TOLL_USED_: "Toll Used",
  _TOLL_CHARGES_: "Toll Charges",
  _VEHICLE_DAMAGE_CHARGE_: "Vehicle Damage Charge",
  _TRAFFIC_FINE_: "Traffic Fine",
  _TOTAL_AMOUNT_CHARGABLE_: "Total Amount Chargeable",
  _INITIAL_ODOMETER_: "Initial Odometer",
  _INITIAL_VEHICLE_CONDTION_: "Initial Vehicle Condition",
  _VEHICLE_PRESENT_CONDTION_: "Vehicle Present Condition",
  _RETURN_ODOMETER_: "Return Odometer",
  _SEQUENCE_: "Sequence",
  _STOP_IDLE_POINTS_: "Stop/Idle Points",
  _SELECT_ADDRESS_: "Select Address",
  _GRAPHS_: "Graphs",
  _SPEED_GRAPH_: "Speed Graph",
  _DISTANCE_GRAPH_: "Distance Graph",
  _IDLE_GRAPH_: "Idle Graph",
  _FUEL_GRAPH_: "Fuel Graph",
  _USAGE_GRAPH_: "Usage Graph",
  _TEMP_GRAPH_: "Temp Graph",
  _TEMPERATURE_GRAPH_: "Temprature Graph",
  _DASHBOARD_GRAPH_: "Dashboard Graph",
  _MULTISCREEN_GRAPH_: "Multiscreen Graph",
  _DOWNLOAD_: "Download",
  _SPEED_VS_TIME_: "Speed Vs Time Graph",
  _FROM_DATE_TIME_: "From Date Time: ",
  _SPEED_KM_PER_HOUR_: "Speed (Km/hr)",
  _TIME_MIN_: "Time(Min)",
  _SUNDAY_: "Sunday",
  _MONDAY_: "Monday",
  _TUESDAY_: "Tuesday",
  _WEDNESDAY_: "Wednesday",
  _THURSDAY_: "Thursday",
  _FRIDAY_: "Friday",
  _SATURDAY_: "Saturday",
  _VALID_FROM_: "Valid From",
  _VALID_TO_: "Valid To",
  _ZONE_: "Zone",
  _POINT_NAME_: "Point Name",
  _NO_OF_STUDENTS_: "No of Students",
  _SEQUENCE_NO_: "Sequence No",
  _TRAVEL_DISTANCE_: "Travel Distance",
  _SELECT_DAYS_: "Select Days",
  _FUEL_FILLED_DETAILS_: "Fuel Filled Details",
  _SEND_PUSH_NOTIFICATION_: "Send Push Notification",
  _ALERT_SETTING_: "Alert Setting",
  _INSIDE_GEOFENCE_: "Inside Geofence",
  _TIRE_CHANGE_REMINDER_: "Tire Change Reminder",
  _OIL_CHANGE_REMINDER_: "Oil Change Reminder",
  _AREAWISE_SPEED_VIOLATION_: "Area Wise Speed Violation",
  _POWER_CUT_: "Power Cut",
  _ALLOCATE_ASSET_LIST_: "Allocate Asset List",
  _RETURN_ASSET_LIST_: "Return Assets List",
  _MAINTENANCE_DETAILS_: "Maintenance Details",
  _SEARCH_BY_GEOFENCE_NAME_: "Search by Geofence Name",
  _TRIP_DETAILS_ASSIGN_: "Create/Assign Trip Details",
  _TASK_MANNAGE_LIST_: "Task Management List",
  _FUEL_DETAIL_LIST_: "Fuel Details List",
  _FUEL_FILLED_LIST_: "Fuel Filled List",
  _FINISH_JOB_: "Finish Job",

  _KM_PER_HOUR_: "(Km/hr)",
  _FOR_: " for ",
  _HOURS_: "Hrs",
  _hour_: "Hours",

  _MIN_: "Min",
  _TOTAL_TRAVEL_DISTANCE: "Total Travel Distance",
  _DISTANCE_NOT_APPLICABLE: "Total Travel Distance: Not Avalable",
  _IDLE_IN_MINUTE: "Idle(In Min)",
  _IDLE_DURATION_IN_MINUTE_: "Idle Duration(In Min)",

  _TOTAL_OF_ASSETS_: "Total No of Assets: ",
  _VEHICLES_: "Vehicle",

  _CONTACT_INFORMATION_: "Contact Information",
  _BROWSE_: "Browse...",
  _TEMPID1_: "TempId1",
  _TEMPID2_: "TempId2",
  _TEMPID3_: "TempId3",
  _TOTAL_ASSET_COST_: "Total Asset Cost",
  _LOW_LIMIT1_: "Low Limit1",
  _HIGH_LIMIT1_: "High Limit1",
  _SENSOR2_: "Sensor 2",
  _SENSOR4_: "Sensor 4",
  _LOW_LIMIT2_: "Low Limit2",
  _HIGH_LIMIT2_: "High Limit2",
  _SERATER_: "Seater",
  _COMPANY_VEHICLES_: "Company Vehicles",
  _GROUP_VEHICLES_: "Group Vehicles",
  _ENTER_LOCATION_: "Enter Location",
  _USER_PROFILE_: "User Profile",
  _ADD_MAINTENANCE_DETAILS_: "Add Maintenance Details",
  _EXCEL_FILE_: "Excel File",
  _SELECT_EXCEL_TO_UPLOAD_: "Please Select Excel File...",
  _FILE_UPLOAD_: "File Upload",
  _NOTIFICATIOS_: "Notificatons",
  _SELECT_ALERT_TYPE_: "Select Alert Type",
  _SHOW_GEOFENCES_: "SHOW GEOFENCES",
  _SHOW_ALL_GEOFENCE_: "Show All Geofences",
  _CURRENT_VEHICLE_LOCATION_: "Current Vehicle Location",
  _MOVING_VEHICLES_: "Moving Vehicles",
  _STOPPED_VEHICLES_: "Stopped Vehicles",
  _IDLING_VEHICLES_: "Idling Vehicles",
  _NOT_REPORTING_VEHICLES_: "Not Reporting Vehicles",
  _EX_IDLE_: "Ex Idle",
  _TOTAL_VEHICLES_: "Total Vehicles",
  _LOCK_UNLOCK_MAP_: "Lock/Unlock Map",
  _CLEAR_LAST_ROUTE_: "Clear Last Route",
  _EXPORT_DETAILS_: "Export Details",
  _SELECT_FROM_HISTORY_: "Select From History",
  _DISCARD_CREATE_NEW_: `Discard & Create New`,
  _LOAD_: "Load",
  _SEARCH_ADDRESS_: "Search Address",
  _SEARCH_GEOFENCE_: "Search Geofence",
  _SEARCH_: "Search",
  _ENTER_DISTANCE_: "Enter Distance",
  _SEARCH_NEARST_VEHICLE_: "Search Nearest Vehicle",
  _CLICK_ON_MAP_: "Click On Map",
  _STOPPED_: "Stopped",
  _RUNNING_: "Running",
  _IDLE_: "Idle",
  _EX_IDLE_: "Ex-Idle",
  _NO_GPS_: "No GPS",
  _NO_DATA_: "No Data",
  _SEC_: "(Sec)",
  _SEARCH_BY_ESN_: "Search by ESN",
  _SELECT_ROUT_OPTION_: "Select Route Option",
  _SELECT_TRIP_EVENT_: "Select Trip Event",
  _SELECT_SPEED_: "Select Speed",
  _PAUSE_: "Pause",
  _PLAY_: "Play",
  _ASSET_HISTORY_DETAILS_: "Asset History Details",
  _HEATMAP_: "Heatmap",
  _UPDATE_DATE_: "Update Date",
  _MONITOR_CENTER_: "Monitor Center",
  _ROUTING_DASHBOARD_: "Routing Dashboard",
  _ALERT_TYPE_: "Alert Type",
  _SEARCH_DERIVER_: "Search by Driver",
  _TEMPERATURE_GRAPH_: "Temperature Graph",
  _TEMP_SENSOR1_: "Temp sensor 1",
  _TEMP_SENSOR2_: "Temp sensor 2",
  _TEMP_SENSOR3_: "Temp sensor 3",
  _TEMP_SENSOR4_: "Temp sensor 4",
  _TEMPERATURE_SENSOR1_: "Temperature Sensor 1",
  _TEMPERATURE_SENSOR2_: "Temperature Sensor 2",
  _TEMPERATURE_SENSOR3_: "Temperature Sensor 3",
  _SHOW_ALL_: "Show All",
  _TRACK_DATE_: "Track Date",
  _CLEAR_ALL_: "Clear All",
  _PAYMENT_MODE_: "Payment Modes",
  _INVOICE_: "Invoice",
  _CONTACT1_: "Primary Contact",
  _CONTACT2_: "Secondary Contact",
  _CONTACT3_: "Contact 3",
  _MONTHLY_RENTAL_: "Monthly Rental",
  _DEVICE_CHARGES_: "Device Charges",
  _SIM_CHARGES_: "Sim Charges",
  _APPLICATON_CHARGES_: "Application Charges",
  _ADDON_: "Add On",
  _RFID_CHARGES_: "RFID Charges",
  _TEMPERATURE_SENSOR_CHARGE_: "Temperature Sensor Charges",
  _DRIVERID_CHARGES_: "DriverId Charges",
  _FUEL_SENSOR_CHARGES_: "Fuel Sensor Charges",
  _CANBUS_: "Canbus",
  //_TOTAL_:'Total',
  _MARK_LOCATION_: "Mark Location",
  //_WEBSITE_:'Website',
  //_INDUSTRY_:'Industry',
  _RENEW_: "Renew",
  _ATTACH_INVOICE_: "Attach Invoice",
  _PHOTO_: "Photo",
  _LOCATION_ON_MAP_: "Location On Map",
  _ADD_NEW_DEPARTMENT_: "Add New Department",
  _POLLING_IMEI_: "Polling IMEI",
  _VEHICLE_EXPIRY_DATE_: "Vehicle Expiry Date",
  _RENEWED_AT_: "Renewed At",
  _ATTENDAN_: "Attendant Mobile No",
  _TEMPERATURE_SENSOR_: "Temperature Sensor",
  _MULKIYA_RENEWAL_DATE_: "Istimara renewal date",
  _MULKIYA_RENEWAL_DATE_1: "Mulkiya Renewal Date",
  _INSURANCE_EXPIRY_DATE_: "Insurance expiry date",
  _MACHINE_SERVICE_HOURS_: "Machine Service Hours(Hours)",
  _VEHICLE_PURCHASE_DATE_: "Vehicle Purchase Date",
  _TOTAL_VEHICLE_COST_: "Total Vehicle Cost",
  _DOWN_PAYMENT_: "Down Payment",
  _MONTHLY_EMI_: "Monthly EMI",
  _NO_OF_EMI_: "No Of EMI",
  _EMI_DUE_DATE_: "Emi Due Date",
  _PERMIT_EXPIRY_DATE_: "Permit Expiry Date",
  _NATIONAL_PERMIT_EXPIRY_DATE_: "National Permit Expiry Date",
  _POLLUTION_EXPIRY_DATE_: "Pollution Expiry Date",
  _VEHICLE_MILAGE_: "Vehicle Mileage",
  _SENSOR3_: "Sensor 3",
  _LOWLIMIT3_: "Low Limit3",
  _HIGH_LIMIT3_: "High Limit3",
  _INSURANCE_TYPE_: "Insurance type",
  _MULKIYA_TYPE_: "Istimara type",
  _TERM_: "Term",
  _FEE_: "Fee",
  _CLASS_NAME_: "Class name",
  _CLASS_DIV_: "Class Div",
  _STUDENT_NAME_: "Student Name",
  _SCHOOL_NAME_: "School Name",
  _SCHOOL_: "School",
  _CC_DETAILS_: "CC Details",
  _EMAIL_DETAILS_: "Email Details",
  _REMOVE_EVENT_: "Remove Event",
  _SELECT_COMPANY_: "Select Company",
  _TRACK_DATA_MAP_: "Track Data Map",
  _REPORTS_PRIOR_CONTACT_ADMINISTRATOR_:
    "For Reports prior to 6 months please contact administrator",
  _BREAK_COUNT_: "Echo Brake Count",
  _HARSH_BRAKE_COUNT_: "Echo Harsh Brake Count",
  _HARSH_ACCELERATION_COUNTER_: "Echo Harsh Acceleration Counter",
  _IDLING_EVENT_: "Echo Idling Event",
  _MAXSPEED_: "Echo Maxspeed",
  _MAX_SPEED_: "Echo Max Speed",
  _ENGINEER_TIMER_: "Echo Engineon Timer",
  _IDLING_TIMER_: "Echo Idling Timer",
  _OVERSPEED_TIMER_: "Echo Overspeed Timer",
  _RULE_DELETE_: "Are You Sure You Want To Delete The Rule?",
  _MINUTES_: "Minutes",
  _IBUTTONID_: "Ibuttonid",
  _PASSENGER_ID_: "Passenger Id",
  _MANAGE_: "Manage",
  _INPUT2_: "Input2",
  _MENU_SETTING_: "Menu Setting",
  _SIM_LIST_: "Sim List",
  _NOTIFICATION_: "Notification",
  _INSPECTION_: "Inspection",
  _ERROR_: "Error",
  _SELECT_EXCEL_OR_XML_: "Please Select Excel or XML file only..",
  _FROM_DATETIME_: "From DateTime",
  _TO_DATETIME_: "To DateTime",
  _UPLOADING_FILE_: "Uploading your file..",
  _INTERVEL_: "Intervel",
  _SAMPLE_EXCEL_: "Sample Excel",
  _VEHICLE_HISTORY_DETAILS_: "Vehicle History Details",
  _END_ODOMETER_: "End Odometer",
  _REMOVE_RECORD_: "Remove Record",
  _DELETE_SIM_RECORD_: "Do you want to delete sim record",
  _INT_: "Int",
  _SERVICE_PROVIDER_: "Service Provider",
  _SERIAL_NO_: "Serial No",
  _WANT_TO_DELETE_DEVICE_DETAILS_: "Do you want to delete Device details?",
  _EDIT_DEVICE_DETAILS_: "Edit Device detail",
  _DELETE_DEVICE_DETAILS_: "Delete Device Detail",
  _DISTANCE_TRAVEL_: "Distance Travel",
  _STARTTIME_: "Start Time",
  _STOPTIME_: "Stop Time",
  _DISTANCETRAVELLED_: "DistanceTravelled",
  _IDLE_MAP_: "Idle Map",
  _IDLE_TILL_: "Idle Till",
  _EXCESSIVE_IDLE_MAP_: "Excessive Idle Map",
  _PICKUP_DROP_: "Pickup/Drop",
  _VEHICLE_NOT_PULL_MAP_: "Vehicle Not Pull Map",
  _SPEED_EXCEPTION_REPORT_: "Speed Exception Report",
  _SPEED_KM_: "Speed(Km)",
  _SHIPMENT_DATE_: "Shipment Date",
  _WARRANTY_DATE_: "Warranty Date",
  _ROUTE_: "Route",
  _NO_OF_TRIPS_: "No of Trips",
  _IMEI_NO_: "IMEI no",
  _SHIPMENT_RECEIVED_DATE_: "Shipment Received Date",
  _WARRANTY_VALIDITY_DATE_: "Warranty Validity Date",
  _TRIP1_START_TIME_: "Trip1 Start Time",
  _TRIP2_START_TIME_: "Trip2 Start Time",
  _TRIP1_END_TIME_: "Trip1 End time",
  _ADD_DEVICE_DETAILS_: "Add Device Details",
  _TRIP2_END_TIME_: "Trip2 End time",
  _GEOFENCE_OR_POI_OUT_MAP_: "Geofence or POI In Out On Map",
  _RPM_SPEED_: "RPM and Speed",
  _WANT_TO_DELETE_NOTIFICATION_: "Do you want to delete notification?",
  _EDIT_SCHOOL_CALENDAR_: "Edit School Calender",
  _DELETE_NOTIFICATION_: "Delete Notification",
  _MH14_J5645_: "MH-14 J-5645",
  _GEOFENCE_ID_: "Geofence Id",
  _HTML_: "HTML",
  _DRILL_START_TIME_: " Drill Start Time",
  _DRILL_STOP_TIME_: " Drill Stop Time",
  _OPERATION_HOUR_: "Operation hour",
  _FATIQUE_HOURS_: "Fatique Hours",
  _SELECT_GROUP_: "Select Group",
  _EVENT_DESCRIPTION_: "Event Description",
  _RFID1_: "RFID1",
  _DURATION_HH_MM_SS_: "Duration (HH:MM:SS)",
  _DISTANCE_KM_: "Distance (KM)",
  _TRAVEL_INT_: "Travel Int",
  _STOP_INT_: "Stop Int",
  _IDLE_INT_: "Idle Int",
  _TEMP_REPORT_MAP_: "Temp Report Map",
  _T1_MIN_: "T1 Min",
  _T1_MAX_: "T1 Max",
  _T2_MIN_: "T2 Min",
  _T2_MAX_: "T2 Max",
  _T3_MIN_: "T3 Min",
  _T3_MAX_: "T3 Max",
  _END_TIME_: "End Time",
  _DRIVER_TOTAL_DETAILS_: "Driver Total Details",
  _NO_OF_VEHICLES_USED_: "No of vehicles used",
  _MACHINE_START_TIME_: "Machine Start Time",
  _MACHINE_STOP_TIME_: " Machine Stop Time",
  _LAST_DRILL_START_TIME_: "Last Drill Start Time",
  _LAST_DRILL_STOP_TIME_: "Last Drill Stop Time",
  _MOVEMENT_SENSOR_: "Movement Sensor",
  _IVCAN_VEHICLE_SPEED_: "Ivcan Vehicle Speed",
  _IVCAN_PEDAL_POSITION_: "Ivcan Pedal Position",
  _ENGINE_TEMP_: "Engine Temp",
  _FUEL_PERCENT_: "Fuel Percent",
  //  _FUEL_RATE_: 'Fuel Rate',
  _FUEL_CONSUMED_COUNTED_: "Fuel Consumed Counted",
  _TOTAL_MILEAGE_COUNTED_: "Total Mileage Counted",
  _IVCAN_PROGRAM_NUMBER_: "Ivcan Program Number",
  _ENGINE_WORKING_TIME_: "Engine Working Time",
  _PROJECT_NAMES_: "Project Names",
  _TRUCK_: "Truck",
  _CAR_: "car",
  _car_: "Car",
  _TOTAL_FUEL_CONSUMED_LTRS_: "Total Fuel Consumed(Ltrs)",
  _MILEAGE_DIST_LTR_: "Mileage(Dist/Ltr)",
  _FUEL_LEVEL_: "Fuel Level",
  _BEFORE_REFILL_LTRS_: "Before Refill(Ltrs)",
  _AFTER_FUEL_LEVEL_LTRS_: "After Fuel Level(Ltrs)",
  _REFILL_FUEL_LEVEL_LTRS_: "Refill Fuel Level(Ltrs)",
  _DRIVER_MOBILE_: "Driver Mobile",
  _DELIVERED_AT_: "Delivered At",
  _DELIVERY_STATUS_: "Delivery Status",
  _FEEDBACK_: "Feedback",
  _ATTACHED_IMAGE_: "Attached Image",
  _ROAD_SPEED_VIOLATION_REPORT_: "Road Speed Violation Report",
  _ROADSPEED_: "RoadSpeed",
  _ROAD_MAX_SPEED_: "Road Max Speed",
  _SALES_PERSON_TOTAL_DETAILS_: "Sales Person Total Details",
  _SALES_PERSON_NAME_: "Sales Person Name",
  _SALES_COORDINATOR_NAME_: "Sales Coordinator Name",
  _TOTAL_MEETING_: "Total Meeting",
  _TOTAL_MEETING_ATTEND_: "Total Meeting Attend",
  _TOTAL_HOT_CUSTOMER_: "Total Hot Customer",
  _TOTAL_WARM_CUSTOMER_: "Total Warm Customer",
  _TOTAL_COOL_CUSTOMER_: "Total Cool Customer",
  _MEETING_DATE_: "Meeting Date",
  _MEETING_TIME_: "Meeting Time",
  _MEETING_STATUS_: "Meeting status",
  _FINISHED_AT_: "Finished At",
  _NEXT_MEETING_DATE_: "Next Meeting Date",
  _NEXT_: "Next",
  _SUBMIT_: "Submit",
  _CUSTOMER_STATUS_: "Customer Status",
  _SALES_PERSON_LOCATION_: "Sales Person Location",
  _VEHICLE_LOCATION_: "Vehicle Location",
  _B_CARD_: "B Card",
  _WANT_TO_DELETE_INSPECTION_: "Do you want to delete Inspection?",
  _DELETE_INSPECTION_: "Delete Inspection ?",
  _EDIT_INSPECTION_: "Edit Inspection details",
  _TECHNICIAN_NAME_: "Technician Name",
  _REPAIR_: "Repair",
  _REMOVAL_: "Removal",
  _CHECKING_: "Checking",
  _SEND_: "SEND",
  _COMMAND_TYPE_: "Command Type",
  _ENTER_APN_: "Enter APN",
  _ENTER_IP_: "Enter IP",
  _ENTER_PORT_: "Enter Port",
  _ENTER_INTERVAL_: "Enter Interval",
  _SMS_COMMAND_: "SMS Command",
  _SALES_PERSON_: "Sales Person",
  _COMAPNY_NAME_: "Company Name",
  _SUBJECT_: "Subject",
  _EMAIL_BODY_: "Email Body",
  _LAST_EXPIRY_DATE_: "Last Expiry Date",
  _CURRENT_EXPIRY_DATE_: "Current Expiry date",
  _OPTION_: "Option",
  _PUNCH_IN_TIME_: "Punch In Time",
  _PUNCH_IN_LOCATION_: "Punch In Location",
  _PUNCH_OUT_TIME_: "Punch Out Time",
  _PUNCH_OUT_LOCATION_: "Punch Out Location",
  _GPS_: "GPS",
  _STATIONARY_FROM_: "Stationary From",
  _VEHICLE_START_TIME_: "Vehicle Start Time",
  _VEHICLE_STOP_TIME_: "Vehicle Stop Time",
  _TOTAL_TRAVEL_TIME_: "Total Travel Time",
  _TOTAL_FS_CUSTOMER_: "Total FS Customer",
  _TOTAL_RT_CUSTOMER_: "Total RT Customer",
  _TOTAL_CUSTOMER_: "Total Customer",
  _FS_DELIVERY_TIME_: "FS Delivery Time",
  _RT_DELIVERY_TIME_: "RT Delivery Time",
  _TO_DATE_COL_: "To Date:",
  _IDLE_DURATION_IN_MIN_: "Idle Duration(In Min)",
  _TOTAL_NO_OF_VEHICLES_: "Total No of Vehicle: ",
  _SENSOR_1_: "Sensor1 : ",
  _SENSOR_2_: "Sensor2 : ",
  _SENSOR_3_: "Sensor3 : ",
  _SENSOR_4_: "Sensor4 : ",
  _VEHICLE_DATA_NOT_AVAILABLE_: "Vehicle Data Not Available...",
  _DETAIL_TRACKING_: "Detail Tracking",
  _SEARCH_BY_VEHICLE_: "Search by Vehicle",
  _ASSIGN_VEHICLE_: "Assign Vehicle",
  _SELECT_USER_: "Select User",
  _DETAIL_TRACKING_MAP_: "Detail Tracking on Map",
  _LIVE_ROUTE_PATH_: "Live Route Path",
  _LIVE_TRAVERSED_: "Live Traversed",
  _ADD_DEPARTMENT_: "Add Department",
  // _ASSIGN_VEHICLE_TO_COMPANY: 'Assign Vehicle To Company',
  _ADD_JOB_TYPE_: "Add Job Type",
  _SELECT_JOB_TYPE: "Select Job Type",
  _ADDED_SUCCESSFULLY_: "Successfully Added",
  _DEPARTMENT_DETAIL_: "Department Detail",
  _ASSIGN_BUS_TO_STUDENT_: "Assign Bus To Student",
  _SAVE_TO_ASSIGN_: "Save To Assign",

  _JOB_DETAILS_: "Job Details",
  _STATUS_PENDING_: "Status: Pending",
  _NO_VEHICLE_ASSIGNED: "No Vehicle Assigned",
  _NO_DATA_FOUND_SELECTED_DATE_: "No data found for selected date",
  _JOB_NO_: "Job No",
  _JOBS_: "Jobs",
  _TEXT_: "text",
  _MAIL_SETTINGS_: "Mail Settings",
  _MENU_SETTINGS_: "Menu Settings",
  _ADD_SIM_DETAILS_: "Add Sim Details",
  _ADD_INSPECTION_: "Add Inspection",
  _TRIGGER_DETAILS_WINDOW_: "Trigger Details Window",
  _ROUTE_INFO_: "Route Info",
  _ADDRESS_INFO_WINDOW_: "Address Info Window",
  _VEHICLES_NOT_REPORTING_: "Vehicles Not Reporting",
  _EXCESSIVE_IDLING_: "Excessive Idling",
  _OVERSPEED_REPORT_: "Overspeed Report",
  _DATE_COL_: "Date :",
  _INVALID_ADDRESS_: "Invalid Address",
  _POI_SAVED_SUCCESSFULL_: "POI saved successfull",
  _SELECT_SHAPE_DRAW_FENCE_: "Please select Shape and draw fence",
  _LOADING_DATA_: "Loading Data...",
  _RECORDS_NOT_FOUND_: "Records Not Found",
  _REMOVE_MARKER_: "Remove Marker",
  _INFORMATION_: "Information",
  _VEHICLE_RENEWAL_ALERT_: "Vehicle Renewal Alert",
  _LIVE_ROUTE_: "Live Route",
  _CREATE_REPORT_: "Create Report",
  _EXCESSIVE_IDLE_REPORT_: "Excessive Idle Report",
  _VEHICLE_SUMMARY_: "Vehicle Summary",
  _UNAUTHORIZED_USAGE_: "Unauthorized Usage",
  _GEOFENCE_REPORT_: "Geofence Report",
  _DRIVER_BEHAVIOUR_REPORT_: "Driver Behavior Report",
  _FUEL_USAGE_REPORT_: "Fuel Usage Report",
  _DELIVERY_REPORT_: "Delivery Report",
  _VEHICLE_MANAGEMENT_: "Vehicle Management",
  _SCHOOL_MANAGEMENT_: "School Management",
  _JOB_MANAGEMENT_: "Job Management",
  _MASTERS_: "Masters",
  _VEHICLES_TO_RENEW: "Vehicles To Renew",
  _OTHER_: "Other",
  _HIDE_VEHICLE_NO_: "Hide Vehicle No",
  _EDIT_GEOFENCE_: "Edit Geofence",
  _ACTIVITY_PANEL_SETTING_: "Activity Panel Setting",
  _IMPORT_POI_SUCCESSFULLY_: "Import poi successfully",
  _EVENT_NAME_: "Event Name",
  _INFO_: "Info",
  _MAXIMUM_4_VEHICLES_ALLOWED_: "Maximum 4 vehicles allowed",
  _PASSENGER_LOG_: "Passenger Log",
  _BUS_REGISTRATION_REPORT_: "Bus Registration Report",
  _RPM_GRAPH_REPORT_: "RPM Graph Report",
  _RULE_TRIGGER_: "Rule Trigger",
  _GEOFENCE_SUMMARY_: "Geofence Summary",
  _DRIVER_LOGIN_: "Driver Login",
  _DRIVER_PERFORMANCE_: "Driver Performance",
  _VEHICLE_PERFORMANCE_: "Vehicle Performance",
  _MACHINE_WORKING_HOURS_: "Machine Working Hours",
  _CANBUS_REPORT_: "Canbus Report",
  _PROJECT_VEHICLE_REPORT_: "Project Vehicle Report",
  _FUEL_CONSUMPTION_REPORT_: "Fuel Consumption Report",
  _FUEL_REFILL_REPORT_: "Fuel Refill Report",
  _SALES_MANAGEMENT_: "Sales Management",
  _VEHICLE_EXPIRY_REPORT_: "Vehicle Expiry Report",
  _SALES_PERSON_TRACK_REPORT_: "Sales Person Track Report",
  _STATIONARY_REPORT_: "Stationary Report",
  _DISTANCE_KM_REPORT_: "Distance(km) Report",
  _FLEET_GEOFENCE_SUMMARY_REPORT_: "Fleet Geofence Summary Report",

  /*Form Keyword */
  _CHECK_BUS_ROUT_: "Check Bus Route",
  _TRIP_ID_: "TripId",
  _MOOBILE_APP_: "Mobile App",
  _SIBLING1_NAME_: "Sibling 1 Name",
  _SIBLING1_ID_: "Sibling 1 Id ",
  _SIBLING1_CLASS_: "Sibling 1 Class ",
  _SIBLING2_NAME_: "Sibling 2 Name ",
  _SIBLING2_ID_: "Sibling 2 Id ",
  _SIBLING2_CLASS_: "Sibling 2 Class ",
  _FATHER_NAME_: "Father Name",
  _FATHER_CONTACT_NO_: "Father Contact No",
  _MOTHER_NAME_: "Mother Name",
  _MOTHER_CONTACT_NO_: "Mother Contact No",
  _GUARDIAN_NAME_: "Guardian Name",
  _GUARDIAN_CONTACT_NO_: "Guardian Contact No",
  _PICKUP_POINT_: "Pickup Point",
  _PICKUP_TIME_: "Pickup Time",
  _AVAILABLE_SEATS_: "Available Seats",
  _ROUT_TRIP_LIST_: "Route Trip List",
  _TRACKING_TIME_FROM_: "Tracking Time From",
  _TRACKING_TIME_TO_: "Tracking Time To",
  _STAFF_DETAILS_: "Staff Details",
  _TRACKING_INTERVAL_MIN_: "Tracking Interval (Min)",
  _OTHER_PERMIT_: "Other Permit",
  _STAFF_DATA_SAVE_: "Staff Data Save Succssfully",
  _SAVING_SALES_PERSON_DATA_: "Saving Sales Persons Data..",
  _USERNAME_ALREDY_USE_:
    "Username is already in use.Please try different Username",
  _SALES_PERSON_ID_: "Sales Person Id",
  _EMPLOYEE_STATUS_: "Employee Status",
  _REMOVE_SALES_PERSON_: "Remove Sales Person",
  _TIME_FROM_: "Time From",
  _TIME_TO_: "Time To",
  _LOADING_HOUR__DETAILS_: "Loading Working Hour Details",
  _WORKING_HOUR_DETAILS_: "Working Hour Details Added Succesfully",
  _SENSOR1_NAME_: "°C Sensor1 Name",
  _SENSOR1_LOW_LIMIT_: "°C Sensor1 Low Limit",
  _SENSOR1_HIGH_LIMIT_: "°C Sensor1 High Limit",
  _SENSOR2_NAME_: "°C Sensor2 Name",
  _SENSOR2_LOW_LIMIT_: "°C Sensor2 Low Limit",
  _SENSOR2_HIGH_LIMIT_: "°C Sensor2 High Limit",
  _SENSOR3_NAME_: "°C Sensor3 Name",
  _SENSOR3_LOW_LIMIT_: "°C Sensor3 Low Limit",
  _SENSOR3_HIGH_LIMIT_: "°C Sensor3 High Limit",
  _RETURNED_DATE_: "Returned Date",
  _RETURNED_FROM_: "Return From",
  _EDIT_RETURN_ASSET_: "Edit Return assets Details",
  _DELETE_VEHICLE_DETAILS_: "Delete Vehicle Details",
  _LICENSE_NO_: "License No",
  _SALIK_USED_: "Salik Used",
  _SALIK_CHARGES_: "Salik Charges",
  _RETURN_ASSET_: "Return Assets",
  _PROJECT_NAME_: "Project Name",
  _ADD_NEW_PROJECT_: "Add New Project",
  _SAVING_DETAILS_: "Saving Details...",
  _ALREADY_ENTERED_FOR_DATE_:
    "You have already entered readings for selected vehicle for this date.",
  _EDIT_VEHICLE_DETAILS_: "Edit Vehicle Details",
  _DELETE_MAINTENANCE_: "Delete Maintenance  List",
  _EDIT_MAINTENANCE_LIST_: "Edit Maintenance List",
  _TOTAL_DELIVERIES_: "Total Deliveries",
  _DATA_NOT_AVAILABLE_: "Data Not Available",
  _DATA_BROWSER_STATS_2012_: "Data: Browser Stats 2012",
  _DELIVERY_DASHABOARD_: "Delivery Dashaboard",
  _REMOVE_DELIVERY_DETAIL_: "Remove Delivery Detail",
  _CUSTOMER_MOBILE_NO_: "Customer Mobile No.",
  _SALES_EXECUTIVE_NAME_: "Sales Executive Name",
  _DELIVERED_TIME_: "Delivered Time",
  _CREATING_GEOFENCE_: "Creating Geofence..",
  _ONLY_9_DIGITS_ALLOWED_: "Only 9 digits allowed.",
  _SALES_PERSON_EXECUTIVE_: "Sales Person Executive",
  _SALES_PERSON_COORDINATOR_: "Sales Person Coordinator",
  _NOTE_TO_SALES_PERSON_: "Note to Sales Person",
  _AUTO_FINISH_: "Auto Finish",
  _LANDLINE_NUMBER_: "LandLine Number",
  _LANDLINE_: "Landline",
  _ADD_NEW_JOB_: "Add New Job",
  _REQUEST_DATE_: "Request Date",
  _ADD_JOB_REQUEST_DETAILS_: "Add Job Request Details",
  _INVOICE_NUMBER_: "Invoice Number",
  _REQUEST_TIME_: "Request Time",
  _REMOVE_JOB_REQUEST_DETAIL_: "Remove Job Request Detail",
  _DEPARTMENT_NAME_: "Department Name",
  _CONTACT_NO_: "Contact No.",
  _ALLOCATE_AT_: "Allocate At",
  _REMOVE_PARENT_: "Remove Parent?",
  _STUDENTS_NAME_: "Students Name",
  _CLASS_: "Class",
  _PARENTS_NAME_: "Parents Name",
  _FUEL_DETAILS_: "Fuel Details",
  _GRADE_LIST_: "Grade list",
  _GRADE_BUS_TIMING_: "Grade Bus Timing",
  _SCHOOL_CALENDER_LIST_: "School Calender list",
  _SCHOOL_CALENDER_: "School Calendar",
  _STAFF_: "Staff",
  _REMOVE_PASSENGER_: "Remove Passenger?",
  _CHECK_ROUTE_: "Check Route",
  _SELECT_VEHICLES_: "Please Select Vehicles",
  _SUCCESS_: "Success",
  _FILE_UPLOADED_: "Your file has been uploaded",
  _REASSIGN_VEHICLE_: "Reassign Vehicle",
  _EXPORT_TO_EXCEL_: "Export To Excel",
  _SEARCH_DELIVERY_: "Search Delivery",
  _SEARCH_VEHICLE_: "Search Vehicle",

  _EDIT_FUEL_FILLED_: "Edit Fuel Filled",
  _DELETE_FUEL_FILLED_: "Delete Fuel Filled",
  _FUEL_FILLED_LITERS_: "Fuel Filled(litre)",
  _LOADING_FUEL_DETAILS_: "Loading Fuel Details",
  _FUEL_FILLED_DETAILS_ADDED_SUCCESS_: "Fuel Filled Details Added Succesfully",
  _EDIT_FUEL_DETAILS_: "Edit Fuel Details",
  _DELETE_FUEL_DETAILS_: "Delete Fuel Details",
  _FUEL_DETAIL_IS_EMPTY_: "Fuel Details field is empty.",
  _FUEL_DETAILS_LIST_: "Fuel Detail list",
  _EXPORT_TO_EXCEL_: "Export To Excel",
  _RECORDS_: "Rcords",
  _PLEASE_SELECT_VEHICLES_: "Please Select Vehicles",
  _VEHICLE_DETAILS_: "Vehicle Details",
  _ALLOCATE_VEHICLE_DETAILS_LIST_: "Allocate Vehicle List",
  _SEARCH_BY_ALLOCATE_DATE_: "Search by allocate date",
  _SEARCH_BY_RETURN_DATE_: "Search by Return Date",
  _ROUT_ALLOCATION_LIST_: "Route Allocation List",
  _REASSIGN_VEHICLE_: "Reassign Vehicle",
  _ADD_STUDENT_: "Add Student",
  _GRADE_TIMING_LIST_: "Grade Timing list",
  _GRADE_LIST_: "Grade list",
  _GRADE_BUS_TIMINGS_: "Grade Bus Timings",
  _SCHOOL_CALENDER_LIST_: "School Calender list",
  _SCHOOL_CALENDER_: "School Calendar",
  _PARENTS_LIST_: "Parents List",
  _PARENTS_NAME_: "Parents Name",
  _CLASS_: "Class",
  _SEARCH_: "Search",
  _PARENTS_: "Parents",
  _GRADE_: "Grade",
  _ASSIGN_TRIP_: "Assign Type",
  _CAPACITY_: "Capacity:",
  _AVAILABLE_SEAT_: "Available seat:",
  _STUDENT_ID_: "StudentId",
  _GRADE_TIMING_DETAILS_: "Grade Timing Details",
  _LOADING_GRADE_DETAILS_: "Loading Grade Details",
  _GRADE_TIMING_ADDED_SUCCESS_: "Grade Timing Details Added Succesfully",
  _EDIT_GRID_: "Edit Grade",
  _EDIT_GRID_TIMING_: "Edit Grade Timing",
  _DELETE_GRID_TIMING_: "Delete Grade Timing",
  _DELETE_GRID_: "Delete Grade",
  _ACADEMIC_YEAR_FROM_: "Academic Year From",
  _ACADEMIC_YEAR_TO_: "Academic Year To",
  _EDIT_SCHOOL_CALENDER_: "Edit School Calendar",
  _DELETE_SCHOOL_CALENDER_: "Delete School Calendar",
  _SCHOOL_REOPEN_: "School Reopen",
  _HOLYDAYS_: "Holidays",
  _PARENTS_MEETING_: "Parents Meeting",
  _MEETING_: "Meeting",
  _DELIVERY_: "Delivery",
  _EVENT_: "Event",
  _WINTER_BREAK_FROM_: "Winter Break From",
  _WINTER_BREAK_TO_: "Winter Break To",
  _SPRING_BREAK_FROM_: "Spring Break From",
  _SPRING_BREAK_TO_: "Spring Break To",
  _SUMMER_BREAK_FROM_: "Summer Break From",
  _SUMMER_BREAK_TO_: "Summer Break To",
  _FIRST_TERM_: "First Term",
  _FULL_TERM_: "Full Term",
  _SCHOOL_CALENDER_DETAILS_: "School Calendar Details",
  _SECOND_TERM_: "Second Term",
  _THIRD_TERM_: "Third Term",
  _SCHOOL_CALENDER_UPDATED_: "School Calender Updated Succesfully",
  _SCHOOL_CALENDER_ADDED_: "School Calender Added Succesfully",
  _LOADING_SCHOOL_CALENDER_DETAILS_: "Loading School Calender Details",
  _PLEASE_SELECT_START_POINT_FIRST_: "Please Select Start Point First",
  _SUGGEST_ROUTE_: "Suggest Route",
  _DEPARTURE_TIME_: "Depature Time",
  _PLEASE_SELECT_ADDRESS_AND_SEQUENCE_:
    "Please Select Address and sequence both.",
  _PLEASE_SELECT_START_POINTAND_ADDRESS_:
    "Please Select Start Point and address.",
  _EDIT_TRIP_: "Edit Trip",
  _DELETE_TRIP_: "Delete Trip",
  _ASSIGN_BUS_TO_STUDENT_: "Assign Bus To Student",
  _COPY_TRIP_: "Copy Trip",
  _REMOVE_TRIP_: "Remove Trip?",
  _SELECT_VEHICLE_: "Select Vehicle",
  _SELECT_DISTRIBUTOR1_: "Select Distributor1",
  _DISTRIBUTOR_: "Distributor",
  _HIJRI_: "Hijri",
  _GREGORIAN_: "Gregorian",
  _FILTER_TYPE_: "Filter By Type",
  _SALES_PERSON_LIST_: "Sales Person List",
  _STAFF_LIST_: "Staff List",
  _JOB_REQUEST_: "Job Request",
  _DELETE_JOB_: "Delete Job",
  _FINISH_JOB_: "Do you want to finish job?",
  _SELECT_ONE_JOB_: "Please select  atleast one job.",
  _SURE_TO_DELETE_: "Are you sure you want to delete?",
  _SELECT_JOB_: "Please Select Job",
  _SURE_TO_FINISH_JOB_: "Are you sure you want to finish job?",
  _SEARCH_REQUEST_: "Search request",
  _SELECT_DISTRIBUTOR_: "Select Distributor",
  _SELECT_DEPARTMENT_: "Select Department",
  _PENDING_: "Pending",
  _PROCESSED_: "Processed",
  _FINISHED_: "Finished",
  _SELECT_SALES_EXECUTIVE_: "Select Sales Executive",
  _EXCEL_DETAILS_ID_NAME_: "Excel details (ID/Name)",
  _JOB_REQUEST_LIST_: "Job Request List",
  _WANT_TO_DELETE_: "Do you want to delete ?",
  _EDIT_RECORD_: "Edit Record",
  _DELETE_RECORD_: "Delete Record",
  _SEARCH_BUS_NO_: "Search by Bus No",
  _SEARCH_NAME_: "Search by Name",
  _SEARCH_TAGID_: "Search by TagId",
  _WRONG_BUS_BOARDING_: "Wrong Bus Boarding",
  _TRIP_DETAILS_: "Trip Details",
  _SEARCH_BY_BUS_NO_: "Search by Bus No",
  _SEARCH_BY_NAME_: "Search by Name",
  _SEARCH_BY_TAGID_: "Search by TagId",
  _TRIP_START_TIME_: "Trip Start Time",
  _POINT_COVERED_: "Point Covered",
  _POINT_LEFT_: "Point Left",
  _TRIP_END_TIME_: "Trip End Time",
  _START_LOCATION_: "Start Location",
  _COLOR_CODE_: "Color Code",
  _ASSIGN_VEHICLE_TOJOB_REQ_: "Assign Vehicle To Job Request",
  _ASSIGN_VEHICLE_TO_COMPANY_: "Assign Vehicle to Company",
  _SELECT_DEVICE_GROUP_: "Select Device Group",
  _ASSIGN_DEVICE_GROUP_TO_VEHICLE_: "Assign Device Group To Vehicle",
  _NO_VEHICLE_PLZ_ADD_: "No Vehicles. Please add vehicles",
  _DEVICE_GROUP_APPLIED_TO_SELECTED_:
    "Device Group Applied to Selected Vehicles",
  _SAVE_GROUP_DEVICE_DATA_: "Saving Device Group Data...",
  _CLASS_DEPARTMENT_: "Class/Department",
  _STUDENT_OFF_BOARDS_: "Student off Boards",
  _STUDENT_ON_BOARDS_: "Student on Boards",
  _NOT_VALID_TIME_: "Not a valid time",
  _SEARCH_ZONE_ADDRESS_: "Search Zone or Address",
  _ADD_WAYPOINTS_: "Add WayPoints",
  _GET_LOCATION_: "Get Location",
  _ENTER_ADDRESS_: "Enter your address",
  _DETAILS_: "Details",
  _DATA_NOT_AVALABLE_: "Data Not Avalable",
  _STUDENT_DASHABOARD_: "Student Dashaboard",
  _SELECT_BUS_: "Select Bus",
  _SETTINGS_: "Settings",
  _PASSWORD_SENT_TO_EMAIL_ADDRESS_:
    "Your Password has been sent to your e-mail address.",
  _DETAILS_SEND_SUCCESSFULLY_: "Details Send Succsessfully",
  _NOT_VALID_NUMBER_: "Not valid number",
  _NOT_VALID_TEXT_: "Not valid text",
  _ENTER_EMAILID_: "Please Enter Your EmailId",
  _NAVIGATION_WINDOW_: "Navigation Window",
  _AN_ADDRESS_: "An address",
  _ANOTHER_ADDRESS_: "Another address",
  _GET_DIRECTION_: "Get Direction",
  _VEHICLE_NOT_AVAILABLE_: "Vehicle not Available",
  _GEOFENCE_: "Geofence",
  _VEHICLE_ZONE_: "Vehicle Zone",
  _ADD_ROUTE_ALLOCATION_: "Add Route Allocation",
  _SUCCESSFULLY_UPDATED_: "Successfully Updated",
  _SEARCH_SERVICE_TYPE_: "Search Service Type",
  _SEARCH_GARAGE_: "Search Garage",
  _SEARCH_DATE_: "Search By Date",
  _SELECT_EXCEL_FILE_ONLY_: "Please Select Excel file Only.",
  _RECORDS_: "Records",
  _STUDENT_ABSENT_: "Student Absent",
  _DISTANCE_FUELVOLUME_: "Distance and FuelVolume",
  _FUELVOLUME_: "Fuelvolume",
  _SELECT_ZONE_: "Select Zone",
  _ASSIGN_VEHICLES_TO_ZONES_: "Assign Vehicles To Zones",
  _GEO_FANCE_: "Geo Fance",
  _FLEET_REPORTS_GEONAME_: "Geofence Name",
  _GENERAL_: "General",
  _LOCATIONS_: "Locations",
  _ALERTS_: "Alerts",
  _MESSAGES_LIST_: "Messages List",
  _EVENTS_: "Events",
  _MDT_: "MDT",
  _VEHICLE_OFFLINE_TRACKING_: "Vehicle Offline Tracking",
  _VEHICLE_TRACKING_: "Vehicle Tracking",
  _TRIP_NO_: "Trip No",
  _MAXIMUM_SPEED_: "Maximum Speed",
  _FUEL_USED_: "Fuel used",
  _VEHICLE_TRIP_REPORT_: "Vehicle Trip Report",
  _GEO_BOUNDARY_REPORT_: "Geo-Boundary Report",
  _ADD_EVENT_: "Add Event",
  _REMOVE_ROUTE_: "Remove Route",
  _OPTION2_: "Option 2",
  _OPTION3_: "Option 3",
  _REMOVE_MAINTENANCE_DETAIL_: "Remove Maintenance Detail",
  _GRADE_TIMINGS_: "Grade Timings",
  _VEHICLE_DISTANCE_: "Vehicle Distance",
  _VEHICLE_DISTANCE_LIST_: "Vehicle Distance List",
  _DUTY_TIME_FROM_: "Duty Time From",
  _DUTY_TIME_TO_: "Duty Time To",
  _SHIFT_NAME_: "Shift Name",
  _ADD_NOTIFICATION_: "Add Notification",
  _POSTED_AT_: "Posted At",
  _SEND_NOW_: "Send Now",
  _VIDEO_MONITORING_: "Video Monitoring",
  _MDVRAlarm_Report_: "MDVRAlarmReport",
  _WELCOME_: "Welcome ",
  _MENU_SETTING_LIST_: "Menu Settings List",
  _NOTIFICATION_LIST_: "Notification List",
  _INSPECTION_LIST_: "Inspection List",
  _MDVR_ALARM_REPORT_: "MDVR Alaram Report",
  _DEVICE_ID_: "Device Serial Id",
  _ALARM_TYPE_: "Alarm Type",
  _CONTENT_: "Content",
  _LAST_DATE_TIME_: "Last Date Time",
  _LIVE_VIDEO_STREAMING_: "Live Video Streaming",
  _CHANNEL1_: "Channel 1",
  _CHANNEL2_: "Channel 2",
  _CHANNEL3_: "Channel 3",
  _CHANNEL4_: "Channel 4",
  _CHANNEL_: "Channel",
  _PLAY_BACK_OPTION_: "Playback Options",
  _COLLECTION_LIST_: "Collection List",
  _REMOVE_COLLECTION_IMAGE_: "Remove Collection Face",
  _REMOVE_COLLECTION_IMAGE_MSG_:
    "Do you want to remove the image from AWS Collection?",
  _AWS_: "AWS",
  _IMAGE_UPLOAD_: "Image Upload",
  _SELECT_IMAGE_: "Please select image...",
  _BLACKLIST_PERSON_REPORT_: "Blacklist Person Report",
  _SIMILARITY_: "Similarity",
  _DESCRIPTION_: "Description",
  _BLACKLIST_: "Blacklist",
  _BLACKLISTED_AT_: "Blacklisted At",
  _BLACKLIST_PERSON_: "Blacklist Person",
  _FIRST_NAME_: "First Name",
  _LAST_NAME_: "Last Name",
  _OFFICER_ID_: "Police Officer Id",
  _OFFICER_DETAILS_: "Police Officer Details",
  _LIVE_LOCATION_: "Live Location",
  _LIVE_LOCATION_TITLE_: "Live Location List",
  _CREATE_LINK_: "Create Link",
  _LIVE_LOCATION_URL_: "Live Location URL",
  _CURRENT_GPS_ODOMETER_: "Current GPS Odometer",
  _CHANGE_ODOMETER_: "Change Odometer",
  _ODOMETER_TRAVELLED_: "Odometer Travelled",
  _TOTAL_ODOMETER_: "Total Odometer",
  _DIFF_ODOMETER_: "Diff Odometer",
  _ADJUST_ODOMETER_: "Adjust Odometer",
  _GPS_ODOMETER_: "GPS Odometer",
  _ODOMETER_ADJUSTMENT_: "Odometer Adjustment",
  _WASL_DRIVER_LIST_: "WASL Driver List",
  _VIEW_ON_MAP_: "View On Map",
  _POLICE_OFFICERS_LIST_: "Police Officers List",
  _POLICE_OFFICER_NAME_: "Police Officer Name",
  _POLICE_OFFICER_MOBILE_NO_: "Police Officer MobileNo",
  _POLICE_OFFICER_USERNAME_: "Police Officer UserName",
  _POLICE_OFFICER_: "Police Officer",
  _BLACK_LIST_: "Black List",
  _VEHICLE_GROUPS_: "Vehicle Groups",
  _TOTAL_COUNT_: "Total Count",
  _BLOCK_UNBLOCK_: "Block/Unblock",
  _GENDER_: "Gender",
  _MALE_: "Male",
  _FEMALE_: "Female",
  _CUSTOMER_DISTRIBUTOR_: "Customer/Distributor",
  _WASL_REGISTRATION_: "WASL Registration",
  _UPLOADING_: "Uploading...",
  _SELECT_RECORD_: "Please Select Records",
  _PROCESSING_: "Processing...",
  _ALERT_BLOCK_UNBLOCK_: "Are You Sure You Wants To Block/Unblock?",
  _ALERT_REMOVE_FROM_BLACKLIST_:
    "Are You Sure You Wants To Remove The Person From BlackList?",
  _BILL_NO_: "Bill No",
  _ADD_SERVICE_: "Add Service",
  _ID_: "ID",
  _ITEM_: "Item",
  _PRICE_: "Price",
  _GROSS_PRICE_: "Gross Price",
  _INCHARGE_NAME_: "Incharge Name",
  _WORKING_DAYS_: "Working Days",
  _CURRENT_SPEED_: "Current Speed",
  _FLEET_REPORTS_DRIVERNAME_: "Driver Name",
  _FLEET_REPORTS_AMOUNT_: "Amount",
  _LOAD_SENSOR_: "Load Sensor",
  _MINIMUM_VOLT_: "Minimum Volt",
  _MAXIMUM_VOLT_: "Maximum Volt",
  _HEAD_WEIGHT_: "Head Weight",
  _ATTACH_TAILER_: "Attach Trailer",
  _TRAILER_ID_: "Trailer Id",
  _TRAILER_TYPE_: "Trailer Type",
  _TRAILER_EMPTY_WEIGHT_: "Trailer Empty Weight",
  _ADD_TRAILER_: "Add Trailer",
  _ADD_TRIP_COST_: "Add Trip Cost",
  _TRAILER_LIST_: "Trailer List",
  _TAILER_: "Trailer",
  _ATTACH_TAILER_: "Attach Trailer",
  _SEARCH_BY_ZONE: "Search By Zone",
  _HEAD_WEIGHT_: "Head Weight",
  _FLEET_VEHICLEDETAIL_DIGITALIPDDL_SEATBELT1_: "Driver Seatbelt",
  _FLEET_VEHICLEDETAIL_DIGITALIPDDL_SEATBELT2_: "Passenger Seatbelt",
  _BLE_: "BLE",
  _analog_: "Analog",
  _VOLUME_PER_UNIT_: "Volume Per Unit",
  _BLE_TEMP_: "BLE Temp",
  _BLE_HUMIDITY_: "BLE Humidity",
  _BLE_TEMP_: "BLE Temp",
  _BLE_HUMIDITY_: "BLE Humidity",
  _BLE_TEMP1_: "BLE Temp1",
  _BLE_TEMP2_: "BLE Temp2",
  _BLE_TEMP3_: "BLE Temp3",
  _BLE_TEMP4_: "BLE Temp4",
  _MIN_TEMP1_: "Min Temp1",
  _MIN_TEMP2_: "Min Temp2",
  _MIN_TEMP3_: "Min Temp3",
  _MIN_TEMP4_: "Min Temp4",
  _MAX_TEMP1_: "Max Temp1",
  _MAX_TEMP2_: "Max Temp2",
  _MAX_TEMP3_: "Max Temp3",
  _MAX_TEMP4_: "Max Temp4",
  _BLE_HUMIDITY1_: "BLE Humidity1",
  _BLE_HUMIDITY2_: "BLE Humidity2",
  _BLE_HUMIDITY3_: "BLE Humidity3",
  _BLE_HUMIDITY4_: "BLE Humidity4",
  _MIN_HUMIDITY1_: "Min Humidity1",
  _MIN_HUMIDITY2_: "Min Humidity2",
  _MIN_HUMIDITY3_: "Min Humidity3",
  _MIN_HUMIDITY4_: "Min Humidity4",
  _MAX_HUMIDITY1_: "Max Humidity1",
  _MAX_HUMIDITY2_: "Max Humidity2",
  _MAX_HUMIDITY3_: "Max Humidity3",
  _MAX_HUMIDITY4_: "Max Humidity4",
  _FLEET_RULESELECTORDETAIL_BLE_DESCRIPTION_:
    "This rule will be triggered when temperature/humidity goes beyond the defined limit. User can set minimum and maximum temperature/humidity.",
  _BLE_TEMP_HUMIDITY_: "BLE Temp/Humidity",
  _IDLE_DURATION_: "Idle Duration",
  _START_LOAD_VOLTAGE_: "Start Load Voltage",
  _END_LOAD_VOLTAGE_: "End Load Voltage",
  _START_LUGGAGE_LOAD_: "Start Luggage Load",
  _END_LUGGAGE_LOAD_: "End Luggage Load",
  _START_EMPTY_WEIGHT_: "Start Empty Weight",
  _END_EMPTY_WEIGHT_: "End Empty Weight",
  _START_TOTAL_WEIGHT_: "Start Total Weight",
  _END_TOTAL_WEIGHT_: "End Total Weight",
  _PLAYBACK_: "Playback",
  _PLAY_BACK_OPTION_: "Playback Options",
  _FILE_START_DATE_: "File Start Date",
  _FILE_START_TIME_: "File Start Time",
  _FILE_END_DATE_: "File End Date",
  _FILE_END_TIME_: "File End Time",
  _START_TIME_: "Start Time",
  _END_TIME_: "End Time",
  _SOURCE_FILE_START_DATE_: "Source File Start Date",
  _SOURCE_FILE_START_TIME_: "Source File Start Time",
  _SOURCE_FILE_END_DATE_: "Source File End Date",
  _SOURCE_FILE_END_TIME_: "Source File End Time",
  _FILE_TAG_: "File Tag",
  _FILE_PATH_: "File Path",
  _FILE_SIZE_: "File Size",
  _RECORD_TYPE_: "Record Type",
  _GENERAL_: "General",
  _ALARM_: "Alarm",
  _DOWNLOAD_TYPE_: "Download Type",
  _WHOLE_FILE_: "Whole File",
  _SEGMENT_: "Segment",
  _DEVICE_NUMBER_: "Device Number",
  _INDEX_: "Index",
  _TIME_: "Time",
  _TYPE_: "Type",
  _FILE_LOCATION_: "File Location",
  _FILE_: "File",
  _FILE_TYPE_: "File Type",
  _DATE_: "Date",
  _START_: "Start",
  _END_: "End",
  _MDVR_ALARM_REPORT_: "MDVR Alarm Report",
  _ALARM_TYPE_: "Alarm Type",
  _START_SPEED_: "Start Speed(KM/H)",
  _END_SPEED_: "End Speed(KM/H)",
  _HOUR_METER_READING_: "Hour Meter Reading",
  _REGULAR_RUNNING_HOUR_: "Regular Running Hours",
  _MAX_HOUR_PER_DAY_: "Max Hours Per Day",
  _GENSET_WORKING_HOUR_REPORT_: "Machinery Report",
  _FLEET_DASHBORD_HISTORY_TOOLTIP_LOCATION_: "Location",
  _SEND_: "Send",
  _HARSH_ACCEL_: "Harsh Accel",
  _HARSH_BRACKING_: "Harsh Braking",
  _EX_IDELING_: "Ex-Idling",
  _DRIVER_BEHAVIOR_SETTINGS_: "Driver Behaviour Settings",
  _SPEEDING_: "Speeding",
  _RPM_EXCESS_: "RPM Excess",
  _Min_Mileage_: "Min. Mileage (Km)",
  _DISPLAY_ABSOLUTE_VAL_: "Display Absolute Value",
  _TOP_SCORE_: "Top Score",
  _USER_COSTIMIZED_SCORE_: "User Customized Score",
  _ASSET_OR_VEHICLES_: "Asset Or Vehicles",
  _ASSET_DEVICE_MANAGEMENT_: "Asset Device Assignments",
  _RPM_LIMIT_: "RPM Limit",
  _MIN_ALLOWED_EVENTS_: "Min Allowed Events",
  _ABSOLUTE_VAL_: "Absolute Value",
  _DRIVER_SCORE_REPORT_: "Driver Score Report",
  _ACCEL_N_: "Acce.",
  _BRAKE_: "Brake",
  _EXIDLE_: "Ex Idle",
  _SCORE_: "Score",
  _DBRAKE_: "Brake",
  _TOTAL_WEIGHT_: "Total Weight",
  _PAGE_: "Page",
  _OF_: "of {0}",
  _TYPE_: "Type",
  _DRIVER_SCORE_GRAPH_: "Driver Score Graph",
  _HORIZONTAL_: "Horizontal",
  _VERTICAL_: "Vertical",
  _MAX_SPEED_GRAPH_: "Max Speed Graph",
  _RENEW_OPTION_: "Renew",
  _UPLOAD_: "Upload",
  _SAMPLE_: "SAMPLE",
  _IMPORT_EXPORT_EXCEL_: "Import From Excel",
  _FULL_PATH_: "Full Path",
  _STRAIGHT_LINE_: "Straight Line",
  _TRIP_: "Trip",
  _OVERSPEED_: "Overspeed",
  _ALL_: "All",
  _TRAVEL_: "Travel",
  _FAST_: "Fast",
  _MEDIUM_: "Medium",
  _SLOWER_: "Slower",
  _STOPPED_: "Stopped",
  _IDLE_: "Idle",
  _HARSH_ACCELERATION_: "Harsh Acceleration",
  _HARSH_DEACCELERATION_: "Harsh  Deacceleration",
  _LOAD_SENSOR_DISCONNECT_ALARM_: "Load Sensor Disconnect Alarm",
  _POWER_CUT_ALARM_: "Power Cut Alarm",
  _HIGH_IMPACT_ACCIDENT_: "High Impact(Accident)",
  _LOW_IMPACT_ACCIDENT_: "Low Impact(Incident)",
  _GSM_JAMMING_ALARM_: "GSM Jamming Alarm",
  _NO_EVENT_: "No Event",
  _STOP_: "Stop",
  _EXIDLE_: "ExIdle",
  _RUNNING_: "Running",
  _NODATA_: "Nodata",
  _NoData_: "No Data",
  _TOTAL_: "Total",
  _EXPIRED_: "Expired",
  _EXPIRINGSOON_: "Expiring Soon",
  _SELECT_ALL_GROUPS_: "Select All Groups",
  _HISTORY_OF_MULTIPLE_VEHICLES_: "History Of Multiple Vehicles",
  _APPLY_: "Apply",
  _CANCEL_: "Cancel",
  _CUSTOME_RANGE_: "Custom Range",
  _SEND_WEB_PUSH_NOTIFICATION_: "Web Push Notification",
  _MULTI_VIDEO_MONITORING_: "Multi Video Monitoring",
  _TRACK_BY_GROUP_: "Track By Group ",
  _GROUP_NAME_: "Group Name",
  _COMPANY_: "Company",
  _DRIVER_: "Driver",
  _IGNITION1_: "Ignition",
  _TYPE_: "Type",
  _YES_: "Yes",
  _NO_: "No",
  _MAINTENANCE_DASHBOARD_: "Maintenance Dashboard",
  _Monthly_Expenses_: "Monthly Expenses",
  _Weekly_Expenses_: "Weekly Expenses",
  _REMINDERS_: "Reminders",
  _Cost_InThousand_: "Cost (In Thousand)",
  _FLEET_VEHICLE_VEHICLEDETAIL_LASTBATTERY_: "Last Battery Change",
  _FLEET_VEHICLE_VEHICLEDETAIL_BATTERY_CHANGE_INT_:
    "Battery Change Interval(Days)",
  _ORDER_STATUS_: "Status",
  _IASTIMARA_RENEWAL_: "Istimara Renewal",
  _INSURANCE_RENEWAL_: "Insurance Renewal",
  _Battery_Next_Change_Date_: "Battery Next Change Date",
  _PAYMENT_: "Payment",
  _NEW_EXPIRY_DATE_: "New Expiry Date",
  _PAYMENT_AMOUNT_: "Amount",
  _PAYMENT_LIST_: "Payment List",
  _PAYMENT_DETAILS_LIST_: "Payment Details List",
  _PAYMENT_ID_: "Payment Id",
  _PAYMENT_METHOD_: "Payment Method",
  _TOTAL_VEHICLE_: "Total Vehicle",
  _TOTAL_AMOUNT_: "Total Amount",
  _PAYMENT_DATE_: "Payment Date",
  _VEHICLE_DETAIL_: "Details",
  _NON_STOPPAGE_FOR_: "Moving Since(min) for Non Stoppage",
  _RENEWAL_PRICE_: "Renewal Price",
  _WASL_REPORT_: "WASL Report",
  _TEMPERATURE_: "Temperature",
  _HUMIDITY_: "Humidity",
  _RFID_CHARGES_: "RFID Charges",
  _TOTAL_CHARGES_: "Total Charges",
  _SEARCH_DERIVER_IDENTIFYNUMBER_: "Search by Driver Identify Number",
  _COMMERCIAL_RECORD_BUMBER_: "Commercial Record No.",
  _COMMERCIAL_RECORD_ISSUE_DATE1_: "Commercial Record Issue Date",
  _VEHICLE_MODEL_: "Model",
  _MANUFACTURER_: "Manufacturer",
  _TYPE_OF_VEHICLE_: "Type of Vehicle",
  _FUEL_TYPE_: "Fuel Type",
  _COMMENTS_: "Comments",
  _SELECT_ACTIVITY_: "Select Activity",
  _REGISTRATION_DATE_: "Registration Date",
  _WEIGHT_REPORT_: "Weight Report",
  _BLE1CUSTOM2_: "BLE1 Custom2",
  _BLE1CUSTOM3_: "BLE1 Custom3",
  _BLE1CUSTOM4_: "BLE1 Custom4",
  _BLE1CUSTOM5_: "BLE1 Custom5",

  _BLE2CUSTOM2_: "BLE2 Custom2",
  _BLE2CUSTOM3_: "BLE2 Custom3",
  _BLE2CUSTOM4_: "BLE2 Custom4",
  _BLE2CUSTOM5_: "BLE2 Custom5",

  _BLE3CUSTOM2_: "BLE3 Custom2",
  _BLE3CUSTOM3_: "BLE3 Custom3",
  _BLE3CUSTOM4_: "BLE3 Custom4",
  _BLE3CUSTOM5_: "BLE3 Custom5",

  _BLE4CUSTOM2_: "BLE4 Custom2",
  _BLE4CUSTOM3_: "BLE4 Custom3",
  _BLE4CUSTOM4_: "BLE4 Custom4",
  _BLE4CUSTOM5_: "BLE4 Custom5",

  _WAREHOUSE_LIST_: "Warehouse List",
  _ADD_WAREHOUSE_: "Add Warehouse",
  _NAME_WAREHOUSE_: "Name Of Warehouse",
  _ADDRESS_OF_WAREHOUSE_: "Address Of Warehouse",
  _LAND_CORDINATES_: "Land Cordinates",
  _LAND_CORDINATE_NAME_: "e.g lat1:long1,lat2:long2,lat3:long3,lat4:long4",
  _LICENSE_ISSUE_DATE_: "License Issue Date",
  _LICENSE_EXPIRY_DATE_: "License Expiry Date",
  _PHONE_: "Phone",
  _WAREHOUSE_LAND_AREA_: "Land Area (Sq.mt)",
  _WAREHOUSE_EMAIL_: "Manager Email Address",
  _INVENTORY_: "Inventory",
  _INVENTORY_LIST_: "Inventory List",
  _ADD_INVENTORY_: "Add Inventory",
  _INVENTORY_NAME_: "Inventory Name",
  _INVENTORY_NUMBER_: "Inventory Number",
  _STORING_CATEGORY_: "Storing Category",
  _STORING_TYPE_: "Storing Type",
  _FLEET_GROUP_GROUPDETAIL_WAREHOUSE_SETLOAD_: "Saving Warehouse Data",
  _NAME_GRID_OF_WAREHOUSE_: "Warehouse",
  _CITY_OF_WAREHOUSE_: "City",
  _LICENSE_GRID_NUMBER_: "License No",
  _LICENSE_GRID_ISSUE_DATE_: "Issue Date",
  _LICENSE_GRID_EXPIRY_DATE_: "Expiry Date",
  _PHONE_: "Phone",
  _PLOT_DEFAULT_: "Plot Default",
  _CLUSTER_: "Cluster",
  _MANAGER_GRID_MOBILE_: "Manager Mobile",
  _EMAIL_GRID_: "Manager Email",
  _WAREHOUSE_GRID_LAND_AREA_: "Land Area",
  _WAREHOUSE_WASL_REPLY_: "WASL Reply",
  _REGISTER_IN_WASL_: "Register in WASL",
  _WAREHOUSE_WASL_RESPONSE_: "WASL Response",
  _SAVE_INVENTORY_DATA_: "Save Inventory Data",
  _SFDA_COMPANY_ACTIVITY_: "SFDA Company Activity",
  _SENSOR_REPORT_: "Sensor Report",
  _TIMESTAMP_: "TimeStamp",

  _ALARMTYPE_: "Alarm Type",
  _BATTORY_VOLTAGE_: "Battery Voltage",
  _POWER_VOLTAGE_: "Power Voltage",
  _SENSOR_TYPE_: "Sensor Type",
  _SENSOR_COUNT_: "Sensor Count",
  _SENSOR_ID_: "Sensor Id",
  _SENSORID_: "SensorId",
  _BATTORY_VOLTAGE_STATUS_: "Battery Voltage Status",
  _SENSOR_BATTORY_VOLTAGE_: "Sensor battery voltage",
  _TEMPRATURE_ALERT_STATUS_: "Temperature Alert Status",
  _SENSORE_TEMPERATURE_: "Sensor Temperature",
  _SENSOR_BUTTON_STATUS_: "Sensor Button Status",
  _SENSOR_STATUS_: "Sensor Status",
  _HUMIDITY_: "Humidity",
  _RSSI_: "RSSI",
  _SENSOR_TIME_: "Sensor Time",
  _LUBE_OIL_VOLUME_: "Lube Oil Volume",
  _LUBE_OIL1_PERCENT_: "Lube Oil Level %",
  _LUBE_OIL_: "Lube Oil",
  _FUEL_CALIBRATION_NAME_: "Fuel Calibration Name",
  _FUEL_TANK_CAPACITY_: "Fuel Tank Capacity ",
  _TANK_L_IN_CM_: "Tank (L) in Cm.",
  _TANK_W_IN_CM_: "Tank (W) in Cm.",
  _TANK_HEIGHT_IN_CM_: " Tank (Height) in Cm.",
  _TANK1_l_IN_CM_: "Tank1(L) in Cm.",
  _TANK1_D_IN_CM_: "Tank1(D) in Cm.",
  _MAX_VOLTAGE_: "Max Voltage",
  _MIN_VOLTAGE_: "Min Voltage",
  _INVENTORY_STATUS_REPORT_: "Inventory Status Report",
  _FLEET_REPORTS_VEHICLENO_: "Asset Name",
  _INVENTORY_WASL_KEY_: "Inventory WASL Key",
  _WASREHOUSE_WASL_KEY_: "Warehouse WASL Key",
  _ADD_WAREHOUSE_: "Warehouse",
  _TEMP_: "Temp",
  _TEMP_PDF_: "Temp PDF",
  _INVENTORIES_: "Inventories",
  _WAREHOUSE_MONITORING_: "Warehouse Monitoring",
  _WAREHOUSE_NAME_: "Warehouse Name",
  _WAREHOUSE_LICENSE_: "License No",
  _REMINDER_: "Reminder",
  _UPDATE_IMEI_: "Update Imei",
  _REGION_: "Region",
  _BRANCH_: "Branch",
  _DEPARTMENT_: "Department",
  _YEAR_: "Year",
  _OWNERSHIP_: "Ownership",
  _FLEET_VEHICLENAME_: "Vehicle Name",
  _INSTALLATION_TYPE_: "Installation Type",
  _STOP_LOCATION_: "Stop Location",
  _DEVICE_TESTING_: "Device Testing",
  _ENTER_IMEI_: "Enter IMEI",
  //WORDS ADDED BY ASHVINI
  _ACCIDENT_ALERT_: "Accident Alert",
  _TOWING_ALERT_: "Towing Alert",
  _STAY_ALERT_: "Stay Alert",
  _PARKING_ALERT_: "Parking alert",
  _VEHICLE_HARSH_CONNECTING_: "Vehicle harsh cornering",
  _VEHICLE_HARSH_BRACKING_: "Vehicle harsh braking",
  _ROAD_OVERSPEED_: "Road overspeed",
  _PAYMENT_: "Payment",
  _TESTING_POWER_CONNECTION_: "Testing Power Connection",
  _TESTING_ACC_: "Testing ACC",
  _TESTING_GPS_: "Testing GPS",
  _LAST_KNOWN_DISTANCE_: "Last known distance",
  _LAST_KNOWN_BLE_HUMIDITY_: "Last known BLE Humidity",
  _LAST_KNOW_BLE_TEMPERATURE_: "Last know BLE Temperature",
  _TESTING_VOLTAGE_: "Testing Voltage",
  _TESTING_WEIGHT_: "Testing Weight",
  _DEVICE_TESTING_: "Device Testing",
  _POWER_CONNECTED_: "Power Connected",
  _LATTITUDE_AND_LONGITUDE_: "Latitude And Longitude",
  _FILTER_BY_: "Filter By",
  _REPORT_FORMAT_: "Report Format",
  _ASSIGN_ALL_VEHICLES_: "Assign All Vehicles",
  _WASL_STATION_: "WASL Station",
  _STATION_LIST_: "Station List",
  _STATION_CODE_: "Station Code",
  _ARABIC_NAME_: "Arabic Name",
  _ENGLISH_NAME_: "English Name",
  _CITY_CODE_: "City Code",

  _INSIDE_CITY_: "Inside City",
  _BETWEEN_CITIES_: "Between City",
  _BETWN_CITIES_: "Between Cities",
  _STATIONS_TATUS_: "Station Status",
  _PHONE_EXTENSION_: "Phone Extension",
  _STATION_REGISTRATION_: "Station Registration",
  _WASL_TRIP_: "WASL Trip",
  _WASL_TRIP_LIST_: "WASL Trip List",
  _PRIMARY_DRIVER_: "Primary Driver",
  _ASSISTANT_DRIVER_: "Assistant Driver",
  _WASL_TRIP_REGISTRATION_: "WASL Trip Registration",
  _WASL_TRIP_TYPE_: "Trip Type",
  _WASL_TRIP_DEPARTURE_STATION_CODE_: "Departure Station Code",
  _WASL_TRIP_ARRIVAL_STATION_CODE_: "Arrival Station Code",
  _WASL_TRIP_ARRIVAL_TIME: "Expected Arrival Time",
  _WASL_TRIP_DEPARTURE_TIME: "Expected Departure Time",
  _INVOICE_NUM_: "Invoice No",
  _FLEET_VEHICLE_VEHICLEDETAIL_DIGITAL4_: "DigiInput4",
  _PASSENGER_OUT_: "Passenger Out",
  _PASSENGE_IN_: "Passenger In",
  _WASL_TRIP_STATION_CODE_: "Station Code",
  _UPDATE_WASL_TRIP_: "Update Wasl Trip",
  _PRIMARY_DRIVER_IDENTITY_: "Primary Driver Identity No.",
  _ASSISTANT_DRIVER_IDENTITY_: "Assistant Driver Identity No.",
  _TRIP_NUMBER_: "Trip Number",
  _DISTANCE_IN_METERS_: "Distance In Meters",
  _NO_OF_PASSENGER: "No of Passenger",
  _NO_OF_PASSENGERS_: "No of Passengers",
  _WASL_BUS_REPORT_: "Wasl Bus Trip Report",
  _WASL_BUS_TRIP_REPORT_: "Wasl Bus Trip Report",
  _TRIP_STATUS_: "Trip Status",
  _GEOFENCE_VISIT_: "Geofence Visit Report",
  _GEOFENCE_VISIT_REPORT_: "Geofence Visit Report",
  _GEOFENCEVISIT_: "Geofence Visit",
  _LATITUDE_: "Latitude",
  _LOGITUDE_: "Longitude",
  _LOCATION_: "Location",
  _DRIVERID_: "Driver",
  _BREAKE_SWITCH_: "Brake Switch",
  _WHEEL_BASE_SPEED_: "Wheel Based Speed",
  _CRUISE_CONTROL_ACTIVE_: "Cruise Control Active",
  _CLUTCH_SWITCH_: "Clutch Switch",
  _PTO_STATE_: "Pto State",
  _ACCELERATION_PEDAL_POSITION_: "Acceleration Pedal Position",
  _ENGINEE_CURRENT_LOAD_: "Engine Current load",
  _ENGINE_TOTAOL_FUEL_USE_: "Engine Total Fuel Used",
  _FUEL_LEVEL_: "Fuel level",
  _ENGINE_SPEED_: "Engine Speed",
  _AXEL_WIDTH1_: "Axle_Weight1",
  _AXEL_WIDTH2_: "Axle_Weight2",
  _AXEL_WIDTH3_: "Axle_Weight3",
  _AXEL_WIDTH4_: "Axle_Weight4",
  _AXEL_WIDTH5_: "Axle_Weight5",
  _AXEL_WIDTH6_: "Axle_Weight6",
  _AXEL_WIDTH7_: "Axle_Weight7",
  _AXEL_WIDTH8_: "Axle_Weight8",
  _AXEL_WIDTH9_: "Axle_Weight9",
  _AXEL_WIDTH10_: "Axle_Weight10",
  _AXEL_WIDTH11_: "Axle_Weight11",
  _AXEL_WIDTH12_: "Axle_Weight12",
  _AXEL_WIDTH13_: "Axle_Weight13",
  _AXEL_WIDTH14_: "Axle_Weight14",
  _AXEL_WIDTH15_: "Axle_Weight15",
  _ENGINE_TOTAL_HOURSE_OF_OPERATION_: "Engine Total Hours Operation",
  _SOPTWARE_VERSION_SUPPORTED_: "Software Version Supported",
  _DIGNOSTIC_SUPPORTED_: "Diagnostics Supported",
  _REQEST_SUPPORTED_: "Requests Supported",
  _SERVICE_DISTANCE_: "Service Distance",
  _DIRECTION_INDICATION_: "Direction Indication",
  _TACHOGRAPH_PERFORMANCE_: "Tachograph Performance",
  _HANDLING_INFO_: "Handling Info",
  _SYSTEM_EVENT_: "System Event",
  _ENGINE_COOLENT_TEMETATURE_: "Engine Coolant Temperature",
  _AMBIENT_AIR_TEMPERATURE_: "Ambient Air Temperature",
  _FUEL_RATE_: "Fuel Rate2",
  _INSTANTANIOUSE_FUEL_ECONOMY_: "Instantaneous Fuel Economy",
  _PTO_DRIVE_ENGAGEMENT_: "Pto Drive Engagement",
  _HIGH_RESOLUTION_ENGINE_TOTAL_FUEL_USED_:
    "High Resolution Engine Total Fuel Used",
  _GROSS_COMBINATION_VEHICLE_WEIGHT_: "Gross Combination Vehicle Weight",
  _ADBLUE_STATUS_: "AdBlue Status",
  _BREAKE_PEDAL_POSITION_: "Brake Pedal Position",
  _FUEL_LEVEL2_: "Fuel Level2",
  _MIL_INDICATOR_: "Mil Indicator",
  _TELL_TALE0_: "Tell_Tale0",
  _TELL_TALE1_: "Tell_Tale1",
  _TELL_TALE2_: "Tell_Tale2",
  _TELL_TALE3_: "Tell_Tale3",
  _ADBLUE_LEVEL1_: "AdBlue Level",
  _USER_ACTIVITY_REPORT_: "User Activity Report",
  _REGISTERED_AT_: "Registered At",
  _REGISTERED_BY_: "Registered By",
  _REGISTERED_BY_IP: "Registered By IP",
  _UPDATED_AT_: "Updated At ",
  _UPDATED_BY_: "Updated By",
  _UPDATED_BY_IP: "Updated By IP",
  _DELETED_AT_: "Deleted At",
  _DELETED_BY_: "Deleted By",
  _DELETED_BY_IP_: "Deleted By IP",
  _SELECT_ADMIN_USER_: "Select Distributor",
  _YEARLY_: "Yearly",
  _MONTHLY_: "Monthly",
  _QUARTERLY_: "Quarterly",
  _STUDENT_: "Student",
  _EMPLOYEE_: "Employee",
  _EMPLOYEE_LIST_: "Employee List",
  _EMPLOYEE_DETAILS_: "Employee Details",
  _CREATE_DATE: "Create Date",
  //maintenance dashboard
  _TYRE_CHANGE_REMINDER_: "Tyer Change Reminder",
  _SERVICE_REMINDER_: "Service Reminder",
  _INSURANCE_REMINDER_: "Insurance Reminder",
  _UPCOMING_: "Upcoming",
  _DUE_: "Due",
  _OVERDUE_: "Overdue",
  _OIL_CHANGE_REMINDER_: "Oil Change Reminder",
  _BATTERY_CHANGE_REMINDER_: "Battery Change Reminder",
  _SERVICE_: "Service",
  _TYRE_CHANGE_: "Tyre Change",
  _OIL_CHANGE_: "Oil Change",
  _ISTIMARA_REMINDER_: "Istimara Insurance",
  _INSURANCE_: "Insurance",
  _ISTIMARA_: "Istimara",
  _VEHICLEID_: "Vehicle Id",
  _TOTAL_RUNNING_HOURS_: "Total Running Hours",
  _EXCESS_RUNNING_HOURS_: "Excess Running Hours",
  _STOP_SINCE_: "Stop Since",
  _STATIONARY_REPORT_HOURS_: "Hours",
  _EQUIPMENT_NAME_: "Equipment Name",
  _BRAND_: "Brand",
  _FUEL_CALIBRATION_: "Fuel Calibration",
  _ADD_RECORD_: "Add Record",
  _TOLERANCE_: "Tolerance(mt)",
  _ADD_JOB_DETAILS: "Add Job Details",
  _SELECT_FROM_MAP: "Select From Map",
  _MAP_SETTING_: "Map Setting",
  _OFFLINE_ALERT_: "Offline Alerts",
  _DRIVER_BEHAVIOR_LIST_: "Driver Behavior List",
  _STOP_: "Stop",
  _TODAY_ACTIVITY_: "Today Activity",
  _FACEBOOK_: "Facebook",
  _INSTAGRAM_: "Instagram",
  _LINKEDIN_: "Linkedin",
  _TWITTER_: "Twitter",
  _SKYPE_: "Skype",
  _WHATSAPP_: "WhatsApp",
  _LINK_TO_SHARE_: "Link To Share",
  _COPY_LINK_: "COPY LINK",
  _PHONE_NUMBER_1_: "Primary Phone",
  // _CONTACT_2_: 'Contact 2',
  _EMAIL_2_: "Secondary Email",
  _EMAIL_1_: "Primary Email",
  _PHONE_NUMBER_2_: "Secondary Mob No",
  _HALF_YEARLY_: "Half Yearly",
  _NO_ROLE_: "No Role",
  _DEFAULT_ROLE_: "Default Role",
  _BASIC_: "Basic",
  _INDIVIDUAL_: "Individual",
  _DEFAULT_: "DEFAULT",
  _TOW_CAR_: "TOW CAR",
  _SPECIALITY_TRANSPORT_: "SPECIALITY TRANSPORT",
  _BUS_RENTAL_: "BUS RENTAL",
  _EDUCATIONAL_TRANSPORT_: "EDUCATIONAL TRANSPORT",
  _SFDA_: "SFDA",
  _PTA_: "PTA",
  _PERMANENT_: " Permanent",
  _TEMPORARY_: "Temporary",
  _SELECT_COUNTRY_: "Select Country",
  _CLICK_TO_UPLOAD_PHOTO_ID: " Click to Upload Photo Id",
  _SELECT_EMPLOYEE_TYPE: "Select Employee Type",
  _SELECT_COUNTRY_: "Select Country",
  _SELECT_DOB_FORMAT_: "Select DOB Format",
  _EDIT_ACCOUNT_DETAILS_: "Edit Account Details",
  _DELETE_ACCOUNT_: "Delete Account",
  _WASL_STATUS_: "WASL Status",
  _ASSIGN_: "Assign",
  _EDIT_STAFF_DETAILS_: "Edit Staff Details",
  _DELETE_STAFF_DETAILS_: "Delete Staff Details",
  _EDIT_PASSENGER_DETAILS_: "Edit Passenger Details",
  _DELETE_PASSENGER_: "Delete Passenger",
  _SEARCH_BY_NAME_MOBILE_EMAIL_: "Search By Name,Mobile No,Email",
  _SEARCH_BY_DRIVER_NAME_MOBILE_: "Search by Driver Name,Mobile No",
  _SEARCH_BY_NAME_MOBILE_TAGID_BUSNO_: "Search by Name,Mobile No,Tag Id,Bus No",
  _SEARCH_BY_VEHICLE_NO_DRIVER_NAME_: "Search by Vehicle No,Driver Name",
  _SEARCH_BY_DRIVER_VEHICLE_: "Search by Vehicle,Driver",
  _PROJECT_: "Project",
  _CLICK_TO_UPLOAD_: "Click to upload ",
  _SELECT_PROJECT_: "Select Project",
  _ADD_PROJECT_: "Add Project",
  _PROJECT_DETAILS_: "Project Details",
  _RETURNED_FROM_: "Returned From",
  _EDIT_RETURN_ASSET_: "Edit Return Asset",
  _EDIT_MAINTENANCE_: "Edit Maintenance",
  _DELETE_MAINTENANCE_: "Delete Maintenance",
  _OPERATION_: "Operation",
  _Id_: "Id",
  _SEARCH_BY_GARAGE_SERVICE_VEHICLE_: "Search by Garage,Service Date,Vehicle",
  _SEARCH_BY_GARAGE_VEHICLE_: "Search by Garage,Vehicle",
  _ASSIGNED_TO_: "Assigned To",
  _SHIFT_NAME_: "Shift Name ",
  _ASSIGN_TO_: "Assign To",
  _ASSIGN_BUS_TO_STUDENT_: "Assign Bus To Student",
  _EDIT_DETAILS_: "Edit Details",
  _PLEASE_CREATE_LINK_: "Please Create Link",
  _SCHOOL_BUS_: "SchoolBus",
  _PICKUP_: "PickUp",
  _PICKuP_: "Pickup",
  _2TON_PICKUP_: "2Ton PickUp",
  _3TON_PICKUP_: "3Ton PickUp",
  _5TON_PICKUP_: "5Ton PickUp",
  _10TON_PICKUP_: "10Ton PickUp",
  _CRANE_: "Crane",
  _JCB_: "JCB",
  _VAN_: "Van",
  _HIACE_: "Hiace",
  _URVAN_: "Urvan",
  _TANKER_: "Tanker",
  _BULLDOZER_: "Bulldozer",
  _DUMPER_: "Dumper",
  _REEFER_: "Reefer",
  _SHOVEL_: "Shovel",
  _EXCAVATOR_: "Excavator",
  _FORKLIF_: "Forklif",
  _BOBCAT_: "Bobcat",
  _ROLLER_: "Roller",
  _CANTER_: "Canter",
  _MINIBUS_: "Mini Bus",
  _TOURIST_BUS_: "Tourist Bus",
  _BOOM_LOADER_: "Boom Loader",
  _GENERATOR_: "Generator",
  _HILUX_: "Hilux",
  _MOTORCYCLE_: "Motorcycle",
  _6_MONTHS_: "6 Months",
  __PRIVATE_CAR_: "Private Car",
  _PUBLIC_TRANSPORT_: "Public Transport",
  _PRIVATE_TRANSPORT_: "Private Transport",
  _PUBLIC_MINIBUS_: "Public Mini Bus",
  _PRIVATE_MINIBUS_: "Private Mini Bus",
  _TAXI_: "Taxi",
  _HEAVY_EQUIPMENT_: "Heavy Equipment",
  _DIPLOMATIC_: "Diplomatic",
  _VEHICLE_INFO_: "Vehicle Info",
  _FUEL_ID_1_: "Fuel Id1",
  _FUEL_ID_2_: "Fuel Id2",
  _TEMP_ID1_: "Temp Id1",
  _TEMP_ID2_: "Temp Id2",
  _TEMP_ID3_: "Temp Id3",
  _TANK_DETAILS_: "Tank Details",
  _TANK2_L_IN_CM_: "Tank2(L) in Cm.",
  _TANK2_W_IN_CM_: "Tank2(W) in Cm.",
  _TANK2_H_IN_CM_: "Tank2(H) in Cm.",
  _TANK2_D_IN_CM_: "Tank2(D) in Cm.",
  _F_ID_2_: " F Id 2",
  _SPACE_2_CM_: "Space 2(Cm)",
  _TANK3_IN_CM_: "Tank3(L) in Cm.",
  _TANK3_W_IN_CM_: " Tank3(W) in Cm.",
  _TANK3_H_IN_CM_: "Tank3(H) in Cm.",
  _TANK3_D_IN_CM_: "Tank3(D) in Cm.",
  _F_ID_3_: " F Id 3",
  _SPACE_3_CM_: "Space 3(Cm)",
  _ADD_NEW_VEHICLE_: "Add New Vehicle",
  _COLUMNS_: "Columns",
  _SELECT_COLUMNS_: "Select Columns",
  _SELECT_STATION_CODE_: "Select Station Code",
  _DISTANCE_IN_METERS_: "Distance In Meters",
  _PLATE_NO_SEQUENCENO_: "Plate No,Sequence Number",
  _SEARCH_BY_BRAND_DRIVERNAME_SIMNO_IMEI_ASSET_:
    "Search By Brand,Driver Name,Sim No,IMEI,Asset",
  _INSTALLED_: "Installed",
  _UNINSTALLED_: "Uninstalled",
  _DELETED_: "Deleted",
  _SELECT_TANK_SHAPE_: "Select Tank Shape",
  _SPACE_1_CM_: "Space 1(Cm)",
  _SELECT_TANK2_SHAPE_: "Select Tank2 Shape",
  _SELECT_TANK3_SHAPE_: "Select Tank 3 Shape",
  _SPACE_2_CM_: "Space 2(Cm)",
  _SPACE_3_CM_: "Space 3(Cm)",
  _SELECT_CLASS_: "Select Class",
  _SEARCH_BY_STUDENT_PARENT_MOBILE_EMAIL_:
    "Search by Student Name,Parent Name,Mobile No,Email Id",
  _SEARCH_MAP_: "Search Map",
  _DOYOU_WANT_TO_REGISTER_: " Do you want to Register",
  _IN_WASL_: "in WASL ? ",
  _VENDOR_CODE_: "Vendor Code",

  _STATE_: "State",
  _ENTER_ACADEMIC_YEAR_FROM_: "Enter Academic Year From",

  //Advanced Dashboard
  _FLEET_STATUS_: "Fleet Status",
  _IN_ACTIVE_: "In active",
  _OVERSPEED_: "Overspeed",
  _OUTSIDE_GEOFENCE_: "Outside Geofence",
  _CONNECTED_: "Connected",
  _DISCONNECTED_: "Disconnected",
  _ALERTS_TRIGGERED_: "Alerts Triggered",
  _HARSH_BREAKING_: "Harsh braking",
  _HARSH_TURNING_: "Harsh Turning",
  _FLEET_IDLE_: "Fleet Idle",
  _TOTAL_FLEET_IDLE_HOUR_: "Total Fleet Idle Hour",
  _APPROX_FUEL_WASTE_LTR_: "Approx Fuel Wastage Liters",
  _LITRES_: "Liters",
  _NOTE_: "Note",
  _FLEET_IDLE_NOTE_MSG_:
    "Generally, an idling car uses somewhere between 1.89 to 2.74liters of fuel per hour",
  _JOB_INFO_: "Job Information",
  _ON_GOING_: "On Going",
  _CANCELLED_: "Canceled",
  _TOTAL_FLEET_USAGE_: "Total Fleet Usage",
  _AVG_DIST_VEH_: "Avg. Distance / Vehicle",
  _DAY_HRS_: "Day hours",
  _NIGHT_HRS_: "Night hours",
  _RENEWAL_: "Renewal",
  _DISTANCE_CLASSIFICATION_: "Distance Classification",
  _ONDUTY_KM_TIME_: "On duty km and time",
  _OFFDUTY_KM_TIME_: "Off duty km and time",
  _TOTAL_DISTANCE_COVERED_: "Total Distance Covered",
  _VEHICLE_SCORE_: "Vehicle Score",
  _VEHSCORE_AC_COUNT_: "account",
  _VEHSCORE_BREAK_COUNT_: "brakecount",
  _VEHSCORE_EXIDLE_COUNT_: "exidelcount",
  _VEHSCORE_RPM_COUNT_: "rpmcount",
  _VEHSCORE_SPEED_COUNT_: "speedcount",
  _DELETE_TRIP_DETAILS_: "Delete Trip Details",
  _ROUT_DEVIATION_ALERT_: " Route Deviation Alert",
  _PLEASE_SELECT_ACCOUNT_: "Please select Account",
  _SEARCH_ON_MAP_: "Search On Map",
  _HISTORY_: "History",
  _START_POINT_: "Start Point",
  _END_POINT_: "End Point",
  // _MODIFIED_BY_:'Modified By'
  _DEVICE_LOG_LIST_: "Device Log List",
  _ModBus_: "ModBus",
  _NORMAL_: "Normal",
  _EDIT_DEVICE_RECORD_: "Edit Device Record",
  _DELETE_DEVICE_RECORD_: "Delete Device Record",
  _PLEASE_SELECT_VEHICLE_: "Please select vehicles",

  _SAMPLE_: "Sample",
  _NEW_GEOFENCE_: " New Geofence",
  _RETAILS_: "Retails",
  _FOOD_SERVICE_: "Food Service",
  _HEAD_OFFICE_: "Head Office",
  _GARAGE_: "Garage",
  _OUTLET_: "Outlet",
  _WE_NEED_TO_DRAW_GEOFENCE_: "We need to Draw Geofence for Address",
  _DISTANCE_BETWEEN_THEM_: "Distance between them",
  _PLEASE_CLICK_MAP_TO_SELECT_LOCATIONS_:
    "Please click on map to select locations.",
  _VEHICLE_OR_DRIVER_: "Vehicle or Driver",
  _ALL_DATA_: "All Data",
  _TEMP_ID_: "Temp Id",
  _MOVING_: "Moving",
  _MANUFACTURING_YEAR_: "Manufacturing Year",
  _NO_OF_PASSENGER_: "Number of Passenger",
  _IDLE_RPM_: "Idle RPM",
  _STOPPED_RPM_: "Stopped RPM",
  _MOVING_RPM_: "Moving RPM",
  _ADDRESS_GRID_OF_WAREHOUSE_: "Address Grid Of Warehouse",
  _HUMIDITY_: "Humidity",
  _INPUT_2_: "Input 2",
  _SEND_EMAIL_: "Send Email",
  _ALL_EVENTS_: "All Events",
  _ACCIDENT_: "Accident",
  _DROP_: "Drop",
  _WASL_COMPANY_: "WASL Company",
  _WASL_VEHICLE_: "WASL Vehicle",
  _WASL_DRIVER_: " WASL Driver",

  _ELAPSED_TIME_: "Elapsed Time",
  _GEOFENCE_DETAILS_: "Geofence Details",
  _TOTAL_EXCEL_: "Total Excel",
  _TOTAL_PDF_: "Total PDF",
  _ROLE_SETTING_: "Role Setting",
  _DELETE_SETTING_: "Delete Setting",
  _EDIT_SETTING_: "Edit Setting",
  _EDIT_BEHAVIOUR_SETTING_: "Edit Behaviour Setting",
  _DELETE_BEHAVIOUR_SETTING_: "Delete Behaviour Setting",
  _ON_: "On",
  _OFF_: "Off",
  _DAYS_: "Days",
  _STOPPAGE_: "Stoppage",
  _SEATBELT_VIOLATION_: "Seatbelt Violation",
  _BASE_LOCATION_: "Base Location",
  _DRIVER_MOBILE_NO_: "Driver Mobile No",
  _EX_IDLE_COUNT_: "Ex Idle Count",
  _SEARCH_VEHICLE_DRIVER_: "Search by Vehicle,Driver",
  _MULKIYA_RENEWAL_: "Mulkiya Renewal",
  _EXCESSIVE_IDLE_: "Excessive Idle",
  _VEHICLE_HARSH_ACCEL_: "Vehicle Harsh Acceleration",
  _NON_STOPPAGE_: "Non Stoppage",
  _GPS_BATTERY_DISCONNECTION_: "GPS Battery Disconnection",
  _UNAUTHO_DRIVING_: "Unauthorized Driving",
  _NON_STOPPAGE_MIN: "Non Stoppage Min",
  _COMPANY_LIST_: "Company List",
  _PASSENGER_LIST_: "Passenger List",
  _SCHOOL_CALENDER_LIST_: "School Calendar List",
  _PARENT_LIST_: "Parent List",
  _TRIP_MANAGEMENT_: "Trip Management",
  _ADVANCE_DASHBOARD_: "Advanced Dashboard",
  _ASSET_REGISTER_REPORT_: "Asset Register Report",
  _DRIVER_LOGIN_REPORT_LIST_: "Driver Login Report List",
  _SIM_LIST_: "SIM List",
  _ALERT_LIST_: "Alert List",
  _ALERT_MSG_DISPLAY_8_VEHICLES_AT_TIME_:
    "Only 8 vehicles can be displayed at a time",
  _DRIVER_NOT_AVAILABLE_: "Driver not Available",
  _PLEASE_ENTER_COMPANY_NAME_: "Please Enter Company Name",
  _PLEASE_SELECT_VEHICLE_: "Please Select Vehicle",
  _PLEASE_SEARCH_BY_OPTION_: "Please Select Search By Option",
  _PLEASE_SELECT_GEOFENCE_: "Please Select Geofence",
  _PLEASE_ENTER_LOCATION_: "Please Enter Location",
  _PLEASE_CLICK_ON_MAP_: "Please Click On Map",
  _PLEASE_ADD_DISTANCE_: "Please Add Distance",
  _DISCARD_: "Discard",
  _JOB_MANAGEMENT_LIST_: "Job Management List",
  _GRADE_BUS_TIMING_LIST_: "Grade Bus Timing List",
  _SELECT_TIME_: "Select Time",
  _OPENSTREET_: "Openstreet",
  _MIN_TEMP_: "Min Temp",
  _ACCELERATION_FOR_: "Acceleration for",
  _BRAKING_FOR_: "Braking for",
  _SPEEDING_FOR_: "Speeding for",
  _RPM_FOR_: "RPM for",
  _ACCELERATION_RATE_: "Acceleration Rate",
  _BRAKE_RATE_: "Brake Rate",
  _IDLE_RATE_: "Idle Rate",
  _RPM_RATE_: "RPM Rate",
  _SPEED_RATE_: "Speed Rate",
  _EX_IDLING_FOR_: "Ex-Idling for",
  _TOTAL_NO_VEHICLE_: "Total Number of Vehicle",
  _EXIDLE_GRAPH_: "ExIdle Graph",
  _TYRE_CHANGE_REAMINDER_: "Tyre Change Reminder",
  _HELP_: "Help",
  _VEHICLE_LIST_: "Vehicle List",
  _VEHICLE_GRP_LIST_: "Vehicle Group List",
  _VEHICLE_LIVE_LOCATION_LIST_: "Vehicle Live Location List",
  _VEHICLE_ODOMETER_LIST_: "Vehicle Odometer List",
  _VEHICLE_FUEL_CALIBERATION_LIST_: "Vehicle Fuel Calibration List",
  _EXIDLE_REPORT_: "EX-IDLE Report",
  _DRIVER_RELATED_: "Driver Related",
  _DRIVER_LOGIN_REPORT_LIST_: "Driver Login Report List",
  _DRIVER_PERFORMANCE_REPORT_LST_: "Driver Performance Report List",
  _DRIVER_COMPLAIN_REPORT_LIST_: "Driver Complain Report List",
  _ASSIGN_DRIVER_: "Assign Driver",
  _ASSIGN_ASSET_: "Assign Asset",
  _VEHICLE_DISTANCE_TRAVELLED_DETAILS_: "Vehicle Distance Travelled Details",
  _ENTER_MONTHS_: "Enter Months",
  _SECONDARY_CONTACT_NO_: "Secondary Contact No",
  _Enter_Primary_Contact_: "Enter Primary Contact",
  _Identity_Number_should_be_10_Digit_Number_:
    "Identity Number should be 10 Digit Number",
  _Account_Name_Identity_Number_CR_Number_:
    "Account Name,Identity Number,CR Number",
  _Enter_7_or_10_Digit_Number_: "Enter 8 - 10 Digit Number",
  _Enter_8_or_10_Digit_: "Enter 8-10 Digit No",
  _Enter_5_or_12_Digit_Number_: "Enter 5 or 12 Digit Number",
  _Search_By_Driver_Name_Driver_Identity_Number_:
    "Search By Driver Name,Driver Identity Number",
  _PASSENGER_DELETE_POPUP_: "Are you sure you want to delete passenger?",
  _FILE_SIZE_UPTO_10_MB: "The file size must be less than or equal to 10 MB",
  _INSTALLATION_TYPE_MESSAGE_:
    "Please select installation type as PTA to enable load Sensor",
  _RENEWAL_FOR_: "Renewal for",
  _RENEW_VEHICLE_: "Renew Vehicle",
  _ENTER_PROJECT_NAME_: "Please Enter Project Name",
  _PLEASE_SELECT_A_VEHICLE_TO_SET_GPS_ODOMETER_:
    "Please select a vehicle to set GPS odometer",
  _HOLIDAY_EVENT_: "Holiday Event",
  _CLEAR_MAP_: "Clear Map",
  _PLEASE_ENTER_IMEI_: "Please Enter IMEI",
  _TOTAL_SHOULD_BE_MORE_THAN_100_: "Kindly check Total %, Total should be 100%",
  PLEASE_ENTER_NEW_PASSWORD_: "Please enter new password",
  PLEASE_ENTER_OLD_PASSWORD_: "Please enter old password",
  PLEASE_ENTER_CONFIRM_PASSWORD_: "Please enter confirm password",
  _NEW_PASSWORD_AND_CONFIRM_PASSWORD_MESSAGE_:
    "The new password and confirm password do not match",
  _PLEASE_ENTER_TEMPERATURE_: "Please Enter Temperature",
  _PLEASE_ENTER_TIME_LIMIT_: "Please Enter Time Limit",
  _SET_PARKING_TIME_LIMIT_: "Set parking time limit (minutes)",
  _TIME_LIMIT_: "Time Limit",
  PLEASE_CHECK_SEND_PUSH_NOTIFICATION_:
    "Please Check The Send Push Notification",
  _FLEET_RULESELECTORDETAIL_RULE_PASSENGER_DESCRIPTION_:
    "This rule is triggered when vehicle is moving with speed:10 kmph and passenger is not wearing the seatbelt.",
  _PLEASE_ENTER_A_VALID_EMAIL_ADDRESS_: "Please enter a valid email address",
  _FLEET_RULESELECTORDETAIL_RULE_INSURANCE_RENEWAL_DESCRIPTION_:
    "This Rule is triggered with Tire has been expired.",
  _FLEET_RULESELECTORDETAIL_RULE_SPEED_VIOLATION_DESCRIPTION_:
    "This rule is triggered when Vehicle exceeds the defined speed for selected geofences.",
  _FLEET_RULESELECTORDETAIL_RULE_UNAUTHORIZED_DRIVER_DESCRIPTION_:
    "This alert is triggered when vehicle is driven by unauthorized driver.",
  _FLEET_RULESELECTORDETAIL_RULE_GPS_BATTERY_DESCRIPTION_:
    " This alert is triggered when internal battery of gps is about to drain.",
  _FLEET_RULESELECTORDETAIL_RULE_NON_STOPPAGE_DESCRIPTION_:
    "This Alert will trigger when vehicle is moving for more than defined time period.",
  _FLEET_RULESELECTORDETAIL_RULE_BLE_TEMP_DESCRIPTION_:
    "This rule will be triggered when temperature/humidity goes beyond the defined limit. User can set minimum and maximum temperature/humidity",
  _FLEET_RULESELECTORDETAIL_RULE_ACCIDENT_ALERT_DESCRIPTION_:
    "This alert will be triggered when vehicle had an accident.",
  _FLEET_RULESELECTORDETAIL_RULE_TOWING_ALERT_DESCRIPTION_:
    "This alert will be triggered when vehicle is moving and its ignition is off.",
  _FLEET_RULESELECTORDETAIL_RULE_STAY_ALERT_DESCRIPTION_:
    "This alert will be triggered if vehicle stays inside selected geofence for more than defined time limit.",
  _FLEET_RULESELECTORDETAIL_RULE_PARKING_ALERT_DESCRIPTION_:
    "This alert is generated if vehicle is parked for more than defined time limit.",
  _FLEET_RULESELECTORDETAIL_RULE_POWER_CUT_DESCRIPTION_:
    "This alert will be triggered when device main power gets disconnected.",
  _FLEET_RULESELECTORDETAIL_RULE_PANIC_DESCRIPTION_:
    "This alert will be triggered when panic button is pressed.",
  _FLEET_RULESELECTORDETAIL_RULE_VEHICLE_HARSH_BRAKING_DESCRIPTION_:
    "This alerts will be triggered based on event received from Gps devices.",
  _FLEET_RULESELECTORDETAIL_RULE_MULKIYA_RENEWAL_DESCRIPTION_:
    "This Rule is triggered with Oil has been expired.",
  _FLEET_RULESELECTORDETAIL_RULE_EXCESSIVE_IDLE_DESCRIPTION_:
    " This rule will be triggered when vehicle idles beyond the set limit defined in Vehicle page.",
  _FLEET_RULESELECTORDETAIL_RULE_EXCESSIVE_IGINITION_DESCRIPTION_:
    "This Rule will be triggered when vehicles ignition is switched On and Off.",
  _FLEET_RULESELECTORDETAIL_RULE_ROAD_OVERSPEED_DESCRIPTION_:
    "This Rule will be triggered when vehicle's speed is greater than road speed.",
  _FLEET_RULESELECTORDETAIL_RULE_ENGINE_TEMP_DESCRIPTION_:
    " This Alert will be triggered when Engine Temp exceeds defined limit",
  _DURATION_: "Duration(Min)",
  _RATE_: "Rate",
  _SENSOR_: "Sensor",
  _COMMAND_NOT_DEFINED_: "Command Not Defined",
  _SELECT_SEQUENCE_FIRST_: "Select Sequence First",
  _VEHICLE_MAINTENANCE_LIST_: "Vehicle Maintenance List",
  _EXIDLE_DURATION_IN_MINUTE_: "Ex-Idle Duration(In Min)",
  _VEHICLE_TEMP_SENSOR_LIST_: "Vehicle Temp Sensor List",
  _VEHICLE_WORKING_HOURS_LIST_: " Vehicle Working Hours List",
  _VEHICLE_TRAILER_LIST_: "Vehicle Trailer List",
  _HEIGHT__: "Height",
  _WIDTH_: "Width",
  _DIAMETER_: "Diameter",
  _COMMERCIAL_RECORD_ISSUE_DATE2_: "Record Issue Date",
  _UPDATE_GEOFENCE_: "Update Geofence",
  _SELECT_CHANNEL_: "Select Channel",
  _CHANGE_RADIUS_: "Change Radius",
  _CHANGE_OPACITY_: "Change Opacity",
  _CHANGE_GRADIENT_: "Change Gradient",
  _TOGGLE_HEATMAP_: "Toggle Heatmap",
  _WAREHOUSE_MONITORING_: "Warehouse Monitoring",
  _WAREHOUSE_: "Warehouse",
  _ACC_EVENT_: "ACC Event",
  _BRAKE_EVENT_: "Brake Event",
  _EXIDLE_EVENT_: "EX Idle Event",
  _SPEED_EVENT_: "Speed Event",
  _ACC_RATE_: "ACC Rate",
  _BRAKE_RATE_: "Brake Rate",
  _EXIDLE_RATE_: "EX Idle Rate",
  _SPEED_RATE_: "Speed Rate",
  _SEATBELT_EVENT_: "Seatbelt Event",
  _SEATBELT_RATE_: "Seatbelt Rate",
  _ENTER_PHONE_NO_: "Enter Phone No",
  _CURRENT_FUEL_LEVEL_: "Current Fuel Level",
  _COMPANY_NAME_ARABIC_: "Company Name Arabic",
  _STATUS_ACTIVE_: "Are you sure to change status to Active?",
  _STATUS_INACTIVE_: "Are you sure to change status to Inactive?",
  _EXIDLE_GRAPH_: "Ex-Idle Graph",
  _ADMIN_REPORT_: "Admin Report",
  _SELECT_DATE_: "Select Date",
  _ALERT_TRIGGER_: "Alert Trigger",
  _INVENTORY_ONE_: "Inventory 1",
  _INVENTORY_TWO_: "Inventory 2",
  _INVENTORY_THREE_: "Inventory 3",
  _INVENTORY_FOUR_: "Inventory 4",
  _NO_DATA_AVAILABLE_: "No Data Available",
  _TEMPERATURE_DASHBOARD_: "Temperature Dashboard",
  _HOME_: "Home",
  _SELECT_SUB_GROUP_: "Select Sub Group",
  _MINIMUM_: "Min",
  _MAXIMUM_: "Max",
  _SENSORS_: "Sensors",
  _FAULTY_: "Faulty",
  _SENSOR_DETAILS_: "Sensor Details",
  _MIN_TEMP_ALERT_: "Min Temp Alert",
  _MAX_TEMP_ALERT_: "Max Temp Alert",
  _TEMPERATURE_ALERTS_: "Temperature Alerts",
  _NO_ALERTS_GENERATED_: "No Alerts Generated",
  _RANGE_VIOLATION_ALERTS_:
    "All temperature and humidity range violation alerts will be reflected here.",
  _CURRENT_STATUS_: "Current Status",
  _STATE_DURATION_: "State Duration",
  _LAST_SIGNAL_SYNC_: "Last Signal Sync",
  _FULL_SUMMARY_: "Full Summary",
  _FINISH_LOCATION_: "Finish Location",
  _WORK_TIME_: "Work Time",
  _STOP_DURATION_: "Stop Duration",
  _DRIVING_DURATION_: "Driving Duration",
  _ACCIDENT_COUNT_: "Accident Count",
  _SHARE_LIVE_LOCATION_: "Share Live Location",
  _ENTER_EXCEL_: "Please Enter Excel File",
  _USER_ID_: "User Id",
  _CUSTOMER_CODE_: "Customer Code",
  _ROUTE_ID_: "Route Id",
  _ROUTE_NAME_: "Route Name",
  _CREATED_DATE_: "Created Date",
  _INVOICE_DATE_: "Invoice Date",
  _DEPOSITOR_: "Depositor",
  _ALLOCATE_TIME_: "Allocate Time",
  _QUIT_: "Quit",
  _LOGOUT_STATUS_: "Are you sure you want to logout?",
  _VOLTAGE_DROP_: "Voltage Drop",
  ARABIC_VEHICLE_NO_: "Arabic Vehicle No",
  _MULKIYA_TYPE_: "Mulkiya Type",
  _AREA_: "Area",
  _RESPONSIBLE_MANAGER: "Responsible Manager",
  _VEHICLE_OWNERSHIP_: "Vehicle Ownership",
  _OWN_: "Own",
  _RENTED_: "Rented",
  _SMART_PHONE_: "Smart Phone",
  _MDVR_IMEI_: "MDVR IMEI",
  _MIN_FINE_AMOUNT_: "Minimum Fine Amount",
  _FINE_PER_MINUTE_: "Fine Per Minute",
  _SPEEDMILEAGE_: "Speed/Mileage",
  _RULE_: "Rule",
  _SEAT_SENSORS_: "Seatsensor",
  _DEVICE_UDID_: "Device UDID",
  _DEVICE_UDID2_: "Device UDID2",
  _SEATING_CAPACITY_: "Seating Capacity",
  _MAX_NO_BAGGAGE_: "Max No of Baggage",
  _INPUT_DIGITAL_: "Input Digital",
  _PULSE_COUNT_PER_CIRCLE_: "Pulse Count Per Circle",
  _MILEAGE_PER_CIRCLE_: "Mileage Per Circle",
  _SPEED_CONSTANT_: "Speed Constant",
  _SUBTOTAL_AMOUNT_: "Subtotal Amount",
  _VAT5_: "VAT 5%",
  _SERVICE_CATEGORY_: "Service Category",
  _CUSTOMER_NAME_INVOICE_NO_: "Customer Name,Invoice No,Invoice Date",
  _ODOMETER_RECORDS_: "Odometer Records",
  _PROCESS_DATA_: "Process Data",
  _SHEDULED_AT_: "Scheduled At",
  _SUB_GROUP_: "Sub Group",
  _GROUP_: "Group",
  _ADD_INVOICE_: "Add Invoice",
  _INVOICE_ID_: "Invoice Id",
  _CANCEL_JOB_: "Cancel Job",
  _REASON_TO_CANCEL_JOB_: "Reason To Cancel Job",
  _TRIP_DISTANCE_TRAVELLED_: "Trip Distance Travelled",
  _DOOR_OPEN_CLOSE_ALERT_: "Door Open/Close Alert",
  _DOOR_OPEN_CLOSE_ALERT_STATEMENT_:
    "This alert will be triggered when Vehicles Door is opened or Closed.",
  _MULKIYA_RENEWAL_ALERT_: "Mulkiya Renewal Alert",
  _MULKIYA_RENEWAL_ALERT_STATEMENT_:
    "This alert is triggered 30 days,15 days 7 days prior to Mulkiya expiry date.",
  _INSURANCE_RENEWAL_ALERT_: "Insurance Renewal Alert",
  _INSURANCE_RENEWAL_ALERT_STATEMENT_:
    "This alert is triggered 30 days,15 days 7 days prior to Insurance expiry date.",
  _BATTERY_CHANGE_ALERT_: "Battery Change Alert",
  _BATTERY_CHANGE_ALERT_STATEMENT_:
    "This alert is triggered 30 days,15 days 7 days prior to next battery change date.",
  _SERVICE_OIL_TYRE_CHANGE_ALERT_: "Service/Oil/Tyre Change Alert.",
  _SERVICE_OIL_TYRE_CHANGE_ALERT_STATEMENT_:
    "This alert is triggered when servicing, oil or tyre change is due for selected vehicle.",
  _TAXI_CUSTOMERS_: "Taxi Customers",
  _NAME_: "Name",
  _EMAIL_ADDRESS_: "Email Address",
  _DISCOUNT_LIST_: "Discount List",
  _DISCOUNT_NAME_: "Discount Name",
  _DISCOUNT_TYPE_: "Discount Type",
  _DISCOUNT_: "Discount",
  _ADD_DISCOUNT_: "Add Discount",
  _FLAT_AMOUNT_: "Flat Amount",
  _DISCOUNT_AMOUNT_: "Discount Amount",
  _ADVERTISEMENT_: "Advertisment",
  _INTERVAL_SEC_: "Interval(Sec)",
  _IMAGE_VIDEO_TYPE_: "Image/Video Type",
  _PICTURE_VIEW_: "Picture View",
  _PLEASE_SELECT_IMAGE_VIDEO_: "Please Select Image/Video",
  _FULL_BANNER_: "Full Banner",
  _MIDDLE_BANNER_: "Middle Banner",
  _BOTTOM_BANNER_: "Bottom Banner",
  _AUDIO_MESSAGE_: "Audio Message",
  _AUDIO_VIEW_: "Audio View",
  _PLEASE_SELECT_AUDIO_: "Please Select Audio",
  _POINT_OF_INTEREST_: "Point of Interest",
  _POINT_OF_INTEREST_TYPE_: "Point of Interest Type",
  _EVENT_LIST_: "Event List",
  _TITLE_: "Title",
  _NEWS_: "News",
  _SPECIAL_OFFER_: "Special Offer",
  _ADD_NEWS_: "Add News",
  _ADD_OFFER_: "Add Offer",
  _ADD_POI_: "Add Point of Interest",
  _NEW_MAINTENANCE_: "New Maintenance",
  _MAINTENANCE_TYPE_: "Maintenance Type",
  _ADD_NEW_MAINTENANCE_: "Add New Maintenance",
  _ADD_NEW_SERVICE_: "Add New Service",
  _SUB_GROUP_LIST_: "Sub Group List",
  _SERVICE_TEMPLATE_: "Service Template",
  _SERVICE_ID_: "Service Id",
  _VEHICLE_AUDIT_: "Vehicle Audit",
  _ACCIDENT_ANALYSIS_: "Accident Analysis",
  _VEHICLE_AUDIT_SCORE_: "Vehicle Audit Score",
  _ACCIDENT_REASON_: "Accident Reason",
  _OUTSIDE_PARTY_: "Outside Party",
  _ACCIDENT_TYPE_: "Accident Type",
  _TAXI_TYPE_: "Taxi Type",
  _TAXI_TYPE_LIST_: "Taxi Type List",
  _QUEUE_LIST_: "Queue List",
  _POSITION_: "Position",
  _SUB_GROUP_DETAILS_: "Sub Group Details",
  _TAXI_MANAGEMENT_: "Taxi Management",
  _TAXI_DEFAULT_PARAMETERS_: "Taxi Default Parameters",
  _FARE_DETAILS_: "Fare Details",
  _DAY_TIME_FROM_: "Day Time From(Hrs)",
  _DAY_TIME_TO_: "Day Time To(Hrs)",
  _SPEED_DISTANCE_: "Speed/Distance",
  _MAX_SPEED_INTERVAL_SEC_: "Max Speed Interval(Sec)",
  _DIGITAL_INPUT_1: "Digital Input 1",
  _DIGITAL_INPUT_2: "Digital Input 2",
  _DIGITAL_INPUT_3: "Digital Input 3",
  _DIGITAL_INPUT_4: "Digital Input 4",
  _PRINTER_: "Printer",
  _TAXI_DEFAULT_PARAMETERS_DETAILS_: "Taxi Default Parameters Details",
  _TAXI_PARAMETER_LIST_: "Taxi Parameter List",
  _TARIFF_NAME_: "Tariff Name",
  _BASIC_FARE_: "Basic Fare",
  _MINIMUM_FARE_: "Minimum Fare",
  _DISTANCE_FARE_: "Distance Fare",
  _TIME_FARE_: "Time Fare",
  _VALID_FROM_DATETIME_: "Valid From Datetime",
  _VALID_TO_DATETIME_: "Valid To Datetime",
  _FAIR_TYPE_: "Fair Type",
  _TAX_PERCENTAGE_: "Tax Percentage",
  _BOOKING_CHARGES_: "Booking Charges",
  _ADD_VERSION_: "Add Version",
  _VERSION_: "Version",
  _HOLIYDAY_: "Holiday",
  _LONG_DISTANCE_FARE_: "Long Distance Fare",
  _LONG_DISTANCE_KM_: "Long Distance(Km)",
  _PEAK_TIME_FROM_: "Peak Time From",
  _PEAK_TIME_TO_: "Peak Time To",
  _PEAK_DISTANCE_FARE_: "Peak Distance Fare",
  _PEAK_TIME_FARE_: "Peak Time Fare",
  _PEAK_BASIC_FARE_: "Peak Basic Fare",
  _PEAK_MIN_FARE_: "Peak Min Fare",
  _TAXI_PARAMETERS_: "Taxi Parameters",

  _ASSIGN_ZONE_: "Assign Zone",
  _QUESTION_: "Question",
  _ANSWER_: "Answer",
  _PREDEFINED_MESSAGES_LIST_: "Predefined Messages List",
  _ADD_PREDEFINED_MESSAGES_: "Add Predefined Message",

  _START_ADDRESS_: "Start Address",
  _END_ADDRESS_: "End Address",
  _END_POINT_2_: "End Point / Common Point",
  _TRIP_DISTANCE_: "Trip Distance",
  _TOTAL_JOB_: "Total Job",
  _STARTED_: "Started",
  _DELIVERIES_: "Deliveries",
  _JOB_COMPLETED_: "Job Completed",
  _PENDING_JOBS_: "Pending Jobs",
  _CANCELLED_JOBS_: "Cancelled Jobs",
  _CREATED_AT_: "Created At",
  _UPDATED_FIELD_: "Updated Field",
  _CANCELLED_AT_: "Cancelled At",
  _CANCELLED_BY_: "Cancelled By",
  _DELIVERY_LOG_: "Delivery Log",
  _ORDER_STATUS1_: "Order Status",
  _UNDELIVERED_REASON_: "Undelivered Reason",
  _NO_OF_ATTEMPTS_: "No of Attempts",
  _LOG_: "Log",
  _FLEET_REPORTS_GEOFENCEREPORT_LOCATION_IN_: "Geofence In Location",
  _FLEET_REPORTS_GEOFENCEREPORT_LOCATION_OUT_: "Geofence Out Location",
  _DETAILED_JOB_REPORT_: "Detailed Job Report",
  _TOTAL_KM_DAY_: "Total Km/Day",
  _JOB_STARTED_TIME_: "Job Started Time",
  _JOB_FINISHED_TIME_: "Job Finished Time",
  _TRAVEL_KM_: "Travel Km",
  _SELECT_VEHICLE_BY_: "Select Vehicle By",
  _SPEED_DURATION_: "Speed Duration",
  _VENDOR_ID_: "Vendor Id",
  _SPECIAL_: "Special",
  _NEIGHT_PRICE_: "Neight Price",
  _BIG_PRICE_: "Big Price",
  _BIG_NEIGHT_PRICE_: "Big Neight Price",
  _REMARK_: "Remark",
  _CERTIFICATE_: "Certificate",
  _CHASSIS_NUMBER_: "Chassis Number",
  _TRACKER_ID_: "Tracker Id",
  _GLOBAL_SEARCH_: "Global Search",
  _SEARCH_: "Search",
  _AGREEMENT_: "Agreement",
  _TRADE_LICENSE_: "Trade License",
  _CERTIFICATE_REPORT_: "Certificate Report",
  _ISSUING_DATE_: "Issuing Date",
  _S_NO_: "S.No",
  _ASSIGN_GEOFENCE_TO_COMPANY_: "Assign Geofence To Company",
  _ASSIGN_GEOFENCE_: "Assign Geofence",
  _SOCIAL_MEDIA_: "Social Media",
  _ADD_SOCIAL_MEDIA_: "Add Social Media",
  _RTA_OFFICIALS_: "RTA Officials",
  _DAILY_JOB_REPORT_: "Daily Job Report",
};
