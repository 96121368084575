import {
  Button,
  Checkbox,
  Card,
  Col,
  Input,
  Row,
  Space,
  Table,
  Select,
  Image,
  Radio,
  FloatButton,
  Popover,
  Menu,
  Form,
  DatePicker,
  message,
  Modal,
  Upload,
  Tooltip as AntTooltip,
  Spin,
} from "antd";
import React, { Component } from "react";
import VehicleOtherInfo from "./VehicleOtherInfo";
import {
  MapContainer,
  TileLayer,
  Popup,
  Circle,
  Polygon,
  Tooltip,
  FeatureGroup,
  ZoomControl,
  Polyline,
  Marker,
} from "react-leaflet";
import RouteShowingMap from "./RouteShowingMap";
import { EditControl } from "react-leaflet-draw";
import CompanyAutocomplete from "./CompanyAutocomplete";

import DraggableModal from "./DraggableModal";
import LiveTrackingStore from "../store/LiveTrackingStore";
import MarkerClusterGroup from "react-leaflet-cluster";
// import DriftMarker from "react-leaflet-drift-marker";
import { Icon, divIcon, point } from "leaflet";
import { format, addMinutes } from "date-fns";

import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

import config from "../config";
import VehicleStore from "../store/VehicleStore";
import DriversStore from "../store/DriversStore";

import "leaflet-arrowheads";
import {
  SearchOutlined,
  CloseOutlined,
  EditOutlined,
  DeleteFilled,
  UploadOutlined,
  PlusOutlined,
  LockOutlined,
  UnlockOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import L from "leaflet";
import { autorun } from "mobx";
import {
  ArrowsMove,
  CarFront,
  GlobeCentralSouthAsia,
  Pencil,
  Bezier2,
  House,
  Hospital,
  FuelPump,
  BusFront,
  HouseGear,
  PersonWorkspace,
  PinMap,
  Eraser,
  PersonBoundingBox,
  Truck,
  PauseCircle,
  PlayCircle,
} from "react-bootstrap-icons";
import Draggable from "react-draggable";
// import DraggableModal from './DraggableModal';
import "./Tracking.css";
import MeasureDist from "./MeasureDist";
import dayjs from "dayjs";
import Autocomplete from "react-google-autocomplete";
import { Link, json } from "react-router-dom";

import DeleteConfirm from "./DeleteConfirm";
import { observer } from "mobx-react";
import withRouter from "../withRouter";
import { StoreContext } from "../store/RootStore";
import LiveRootPathStore from "../store/LiveRootPathStore";

import VehicleOtherInfoStore from "../store/VehicleOtherInfoStore";
import Cookies from "universal-cookie";

import CompanyStore from "../store/CompanyStore";

import HistoryTracking from "./HistoryTracking";
import MapForVehiclePosition from "./MapForVehiclePosition";
import SendCommandPopup from "./SendCommandPopup";
import MarkerClusterPopup from "./MarkerClusterPopup";
import AdditionalContentInMap from "./AdditionalContentInMap";

import { throttle } from "lodash";
import GetVehicleGroups from "./GetVehicleGroups";
import ShareModalWindow from "./ShareModalWindow";
import AddGeofenceFields from "./Plugins/AddGeofence/AddGeofenceFields";
import AddVersionModal from "./Plugins/AddGeofence/AddVersionModal";
import VideoMonitoringStore from "../store/VideoMonitoringStore";

const { Search } = Input;
const { Option } = Select;

const dotIcon = L.divIcon({
  className: "dot-icon",
  iconSize: [5, 5],
  html: `
  <div style="
    width: 5px;
    height: 5px;
    border: 2px solid blue;
    border-radius: 50%;
    background-color: transparent;
  "></div>
`,
  // html: ' <div style="width: 5px; height: 5px; background-color: blue; border-radius: 15%;"></div>',
});

const firstIcon = L.divIcon({
  className: "dot-icon",
  iconSize: [20, 35], // Adjusted the iconSize to match the image dimensions
  html: `<img src="${require("../assets/images/location-pin.png")}" width="20px" height="35px" />`,
});

class LiveTracking extends Component {
  constructor(prop, context) {
    super(prop);
    this.state = {
      pageSize: 50,
      currentPage: 1,
      showSlider: false,
      companyId: "",
      selectedDriver: "",
      offSet: "",
      limit: "",
      // currentPage: "",
      loadState: 0,
      selectionType: "checkbox",
      position: [],
      bounds: null,
      markerGroupImage: "../assets/images/bus-icon-red.png",
      mapType: "google",
      popupSearch: "",
      disableSearch: true,
      timer: 30,

      interval: 30000,
      timerPause: true,
      measureDistModal: false,

      timerPause: true,
      timerCount: 0,

      closebtnClick: false,
      removeBtnClick: false,
      AddBtn: false,
      selectedIndex: null,

      closebtnClick: false,
      removeBtnClick: false,
      AddBtn: false,
      selectedIndex: null,

      drawGeofence: false,
      update: 0,

      editing: false,
      drawnShapes: [],
      GeofancenType: "",

      ShowNearAssetPopup: false,

      drawingBounds: "",
      radius: "",
      vehicleId: "",
      fromDate: dayjs(),
      toDate: dayjs(),
      fromTime: "00:00:00",
      toTime: dayjs().format("HH:mm A"),
      showGofence: "",

      geofenceData: [],
      selectGeofenceInList: [],
      selectGeofenceObjects: [],
      zoneId: -1,
      navigationWindow: false,
      SearchAssetPopup: false,
      SearchDriverPopup: false,
      companyList: [],
      vehicleList: [],
      DriverList: [],

      origin: "",
      destination: "",
      distance: "",
      getDirection: false,
      selectedVehicles: false,
      searchAddressWindowShow: false,
      searchGeoFence: "",
      showImportExcelModal: false,
      liveTrackingMainModal: false,
      vehicleDetailsShow: false,
      daySummary: {},
      selectedRowKeysArray: [],

      addressInfo: this.props.addressInfo,
      type: false,
      key: new Date(),
      traffic: false,
      flag: true,
      mapBtn: false,
      satelliteBtn: false,

      selectedVehicleIds: [],

      historyTrackingWindow: false,
      trackingMapBoolean: true,
      keepAnEyeModal: false,
      sendCommandPopUp: false,
      fuelStationCenterAreaLatLong: [24.7136, 46.6753],
      selectGeofenceRows: [],

      markerClusterGroupPopup: false,
      loadingCheckbox: false,
      drawGeoLocation: "",
      vehicleOtherInfo: false,
      clientHeight: window.innerHeight,
      company_value: "",
      geofenceTitle: context.translate("_NEW_GEOFENCE_"),
      isadminlogin: false,
      vehicleStatusInfo: [],
      userId: "",
      excelCompanyId: "",

      locationUrl: "",
      shareModalVisible: false,
      Id: -1,
      showCalculatedDist: false,
      selectedCheckBox: "",
      vehicleUpdatedPosition: [],
      // selectAll: 12,
      selectAllMarkers: false,
      selectedRowsLength: "",
      vehicleMarkerPos: [],
      isLocked: false,
      openAddVersionModal: false,

      openVideoMonitoringModal: false,
      videoMonitoringData: [],
      iframeUrl: "",
      videoMonitoringUrl: {},
    };

    // this.windoHeight =  window.addEventListener("resize", () =>window.innerHeight)
    this.cookies = new Cookies();
    this.mapSetting2 = true;
    this.loading = false;
    this.originInputRef = React.createRef();
    this.destinationInputRef = React.createRef();

    this.liveTrackingStore = new LiveTrackingStore(this);
    this.companyStore = new CompanyStore(this);
    this.vehicleStore = new VehicleStore();
    this.vehicleOtherinfoStore = new VehicleOtherInfoStore(this);

    this.liveRootPathStore = new LiveRootPathStore(this);
    this.driverStore = new DriversStore();

    this.videoMonitoringStore = new VideoMonitoringStore(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.mapRef = React.createRef();
    this.drawnItemsRef = React.createRef();
    this.groupRef = React.createRef();
    this.formRef = React.createRef();
    this.searchAssetformRef = React.createRef();
    this.livelocationFormRef = React.createRef();
    this.intervalCall = false;
    this.timer2 = false;
    this.greenIcon = L.icon({
      iconUrl: require("../assets/images/bus-icon-blue.png"),
      iconSize: [30, 20], // size of the icon
    });

    // this.vehiCleLiveLatLongObj = {}; // Initialize an empty object for the vehicleId
    this.vehiCleLiveLatLongObj = [];
    this.vehiclePositionMap = {};

    this.polylineRef = React.createRef();

    this.mapOptions = [
      {
        label: "Google",
        value: "google",
      },
      {
        // label: "Openstreet",
        label: context.translate("_OPENSTREET_"),
        value: "openstreet",
      },
    ];

    this.handleMapTypeChange = this.handleMapTypeChange.bind(this);
    this.popupSearch = this.popupSearch.bind(this);
    this.AddFunc = this.AddFunc.bind(this);
    this.RemoveFunc = this.RemoveFunc.bind(this);
    this.SearchMenuItemClick = this.SearchMenuItemClick.bind(this);
    this.SearchAssetFunc = this.SearchAssetFunc.bind(this);
    this.handleVehicleChange = this.handleVehicleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.customIconFunc = this.customIconFunc.bind(this);
    this.selectedSearchFunc = this.selectedSearchFunc.bind(this);
    this.handleDriverChange = this.handleDriverChange.bind(this);
    this.getVehicleOtherInfo = this.getVehicleOtherInfo.bind(this);
    this.setIntervals = this.setIntervals.bind(this);
    this.clearIntervals = this.clearIntervals.bind(this);

    this.searchMenu = [
      {
        label: context.translate("_FLEET_DASHBORD_NAVIRIGHT_SEARCHVEHICLE_"),
        key: "searchasset",
        icon: <BusFront />,
      },
      {
        label: context.translate("_FLEET_DASHBORD_NAVIRIGHT_SEARCHADDRESS_"),
        key: "searchaddress",
        icon: <House />,
      },
      {
        label: context.translate("_HOSPITAL_"),
        key: "hospital",
        icon: <Hospital />,
      },
      {
        label: context.translate("_FLEET_DASHBORD_NEWPOI_TYPEDDL_FUEL_"),
        key: "fuelstation",
        icon: <FuelPump />,
      },
      {
        label: context.translate("_POLICE_STATION_"),
        key: "policestation",
        icon: <PersonBoundingBox />,
      },
      {
        label: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"),
        key: "driver",
        icon: <PersonWorkspace />,
      },
      {
        label: context.translate("_NEAREST_ASSET_"),
        key: "nearestasset",
        icon: <Truck />,
      },
      {
        label: context.translate("_GARAGE_"),
        key: "garage",
        icon: <HouseGear />,
      },
      {
        label: context.translate("_NAVIGATION_"),
        key: "navigation",
        icon: <PinMap />,
      },
      {
        label: context.translate("_CLEAR_MAP_"),
        key: "clermap",
        icon: <Eraser />,
      },
    ];

    this.geofenceOptions = [
      // context.translate("_FLEET_DASHBORD_NAVIRIGHT_DROWGEO_"),
      context.translate("_FLEET_DASHBORD_NAVIRIGHT_SHOWGEO_"),
      // context.translate("_IMPORT_EXPORT_EXCEL_"),
    ];
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);

    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
    }
  }

  handleResize = () => {
    this.setState({
      clientHeight: window.innerHeight,
    });
    // console.log(this.state.clientHeight,"sdfghjksdfghj")
  };

  geofenceCardTitle = async (title) => {
    await this.setState({ geofenceTitle: title });
  };

  async componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", throttle(this.handleResize, 200));

    // await this.addCustomClasses()
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
      // await this.handleSearch();
      await this.setState({
        excelCompanyId: this.props.getUserData().userCompanyId,
      });

      await this.liveTrackingStore.getGofenceList({
        companyId: this.props.getUserData().userCompanyId,
        offset: 0,
        limit: 50,
      });
      await this.setState({
        // company_value: this.props.getUserData().userCompanyId,
      });

      var vehicleList = await this.vehicleStore.getVehiclesCompanyId(
        this.props.getUserData().userCompanyId
      );
      await this.setState({ vehicleList: vehicleList });

      var DriverList = await this.driverStore.getDriverFunc({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ DriverList: DriverList });

      //  await this.onEditGeofence()
      await this.liveTrackingStore.poiCurrentVehicleUsingCompanyId({
        companyId: this.props.getUserData().userCompanyId,
      });

      const singleData = await this.companyStore.getCompanyDataById(
        this.props.getUserData().userCompanyId
      );

      await this.liveTrackingStore.getVehiclesUsingCompanyId({
        companyId: this.props.getUserData().userCompanyId,
      });

      // await this.setState({ companyId: singleData.value});
      await this.handleSearch();

      // await this.startVehiclePosition();
      //default selected vehicles
      if (this.props.getUserData()?.mapSetting2 === "1") {
        let defaultSelectedvehicleIds =
          this.liveTrackingStore?.liveTrackingData?.map((val) =>
            Number(val.vehicleId)
          );

        await this.setState({
          selectedRowKeysArray: defaultSelectedvehicleIds,
          position: this.liveTrackingStore?.liveTrackingData,
        });
        this.liveTrackingStore.selectedVehicles =
          this.liveTrackingStore?.liveTrackingData;
        await this.setState({ loadData: 1 });
      }
    }

    this.showGeofenceListColumn = [
      {
        // title: "Name",
        title: this.context.translate("_FLEET_DASHBORD_NEWPOI_NAME_"),
        dataIndex: "name",
        render: (_, record) => {
          return <>{_}</>;
        },
      },
      {
        // title: "Address",
        title: this.context.translate("_FLEET_DASHBORD_NEWPOI_ADDRESS_"),
        dataIndex: "address",
      },
      {
        // title: "Operation",
        title: this.context.translate("_OPERATION_"),
        dataIndex: "operation",

        render: (_, val) => {
          return (
            <Space>
              <Link
                onClick={async () => {
                  this.onEditGeofence(val);

                  this.setState({ showGofence: false });
                  if (this.state.company_value) {
                    await this.geofenceCardTitle(
                      this.context.translate("_UPDATE_GEOFENCE_")
                    );
                  }
                }}
              >
                <AntTooltip
                  // title="Edit Geofence"
                  title={this.context.translate("_EDIT_GEOFENCE_")}
                  placement="bottom"
                  color={config.tooltipColorEdit}
                >
                  <EditOutlined style={{ fontSize: "15px" }} />
                </AntTooltip>
              </Link>
              <DeleteConfirm
                onConfirm={(e) => {
                  this.deleteGeofence(val);
                }}
              >
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <AntTooltip
                    // title="Delete Geofence"
                    title={this.context.translate(
                      "_FLEET_DASHBORD_SHOWGEO_DELETE_"
                    )}
                    placement="bottom"
                    color={config.tooltipColorDelete}
                  >
                    <DeleteFilled style={{ color: config.deleteColor }} />
                  </AntTooltip>
                </Link>
              </DeleteConfirm>
            </Space>
          );
        },
      },
    ];

    this.columns = [
      {
        key: "1",
        // title: "Vehicle",
        title: this.context.translate("_VEHICLES_"),
        dataIndex: "vehicleNo",
        // ellipsis: true,
        width: 20, //170
        fixed: "left",
        // align: "center",
        render: (_, record) => {
          return (
            <AntTooltip
              title={`${this.context.translate(
                "_FLEET_DASHBORD_VEHICLE_INFORMATION_MOBILE_"
              )}: ${record.mobileno ? record.mobileno : "-"}`}
              color="green"
            >
              <div>
                <p style={{ margin: "0px" }}>
                  <Image
                    // onClick={async () => {
                    //   await this.setState({ vehicleOtherInfo: record });
                    //   await this.setState({ update: 1 });
                    // }}
                    onClick={async () => {
                      this.liveTrackingStore.getDriverPerformance({
                        companyId: record.companyId,
                        driverId: record.driverId,
                      });

                      this.liveTrackingStore.daySummary({
                        currentDate: dayjs().format("YYYY-MM-DD"),
                        companyId: record.companyId,
                        vehicleId: [record.vehicleId],
                      });

                      await this.setState({ vehicleOtherInfo: false });
                      await this.getVehicleOtherInfo(record);
                      await this.setState({ liveTrackingMainModal: false });
                    }}
                    width={35}
                    src={require("../assets/images/detailtracking/" +
                      record.listImage)}
                  ></Image>
                  {record.vehicleNo}/{record.name}
                </p>

                <p style={{ margin: "0px" }}>{record.formatted_address}</p>
              </div>
            </AntTooltip>
          );
        },
      },
      {
        key: "2",
        title: this.context.translate("_DATE_TIME_"),
        // title: "Date Time ",
        dataIndex: "updatedate",
        width: 10, //100
        render: (val) => {
          return dayjs(val).format("DD-MM-YYYY h:mm:ss A");
        },
      },
      /*{
        key: "driverName",
        width: 60, //100
        // title: "Driver Name",
        title: this.context.translate(
          "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
        ),
        dataIndex: "name",
      },*/
      // {
      //   key: "mobileno",
      //   width: '7%', //100
      //   // title: "Mobile No",
      //   title: this.context.translate(
      //     "_FLEET_DASHBORD_VEHICLE_INFORMATION_MOBILE_"
      //   ),
      //   dataIndex: "mobileno",
      // },
      /* {
        key: "10",
        width: 100,
        // title: "Last Location",
        title: this.context.translate("_LAST_LOCATION_"),
        dataIndex: "lastLocation",
      },*/
      {
        key: "4",
        width: 7,
        title: (
          <AntTooltip title={this.context.translate("_SPEED_")} color="blue">
            <i className="bi bi-speedometer2"></i>
          </AntTooltip>
        ),
        dataIndex: "speed",
        align: "center",
      },
      {
        key: "5",
        width: 7,
        // title: <i class="bi bi-broadcast-pin"></i>,
        title: (
          <AntTooltip title="GPS" color="blue">
            <span>
              {" "}
              <i class="bi bi-broadcast-pin"></i>
            </span>
          </AntTooltip>
        ),
        dataIndex: "gps",
        align: "center",
        render: (value) => {
          // let value = Number(mainValue);

          if (value === 1) {
            return (
              <div style={{ textAlign: "center" }}>
                <i
                  class="bi bi-broadcast-pin"
                  style={{
                    fontSize: "17px",
                    color: "green",
                    width: "17px",
                    height: "17px",
                    textAlign: "center",
                  }}
                ></i>
              </div>
            );
          } else if (value === 0) {
            return (
              <div style={{ textAlign: "center" }}>
                <i
                  class="bi bi-broadcast-pin"
                  style={{
                    fontSize: "17px",
                    color: "red",
                    width: "17px",
                    height: "17px",
                  }}
                ></i>
              </div>
            );
          } else if (value === undefined || value === null) {
            return (
              <div style={{ textAlign: "center" }}>
                <i
                  class="bi bi-broadcast-pin"
                  style={{
                    fontSize: "17px",
                    color: "gray",
                    width: "17px",
                    height: "17px",
                    textAlign: "center",
                  }}
                ></i>
              </div>
            );
          }
        },
      },
      // {
      //   key: "7",
      //   width: 35,
      //   title: <i class="bi bi-reception-4"></i>,
      //   dataIndex: "speed",
      // },
      {
        key: "8",
        width: 7,
        title: (
          <AntTooltip title={this.context.translate("_POWER_")} color="blue">
            <span>
              <i class="bi bi-power"></i>
            </span>
          </AntTooltip>
        ),
        dataIndex: "power",
        align: "center",
        render: (newValue) => {
          let value = Number(newValue);

          if (value === 0) {
            return (
              <i
                class="bi bi-power"
                style={{
                  fontSize: "19px",
                  color: "#288a28",
                  width: "19px",
                  height: "17px",
                }}
                // style={{ color: "green", height: "30px", width: "30px" }}
              ></i>
            );
          } else if (value === 1) {
            return (
              <i
                class="bi bi-power"
                style={{
                  fontSize: "17px",
                  color: "red",
                  width: "19px",
                  height: "17px",
                }}
                // style={{ color: "red", height: "30px", width: "30px" }}
              ></i>
            );
          } else if (value === undefined || value === null) {
            return (
              <i
                class="bi bi-power"
                style={{
                  fontSize: "17px",
                  color: "gray",
                  width: "17px",
                  height: "17px",
                }}
              ></i>
            );
          }
        },
      },
      {
        key: "3",
        // title: "Status & Last Duration",
        title: this.context.translate("_STATUS_AND_DURATION_"),
        width: 20,
        dataIndex: "currentStatus",

        render: (value, record) => {
          var lastReportingTime = record.updatedate;
          var serverCurrentTime = record.currtime;
          var diff = dayjs(serverCurrentTime) - dayjs(lastReportingTime);
          // console.log(diff);
          diff /= 60000;
          if (record.vehiclestatus == "expired") {
            //stopped
            return <span>Expired</span>;
          } else if (diff >= 90) {
            return (
              <span>
                Not Reporting Since:
                <small>
                  {" "}
                  {record.nodataSince != null
                    ? this.formatTime(record.nodataSince)
                    : "--"}{" "}
                </small>
              </span>
            );
          } else if (record.acc == 1 && record.speed == 0) {
            //idle
            if (record.idleSince == null) {
              // console.log(r.get('vehicleNo'))
            }

            return (
              <span>
                Idle Since:
                <small>
                  {" "}
                  {record.idleSince != null
                    ? this.formatTime(record.idleSince)
                    : this.getDateDiff(record.currtime, record.updatedate)}{" "}
                </small>
              </span>
            );
          } else if (record.acc == 0 || record.acc == null) {
            //stopped
            return (
              <span>
                Stop Since:
                <small>
                  {" "}
                  {record.stopSince != null
                    ? this.formatTime(record.stopSince)
                    : "--"}{" "}
                </small>
              </span>
            );
          } else if (record.acc == 1 && record.speed > 0) {
            //running
            return (
              <span>
                Running Since:
                <small>
                  {" "}
                  {record.runSince != null
                    ? this.formatTime(record.runSince)
                    : "--"}{" "}
                </small>
              </span>
            );
          }
        },
      },
    ];

    autorun(async () => {
      if (this.liveTrackingStore.AutorunVar) {
        this.setState({
          closebtnClick: false,
          removeBtnClick: false,
          loadState: 1,
        });

        this.liveTrackingStore.setAutorunVar(false);
      }

      if (this.liveTrackingStore.AutorunPopup) {
        this.setState({
          ShowNearAssetPopup: this.liveTrackingStore.showNearVehiclePopup,
          loadState: 1,
        });
        this.liveTrackingStore.setAutorunPopup(false);
      }
    });
  }

  convertMinutesToFormattedTime(minutes) {
    // console.log(minutes);
    const days = Math.floor(minutes / (24 * 60));
    const remainingMinutes = minutes % (24 * 60);
    const hours = Math.floor(remainingMinutes / 60);
    const minutesPart = remainingMinutes % 60;

    const daysString = days < 10 ? `0${days}` : days.toString();
    const hoursString = hours < 10 ? `0${hours}` : hours.toString();
    const minutesString =
      minutesPart < 10 ? `0${minutesPart}` : minutesPart.toString();

    return `${daysString} days, ${hoursString}:${minutesString}:00`;
  }

  formatTime = (totalSeconds) => {
    const days = Math.floor(totalSeconds / (24 * 3600));
    const hours = Math.floor((totalSeconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${String(days).padStart(2, "0")} days, ${String(hours).padStart(
      2,
      "0"
    )}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  getDateDiff(currtime, updatedate) {
    // console.log(currtime,updatedate)
    var startDate = dayjs(currtime);
    var endDate = dayjs(updatedate);
    // console.log("In");
    // Calculate the time difference in milliseconds
    var timeDifference = startDate - endDate;

    // Calculate days, hours, minutes, and seconds
    var days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    var a = (days < 10 ? "0" + days : days) + " days,";
    a += hours < 10 ? "0" + hours : hours;
    a += ":" + (minutes < 10 ? "0" + minutes : minutes);
    a += ":" + (seconds < 10 ? "0" + seconds : seconds);
    return a;
  }

  toggleDrawer = () => {
    this.setState({ showSlider: !this.state.showSlider });
  };
  async getVehicleOtherInfo(record) {
    const data = await this.liveTrackingStore.getVehicleTrackingInfo({
      vehicleId: record.vehicleId,
      companyId: record.companyId,
    });

    await this.setState({ vehicleOtherInfo: record });
    await this.setState({ vehicleStatusInfo: data });
    await this.setState({ update: 1 });
  }

  async handleChange(comp) {
    await this.setState({ selectedRowKeysArray: [], popupSearch: "" });
    this.liveTrackingStore.selectedVehicles = [];
    await this.setState({ currentPage: 1, companyId: comp.value });

    const data = await this.companyStore.getCompanyDataById(
      this.state.companyId
    );

    await this.setState({ company_value: data });
    await this.getStatusWiseList();
    await this.handleSearch();
  }

  handleClearFields = () => {
    // Clear the origin and destination states
    this.setState({
      origin: null,
      destination: null,
      distance: "",
    });

    // Clear the input field values by manipulating DOM elements
    if (this.originInputRef.current) {
      this.originInputRef.current.value = "";
    }
    if (this.destinationInputRef.current) {
      this.destinationInputRef.current.value = "";
    }
  };

  async handleCompId(comp) {
    this.setState({
      companyId: comp.value,
    });
    var vehicleList = await this.vehicleStore.getVehiclesCompanyId(comp.value);
    await this.setState({ vehicleList: vehicleList });

    var DriverList = await this.driverStore.getDriverFunc({
      companyId: comp.value,
    });
    await this.setState({ DriverList: DriverList });
  }

  async handleMapTypeChange(comp) {
    await this.setState({ mapType: comp.target.value, listUpdated: 1 });
    // L.on('loading', function (event) {
    //     console.log('start loading tiles');
    // });
  }

  timerPauseFun = async () => {
    let currentTimer = this.state.timer;

    if (!this.state.timerPause) {
      await this.setState({ timer: this.state.timerCount });
    }

    await this.clearIntervals();

    await this.setState({ timerPause: !this.state.timerPause });

    if (this.state.timerPause) {
      this.setIntervals();
      await this.setState({ timer: currentTimer });
    }
  };
  AddFunc = async (e) => {
    await this.setState({
      AddBtn: true,
      selectedIndex: e,
    });
    this.liveTrackingStore.AddSelectedAdrressArray(e);
    await this.setState({
      loadState: 1,
    });
  };
  RemoveFunc = async (e) => {
    await this.setState({
      AddBtn: false,
      removeBtnClick: true,
      // loadState: 1 ,
    });
    this.liveTrackingStore.updateSelectedAdrressArray(e);
    await this.setState({
      loadState: 1,
    });
  };

  iconCreateFunction = function (cluster) {
    //   console.log('innn')
    //console.log(cluster._markers[0].options.currentStatus);

    const markers1 = cluster._markers;
    var count = markers1.length;
    var idle = 0;
    var stopped = 0;
    var running = 0;
    var nodata = 0;
    var indexA = "";
    var clusterIconType = [];

    //if (count != 0) {

    const markers = cluster.getAllChildMarkers();

    Object.keys(markers).map((e, i) => {
      var currentStatus = markers[i].options.currentStatus;

      var lastReportingTime = markers[i].options.options.obj.updatedate;
      var serverCurrentTime = markers[i].options.options.obj.currtime;

      var acc = markers[i].options.options.obj.acc;
      var speed = markers[i].options.options.obj.speed;

      var diff = new Date(serverCurrentTime) - new Date(lastReportingTime);

      diff /= 60000; //diff convert in minutes.

      if (diff >= 90) {
        if (!clusterIconType.includes(1)) {
          clusterIconType.push(1);
        }
      } else if (acc == 1 && speed == 0) {
        //idle
        if (!clusterIconType.includes(2)) {
          clusterIconType.push(2);
        }
      } else if (acc == 0 || speed == null) {
        //stopped
        if (!clusterIconType.includes(3)) {
          clusterIconType.push(3);
        }
      } else if (acc == 1 && speed > 0) {
        //running
        if (!clusterIconType.includes(4)) {
          clusterIconType.push(4);
        }
      }
    });

    switch (parseInt(clusterIconType.sort().join(""))) {
      case 1:
        indexA = 1;
        break;
      case 2:
        indexA = 2;
        break;
      case 3:
        indexA = 3;
        break;
      case 4:
        indexA = 4;
        break;
      case 12:
        indexA = 5;
        break;
      case 13:
        indexA = 6;
        break;
      case 14:
        indexA = 7;
        break;
      case 23:
        indexA = 8;
        break;
      case 24:
        indexA = 9;
        break;
      case 34:
        indexA = 10;
        break;
      case 123:
        indexA = 11;
        break;
      case 124:
        indexA = 12;
        break;
      case 134:
        indexA = 13;
        break;
      case 234:
        indexA = 14;
        break;
      case 1234:
        indexA = 15;
        break;

      default:
        indexA = 1;
        break;
    }

    var imagename = indexA + ".png";

    const imageUrl = require("../assets/images/clusterMarkers/" + imagename);
    return L.divIcon({
      html: `<div style="border-radius: 50%;  width: 40px; height: 40px;position: relative;text-align:center"><img src="${imageUrl}" /><span style="color: black;position: absolute;left: 0;right: 0;
          line-height: 36px;">${cluster.getChildCount()}</span></div>`,
      className: "marker-cluster-custom",
      iconSize: [40, 40],
    });
    //}
  };

  clearIntervals = function () {
    if (this.timer2) {
      clearInterval(this.timer2);
      this.timer2 = null;
    }
  };

  setIntervals = function () {
    this.setState({ timerCount: this.state.timer });

    this.timer2 = setInterval(async () => {
      this.setState({ timerCount: this.state.timerCount - 1 });

      if (this.state.timerCount === 1) {
        this.clearIntervals();

        try {
          // Await the asynchronous operations
          await this.handleSearch();
          await this.startVehiclePosition();
        } catch (error) {
          console.error(
            "Error updating vehicle positions or handling search:",
            error
          );
        }

        // Reset the timer
        this.setState({ timerCount: this.state.timer });
      }
    }, 1000);
  };

  componentDidUpdate(prevProps, prevState) {
    // Update polyline arrowheads
    if (this.polylineRef.current) {
      const leafletPolyline = this.polylineRef.current;
      this.addArrowheads(leafletPolyline);
    }

    // Reset position when companyId changes
    if (prevState.companyId !== this.state.companyId) {
      this.setState({ position: [] });
    }

    if (prevState.position !== this.state.position) {
      const positionLatLngs = this.state.position.map(
        ({ latitude, longitude }) => L.latLng(latitude, longitude)
      );
      // console.log(positionLatLngs, "positionLatLngs");

      const allLatLngs = [];
      this.state.vehicleUpdatedPosition.forEach((polyline) => {
        allLatLngs.push(...polyline);
      });

      const latestLatLng = allLatLngs[allLatLngs.length - 1];
      if (positionLatLngs.length > 0) {
        const bounds = L.latLngBounds();
        const data1 = positionLatLngs.map((val) => {
          bounds.extend(val);
        });

        if (this.mapRef.current) {
          if (this.state.isLocked && latestLatLng) {
            // const center = bounds.getCenter();
            this.mapRef.current.setView(
              latestLatLng,
              this.mapRef.current.getZoom()
            );
          } else {
            this.mapRef.current.fitBounds(bounds);
          }
        }
      }
    }

    if (
      prevState.vehicleUpdatedPosition !== this.state.vehicleUpdatedPosition
    ) {
      const allLatLngs = [];

      this.state.vehicleUpdatedPosition.forEach((polyline) => {
        allLatLngs.push(...polyline);
      });

      const latestLatLng = allLatLngs[allLatLngs.length - 1];
      if (allLatLngs.length > 0) {
        const bounds = L.latLngBounds(allLatLngs);
        if (this.mapRef.current) {
          if (this.state.isLocked && latestLatLng) {
            const center = bounds.getCenter();
            this.mapRef.current.setView(
              latestLatLng,
              this.mapRef.current.getZoom()
            );
          }
          // else {
          //   this.mapRef.current.fitBounds(allLatLngs, { maxZoom: 13 });
          // }
        }
      }
    }
  }

  async handleSearch() {
    // console.log(this.state.companyId);
    await this.liveTrackingStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      poupSearch: this.state.popupSearch,
      vehicleStatus: this.state.vehicleStatus,
      value: this.state.selectedVehicleIds,
    });
    await this.setState({ listUpdated: 1, disableSearch: false }); //need to reload data

    if (this.liveTrackingStore.totalvehstate.total > 0) {
      this.clearIntervals();
      this.setIntervals();
    }
  }

  async handleTableChange(pagination, filters, sorter) {
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleCompanyChange();
  }

  handleCompanyChange = async () => {
    await this.liveTrackingStore.getGofenceList({
      companyId: this.state.companyId,
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
    });
    // console.log(offset,limit)
    this.setState({ loadData: 1 });
  };
  async popupSearch(e) {
    const value = e.target.value;
    await this.setState({ offset: 0, popupSearch: value });
    if (value.length > 2 || value.length === 0) {
      if (this.state.companyId) {
        await this.setState({ offset: 0, popupSearch: value });
        await this.handleSearch();
      }
    }
  }

  getStatusWiseList = async (name) => {
    // console.log(b)
    // console.log(b.target.n);
    // let buttonName = b.target.name;
    await this.setState({ vehicleStatus: name });
    await this.handleSearch();
  };
  setRefreshTime = async (val) => {
    await this.clearIntervals();
    await this.setState({ timer: val });
    await this.setIntervals();
  };

  customIcon = new Icon({
    // iconUrl: "https://cdn-icons-png.flaticon.com/512/447/447031.png",
    iconUrl: require("../assets/images/logo_icon.png"),
    iconSize: [38, 38], // size of the icon
  });

  customIconFunc(data) {
    var customIcon1 = new Icon({
      // iconUrl: "https://cdn-icons-png.flaticon.com/512/447/447031.png",
      // iconUrl:  require("../assets/images/car.png"),
      // iconUrl:  require("'"+(this.LiveTrackingStore.getIcon(data))+"'"),
      iconUrl: this.liveTrackingStore.getIcon(data),

      // iconUrl:  require("../assets/images/livetracking/Car-yellow.png"),
      iconSize: [21, 52], // size of the icon
    });

    return customIcon1;
  }
  // customIcon = async () => {

  //     return new Icon({
  //         // iconUrl: "https://cdn-icons-png.flaticon.com/512/447/447031.png",
  //         iconUrl: require("../assets/images/car.png"),
  //         iconSize: [38, 38] // size of the icon
  //     })

  // }
  createClusterCustomIcon = function (cluster) {
    //console.log(cluster)
    return new divIcon({
      html: `<span class="cluster-icon">${cluster.getChildCount()}</span>`,
      className: "custom-marker-cluster",
      iconSize: point(45, 45, true),
    });
  };

  async SearchMenuItemClick(data) {
    let allStateFields = {
      ShowNearAssetPopup: false,
      editing: false,
      measureDistModal: false,
      SearchDriverPopup: false,
      position: [],
      selectGeofenceObjects: [],
      geofenceData: [],
      navigationWindow: false,
      SearchAssetPopup: false,
      searchAddressWindowShow: false,
      liveTrackingMainModal: false,
      drawGeofence: false,
      showGofence: false,
      showImportExcelModal: false,
      mapType: "google",
    };
    if (data === "nearestasset") {
      await this.liveTrackingStore.setShowNearVehiclePopup(true);
      await this.liveTrackingStore.setAutorunPopup(true);
      await this.setState({
        ...allStateFields,
        ShowNearAssetPopup: this.liveTrackingStore.showNearVehiclePopup,
      });
    } else if (data === "navigation") {
      await this.setState({ ...allStateFields, navigationWindow: true });
    } else if (data === "searchasset") {
      await this.setState({ ...allStateFields, SearchAssetPopup: true });
    } else if (data === "driver") {
      await this.setState({ ...allStateFields, SearchDriverPopup: true });
    } else if (data === "searchaddress") {
      await this.setState({ ...allStateFields, searchAddressWindowShow: true });
      //   drawGeofence:false,
      // showGofence:false,
      // showImportExcelModal:false,
    } else if (data === "drawGeofence") {
      await this.setState({
        ...allStateFields,
        drawGeofence: true,
        editing: true,
      });
    } else if (data === "showGofence") {
      await this.setState({
        ...allStateFields,
        showGofence: true,
        drawGeoLocation: "",
      });
    } else if (data === "showImportExcelModal") {
      await this.setState({ ...allStateFields, showImportExcelModal: true });
    } else if (data === "measureDistModal") {
      await this.setState({
        ...allStateFields,
        measureDistModal: true,
        mapType: false,
      });
    } else if (data === "liveTrackingMainModal") {
      await this.setState({ ...allStateFields, liveTrackingMainModal: true });
    } else if (data === "clermap") {
      this.mapRef.current.eachLayer((layer) => {
        // if (layer instanceof L.Marker) {
        if (layer instanceof L.Polyline) {
          this.mapRef.current.removeLayer(layer);
        }
        this.setState({
          position: [],
          selectGeofenceObjects: [],
          geofenceData: [],
        });
      });
      this.liveTrackingStore.selectedVehicles = [];
      this.setState({ position: [] });
    } else if (data === "hospital") {
      const resp = await this.liveTrackingStore.getTypeData({
        type: "hospital",
        latlong: [24.7136, 46.6753],
      });
      resp.results.forEach((val) => {
        this.showingPetrolPump_Hospital(val, "hospital");
      });
    } else if (data === "fuelstation") {
      const resp = await this.liveTrackingStore.getTypeData({
        type: "gas_station",
        latlong: this.state.fuelStationCenterAreaLatLong,
      });
      resp.results.forEach((val) => {
        this.showingPetrolPump_Hospital(val, "petrol");
      });
    } else if (data === "policestation") {
      const resp = await this.liveTrackingStore.getTypeData({
        type: "police",
        latlong: [24.7136, 46.6753],
      });
      resp.results.forEach((val) => {
        this.showingPetrolPump_Hospital(val, "police");
      });
    } else if (data === "garage") {
      const resp = await this.liveTrackingStore.getTypeData({
        type: "car_repair",
        latlong: [24.7136, 46.6753],
      });
      resp.results.forEach((val) => {
        this.showingPetrolPump_Hospital(val, "garage");
      });
    }
  }

  //
  showingPetrolPump_Hospital = (val, type) => {
    let lat = val.geometry.location.lat;
    let long = val.geometry.location.lng;
    let backgroundColor = val.icon_background_color;
    let icon = val.icon;
    // const customIcon = L.divIcon({
    //   className: 'custom-icon',
    //   html: `<div style="background-color: ${backgroundColor}; border-radius: 50%; width: 24px; height: 24px; text-align: center; line-height: 30px;">
    //             <img src="${icon}" style="width: 14px; height: 14px; ">
    //          </div>`
    // });
    // const marker = L.marker([lat, long], {
    //   icon: customIcon
    // });

    // // Add the marker to your Leaflet map
    // marker.addTo(this.mapRef.current);
    const marker = L.marker([lat, long], {
      icon: new Icon({
        iconUrl: require("../assets/images/" + type + ".png"),
        iconSize: [35, 35],
      }),
      draggable: true,
    }).addTo(this.mapRef.current);
  };
  selectGeofence = async (e) => {
    const value = e.target.value;

    if (value == this.context.translate("_FLEET_DASHBORD_NAVIRIGHT_DROWGEO_")) {
      this.setState({ drawGeoLocation: "" });
      await this.SearchMenuItemClick("drawGeofence");
      // await this.toggleEditing();
      this.setState({ editing: true });
      // } else if (value === "Show Geofence") {
    } else if (
      value === this.context.translate("_FLEET_DASHBORD_NAVIRIGHT_SHOWGEO_")
    ) {
      await this.SearchMenuItemClick("showGofence");
    } else if (value === this.context.translate("_IMPORT_EXPORT_EXCEL_")) {
      //  await this.setState({ showImportExcelModal: true });
      await this.SearchMenuItemClick("showImportExcelModal");
    }

    await this.setState({ update: 1 });
  };

  //   onCreated =async (event) => {
  //     const { layerType, layer } = event;
  //     console.log(layerType);
  //     console.log( layer.getBounds().getCenter());

  //     let type = "";
  //     if (layerType == "rectangle") {
  //       type = 3;
  //     } else if (layerType == "circle") {
  //       type = 2;
  //     } else if (layerType == "polygon") {
  //       type = 3;
  //     }

  //     this.setState({ GeofancenType: type });

  // this.setState({drawingBounds:[layer._bounds._northEast, layer._bounds._northEast._southWest]})
  // const centerLatLng = layer.getBounds().getCenter();
  //   const centerLat = centerLatLng.lat;
  //   const centerLng = centerLatLng.lng;
  //  const address =await this.liveTrackingStore.getAddressUsingLatLong({latitude:centerLat,longitude:centerLng})
  //  console.log(address)
  // //  this.formRef.current.setFields({address:address})
  // // const address = this.liveTrackingStore.getAddressUsingLatLong({latitude,longitude})
  //     // const shape = {
  //     //   type: layerType,
  //     //   coordinates:
  //     //     layerType === "rectangle"
  //     //       ? [layer._bounds._northEast, layer._bounds._northEast._southWest]
  //     //       : [],
  //     // };
  //     // this.setState((prevState) => ({
  //     //   drawnShapes: [...prevState.drawnShapes, shape],
  //     // }));

  //     this.drawnItemsRef.current.addLayer(layer);
  //   };

  onCreated = async (event) => {
    const { layerType, layer } = event;
    // console.log(event,layerType);
    // if (layerType === 'circle') {
    //   layer.setStyle({ className: 'circle-shape' });
    // } else if (layerType === 'rectangle') {
    //   layer.setStyle({ className: 'rectangle-shape' });
    // } else if (layerType === 'polygon') {
    //   console.log("polygon-shape")
    //   layer.setStyle({ className: 'polygon-shape' });
    // }
    const shape = {
      type: layerType,
      coordinates: [],
      radius: null,
    };

    const latLangs = layer._latlngs;

    let boundString;
    if (layerType === "circle") {
      boundString = `${layer._latlng.lat},${layer._latlng.lng}`;
    } else {
      boundString = latLangs[0]
        .map(({ lat, lng }) => `${lat},${lng},0`)
        .join(",");
    }

    this.setState({ drawingBounds: boundString });

    if (layerType === "rectangle") {
      shape.coordinates = [
        layer.getBounds().getNorthEast(),
        layer.getBounds().getSouthWest(),
      ];
    } else if (layerType === "circle") {
      shape.coordinates = [layer.getLatLng()];
      shape.radius = layer.getRadius();
    } else if (layerType === "polygon") {
      shape.coordinates = layer
        .getLatLngs()[0]
        .map((latLng) => [latLng.lat, latLng.lng]);
    }

    // Get the center and radius of the circle or the center of the bounding box for rectangles
    await this.setState({ radius: shape.radius });

    let centerLatLng;
    if (layerType === "circle") {
      centerLatLng = layer.getLatLng();
      this.setState({ GeofancenType: 2 });
    } else if (layerType === "rectangle") {
      this.setState({ GeofancenType: 1 });
      centerLatLng = layer.getBounds().getCenter();
    } else if (layerType === "polygon") {
      this.setState({ GeofancenType: 3 });
      const bounds = layer.getBounds();
      centerLatLng = bounds.getCenter();
    }

    const centerLat = centerLatLng.lat;
    const centerLng = centerLatLng.lng;

    // Reverse geocode the center coordinates to get the address

    const address = await this.liveTrackingStore.getAddressUsingLatLong(
      layerType,
      centerLat,
      centerLng,
      shape.radius
    );

    await this.formRef.current.setFieldsValue({
      address: address,
    });

    this.setState((prevState) => ({
      drawnShapes: [...prevState.drawnShapes, shape],
    }));

    this.drawnItemsRef.current.addLayer(layer);
  };

  // toggleEditing = () => {
  //   this.setState((prevState) => ({ editing: !prevState.editing }));
  // };

  //  addCustomClasses = () => {
  //   const buttons = document.querySelectorAll('.leaflet-draw-toolbar a');
  //     buttons.forEach((button) => {
  //       if (button.title.includes('Draw a circle')) {
  //         console.log("draw-circle")
  //         button.classList.add('draw-circle');
  //       } else if (button.title.includes('Draw a rectangle')) {
  //         console.log("draw-circle")
  //         button.classList.add('draw-rectangle');
  //       } else if (button.title.includes('Draw a polygon')) {
  //         button.classList.add('draw-polygon');
  //       }
  //       // Add more conditions as needed
  //     });
  // }

  getInformationOfVehicle = ({ vehicleId, latLong }) => {
    let latitude;
    let longitude;
    if (vehicleId) {
      const { vehiclePositionArray } = this.liveTrackingStore;
      const vehicleInfoLatLong = vehiclePositionArray.find(
        (val) => val.vehicleId === vehicleId
      );

      latitude = vehicleInfoLatLong?.latitude;
      longitude = vehicleInfoLatLong?.longitude;

      this.mapRef.current.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          this.mapRef.current.removeLayer(layer);
        }
      });

      const marker = L.marker([latitude, longitude], {
        icon: this.customIcon,
      }).addTo(this.mapRef.current);

      this.mapRef.current.setView([latitude, longitude], 13);
    } else {
      // const { latitude, longitude } = latLong;
      latitude = latLong.latitude;
      longitude = latLong.longitude;

      this.mapRef.current.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          this.mapRef.current.removeLayer(layer);
        }
      });

      const marker = L.marker([latitude, longitude], {
        icon: new Icon({
          // iconUrl: "https://cdn-icons-png.flaticon.com/512/447/447031.png",
          iconUrl: require("../assets/images/location-pin.png"),
          iconSize: [38, 38], // size of the icon
        }),
        draggable: true,
      }).addTo(this.mapRef.current);

      marker.on("dragend", (event) => {
        const newLatLng = event.target.getLatLng();
        const newLatitude = newLatLng.lat;
        const newLongitude = newLatLng.lng;
      });

      this.mapRef.current.setView([latitude, longitude], 13);
    }

    // this.mapRef.current.setView([latitude, longitude], 13);
  };

  handleChangeForDrawingGeofence = async (comp) => {
    await this.setState({ companyId: comp.value });
    await this.liveTrackingStore.getVehiclesUsingCompanyId({
      companyId: comp.value,
    });
    await this.formRef.current.setFieldsValue({ companyId: comp.value });
    await this.liveTrackingStore.poiCurrentVehicleUsingCompanyId({
      companyId: comp.value,
    });
    await this.setState({ listUpdated: 1 });
  };

  timeOptions = () => {
    const timeArray = [];

    let startTime = new Date(2000, 0, 1, 0, 0); // 12:00 AM

    let endTime = new Date(2000, 0, 1, 23, 30);
    for (let time = startTime; time <= endTime; time = addMinutes(time, 30)) {
      // const formattedTime = format(time, 'h:mm aa');
      const formattedTime = format(time, "HH:mm:ss");
      var ob = {
        formattedTime: formattedTime,
        time: time,
      };
      timeArray.push(ob);
    }

    return timeArray.map((data) => {
      return (
        <Select.Option key={data.formattedTime} value={data.formattedTime}>
          {format(data.time, "h:mm aa")}
        </Select.Option>
      );
    });
  };

  onFinishDrawing = async (values) => {
    if (
      this.state.companyId &&
      // this.state.vehicleId &&
      this.state.drawingBounds
    ) {
      // const data = {...values,GeofancenType:this.state.GeofancenType,zoneId:-1,isactive:1,createdBy:1,bound:this.state.drawingBounds}
      const data = {
        ...values,
        GeofancenType: this.state.GeofancenType,
        zoneId: this.state.zoneId,
        isactive: 1,
        createdBy: 1,
        radius: this.state.radius,
        bound: this.state.drawingBounds,
        companyId: this.state.companyId,
      };

      const response = await this.liveTrackingStore.addGeofenceDrawing(data);

      if (response?.data?.success === "S") {
        message.success("Geofence saved successfully");
        await this.liveTrackingStore.getGofenceList({
          companyId: this.state.companyId,
          offset: 0,
          limit: 50,
        });
        // this.handleCompanyChange()

        const singleData = await this.companyStore.getCompanyDataById(
          this.state.companyId
        );

        await this.setState({ drawGeofence: false });
        await this.setState({ showGofence: true, company_value: singleData });

        this.mapRef.current.eachLayer((layer) => {
          this.drawnItemsRef.current.clearLayers(layer);
          if (layer instanceof L.Marker) {
            this.mapRef.current.removeLayer(layer);
          }
          this.setState({
            position: [],
            drawGeoLocation: "",
            selectGeofenceObjects: [],
            geofenceData: [],
          });
        });
        await this.clearFields();
      } else {
        message.error();
      }
    } else {
      Modal.warning({
        // title: "Alert",
        // content: "Please select Shape and draw fence",
        title: this.context.translate("_FLEET_ALERT_"),
        content: this.context.translate("_SELECT_SHAPE_DRAW_FENCE_"),
      });
    }
    await this.setState({ listUpdated: 1 });
  };

  LoadClick = async () => {
    await this.formRef.current.setFieldsValue({ stopIdlePoints: [] });
    if (!this.state.companyId) {
      Modal.warning({
        // title: "Alert",
        // content: "Please Enter Company Name",
        title: this.context.translate("_FLEET_ALERT_"),
        content: this.context.translate("_PLEASE_ENTER_COMPANY_NAME_"),
      });
    } else if (!this.state.vehicleId) {
      Modal.warning({
        // title: "Alert",
        title: this.context.translate("_FLEET_ALERT_"),
        content: this.context.translate("_PLEASE_SELECT_VEHICLE_"),
        // content: "Please Select Vehicle",
      });
    }
    if (this.state.companyId && this.state.vehicleId) {
      // console.log(this.state.toTime.format("hh:mm:ss"), dayjs(this.state.toTime).format("hh:mm:ss"), dayjs(this.state.toTime,"hh:mm A").format("hh:mm:ss"))
      const data = {
        companyId: this.state.companyId,
        vehicleId: this.state.vehicleId,
        fromDate:
          this.state.fromDate.format("YYYY-MM-DD") + " " + this.state.fromTime,

        toDate:
          this.state.toDate.format("YYYY-MM-DD") +
          " " +
          dayjs(this.state.toTime, "HH:mm A").format("HH:mm:ss"),
      };
      await this.liveTrackingStore.getstopidle(data);

      await this.setState({ listUpdated: 1 });
    }
  };

  clearFields = () => {
    this.setState({ radius: "", drawingBounds: [] });
    this.formRef.current.setFieldsValue({
      speed: "",
      address: "",
      typeName: "",
      geosettingname: "",
      vehicleId: "",
      companyId: "",
    });

    // this.setState({ companyId: "", vehicleId: "" });
    this.setState({ vehicleId: "" });
  };

  handleGetGofenceList = async (comp) => {
    this.setState({ loading: true });
    // this.setState({ company_value: comp.value });
    await this.setState({
      company_value: { label: comp.label, value: comp.value },
    });
    await this.liveTrackingStore.getGofenceList({
      companyId: comp.value,
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
    });

    await this.setState({ listUpdated: 1 });
    this.setState({ loading: false });
  };

  handleShowingGeofenceInMap = async ({ record, recordFormNearestAsset }) => {
    if (record) {
      let InfoObject = record;
      const pointsArray = record.points
        .split(",")
        .map(Number)
        .filter((_, index) => index % 3 !== 2);

      const formattedPoints = [];
      for (let i = 0; i < pointsArray.length; i += 2) {
        formattedPoints.push([pointsArray[i], pointsArray[i + 1]]);
      }
      const geofenceObj = { ...InfoObject, points: formattedPoints };

      if (geofenceObj.points.length === 1) {
        const [lat, lng] = geofenceObj.points[0];
        const bounds = [
          [lat, lng],
          [lat, lng],
        ];
        // this.mapRef.current.fitBounds(bounds);
        this.mapRef.current.fitBounds(bounds, { maxZoom: 16 });
      } else if (geofenceObj.points.length > 1) {
        this.mapRef.current.fitBounds(geofenceObj.points, { maxZoom: 16 });
      }
      await this.setState({ geofenceData: geofenceObj });
      await this.setState({ listUpdated: 1 });
    } else if (recordFormNearestAsset) {
      this.mapRef.current.fitBounds(recordFormNearestAsset.points, {
        maxZoom: 18,
      });
      await this.setState({ geofenceData: recordFormNearestAsset });
    }
  };

  deleteGeofence = async (val) => {
    let zoneId = [val?.zoneId];
    const zone = zoneId.join(",");
    let response;
    for (let i = 0; i < zoneId.length; i++) {
      response = await this.liveTrackingStore.deleteGeofence({
        zoneId: zoneId[i],
        companyId: val.companyId,
      });
    }
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.mapRef.current.eachLayer((layer) => {
        // this.drawnItemsRef.current.clearLayers(layer);

        this.setState({ selectGeofenceObjects: [] });
      });
      await this.liveTrackingStore.getGofenceList({
        companyId: val.companyId,
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
      });
      this.setState({ drawingBounds: [] });
    } else if (response?.data?.success === "F0") {
      message.error(response.data.errors[0].geodeleteerror);
    }
    await this.setState({ listUpdated: 1 });
  };

  deleteMultipleGeofence = async (val) => {
    let zoneId = val;

    const zone = val.join(",");
    let flag = 0;
    let response;

    zoneId.forEach(async (val, ind) => {
      flag++;
      response = await this.liveTrackingStore.deleteGeofence({
        zoneId: val,
        companyId: this.state.companyId,
      });

      if (response?.data?.success === "S") {
        if (ind === 0) {
          message.success(response.data.message);
        }
        // if(flag===1){

        //   message.success(response.data.message);
        // }

        this.mapRef.current.eachLayer((layer) => {
          // this.drawnItemsRef.current.clearLayers(layer);

          this.setState({ selectGeofenceObjects: [] });
        });
        await this.liveTrackingStore.getGofenceList({
          companyId: this.state.companyId,
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
        });
        this.setState({ drawingBounds: [] });
      } else if (response?.data?.success === "F0") {
        message.error(response.data.errors[0].geodeleteerror);
      }
      await this.setState({ listUpdated: 1 });
    });
  };

  setPointsInArrayFormat = async (obj, editObj) => {
    const selectedRows = obj;
    let geofenceObj = [];
    for (let i = 0; i < selectedRows.length; i++) {
      const pointsArray = selectedRows[i].points
        .split(",")
        .map(Number)
        .filter((_, index) => index % 3 !== 2);

      const formattedPoints = [];
      for (let i = 0; i < pointsArray.length; i += 2) {
        formattedPoints.push([pointsArray[i], pointsArray[i + 1]]);
      }
      geofenceObj.push({
        ...selectedRows[i],
        points: formattedPoints,
      });

      this.mapRef.current.fitBounds(formattedPoints);
    }
    if (editObj) {
      let zoneId = obj[0].zoneId;
      let ans = this.state.selectGeofenceObjects?.some(
        (item) => item.zoneId === zoneId
      );

      let data = this.state.selectGeofenceObjects;
      if (!ans) {
        data = this.state.selectGeofenceObjects;
        data.push(geofenceObj[0]);
      }

      await this.setState({ selectGeofenceObjects: data });
    } else {
      await this.setState({ selectGeofenceObjects: geofenceObj });
    }
  };
  onEditGeofence = async (val) => {
    await this.setState({ drawGeofence: true, editing: true });
    await this.setState({ zoneId: val.zoneId });
    await this.liveTrackingStore.poiCurrentVehicleUsingCompanyId({
      companyId: val.companyId,
    });
    await this.liveTrackingStore.getVehiclesUsingCompanyId({
      companyId: val.companyId,
    });

    await this.setPointsInArrayFormat([val], true);
    const singleData = await this.companyStore.getCompanyDataById(
      val.companyId
    );
    await this.setState({
      companyId: val.companyId,
      drawGeoLocation: val.address,
      drawingBounds: val.points,
    });
    // console.log(singleData,);

    // await this.liveTrackingStore.getGofenceList({
    //   companyId: this.state.companyId,
    // });
    await this.formRef.current.setFieldsValue({
      typeName: val?.typeName ? parseInt(val?.typeName) : [],
      geosettingname: val?.name,
      companyId: singleData,
      address: val?.address,
      speed: val?.speed,
      bigPrice: val?.bigPrice,
      bigNeightPrice: val?.bigNeightPrice,
      neightPrice: val?.neightPrice,
      special: val?.special,
      speedDuration: val?.spdur,
      vendorId: val?.vendorId,
      price: val?.price,

      // drawGeoLocation:val.address
    });

    await this.setState({ company_value: singleData });
  };
  async SearchAssetFunc() {
    // if (!this.state.companyId || !this.state.selectedVehicles){
    //   return;
    // }
    if (this.state.SearchAssetPopup && this.state.selectedVehicles) {
      await this.liveRootPathStore.loadVehiclePosition(
        this.state.selectedVehicles
      );

      if (this.state.SearchAssetPopup) {
        this.mapRef.current.eachLayer((layer) => {
          if (layer instanceof L.Marker) {
            this.mapRef.current.removeLayer(layer);
          }
        });
      }
      if (this.liveRootPathStore.vehiclePosition?.length === 0) {
        Modal.warning({
          // title: 'Alert',
          title: this.context.translate("_FLEET_ALERT_"),
          // content: 'Vehicle not Available',
          content: this.context.translate("_VEHICLE_NOT_AVAILABLE_"),
        });
      }
      this.liveRootPathStore.vehiclePosition?.map((v) => {
        var popup = L.popup().setContent("vehicleNo =" + v.vehicleNo);
        const marker = L.marker([v.latitude, v.longitude], {
          icon: this.customIconFunc(v),
        })
          .bindTooltip(
            "<div style='background:#00ff00;border:solid 1px #000;text-align:center;padding:0'><b>" +
              v.vehicleNo +
              "/" +
              v.name +
              "</b></div>",
            {
              permanent: true,
              direction: "bottom",
              // className: "my-labels"
            }
          )
          .addTo(this.mapRef.current);
      });
      if (this.liveRootPathStore.vehiclePosition?.length > 0) {
        this.mapRef.current.setView(
          [
            this.liveRootPathStore.vehiclePosition[0]?.latitude,
            this.liveRootPathStore.vehiclePosition[0]?.longitude,
          ],
          13
        );
      }
    } else if (this.state.SearchDriverPopup && this.state.selectedDriver) {
      var data = {
        companyId: parseInt(this.state.companyId),
        driverId: this.state.selectedDriver,
      };
      var DriverList = await this.driverStore.getSearchedDriver(data);

      if (DriverList) {
        this.mapRef.current.eachLayer((layer) => {
          if (layer instanceof L.Marker) {
            this.mapRef.current.removeLayer(layer);
          }
        });
      }
      if (DriverList.length === 0) {
        Modal.warning({
          title: this.context.translate("_FLEET_ALERT_"),
          content: this.context.translate("_DRIVER_NOT_AVAILABLE_"),
        });
      }
      DriverList?.map((d) => {
        var popup = L.popup().setContent("vehicleNo =" + d.vehicleNo);
        // const marker = L.marker([v.latitude, v.longitude], {
        //   icon: this.customIcon,
        // }).bindPopup(popup).openPopup().addTo(this.mapRef.current);

        const marker = L.marker([d.latitude, d.longitude], {
          icon: this.customIconFunc(d),
        })
          .bindTooltip(
            "<div style='background:#00ff00;border:solid 1px #000;text-align:center;padding:0'><b>" +
              d.driverName +
              "/" +
              d.vehicleNo +
              "</b></div>",
            {
              permanent: true,
              direction: "bottom",
              // className: "my-labels"
            }
          )
          .addTo(this.mapRef.current);
      });
      if (DriverList?.length > 0) {
        this.mapRef.current.setView(
          [DriverList[0]?.latitude, DriverList[0]?.longitude],
          13
        );
      }
    }
  }
  async handleCancel(val) {
    if (val === "searchAddressWindowShow") {
      this.setState({ searchAddressWindowShow: false });
    } else if (val === "SearchAssetPopup") {
      this.setState({
        SearchAssetPopup: false,
        SearchDriverPopup: false,
      });
    } else if (val === "showNearVehiclePopup") {
      this.liveTrackingStore.setShowNearVehiclePopup(false);
      this.setState({
        ShowNearAssetPopup: false,
        selectGeofenceObjects: [],
        geofenceData: [],
      });
    }
    this.mapRef.current.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        this.mapRef.current.removeLayer(layer);
      }
    });

    //  this.mapRef.current.eachLayer((layer) => {
    //   if (layer instanceof L.Marker) {
    //     this.mapRef.current.removeLayer(layer);
    //   }
    // })
    // this.liveRootPathStore.resetvehiclePosition(null);
  }
  async handleVehicleChange(a) {
    this.setState({ selectedVehicles: a });
    this.liveRootPathStore.setSelectesVehicle(a);
  }
  async selectedSearchFunc(e) {
    if (e.target.value === 1) {
      this.setState({
        SearchAssetPopup: true,
        SearchDriverPopup: false,
      });
    } else if (e.target.value === 2) {
      this.setState({
        SearchAssetPopup: false,
        SearchDriverPopup: true,
      });
    }
  }

  async handleDriverChange(val) {
    this.setState({
      selectedDriver: val,
    });
    // this.liveRootPathStore.setSelectesVehicle(a);
  }
  getDistance = (val) => {
    if (val?.distance) {
      this.setState({ distance: val.distance });
    }
  };
  searchInput = async (e) => {
    const value = e.target.value;
    if (value.length > 3 || value.length === 0) {
      if (this.state.companyId) {
        await this.liveTrackingStore.getGofenceList({
          companyId: this.state.companyId,
          searchField: value,
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
        });
        await this.setState({ loadList: 1 });
      }
    }
  };

  getUploadData = async (fileUp) => {
    let fileinfo = fileUp.file.response;

    if (fileinfo?.success == "S") {
      // console.log(fileinfo.data)

      await this.setState({ excelFile: fileinfo.data });
    }

    this.setState({ update: 1 });
  };
  onImportExcelSubmit = async (fieldsValue) => {
    const data = {
      ...fieldsValue,
      companyId: this.state.excelCompanyId,
      excelFile: this.state.excelFile,
    };

    const resp = await this.liveTrackingStore.addGeofenceByExcel(data);

    if (resp?.success === "S") {
      message.success(resp.message);
      this.setState({ excelFile: "" });
    } else {
      message.error(
        "Allowed File Extension are xls,xlsx,csv and size upto 1mb"
      );
      // this.setState({ showImportExcelModal: false });
      // this.formRef.current?.resetFields();
    }
  };

  rotateIcon = (iconUrl, rotation) => {
    const icon = L.divIcon({
      iconAnchor: [10, 20],
      iconSize: [15, 20],
      // iconAnchor: [10, 20], // Center of the icon
      html: `<img src=${require(`../assets/images/livetracking/${iconUrl}`)} class="icon-image" style="transform: rotate(${rotation}deg) ; ">`,
    });

    return icon;
  };

  handleMarkerClusterGroup = async (event) => {
    const clickedCluster = event.layer;
    const clusterMarkers = clickedCluster.getAllChildMarkers();
    let vehicleObj = [];
    let vehicleIds = [];
    clusterMarkers.forEach((marker) => {
      vehicleIds.push(marker.options?.options?.obj.vehicleId);
      vehicleObj.push(marker.options?.options?.obj);
    });

    const resp = await this.liveTrackingStore.getClusterVehicleData({
      vehicleId: vehicleIds,
      companyId: this.state.companyId,
    });
    if (resp) {
      this.setState({
        markerClusterGroupPopup: {
          vehicleFeature: resp,
          vehicleObj: vehicleObj,
          This: this,
        },
      });
    }
  };

  getCenterOfCoordinates = (coordinates) => {
    if (coordinates.length === 0) {
      return null;
    }
    let sumLat = 0;
    let sumLng = 0;
    for (const coordinate of coordinates) {
      sumLat += coordinate[0]; // Latitude is the first element of each coordinate pair
      sumLng += coordinate[1]; // Longitude is the second element of each coordinate pair
    }
    const avgLat = sumLat / coordinates.length;
    const avgLng = sumLng / coordinates.length;
    return [avgLat, avgLng];
  };

  timeOptionsForLiveLocation = () => {
    const timeArray = [];

    let startTime = new Date(2000, 0, 1, 0, 0); // 12:00 AM

    let endTime = new Date(2000, 0, 1, 24, 0);
    for (let time = startTime; time < endTime; time = addMinutes(time, 15)) {
      // const formattedTime = format(time, 'h:mm aa');
      const formattedTime = format(time, "HH:mm");
      var ob = {
        formattedTime: formattedTime,
        time: time,
      };
      timeArray.push(ob);
    }

    return timeArray.map((data) => {
      return (
        <Select.Option key={data.formattedTime} value={data.formattedTime}>
          {format(data.time, "H:mm")}
        </Select.Option>
      );
    });
  };

  onLiveLocationFormSubmit = async (value) => {
    // console.log(value);

    const data = {
      ...value,
      expiryDate: value.expiryDate.format("YYYY-MM-DD"),
      vehicleId: this.state.vehicleOtherInfo.vehicleId,
      companyId: this.state.vehicleOtherInfo.companyId,
      locationUrl: this.state.locationUrl?.locationUrl,
      status: 1,
      Id: this.state.Id,
    };

    const resp = await this.liveTrackingStore.addLiveLocation(data);

    // this.setState({linkResponse:resp.data.success == "S"})
    if (resp.data.success == "S") {
      message.success(resp.data.message);
      // this.livelocationFormRef.current.setFieldsValue({
      //   locationUrl:resp.locationUrl,
      // });
      // await this.setState({ openLiveLocationModal: false });
    } else if (resp.data.success == "F0") {
      resp.data.errors.map((value) => {
        message.error(value["vehicleNo"]);
        // message.error(resp.data.message);
      });
    }
  };

  getLiveLocationUrl = async () => {
    const data = {
      vehicleId: this.state.vehicleOtherInfo.vehicleId,
    };
    const response = await this.liveTrackingStore.getLocationUrl(data);

    this.setState({ locationUrl: response });

    if (response.Id !== -1) {
      this.livelocationFormRef.current.setFieldsValue({
        locationUrl: response.locationUrl,
        expiryDate: dayjs() ? dayjs(response.expiryDate) : "",
        startTime: response.startTime,
        endTime: response.endTime,
      });
    }
  };

  async startVehiclePosition() {
    // Initialize an empty object if it doesn't exist to store positions for each vehicle
    this.vehiclePositionMap = this.vehiclePositionMap || {};
    const vehiclePositionData = this.liveTrackingStore.selectedVehicles;

    const positionLatLngs = this.liveTrackingStore.selectedVehicles.map(
      ({ latitude, longitude }) => L.latLng(latitude, longitude)
    );

    const allLatLngs = [];
    // console.log(this.state.vehicleUpdatedPosition,"this.state.vehicleUpdatedPosition")
    this.state.vehicleUpdatedPosition.forEach((polyline) => {
      allLatLngs.push(...polyline);
    });
    const latestLatLng = allLatLngs[allLatLngs.length - 1];
    // console.log(latestLatLng);
    if (positionLatLngs.length > 0 && !this.state.isLocked) {
      // const bounds = L.latLngBounds(positionLatLngs);
      const bounds = L.latLngBounds();
      const data1 = positionLatLngs.map((val) => {
        bounds.extend(val);
      });
      this.mapRef.current.fitBounds(bounds);
    }

    if (!vehiclePositionData || !Array.isArray(vehiclePositionData)) {
      this.state.vehicleUpdatedPosition = [];
      return; // Exit early if data is not valid
    }

    if (vehiclePositionData) {
      const lastSelectedVehicle =
        vehiclePositionData[vehiclePositionData.length - 1];

      // Iterate over the data to update or remove positions
      const updatedVehiclePositionMap = {};
      // lastSelectedVehicle.forEach((vehicleData) => {
      const vehicleId = lastSelectedVehicle?.vehicleId;
      const latitude = parseFloat(lastSelectedVehicle?.latitude);
      const longitude = parseFloat(lastSelectedVehicle?.longitude);

      // Ensure that latitude and longitude are valid numbers
      if (!isNaN(latitude) && !isNaN(longitude)) {
        const latLong = [latitude, longitude];

        // Add or update the position in the updated map
        updatedVehiclePositionMap[vehicleId] = [
          ...(this.vehiclePositionMap[vehicleId] || []),
          latLong,
        ];
      }
      // });

      // Filter out vehicles that have been deselected
      Object.keys(this.vehiclePositionMap).forEach((vehicleId) => {
        if (!updatedVehiclePositionMap[vehicleId]) {
          delete this.vehiclePositionMap[vehicleId];
        }
      });

      // Update vehicle positions with new and filtered positions
      this.vehiclePositionMap = updatedVehiclePositionMap;
      // console.log(this.vehiclePositionMap);
      const updatedPositions = Object.values(this.vehiclePositionMap).filter(
        (positions) => Array.isArray(positions) && positions.length > 0
      );
      // console.log(updatedPositions);
      // Update state with the latest positions
      this.setState({
        vehicleUpdatedPosition: updatedPositions,
        // direction,mapImage
      });
    }
  }

  addArrowheads(polyline) {
    // console.log(polyline)
    if (polyline && polyline.arrowheads) {
      polyline.arrowheads({ size: "10px" }); // Customize as needed
    } else {
      console.error("Arrowheads plugin or polyline is not available.");
    }
  }

  handleAddVersionForm = async (values) => {};

  handleAddVersionModal = async () => {
    this.setState({ openAddVersionModal: true });
  };

  updateSelectedVehicles = (selectedVehicleIds) => {
    this.setState({ selectedVehicleIds });
  };

  handleVideoMonitoringData = async (channelId) => {
    const data = { vehicleId: this.state.vehicleOtherInfo?.vehicleId };
    const response = await this.videoMonitoringStore.getDevices(data);

    this.setState({ videoMonitoringData: response[0] });

    if (response) {
      this.loadVideos(channelId, response[0]);
    }

    if (response[0].MDVR == "JT1078") {
      await this.videoMonitoringStore.bsjVideoMonitoring(
        channelId,
        response[0]
      );
    }
  };

  loadVideos = async (channelId, vehicleData) => {
    var username = vehicleData.cmsvssUserName;
    var host = vehicleData.cmsvssWebHost;
    var port = vehicleData.cmsvssWebPort;
    var imei = vehicleData.full_imei;
    var token = vehicleData.token;
    var MDVR = vehicleData.MDVR;
    var password = vehicleData.cmsvssPassword;

    let iframeUrl = "";
    if (MDVR == "JT1078") {
      iframeUrl =
        "https://media.trackeazy.com/test/multimedia#" + imei + "-" + channelId;
    } else if (MDVR == "CMS") {
      iframeUrl = `${host}/808gps/open/player/video.html?lang=en&devIdno=${imei}&account=${username}&password=${password}&channel=1&chns=${
        channelId - 1
      }`;
    } else {
      if (port != null) {
        iframeUrl = `${host}:${port}/vss/apiPage/RealVideo.html?token=${token}&deviceId=${imei}&chs=${
          channelId - 1
        }&stream=0&wnum=0&buffer=0&panel=1`;
      } else {
        iframeUrl = `${host}/vss/apiPage/RealVideo.html?token=${token}&deviceId=${imei}&chs=${channelId}&stream=0&wnum=0&buffer=0&panel=1`;
      }
    }

    // this.setState({ iframeUrl: iframeUrl });

    this.setState((prevState) => ({
      videoMonitoringUrl: {
        ...prevState.videoMonitoringUrl,
        [channelId]: iframeUrl,
      },
    }));
  };

  handleCloseChannel = (channelId) => {
    this.setState((prevState) => {
      const updatedUrls = { ...prevState.videoMonitoringUrl };
      delete updatedUrls[channelId];
      return { videoMonitoringUrl: updatedUrls };
    });
  };
  render() {
    const formItemStyle = { marginBottom: "2px", marginTop: "2px" };
    const { drawnShapes } = this.state;
    const { selectedGeofenceIndex, address } = this.state;
    let selectedGeofence;
    if (this.state.selectGeofenceObjects.length > 0) {
      selectedGeofence = this.state.selectGeofenceObjects;
    } else {
      selectedGeofence = [this.state.geofenceData];
    }

    return (
      <div>
        {!this.state.showSlider && (
          <FloatButton.Group
            className={
              this.state.liveTrackingMainModal
                ? "right-panel-btn-group"
                : "right-panel-btn-group-old"
            }
            shape="square"
          >
            <FloatButton
              icon={<CarFront />}
              onClick={async () => {
                if (this.props.getUserData().isadmin === 1) {
                  await this.handleSearch();
                }

                const liveTrackingData =
                  this.liveTrackingStore?.liveTrackingData || [];
                const positionLatLngs = liveTrackingData.map(
                  ({ latitude, longitude }) => L.latLng(latitude, longitude)
                );

                const allLatLngs = [];
                this.state.vehicleUpdatedPosition.forEach((polyline) => {
                  allLatLngs.push(...polyline);
                });
                const latestLatLng = allLatLngs[allLatLngs.length - 1];
                // console.log(latestLatLng);
                if (positionLatLngs.length > 0) {
                  // const bounds = L.latLngBounds(positionLatLngs);
                  const bounds = L.latLngBounds();
                  const data1 = positionLatLngs.map((val) => {
                    bounds.extend(val);
                  });
                  await this.setState({ liveTrackingMainModal: true });

                  setTimeout(() => {
                    if (this.mapRef?.current && this.mapRef.current._loaded) {
                      if (this.state.isLocked && latestLatLng) {
                        // console.log("CarFront", latestLatLng);
                        const center = bounds.getCenter();
                        this.mapRef.current.setView(
                          latestLatLng,
                          this.mapRef.current.getZoom()
                        );
                      } else {
                        this.mapRef.current.fitBounds(bounds);
                      }
                    } else {
                      console.warn("Map is not ready yet");
                    }
                  }, 500);
                }

                await this.setState({ liveTrackingMainModal: true });

                await this.SearchMenuItemClick("liveTrackingMainModal");
              }}
            />
            <AntTooltip label="fererrre">
              <Popover
                content={
                  <Radio.Group
                    options={this.geofenceOptions}
                    value={this.state.drawGeofence}
                    onChange={(e) => {
                      this.selectGeofence(e);
                    }}
                    optionType="button"
                    buttonStyle="solid"
                  />
                }
                placement={
                  this.context.locale.language === "arabic" ? "right" : "left"
                }
              >
                <FloatButton.BackTop
                  icon={
                    <Pencil
                      onClick={async () => {
                        if (this.props.getUserData().isadmin === 1) {
                          await this.liveTrackingStore.getGofenceList({
                            companyId: this.props.getUserData().userCompanyId,

                            offset:
                              (this.state.currentPage - 1) *
                              this.state.pageSize,

                            limit: 50,
                          });
                          this.setState({ loadData: 1 });
                        }
                        // await this.setState({ showGofence: true });

                        await this.SearchMenuItemClick("showGofence");
                      }}
                    />
                  }
                  visibilityHeight={0}
                />
              </Popover>
            </AntTooltip>
            <Popover
              content={
                <Button
                  onClick={async () => {
                    await this.SearchMenuItemClick("measureDistModal");

                    // await this.setState({ measureDistModal: true });
                  }}
                >
                  {/* Distance Measurement */}
                  {this.context.translate("_DISTANCE_MEASUREMENT_")}
                </Button>
              }
              placement={
                this.context.locale.language === "arabic" ? "right" : "left"
              }
            >
              <FloatButton.BackTop icon={<ArrowsMove />} visibilityHeight={0} />
            </Popover>
            <Popover
              content={
                <Space className="site-button-ghost-wrapper" wrap>
                  <Menu
                    style={{
                      width: 170,
                    }}
                    mode="vertical"
                  >
                    {this.searchMenu.map((s) => {
                      return (
                        <Menu.Item
                          icon={s.icon}
                          onClick={() => {
                            this.SearchMenuItemClick(s.key);
                          }}
                        >
                          {s.label}
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                </Space>
              }
              placement={
                this.context.locale.language === "arabic" ? "right" : "left"
              }

              //   onOpenChange={handleOpenChange}
            >
              <FloatButton icon={<SearchOutlined />} />
            </Popover>
            <Popover
              content={
                <Spin spinning={this.state.loadingCheckbox}>
                  <Radio.Group
                    options={this.mapOptions}
                    onChange={async (v) => {
                      await this.setState({ loadingCheckbox: true });
                      setTimeout(() => {
                        this.setState({ loadingCheckbox: false });
                      }, 3000);
                      await this.handleMapTypeChange(v);
                    }}
                    value={this.state.mapType}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Spin>
              }
              placement={
                this.context.locale.language === "arabic" ? "right" : "left"
              }

              //   onOpenChange={handleOpenChange}
            >
              <FloatButton icon={<GlobeCentralSouthAsia />} />
            </Popover>

            <Popover
              content={
                <Button onClick={() => this.props.navigate("/assigntriplist/")}>
                  {/* Create/Assign Trip */}
                  {this.context.translate(
                    "_FLEET_DASHBORD_NAVIRIGHT_CREATEROUTE_"
                  )}
                </Button>
              }
              placement={
                this.context.locale.language === "arabic" ? "right" : "left"
              }
            >
              <FloatButton.BackTop icon={<Bezier2 />} visibilityHeight={0} />
            </Popover>

            <Button
              style={{ marginBlockStart: "5px", width: "42px" }}
              onClick={() => {
                this.setState((prevState) => ({
                  isLocked: !prevState.isLocked, // Toggle between true and false
                }));
              }}
            >
              {this.state.isLocked ? (
                <UnlockOutlined
                  style={{ fontSize: "25px", marginInlineStart: "-6px" }}
                />
              ) : (
                <LockOutlined
                  style={{ fontSize: "25px", marginInlineStart: "-6px" }}
                />
              )}
            </Button>
          </FloatButton.Group>
        )}

        <div
          dir={this.context.locale.language === "arabic" ? "ltr" : "ltr"}
          className={
            this.context.locale.language === "arabic"
              ? "arabic-map-container"
              : ""
          }
        >
          <MapContainer
            key={this.state.liveTrackingMainModal ? "selected" : "default"}
            ref={this.mapRef}
            zoom={config.mapZoom}
            center={config.mapCenter}
            // zoom={13}
            // minZoom={3}
            // center={[24.7136, 46.6753]}
            // className="mapcontainerStyle"
            style={{
              position: "sticky",
              height: "100vh",
              width: this.state.liveTrackingMainModal ? "62%" : "100wh",
            }}
            // style={{ position: "sticky", height: "100vh", width: 900 }}
            zoomControl={false}
            // whenReady={() => {
            //   if (
            //     this.mapRef?.current &&
            //     this.state.positionLatLngs.length > 0
            //   ) {
            //     const bounds = L.latLngBounds(this.state.positionLatLngs);
            //     this.mapRef.current.fitBounds(bounds, { maxZoom: 12 });
            //   }
            // }}
          >
            {!this.state.measureDistModal && (
              <ZoomControl position="bottomright"></ZoomControl>
            )}

            {this.state.navigationWindow
              ? false
              : this.state.measureDistModal && (
                  //  {this.state.DraggablePopup  &&
                  <MeasureDist
                    closeFun={async (resp) =>
                      await this.setState({
                        measureDistModal: resp,
                        mapType: "google",
                      })
                    }
                    mapRef={this.mapRef}
                  />
                )}
            {/* origin={} destination={} */}
            {this.state.navigationWindow && (
              <RouteShowingMap
                getDistance={
                  this.state.origin &&
                  this.state.destination &&
                  this.state.navigationWindow
                    ? this.getDistance
                    : undefined
                }
                origin={
                  // this.state.getDirection &&
                  // this.state.navigationWindow &&
                  this.state.origin
                }
                destination={
                  // this.state.getDirection &&
                  // this.state.navigationWindow &&
                  this.state.destination
                }
                showCalculatedDist={this.state.showCalculatedDist}
              />
            )}

            {this.state.navigationWindow
              ? false
              : this.state.mapType === "google" && (
                  <ReactLeafletGoogleLayer
                    key={this.state.type ? this.state.type : ""}
                    type={this.state.type ? this.state.type : "roadmap"}
                    googleMapsAddLayers={
                      this.state.traffic ? [{ name: "TrafficLayer" }] : null
                    }
                  />
                )}

            {this.state.navigationWindow
              ? false
              : this.state.mapType == "openstreet" && (
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution="Map data © OpenStreetMap contributors"
                  />
                )}

            {selectedGeofence[0]?.points?.length > 0 &&
              selectedGeofence?.map((selectedGeofence) => {
                return (
                  <>
                    {/* {console.loadData(selectedGeofence)} */}
                    {selectedGeofence.points.length === 1 && (
                      <Circle
                        center={selectedGeofence?.points[0]}
                        radius={selectedGeofence?.radius}
                        pathOptions={{ color: "red" }}
                      >
                        <Popup>{selectedGeofence.name}</Popup>
                      </Circle>
                    )}
                    {selectedGeofence?.points?.length === 4 && (
                      <Polygon
                        positions={selectedGeofence.points}
                        pathOptions={{ color: "red" }}
                      >
                        <Popup>{selectedGeofence.name}</Popup>
                      </Polygon>
                    )}
                    {selectedGeofence?.points?.length > 2 && (
                      <Polygon
                        positions={selectedGeofence.points}
                        pathOptions={{ color: "red" }}
                      >
                        <Popup>{selectedGeofence.name}</Popup>
                      </Polygon>
                    )}
                  </>
                );
              })}

            <div
              className="handle"
              style={{ display: "flex", flexDirection: "row", left: "10%" }}
              // className=""
            >
              {this.state.editing && this.state.drawGeofence && (
                <FeatureGroup ref={this.drawnItemsRef}>
                  <EditControl
                    // position="topright"
                    // onDrawStart={_onDrawStart}
                    // position="topright"
                    // onEdited={_onEdited}
                    position={
                      this.context.locale.language === "arabic"
                        ? "topleft"
                        : "topright"
                    }
                    onCreated={this.onCreated}
                    onDeleted={() =>
                      this.mapRef.current.eachLayer((layer) => {
                        if (layer instanceof L.Marker) {
                          this.mapRef.current.removeLayer(layer);
                        }
                        this.setState({
                          position: [],
                          selectGeofenceObjects: [],
                          geofenceData: [],
                        });
                      })
                    }
                    draw={{
                      circle: true,
                      circlemarker: false,
                      polyline: false,
                      marker: false,
                      rectangle: true,
                      polygon: true,
                    }}
                    //          edit={{
                    //     edit: false,
                    //     remove: false
                    // }}
                    // className="edit-control-class"
                    style={{
                      direction:
                        this.context.locale.language === "arabic"
                          ? "ltr"
                          : "ltr",
                    }}
                  />
                </FeatureGroup>
              )}
            </div>

            {this.state.navigationWindow ? (
              ""
            ) : (
              <AdditionalContentInMap This={this} size="small" />
            )}

            {!this.state.measureDistModal &&
              this.state.vehicleUpdatedPosition.map(
                (polyline, polylineIndex) => {
                  // console.log(polyline);
                  return (
                    <React.Fragment key={`polyline-${polylineIndex}`}>
                      {polyline.map((position, index) => {
                        return (
                          !this.state.selectAllMarkers &&
                          index === 0 && (
                            <Marker
                              key={`marker-${polylineIndex}-${index}`}
                              position={position}
                              icon={firstIcon}
                              // Use firstIcon when index is 1
                              //  icon={this.rotateIcon(vehicleData.mapImage, vehicleData.direction)}
                            />
                          )
                        );
                      })}
                      <Polyline
                        color="blue"
                        positions={polyline}
                        ref={this.polylineRef}
                        // ref={(polylineRef) => {
                        //   if (polylineRef) {
                        //     this.addArrowheads(polylineRef.leafletElement); // Add arrowheads to the polyline
                        //   }
                        // }}
                      />
                    </React.Fragment>
                  );
                }
              )}

            {!this.state.measureDistModal &&
            this.props.getUserData()?.mapSetting === "1" ? (
              <MarkerClusterGroup
                ref={this.groupRef}
                iconCreateFunction={this.iconCreateFunction}
                onClick={(event) => {
                  this.handleMarkerClusterGroup(event);
                }}
              >
                {this.liveTrackingStore.selectedVehicles?.map((val, ind) => {
                  //  console.log(val?.latitude, val?.longitude,"789456123")
                  if (val?.latitude !== null && val?.longitude !== null) {
                    return (
                      <Marker
                        // ref={this.polylineRef}
                        key={val.vehicleId}
                        position={[val.latitude, val.longitude]}
                        // icon={dotIcon}
                        icon={this.rotateIcon(val.mapImage, val.direction)}
                        currentStatus={val.currentStatus}
                        options={{ obj: val }}
                        keepAtCenter={true}
                        angle={val.direction}
                        autoStart={true}
                        eventHandlers={{
                          click: (e) => {
                            console.log(val);
                            this.liveTrackingStore.getDriverPerformance({
                              companyId: val.companyId,
                              driverId: val.driverId,
                            });

                            let daySummary = this.liveTrackingStore.daySummary({
                              currentDate: dayjs().format("YYYY-MM-DD"),
                              companyId: val.companyId,
                              vehicleId: [val.vehicleId],
                            });

                            this.setState({
                              vehicleOtherInfo: val,
                              daySummary: daySummary,
                            });
                          },
                        }}
                      >
                        {" "}
                        <Tooltip
                          permanent={true}
                          offset={[5, -15, 5, 50]}
                          direction="top"
                        >
                          <div
                          // style={{
                          //   backgroundColor: "#00ff00",
                          // }}
                          >
                            <b>
                              {val.name
                                ? `${val.vehicleNo}/${val.name}`
                                : val.vehicleNo}
                            </b>
                          </div>
                        </Tooltip>
                      </Marker>
                    );
                  }
                })}
              </MarkerClusterGroup>
            ) : (
              this.liveTrackingStore.selectedVehicles?.map((val, ind) => {
                if (val?.latitude != null && val?.longitude !== null) {
                  return (
                    !this.state.measureDistModal && (
                      <Marker
                        key={val.vehicleId}
                        position={[val?.latitude, val?.longitude]}
                        // icon={dotIcon}
                        icon={this.rotateIcon(val.mapImage, val.direction)}
                        currentStatus={val.currentStatus}
                        options={{ obj: val }}
                        keepAtCenter={true}
                        angle={val.direction}
                        autoStart={true}
                        eventHandlers={{
                          click: (e) => {
                            this.liveTrackingStore.getDriverPerformance({
                              companyId: val.companyId,
                              driverId: val.driverId,
                            });

                            let daySummary = this.liveTrackingStore.daySummary({
                              currentDate: dayjs().format("YYYY-MM-DD"),
                              companyId: val.companyId,
                              vehicleId: [val.vehicleId],
                            });

                            this.setState({
                              vehicleOtherInfo: val,
                              daySummary: daySummary,
                            });
                          },
                        }}
                      >
                        {" "}
                        <Tooltip
                          permanent={true}
                          offset={[5, -15, 5, 50]}
                          direction="top"
                        >
                          <div
                          // style={{
                          //   backgroundColor: "#00ff00",
                          // }}
                          >
                            <b>
                              {val.name
                                ? `${val.vehicleNo}/${val.name}`
                                : val.vehicleNo}
                            </b>
                          </div>
                        </Tooltip>
                      </Marker>
                    )
                  );
                }
              })
            )}
          </MapContainer>
        </div>
        {/* {console.log(this.liveTrackingStore.vehiCleLiveLatLongObj)} */}
        {this.state.liveTrackingMainModal && (
          <div>
            <div className="slider-container">
              <div className="liveTrackingTableBtn">
                <div className="statusPanelBtn">
                  <AntTooltip
                    title="Idle"
                    color="blue"
                    placement={
                      this.context.locale.language === "arabic"
                        ? "right"
                        : "left"
                    }
                  >
                    <Button
                      className="circleBtn idleBtn"
                      name="idle"
                      onClick={() => this.getStatusWiseList("idle")}
                    >
                      <span>
                        {this.liveTrackingStore.totalvehstate.Idle
                          ? this.liveTrackingStore.totalvehstate.Idle
                          : 0}
                      </span>
                    </Button>
                  </AntTooltip>
                  <AntTooltip
                    title="Running"
                    color="blue"
                    placement={
                      this.context.locale.language === "arabic"
                        ? "right"
                        : "left"
                    }
                  >
                    <Button
                      className="circleBtn runningBtn"
                      name="running"
                      onClick={() => this.getStatusWiseList("running")}
                    >
                      <span>
                        {" "}
                        {this.liveTrackingStore.totalvehstate.Moving
                          ? this.liveTrackingStore.totalvehstate.Moving
                          : 0}
                      </span>
                    </Button>
                  </AntTooltip>
                  <AntTooltip
                    title="Stop"
                    color="blue"
                    placement={
                      this.context.locale.language === "arabic"
                        ? "right"
                        : "left"
                    }
                  >
                    <Button
                      className="circleBtn stopBtn"
                      name="stop"
                      onClick={() => this.getStatusWiseList("stop")}
                    >
                      <span>
                        {" "}
                        {this.liveTrackingStore.totalvehstate.Stopped
                          ? this.liveTrackingStore.totalvehstate.Stopped
                          : 0}
                      </span>
                    </Button>
                  </AntTooltip>
                  <AntTooltip
                    title="No Data"
                    color="blue"
                    placement={
                      this.context.locale.language === "arabic"
                        ? "right"
                        : "left"
                    }
                  >
                    <Button
                      className="circleBtn nodataBtn"
                      name="nodata"
                      onClick={() => this.getStatusWiseList("nodata")}
                    >
                      <span>
                        {this.liveTrackingStore.totalvehstate.NoData
                          ? this.liveTrackingStore.totalvehstate.NoData
                          : 0}
                      </span>
                    </Button>
                  </AntTooltip>
                  <AntTooltip
                    title="All"
                    color="blue"
                    placement={
                      this.context.locale.language === "arabic"
                        ? "right"
                        : "left"
                    }
                  >
                    <Button
                      className="circleBtn allBtn"
                      name="all"
                      onClick={() => this.getStatusWiseList("all")}
                    >
                      <span>
                        {" "}
                        {this.liveTrackingStore.totalvehstate.total
                          ? this.liveTrackingStore.totalvehstate.total
                          : 0}
                      </span>
                    </Button>
                  </AntTooltip>
                </div>
                {/* <LockFill width={30} height={30} /> */}
                <Button
                  className="whiteBtn globeBtn"
                  name="all"
                  onClick={() => {
                    this.liveTrackingStore.exportPoiVehicle(
                      this.state.selectedRowKeysArray
                    );
                  }}
                  // icon=
                >
                  <i style={{ fontSize: "18px" }} class="bi bi-globe2"></i>
                </Button>
                <Button
                  className="whiteBtn lockBtn"
                  name="all"
                  onClick={this.getStatusWiseList}
                >
                  <Image src={require("../assets/images/map-lock-icon.png")} />
                </Button>
              </div>
            </div>
            {/* <Collapse> */}
            <Card
              maskClosable={false}
              mask={false}
              style={{ width: "35%" }}
              title=<div
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <CloseOutlined
                  onClick={async () => {
                    this.setState({ liveTrackingMainModal: false });

                    const liveTrackingData =
                      this.liveTrackingStore?.liveTrackingData || [];
                    const positionLatLngs = liveTrackingData.map(
                      ({ latitude, longitude }) => L.latLng(latitude, longitude)
                    );
                    const allLatLngs = [];
                    this.state.vehicleUpdatedPosition.forEach((polyline) => {
                      allLatLngs.push(...polyline);
                    });
                    const latestLatLng = allLatLngs[allLatLngs.length - 1];
                    // console.log(latestLatLng);
                    if (positionLatLngs.length > 0) {
                      // const bounds = L.latLngBounds(positionLatLngs);
                      const bounds = L.latLngBounds();
                      const data1 = positionLatLngs.map((val) => {
                        bounds.extend(val);
                      });
                      await this.setState({ liveTrackingMainModal: false });

                      setTimeout(() => {
                        if (
                          this.mapRef?.current &&
                          this.mapRef.current._loaded
                        ) {
                          if (this.state.isLocked && latestLatLng) {
                            const center = bounds.getCenter();

                            this.mapRef.current.setView(
                              latestLatLng,
                              this.mapRef.current.getZoom()
                            );
                          } else {
                            this.mapRef.current.fitBounds(bounds);
                          }
                        } else {
                          console.warn("Map is not ready yet");
                        }
                      }, 500);
                    }
                  }}
                />
              </div>
              placement="right"
              open={this.state.liveTrackingMainModal}
              onOk={() => this.setState({ liveTrackingMainModal: false })}
              // onCancel={handleCancel}
              className="rightPanel-black trackingRightPanel"
              onCancel={() => this.setState({ liveTrackingMainModal: false })}
              // visible={this.state.showSlider}
              footer={null}
              // width={500}
            >
              <Row className="listInputRow">
                <div style={{ display: "flex" }}>
                  <div style={{ marginInlineEnd: "10px" }}>
                    {this.props.getUserData().isadmin === 1 && (
                      <GetVehicleGroups
                        handleSearch={this.handleSearch}
                        selectedVehicleIds={this.state.selectedVehicleIds} // Pass selected vehicles
                        updateSelectedVehicles={this.updateSelectedVehicles}
                        liveTrackingStore={this.liveTrackingStore}
                        vehicleStore={this.vehicleStore}
                        style={{ width: 170 }}
                      />
                    )}
                    <CompanyAutocomplete
                      value={
                        this.state.company_value
                          ? this.state.company_value
                          : null
                        // &&
                        // this.liveTrackingStore?.liveTrackingData.length > 0
                        //   ? this.state.company_value
                        //   : null
                      }
                      onChange={async (value) => {
                        await this.handleChange(value);
                      }}
                      style={{ width: 145 }} //160
                      rules={[{ required: true }]}
                      // value={isadmin? }
                    />
                  </div>

                  <Search
                    className="liveTracking-search"
                    // allowClear={false}
                    onChange={this.popupSearch}
                    style={{
                      width: 130, //160
                      // display:"none"
                    }}
                    value={this.state.popupSearch ? this.state.popupSearch : ""}
                    // placeholder="Vehicle or Driver"
                    placeholder={this.context.translate("_VEHICLE_OR_DRIVER_")}
                    // allowClear
                    onSearch={async () => await this.handleSearch()}
                    enterButton
                  />
                </div>
                <div
                  className="liveTrackingTimerCss"
                  style={{ display: "flex", background: "white" }}
                >
                  <div>
                    <div
                      style={{
                        marginRight: "5px",
                        width: "60px",
                        display: "flex",
                      }}
                    >
                      <b style={{ minWidth: "20px", fontWeight: "normal" }}>
                        {" "}
                        {this.state.timerCount}
                      </b>

                      <p
                        style={{
                          padding: "0px",
                          margin: "0px",
                          // marginLeft: "5px",
                        }}
                      >
                        {this.context.translate("_REFRESH_")}
                      </p>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <Select
                      defaultValue="30"
                      style={{
                        bottom: "12px",
                      }}
                      // onClick={this.handleSearch}
                      onChange={this.setRefreshTime}
                      options={[
                        {
                          value: "30",
                          label: "30",
                        },
                        {
                          value: "45",
                          label: "45",
                        },
                        {
                          value: "60",
                          label: "60",
                        },
                      ]}
                    />
                    <div className="refresh-btn">
                      {this.state.timerPause ? (
                        <PauseCircle
                          size={20}
                          value={"pause"}
                          onClick={this.timerPauseFun}
                        />
                      ) : (
                        <PlayCircle
                          size={20}
                          value={"play"}
                          onClick={this.timerPauseFun}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/* </Col> */}
              </Row>

              <Table
                className="list-tbl-height liveTrackingMainTbl"
                bordered
                columns={this.columns}
                rowSelection={{
                  columnWidth: 3,
                  fixed: true,
                  selectedRowKeys: this.state.selectedRowKeysArray,
                  onChange: async (selectedRowKeys, selectedRows) => {
                    // console.log(selectedRowKeys, selectedRows);

                    if (
                      this.mapSetting2 &&
                      this.props.getUserData()?.mapSetting2 === "1"
                    ) {
                      let existingCookie = this.cookies.get("data");
                      let decodedData = JSON.parse(atob(existingCookie));
                      decodedData.mapSetting2 = null; // Assuming newMapSetting2 is the updated value
                      let updatedCookieData = btoa(JSON.stringify(decodedData));
                      this.cookies.set("data", updatedCookieData, {
                        path: "/",
                      });
                      this.mapSetting2 = null;
                    }
                    const deselectedKeys =
                      this.state.selectedRowKeysArray.filter(
                        (key) => !selectedRowKeys.includes(key)
                      );

                    deselectedKeys.forEach((vehicleId) => {
                      delete this.vehiclePositionMap[vehicleId];
                    });

                    // Update the vehicle position array to reflect only selected rows
                    const updatedPositions = Object.values(
                      this.vehiclePositionMap
                    ).filter(
                      (positions) =>
                        Array.isArray(positions) && positions.length > 0
                    );

                    await this.setState({
                      position: selectedRows,
                      selectedRowKeysArray: selectedRowKeys,
                      vehicleUpdatedPosition: updatedPositions,
                    });

                    // await this.setState({
                    //   position: selectedRows,
                    //   selectedRowKeysArray: selectedRowKeys,
                    //   vehicleUpdatedPosition: [],
                    //   vehiclePositionMap: {},
                    //   // selectedRowKeysArray: this.state.selectedRowKeysArray.includes(selectedRowKeys)?this.state.selectedRowKeysArray: selectedRowKeys,
                    // });
                    this.liveTrackingStore.selectedVehicles = selectedRows;
                  },
                  onSelectAll: async (selected, selectedRows, changeRows) => {
                    await this.setState({ selectAllMarkers: selected });
                  },
                }}
                dataSource={[...this.liveTrackingStore?.liveTrackingData]}
                pagination={false}
                scroll={{
                  x: 700,
                  y: this.state.clientHeight - 240,
                }}
                loading={this.liveTrackingStore?.loading}
                rowKey={"vehicleId"}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      const selectedRowKeysArray = [Number(record.vehicleId)]; // Only the clicked row will be selected
                      this.setState({
                        selectedRowKeysArray,
                      });
                      this.mapRef.current.setView(
                        [record.latitude, record.longitude],
                        17
                      );
                      this.liveTrackingStore.selectedVehicles =
                        this.liveTrackingStore?.liveTrackingData.filter((row) =>
                          selectedRowKeysArray.includes(row.vehicleId)
                        );

                      // this.state.vehicleUpdatedPosition=[]
                      // this.vehiclePositionMap ={}
                    },
                  };
                }}
              />
            </Card>
          </div>
        )}

        {this.state.ShowNearAssetPopup && (
          <DraggableModal
            getGeoFenceObj={async (recordFormNearestAsset) => {
              await this.handleShowingGeofenceInMap({ recordFormNearestAsset });
              await this.setState({ update: 1 });
            }}
            mapRef={this.mapRef}
            groupRef={this.groupRef}
            handleCancel={(val) => this.handleCancel(val)}
          />
        )}

        {this.state.drawGeofence && (
          <div>
            <Card
              className="right-menu-panel-newGeofence"
              // headStyle={{
              //   margin: "0px !important",
              //   padding: "0px !important",
              // }}
              title={
                <div>
                  <p style={{ cursor: "move" }}>
                    {/* New Geofence */}
                    {this.context.translate(this.state.geofenceTitle)}
                  </p>
                  <div style={{ paddingBottom: "10px" }}>
                    <Autocomplete
                      className="autocomplete-location-textbox"
                      // options={
                      //   {
                      //     types: ['geocode', 'establishment', 'address', 'locality', 'route'],
                      //   }
                      // }
                      defaultValue={this.state.drawGeoLocation}
                      onChange={(e) => {
                        this.setState({ testState: e.target.value });
                      }}
                      componentRestrictions={{ country: "us" }}
                      options={{
                        types: ["geocode", "establishment"],
                      }}
                      style={{ fontSize: "14px", width: "97%", height: "45px" }}
                      apiKey={config.googleMapKey}
                      onPlaceSelected={async (e) => {
                        await this.setState({
                          drawGeoLocation: e.formatted_address,
                        });
                        await this.getInformationOfVehicle({
                          latLong: {
                            latitude: e.geometry?.location?.lat(),
                            longitude: e.geometry?.location?.lng(),
                          },
                        });

                        // await this.setState({update:1})
                      }}
                      // onPlaceSelected={(e) => {
                      //   console.log(e);
                      // }}
                    />
                  </div>
                </div>
              }

              // style={{ width: 400, padding: "20px" }}
            >
              <Form
                onFinish={this.onFinishDrawing}
                onFinishFailed={(a) => {
                  a.errorFields.forEach((val) => {
                    val.name.forEach((val2) => {
                      if (val2 === "address") {
                        Modal.warning({
                          // title: "Alert",
                          title: this.context.translate("_FLEET_ALERT_"),
                          // content: "We need to Draw Geofence for Address",
                          content: this.context.translate(
                            "_SELECT_SHAPE_DRAW_FENCE_"
                          ),
                        });
                      }
                    });
                  });
                }}
                layout="vertical"
                ref={this.formRef}
              >
                <div
                  style={{
                    overflow: "auto",
                    maxHeight: "395px",
                    paddingInlineEnd: "12px",
                    borderTop: "1px solid",
                    marginTop: "5px solid",
                  }}
                >
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <Form.Item
                      name="companyId"
                      // label="Account"
                      label={this.context.translate(
                        "_FLEET_DASHBORD_NEWPOI_CUSTOMER_"
                      )}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        // value={this.state.companyId}
                        onChange={async (value) => {
                          await this.handleChangeForDrawingGeofence(value);
                          await this.setState({ companyId: value.value });
                        }}
                      />
                    </Form.Item>
                  )}
                  {/* <Form.Item
                        label=" "
                        name="vehicleLoc"
                        className="checkbox-block"
                      > */}
                  <br />
                  <Checkbox
                    className="checkbox-block"
                    checked={this.state.currentVehicleLoc}
                    onChange={(e) =>
                      this.setState({
                        currentVehicleLoc: e.target.checked,
                      })
                    }
                  >
                    {/* Current Vehicle Location */}
                    {this.context.translate("_CURRENT_VEHICLE_LOCATION_")}
                  </Checkbox>
                  {/* </Form.Item> */}

                  <div
                    style={{
                      display: this.state.currentVehicleLoc ? "" : "none",
                    }}
                  >
                    <Form.Item
                      // label="Vehicle No"
                      label={this.context.translate("_VEHICLE_NO_")}
                      name="vehicleId"
                      style={formItemStyle}
                    >
                      <Select
                        onSelect={(val, record) => {
                          this.setState({ vehicleId: val });
                          this.getInformationOfVehicle({ vehicleId: val });
                        }}
                        showSearch
                        filterOption={(inputValue, option) => {
                          if (option.children) {
                            return (
                              option.children
                                .toLowerCase()
                                .indexOf(inputValue.toLowerCase()) >= 0
                            );
                          }
                        }}
                      >
                        {this.liveTrackingStore?.vehiclePositionArray?.map(
                          (val) => {
                            return (
                              <Option vehicleObj={val} value={val.vehicleId}>
                                {val.vehicleNo}
                              </Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                  </div>
                  <Form.Item
                    // label="Address"
                    label={this.context.translate(
                      "_FLEET_DASHBORD_NEWPOI_ADDRESS_"
                    )}
                    name="address"
                    style={formItemStyle}
                    rules={[{ required: true }]}
                  >
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    // label="Name"
                    label={this.context.translate(
                      "_FLEET_DASHBORD_NEWPOI_NAME_"
                    )}
                    name="geosettingname"
                    style={formItemStyle}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  {/* <Form.Item label=" " name="input5" className="checkbox-block"> */}
                  <br />
                  <Checkbox
                    className="checkbox-block"
                    checked={this.state.selectFormHistory}
                    onChange={(e) =>
                      this.setState({
                        selectFormHistory: e.target.checked,
                      })
                    }
                  >
                    {/* Select Form History */}
                    {this.context.translate("_SELECT_FROM_HISTORY_")}
                  </Checkbox>
                  {/* </Form.Item> */}

                  {this.state.selectFormHistory && (
                    <>
                      <Form.Item
                        // label="Vehicle No"
                        label={this.context.translate("_VEHICLE_NO_")}
                        name="vehicleId2"
                        style={formItemStyle}
                        rules={[{ required: true }]}
                      >
                        <Select
                          onSelect={(val) => {
                            this.setState({ vehicleId: val });
                            this.getInformationOfVehicle({ vehicleId: val });
                          }}
                          showSearch
                          filterOption={(inputValue, option) => {
                            if (option.children) {
                              return (
                                option.children
                                  .toLowerCase()
                                  .indexOf(inputValue.toLowerCase()) >= 0
                              );
                            }
                          }}
                        >
                          {this.liveTrackingStore?.vehiclesArray?.map((val) => {
                            return (
                              <Option value={val.vehicleId}>
                                {val.vehicleNo}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>

                      <Row>
                        <Col span={11}>
                          <Form.Item
                            // label="From Date"
                            label={this.context.translate("_FROM_DATE_")}
                            name="fromDate"
                            style={formItemStyle}
                          >
                            <DatePicker
                              format={"MM-DD-YYYY"}
                              defaultValue={this.state.fromDate}
                              onChange={(val) =>
                                this.setState({
                                  fromDate: val?.format("YYYY-MM-DD"),
                                  toDate: val?.format("YYYY-MM-DD"),
                                })
                              }
                              disabledDate={(current) => {
                                return current && current > dayjs();
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                          <Form.Item
                            // label="To Date"
                            label={this.context.translate("_TO_DATE_")}
                            name="toDate"
                            style={formItemStyle}
                          >
                            <DatePicker
                              format={"MM-DD-YYYY"}
                              defaultValue={this.state.toDate}
                              disabledDate={(current) => {
                                return (
                                  current &&
                                  current < dayjs(this.state.fromDate)
                                ); // Updated condition
                              }}
                              onChange={(val) =>
                                this.setState({
                                  toDate: val?.format("YYYY-MM-DD"),
                                })
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={11}>
                          <Form.Item
                            // label="From Time"
                            label={this.context.translate("_FROM_TIME_")}
                            name="fromTime"
                            style={formItemStyle}
                            initialValue={"00:00:00"}
                          >
                            <Select
                              onChange={this.setFromTime}
                              onSelect={(val) =>
                                this.setState({ fromTime: val })
                              }
                              // value={this.state.fromTime}

                              allowClear={true}
                            >
                              {this.timeOptions()}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                          <Form.Item
                            // label="To Time"
                            label={this.context.translate("_TO_TIME_")}
                            name="toTime"
                            style={formItemStyle}
                            initialValue={dayjs().format("hh:mm A")}
                          >
                            <Select
                              onSelect={(val) => this.setState({ toTime: val })}
                              allowClear={true}
                            >
                              {this.timeOptions()}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Form.Item label=" ">
                        <Button
                          type="primary"
                          onClick={this.LoadClick}
                          style={{ width: "100%" }}
                        >
                          {/* Load */}
                          {this.context.translate("_LOAD_")}
                        </Button>
                      </Form.Item>
                      <Form.Item
                        // label="Stop/Idle Points"
                        label={this.context.translate("_STOP_IDLE_POINTS_")}
                        name="stopIdlePoints"
                        // style={formItemStyle}
                      >
                        <Select
                          onChange={(val, b) => {
                            if (b.latLong?.length > 0) {
                              this.getInformationOfVehicle({
                                latLong: {
                                  latitude: b?.latLong[0],
                                  longitude: b?.latLong[1],
                                },
                              });
                            }
                          }}
                        >
                          {this.liveTrackingStore.stopIdleArray?.map((val) => {
                            return (
                              <Select.Option
                                key={val.vehicleNo}
                                value={val.stopIdleLocation}
                                latLong={[val?.latitude, val?.longitude]}
                                // title={val.stopIdleLocation}
                              >
                                {val.stopIdleLocation}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </>
                  )}

                  <Form.Item
                    // label="Type"
                    label={this.context.translate(
                      "_FLEET_DASHBORD_NEWPOI_TYPE_"
                    )}
                    name="typeName"
                    style={formItemStyle}
                  >
                    <Select>
                      <Select.Option value={1}>
                        {this.context.translate("_RETAILS_")}
                      </Select.Option>
                      <Select.Option value={2}>
                        {this.context.translate("_FOOD_SERVICE_")}
                      </Select.Option>
                      <Select.Option value={3}>
                        {this.context.translate(
                          "_FLEET_DASHBORD_NEWPOI_TYPEDDL_WAREHOUSE_"
                        )}
                      </Select.Option>
                      <Select.Option value={4}>
                        {this.context.translate("_FLEET_CUSTOMER_")}
                      </Select.Option>
                      <Select.Option value={5}>
                        {this.context.translate("_HEAD_OFFICE_")}
                      </Select.Option>
                      <Select.Option value={6}>
                        {this.context.translate("_GARAGE_")}
                      </Select.Option>
                      <Select.Option value={7}>
                        {this.context.translate("_OUTLET_")}
                      </Select.Option>
                      <Select.Option value={8}>
                        {this.context.translate("_BRANCH_")}
                      </Select.Option>
                      <Select.Option value={9}>
                        {this.context.translate("Store Start Point")}
                      </Select.Option>
                      <Select.Option value={10}>
                        {this.context.translate("Common Area")}
                      </Select.Option>
                      <Select.Option value={11}>
                        {this.context.translate("Taxi Zones")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    // label="Speed"
                    label={this.context.translate(
                      "_FLEET_DASHBORD_SETTING_SPEED_"
                    )}
                    name="speed"
                    style={formItemStyle}
                  >
                    <Input />
                  </Form.Item>

                  <AddGeofenceFields />
                </div>

                <div className="formBtnGroup">
                  <Button
                    onClick={async () => {
                      this.mapRef.current.eachLayer((layer) => {
                        this.drawnItemsRef.current.clearLayers(layer);
                        if (layer instanceof L.Marker) {
                          this.mapRef.current.removeLayer(layer);
                        }
                        this.setState({
                          position: [],
                          selectGeofenceObjects: [],
                          geofenceData: [],
                        });
                      });
                      await this.clearFields();
                    }}
                    style={{ fontSize: "11px" }}
                    className="formCancelButton"
                    type="primary"
                  >
                    Discard &<br /> Create New
                    {/* {this.context.translate("_DISCARD_CREATE_NEW_")} */}
                  </Button>

                  <Button
                    className="formCancelButton"
                    onClick={() =>
                      this.mapRef.current.eachLayer((layer) => {
                        if (layer instanceof L.Marker) {
                          this.mapRef.current.removeLayer(layer);
                        }
                        this.setState({
                          drawGeofence: false,
                          editing: false,
                          position: [],
                          selectGeofenceObjects: [],
                          geofenceData: [],
                          drawGeoLocation: "",
                        });
                      })
                    }
                  >
                    {/* Close */}
                    {this.context.translate("_FLEET_CLOSE_BTN_")}
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="formSaveButton"
                  >
                    {/* Submit */}
                    {this.context.translate("_SUBMIT_")}
                  </Button>
                </div>
              </Form>
            </Card>
          </div>
        )}
        {this.state.showGofence && (
          <div>
            <Draggable>
              <Card
                className="right-menu-panel showgeofence headerGroup-panel"
                title={
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="handle" style={{ cursor: "move" }}>
                      {" "}
                      {/* Geofence List */}
                      {this.context.translate("_FLEET_DASHBORD_SHOWGEO_TITLE_")}
                    </div>

                    <CloseOutlined
                      onClick={() => {
                        this.setState({
                          showGofence: false,
                          selectGeofenceObjects: [],
                          geofenceData: [],
                          // company_value:''
                        });
                        // this.liveTrackingStore.geofenceListArray = [];
                      }}
                    />
                  </div>
                }
                style={{ width: 400, padding: "20px" }}
              >
                <Row className="listInputRow">
                  <Space>
                    <CompanyAutocomplete
                      style={{ width: "148px" }}
                      // width={250}
                      onClear={true}
                      value={
                        this.state.company_value
                          ? this.state.company_value
                          : null
                        // &&
                        // this.liveTrackingStore.geofenceListArray.length > 0
                        //   ? this.state.company_value
                        //   : null
                      }
                      onChange={async (value) => {
                        await this.setState({ companyId: value.value });
                        await this.handleGetGofenceList(value);
                      }}
                    />

                    <Input
                      // placeholder="Search Geofence"
                      placeholder={this.context.translate("_SEARCH_GEOFENCE_")}
                      style={{ width: "148px" }}
                      onChange={(e) => {
                        this.setState({ searchGeoFence: e.target.value });
                        this.searchInput(e);
                      }}
                    />
                    {/* <Button type="primary" onClick={async()=> {await this.liveTrackingStore.getGofenceList({ companyId: this.state.companyId,searchField:this.state.searchGeoFence ,}); await this.setState({loadData:1})}}><SearchOutlined/></Button> */}
                    {this.state.selectGeofenceInList?.length === 0 ? (
                      <AntTooltip
                        // title="Delete Geofence"
                        title={this.context.translate(
                          "_FLEET_DASHBORD_SHOWGEO_DELETE_"
                        )}
                        placement="bottom"
                        color={config.tooltipColorDelete}
                      >
                        <Button
                          disabled
                          onClick={(e) => {
                            e.preventDefault();
                            if (this.state.selectGeofenceInList?.length === 0) {
                              Modal.warning({
                                // title: "Alert",
                                // content: "Please Select Vehicles"
                                title: this.context.translate("_FLEET_ALERT_"),
                                content:
                                  this.context.translate("_SELECT_VEHICLES_"),
                              });
                            }
                          }}
                        >
                          <i class="bi bi-trash3"></i>
                        </Button>
                      </AntTooltip>
                    ) : (
                      <AntTooltip
                        // title="Delete Geofence"
                        title={this.context.translate(
                          "_FLEET_DASHBORD_SHOWGEO_DELETE_"
                        )}
                        placement="bottom"
                        color={config.tooltipColorDelete}
                      >
                        <DeleteConfirm
                          type="primary"
                          className="deleteBtn"
                          onConfirm={async (e) => {
                            this.deleteMultipleGeofence(
                              this.state.selectGeofenceInList
                            );
                          }}
                        >
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                this.state.selectGeofenceInList?.length === 0
                              ) {
                                Modal.warning({
                                  title: "Alert",
                                  content: "Please Select Vehicles",
                                });
                              }
                            }}
                          >
                            <i class="bi bi-trash3"></i>
                          </Button>
                        </DeleteConfirm>
                      </AntTooltip>
                    )}
                  </Space>
                </Row>

                <Table
                  // currentPage={this.state.currentPage}
                  // pageSize={this.state.pageSize}
                  // total={this.liveTrackingStore.total}
                  bordered
                  scroll={{ x: 300, y: 280 }}
                  rowSelection={{
                    selectedRowKeys: this.state.selectGeofenceInList,
                    selectedRows: this.state.selectGeofenceRows,
                    onChange: async (selectedRowKeys, selectedRows) => {
                      await this.setState({
                        selectGeofenceInList: selectedRowKeys,
                        selectGeofenceRows: selectedRows,
                      });

                      if (selectedRows) {
                        await this.setPointsInArrayFormat(
                          this.state.selectGeofenceRows
                        );
                      }
                    },
                  }}
                  pagination={{
                    currentPage: this.state.currentPage,
                    pageSize: this.state.pageSize,
                    total: this.liveTrackingStore.total,
                  }}
                  loading={this.liveTrackingStore?.loading}
                  rowKey="zoneId"
                  columns={this.showGeofenceListColumn}
                  dataSource={this.liveTrackingStore.geofenceListArray}
                  onChange={this.handleTableChange}
                />

                <div className="list-bottom-btns">
                  <AddVersionModal
                    handleAddVersionModal={this.handleAddVersionModal}
                    companyId={this.state.companyId}
                  />

                  <Button
                    type="primary"
                    onClick={async () =>
                      await this.setState({
                        showImportExcelModal: true,
                        showGofence: false,
                      })
                    }
                    icon=<i class="bi bi-cloud-upload"></i>
                  ></Button>
                  <Button
                    type="primary"
                    icon=<PlusOutlined />
                    onClick={async () => {
                      await this.setState({ drawGeoLocation: "" });
                      await this.SearchMenuItemClick("drawGeofence");

                      await this.geofenceCardTitle(
                        this.context.translate("_NEW_GEOFENCE_")
                      );
                    }}
                  ></Button>
                </div>
              </Card>
            </Draggable>
          </div>
        )}

        {this.state.navigationWindow && (
          <Draggable handle=".handle">
            <Card
              className="right-menu-panel headerGroup-panel"
              title={
                <div
                  className="handle"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {/* <div> Navigation Window</div> */}
                  <div>{this.context.translate("_NAVIGATION_WINDOW_")}</div>
                  <CloseOutlined
                    onClick={() =>
                      this.setState({
                        navigationWindow: false,
                        origin: null,
                        destination: null,
                        distance: "",
                      })
                    }
                  />
                </div>
              }
            >
              <div className="lbl-field">
                <span>
                  {" "}
                  {this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_HISTORY_FROM_"
                  )}{" "}
                </span>
                <Autocomplete
                  defaultValue={this.state.startLocation}
                  placeholder={this.context.translate("_ENTER_LOCATION_")}
                  ref={this.originInputRef}
                  className="autocomplete-location-textbox"
                  apiKey={config.googleMapKey}
                  onPlaceSelected={(e) => {
                    // console.log(e.geometry.location.lat());
                    this.setState({
                      origin: {
                        lat: e.geometry.location.lat(),
                        lng: e.geometry.location.lng(),
                      },
                    });
                  }}
                  // onPlaceSelected={(e) => {
                  //   console.log(e);
                  // }}
                />
              </div>
              <div className="lbl-field">
                {/* <span> To </span> */}
                <span>
                  {" "}
                  {this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_HISTORY_TO_"
                  )}{" "}
                </span>
                <Autocomplete
                  defaultValue={this.state.endLocation}
                  placeholder={this.context.translate("_ENTER_LOCATION_")}
                  ref={this.destinationInputRef}
                  className="autocomplete-location-textbox"
                  apiKey={config.googleMapKey}
                  onPlaceSelected={(e) => {
                    // this.setState({ destination: e.formatted_address });
                    this.setState({
                      destination: {
                        lat: e.geometry.location.lat(),
                        lng: e.geometry.location.lng(),
                      },
                    });

                    // console.log(e.formatted_address);
                  }}
                  // onPlaceSelected={(e) => {
                  //   console.log(e);
                  // }}
                />
              </div>
              {this.state.showCalculatedDist && (
                <div className="distance-total-block">
                  <p>
                    {/* <b>Distance between them : </b> {this.state.distance} */}
                    <b>{this.context.translate("_DISTANCE_BETWEEN_THEM_")}: </b>{" "}
                    {this.state.distance ? `${this.state.distance} km` : ""}
                  </p>
                </div>
              )}
              <div className="formBtnGroup">
                <Button
                  className="formCancelButton"
                  onClick={() => {
                    this.handleClearFields();
                    this.setState({
                      getDirection: false,
                      destination: "",
                      origin: "",
                      startLocation: undefined,
                      endLocation: undefined,
                      showCalculatedDist: false,
                    });
                  }}
                >
                  {/* Clear */}
                  {this.context.translate("_FLEET_CLEAR_BTN_")}
                </Button>
                <Button
                  style={{ fontSize: "13px", padding: "0px" }}
                  type="primary"
                  className="formSaveButton"
                  onClick={() =>
                    this.setState({
                      getDirection: true,
                      showCalculatedDist: true,
                    })
                  }
                >
                  {/* Get Direction */}
                  {this.context.translate("_GET_DIRECTION_")}
                </Button>
              </div>
            </Card>
          </Draggable>
        )}
        {(this.state.SearchAssetPopup || this.state.SearchDriverPopup) && (
          <Draggable handle=".handle">
            <Card
              className="right-menu-panel headerGroup-panel"
              title={
                <div
                  className="handle"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    {" "}
                    {this.state.SearchAssetPopup
                      ? // "Search Asset"
                        // this.context.translate(
                        //   "_FLEET_DASHBORD_SEARCHVEHICLE_TITLE_"
                        // )
                        this.context.translate("_SEARCH_ASSET_")
                      : // "Search Driver"
                        this.context.translate("_SEARCH_DRIVER_")}
                  </div>
                  <CloseOutlined
                    onClick={() => this.handleCancel("SearchAssetPopup")}
                  />
                </div>
              }
            >
              <Form
                layout="vertical"
                name="control-hooks"
                ref={this.searchAssetformRef}
              >
                {(this.props.getUserData().isadmin === -1 ||
                  this.props.getUserData().isadmin === 3) && (
                  <Form.Item
                    name="SelectAccount"
                    // label="Account"
                    label={this.context.translate(
                      "_FLEET_DASHBORD_NEWPOI_CUSTOMER_"
                    )}
                    style={{ marginBottom: "2px", marginTop: "2px" }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <CompanyAutocomplete
                      onChange={async (value) => {
                        await this.handleCompId(value);
                        this.searchAssetformRef.current.setFieldsValue({
                          SelectVehicle: undefined,
                          SelectDriver: undefined,
                        });
                        // if (this.state.companyId === "") {
                        //   this.setState({
                        //     vehicleId: [],
                        //   });
                        // }
                      }}
                    />
                  </Form.Item>
                )}

                <div className="radioBtnGroup">
                  {/* <span>Search By</span> */}
                  <span>{this.context.translate("_SEARCHBY_")}</span>
                  <Radio.Group
                    defaultValue={this.state.SearchAssetPopup ? 1 : 2}
                    onChange={(e) => {
                      this.selectedSearchFunc(e);
                    }}
                  >
                    <Radio value={1}>
                      {this.context.translate(
                        "_FLEET_DASHBORD_SEARCHVEHICLE_VEHICLE_"
                      )}
                    </Radio>
                    <Radio value={2}>
                      {this.context.translate(
                        "_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"
                      )}
                    </Radio>
                  </Radio.Group>
                </div>

                {this.state.SearchAssetPopup ? (
                  <Form.Item
                    name="SelectVehicle"
                    label={this.context.translate(
                      "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                    )}
                    style={{ marginBottom: "2px", marginTop: "2px" }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      placeholder={this.context.translate("_SELECT_VEHICLE_")}
                      onSelect={this.handleVehicleChange}
                      disabled={this.state.vehicleDisable}
                      filterOption={(inputValue, option) => {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {this.state.vehicleList?.map((vehicle) => {
                        return (
                          <Option
                            key={vehicle.vehicleId}
                            value={parseInt(vehicle.vehicleId)}
                            // value={vehicle}
                          >
                            {vehicle.vehicleNo}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="SelectDriver"
                    // label="Driver Name"
                    label={this.context.translate(
                      "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
                    )}
                    style={{ marginBottom: "2px", marginTop: "2px" }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      placeholder={this.context.translate("_SELECT_DRIVER_")}
                      onSelect={this.handleDriverChange}
                      filterOption={(inputValue, option) => {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {this.state.DriverList?.map((d) => {
                        return (
                          <Option
                            key={d.driverId}
                            // value={parseInt(d.driverId)}
                            value={d.driverId}
                          >
                            {d.driverName}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )}

                <div className="formBtnGroup">
                  <Button
                    className="formCancelButton"
                    onClick={() => {
                      this.handleCancel("SearchAssetPopup");
                      this.setState({
                        selectedVehicles: "",
                        selectedDriver: "",
                      });
                      // this.searchAssetformRef.current.setFieldsValue({SelectVehicle:undefined,SelectDriver:undefined,SelectAccount:undefined})
                    }}
                  >
                    {/* Close */}
                    {this.context.translate("_FLEET_CLOSE_BTN_")}
                  </Button>
                  <Button
                    className="formSaveButton"
                    type="primary"
                    disabled={
                      this.state.selectedVehicles || this.state.selectedDriver
                        ? false
                        : true
                    }
                    htmlType="submit"
                    onClick={() => this.SearchAssetFunc()}
                  >
                    {/* Search */}
                    {this.context.translate("_SEARCH_")}
                  </Button>
                </div>
              </Form>
            </Card>
          </Draggable>
        )}

        {this.state.searchAddressWindowShow && (
          <Draggable handle=".handle">
            <Card
              className="right-menu-panel headerGroup-panel"
              title={
                <div
                  className="handle"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {/* <div> Search Address</div> */}
                  <div>{this.context.translate("_SEARCH_ADDRESS_")}</div>
                  <CloseOutlined
                    onClick={() => this.handleCancel("searchAddressWindowShow")}
                  />
                </div>
              }
            >
              <Autocomplete
                className="autocomplete-location-textbox"
                componentRestrictions={{ country: "us" }}
                options={{
                  types: ["geocode", "establishment"],
                }}
                apiKey={config.googleMapKey}
                onPlaceSelected={(e) => {
                  // this.setState({latitude: e.geometry.location.lat(),longitude:e.geometry.location.lng()})
                  this.getInformationOfVehicle({
                    latLong: {
                      latitude: e.geometry.location.lat(),
                      longitude: e.geometry.location.lng(),
                    },
                  });
                }}
              />
            </Card>
          </Draggable>
        )}

        {this.state.showImportExcelModal && (
          <Card
            className="right-menu-panel headerGroup-panel"
            title={
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {/* <div>Import From Excel</div>{" "} */}
                <div>
                  {this.context.translate("_IMPORT_EXPORT_EXCEL_")}
                </div>{" "}
                <CloseOutlined
                  onClick={() => this.setState({ showImportExcelModal: false })}
                />
              </div>
            }
          >
            <Form
              ref={this.formRef}
              name="excelimport"
              layout="vertical"
              onFinish={this.onImportExcelSubmit}
              style={{ maxWidth: "100%" }}
            >
              {this.props.getUserData().isadmin === -1 && (
                <>
                  <Form.Item
                    name="companyId"
                    className="form-fields"
                    label={this.context.translate("_ACCOUNT_")}
                    rules={[{ required: true }]}
                  >
                    <CompanyAutocomplete
                      onChange={(companyId) => {
                        this.setState({ excelCompanyId: companyId.value });
                      }}
                    />
                  </Form.Item>

                  <br />
                  <br />
                </>
              )}
              {this.props.getUserData().isadmin === 1 && <br />}
              <Form.Item
                className="form-fields"
                name="excelFile"
                // label="Excel File"
                rules={[
                  {
                    required: true,
                    message: this.context.translate("_ENTER_EXCEL_"),
                  },
                ]}
              >
                <Upload
                  name="excelFile"
                  action={config.baseURL + "/index.php?c=company&a=fileupload"}
                  listType="text"
                  data={{ fileType: "accountimport", fieldName: "excelFile" }}
                  onChange={this.getUploadData}
                  maxCount={1}
                  // accept=".excel"
                >
                  <Button icon={<UploadOutlined />}>
                    {/* Click To Upload Excel File */}
                    {this.context.translate("_CLICKTOUPLOAD_EXCELFILE_")}
                  </Button>
                </Upload>
              </Form.Item>

              <div className="formBtnGroup">
                <Button
                  className="formCancelButton"
                  key="Sample"
                  // style={{ backgroundColor: config.activeColor }}
                  onClick={() => {
                    this.liveTrackingStore.sampleExcel();
                    // this.setState({ showImportExcelModal: false });
                  }}
                >
                  {/* Sample */}
                  {this.context.translate("_SAMPLE_")}
                </Button>

                <Button
                  className="formSaveButton"
                  type="primary"
                  htmlType="submit"
                  // loading={this.liveTrackingStore.excelLoadig}
                  // onClick={() => {this.setState({ showImportExcelModal: false })}}
                >
                  {/* Upload */}
                  {this.context.translate("_UPLOAD_")}
                </Button>

                <Button
                  className="formCancelButton"
                  type="primary"
                  onClick={() => {
                    this.setState({ showImportExcelModal: false });
                  }}
                >
                  {/* Cancel */}
                  {this.context.translate("_FLEET_DASHBORD_SHOWPOI_CANCELBTN_")}
                </Button>
              </div>
            </Form>
          </Card>
        )}
        {this.state.sendCommandPopUp && (
          <SendCommandPopup
            vehicleInfoObj={this.state.vehicleOtherInfo}
            sendCommandPopUpBoolean={this.state.sendCommandPopUp}
            sendCommandPopUpFunc={(resp) =>
              this.setState({ sendCommandPopUp: resp })
            }
          />
        )}

        {this.state.historyTrackingWindow && (
          <HistoryTracking
            vehicleInfoObj={this.state.vehicleOtherInfo}
            trackingMapBoolean={this.state.trackingMapBoolean}
            closeTrackingMapWindow={(resp) =>
              this.setState({ historyTrackingWindow: resp })
            }
            mapRef={this.mapRef}
          />
        )}

        {this.state.keepAnEyeModal && (
          <MapForVehiclePosition
            showTooltip={this.state.keepAnEyeModal}
            vehicleObj={this.state.vehicleOtherInfo}
            heading={this.state.vehicleOtherInfo?.vehicleNo}
            closeMapModal={(resp) => {
              this.setState({ keepAnEyeModal: resp });
            }}
          />
        )}

        {this.state.markerClusterGroupPopup && (
          <MarkerClusterPopup infoObj={this.state.markerClusterGroupPopup} />
        )}
        <div
          className="handle"
          style={{ display: "flex", flexDirection: "row", left: "10%" }}
        >
          {this.state.vehicleOtherInfo && (
            <VehicleOtherInfo
              This={this}
              update={this.state.update}
              info={this.state.vehicleOtherInfo}
              daySummary={this.liveTrackingStore.daySummaryObj}
              vehicleObj={this.state.vehicleDetailsShow}
              driverPerformanceObj={
                this.liveTrackingStore.driverPerformanceData
              }
              mapRef={this.mapRef}
            />
          )}
          <div
            style={{
              top: "5%",
              backgroundColor: "rgba(24, 23, 34, 0.50)",
              color: "white",
              position: "absolute",
              zIndex: "999",
              width: "20%",
            }}
          >
            {this.state.vehicleOtherInfo && (
              <FloatButton.Group
                shape="square"
                style={{
                  zIndex: "999",
                  top: "60%",
                  insetInlineStart: "126%",
                  position: "absolute",
                }}
              >
                <AntTooltip
                  title={this.context.translate("_HISTORY_")}
                  placement="right"
                  color="green"
                >
                  <FloatButton
                    icon={<i class="bi bi-clock-history"></i>}
                    onClick={() =>
                      this.setState({
                        historyTrackingWindow: true,
                        liveTrackingMainModal: false,
                      })
                    }
                  />
                </AntTooltip>
                <AntTooltip
                  title={this.context.translate("_KEEP_AN_EYE_")}
                  placement="right"
                  color="green"
                >
                  <FloatButton
                    icon={<i class="bi bi-eye"></i>}
                    onClick={() =>
                      this.setState({
                        keepAnEyeModal: true,
                        liveTrackingMainModal: false,
                      })
                    }
                  />
                </AntTooltip>

                <AntTooltip
                  title={this.context.translate("_SEND_COMMAND_")}
                  placement="right"
                  color="green"
                >
                  <FloatButton
                    icon={<i class="bi bi-send"></i>}
                    onClick={() =>
                      this.setState({
                        sendCommandPopUp: true,
                        liveTrackingMainModal: false,
                      })
                    }
                  />
                </AntTooltip>
                <AntTooltip
                  title={this.context.translate("_SHARE_LIVE_LOCATION_")}
                  placement="right"
                  color="green"
                >
                  <FloatButton
                    icon={<i class="bi bi-geo-alt"></i>}
                    onClick={() => {
                      this.setState({ openLiveLocationModal: true });
                      this.getLiveLocationUrl();
                    }}
                  />
                </AntTooltip>

                {[6, 13, 18].includes(
                  this.state.vehicleStatusInfo?.deviceId
                ) && (
                  <>
                    <Popover
                      placement="right"
                      content={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <Button
                            style={{ border: "none" }}
                            onClick={() => this.handleVideoMonitoringData(1)}
                          >
                            {this.context.translate("_CHANNEL1_")}
                          </Button>
                          <Button
                            style={{ border: "none" }}
                            onClick={() => this.handleVideoMonitoringData(2)}
                          >
                            {this.context.translate("_CHANNEL2_")}
                          </Button>
                          <Button
                            style={{ border: "none" }}
                            onClick={() => this.handleVideoMonitoringData(3)}
                          >
                            {this.context.translate("_CHANNEL3_")}
                          </Button>
                          <Button
                            style={{ border: "none" }}
                            onClick={() => this.handleVideoMonitoringData(4)}
                          >
                            {this.context.translate("_CHANNEL4_")}
                          </Button>
                        </div>
                      }
                    >
                      <FloatButton
                        icon={<i class="bi bi-camera"></i>}
                      ></FloatButton>
                    </Popover>
                  </>
                )}
              </FloatButton.Group>
            )}
          </div>
        </div>

        {this.state.openLiveLocationModal && (
          <Draggable>
            <Modal
              open={this.state.openLiveLocationModal}
              maskClosable={false}
              // title="File Upload"
              title={this.context.translate("_LIVE_LOCATION_")}
              onOk={() => this.setState({ openLiveLocationModal: false })}
              onCancel={() => {
                this.setState({ openLiveLocationModal: false });
              }}
              footer={false}
              width={400}
            >
              <Form
                ref={this.livelocationFormRef}
                name="livelocation"
                layout="vertical"
                onFinish={this.onLiveLocationFormSubmit}
                initialValues={{ expiryDate: dayjs() }}
                // className="formClass"
              >
                <Form.Item
                  name="expiryDate"
                  label={this.context.translate("_EXPIRY_DATE_")}
                >
                  <DatePicker
                    format={"YYYY-MM-DD"}
                    style={{ width: "100%" }}
                  ></DatePicker>
                </Form.Item>

                <Form.Item
                  name="startTime"
                  label={this.context.translate("_FLEET_START_TIME_")}
                  rules={[{ required: true }]}
                >
                  <Select>{this.timeOptionsForLiveLocation()}</Select>
                </Form.Item>

                <Form.Item
                  name="endTime"
                  label={this.context.translate("_END_TIME_")}
                  rules={[{ required: true }]}
                >
                  <Select>{this.timeOptionsForLiveLocation()}</Select>
                </Form.Item>

                {this.state.locationUrl.Id !== -1 && (
                  <Form.Item
                    name="locationUrl"
                    label="Link"
                    // rules={[{ required: true }]}
                  >
                    <Input></Input>
                  </Form.Item>
                )}

                <div className="formBtnGroup">
                  <Button
                    type="primary"
                    className="formSaveButton"
                    htmlType="submit"
                  >
                    {this.context.translate(
                      "_FLEET_CREATEROUTE_ROUTEADD_SAVEBTN_"
                    )}
                  </Button>
                  <Button
                    className="formCancelButton"
                    onClick={() => {
                      this.setState({ shareModalVisible: true });
                    }}
                  >
                    {this.context.translate("_FLEET_SERVICE_POILIST_SHARE_")}
                  </Button>
                  <Button
                    className="formCancelButton"
                    onClick={() => {
                      this.setState({ openLiveLocationModal: false });
                    }}
                  >
                    {this.context.translate("_FLEET_CLOSE_BTN_")}
                  </Button>
                </div>
              </Form>
              {/* </Card> */}
            </Modal>
          </Draggable>
        )}

        {this.state.shareModalVisible && (
          <ShareModalWindow
            shareModalCloseFunc={(resp) => {
              this.setState({ shareModalVisible: resp });
            }}
            shareSingleLocation={this.state.locationUrl.locationUrl}
            shareLiveLocation={this.state.locationUrl.locationUrl}
          />
        )}

        <Modal
          width={400}
          title={this.context.translate("_ADD_VERSION_")}
          open={this.state.openAddVersionModal}
          onCancel={() => this.setState({ openAddVersionModal: false })}
          footer={null}
        >
          <Form layout="vertical" onFinish={this.handleAddVersionForm}>
            <Form.Item
              label={this.context.translate("_VERSION_")}
              name="reason"
              rules={[{ required: true }]}
            >
              <Input></Input>
            </Form.Item>

            <Form.Item label="CRC32" name="reason" rules={[{ required: true }]}>
              <Input></Input>
            </Form.Item>

            <div className="formBtnGroup">
              <Button
                type="primary"
                htmlType="submit"
                style={{ height: "40px", marginInlineEnd: "10px" }}
              >
                Gennerate CRC32
              </Button>

              <Button
                type="primary"
                // className="formSaveButton"
                htmlType="submit"
                style={{
                  // color: "#fff",
                  // backgroundColor: "#4096ff",
                  width: "105px",
                  height: "40px",
                }}
                // loading={this.state.loading}
              >
                {this.context.translate("_DOWNLOAD_")}
              </Button>
            </div>
          </Form>
        </Modal>

        <div
          style={{
            position: "absolute",
            bottom: "7%",
            display: "flex",
            gap: "16px",
            justifyContent: "center",
            width: "100%",
            insetInlineStart: "235px",
          }}
        >
          {Object.keys(this.state.videoMonitoringUrl || {}).map((channelId) => (
            <Card
              key={channelId}
              title={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "18px",
                  }}
                >
                  {`Channel ${channelId}`}
                  <CloseOutlined
                    onClick={() => this.handleCloseChannel(channelId)}
                    style={{ cursor: "pointer", color: "black" }}
                  />
                </div>
              }
              style={{
                width: 250,
              }}
              bordered={true}
            >
              {this.state.videoMonitoringUrl[channelId] && (
                <iframe
                  src={this.state.videoMonitoringUrl[channelId]}
                  width="100%"
                  height="200px"
                  frameBorder="0"
                  scrolling="no"
                  allow="fullscreen"
                />
              )}
            </Card>
          ))}
        </div>
      </div>
    );
  }
}

LiveTracking.contextType = StoreContext;
export default observer(withRouter(LiveTracking));
